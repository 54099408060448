import { NotFoundError } from '@magicdoor/errors';
import fontkit from 'pdf-fontkit';
import { PDFDocument } from 'pdf-lib';
import RegularFont from '~/assets/fonts/NotoSansSC-Regular.ttf';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { dateFormat } from '~/utils/date';
import { PdfLoader } from './pdfLoader';
import type { PDFPage } from 'pdf-lib';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';
import type { Document } from '~/pdfsigner/usecases/types/document';
import type { LeaseDraftCreationGatewayRequest } from '~/pdfsigner/usecases/types/leaseDraftCreationGatewayRequest';
import type { DocumentStatus, PaginatedDocumentDescriptor } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

const DRAFT_API = '/api/lease-documents';

interface SignerMap {
  map: { [placeholderId: string]: Signer };
  unmatched: Signer[];
}

interface MergedAnnotatedDocument {
  document: Blob;
  annotations: any[];
  signers: Signer[];
}

class LeaseDraftRepository extends BaseRestRepository {
  public async getLeaseDraft(id: string): Promise<Document> {
    const url = `${DRAFT_API}/${id}`;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    const document = this.createDocumentFromJson(json);
    return document;
  }

  public async getLeaseDrafts(leaseId: string, pageNumber: number): Promise<PaginatedDocumentDescriptor> {
    const url = `${DRAFT_API}?LeaseId=${leaseId}&PageNumber=${pageNumber}`;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    const result: PaginatedDocumentDescriptor = {
      page: json.page,
      pageSize: json.pageSize,
      totalPages: json.totalCount,
      items: json.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        signedUrl: this.getSignedUrl(item.fileId, json.files),
        status: item.status,
      })),
    };
    return result;
  }

  public async saveDraft(pdfs: Document): Promise<string> {
    const url = `${DRAFT_API}/${pdfs.id}`;
    const signers = this.signersToJson(pdfs.signers);
    const body = {
      name: pdfs.title,
      signers,
      annotations: this.getAnnotationsArray(pdfs.pdfPages),
    };
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    const json = await this.getJsonResponse(response);
    return json.id;
  }

  public async createLeaseDraft(request: LeaseDraftCreationGatewayRequest): Promise<string> {
    const url = DRAFT_API;
    const formData = new FormData();
    const mergedDocument = await this.createMergedAnnotatedDocument(request);
    formData.append('LeaseId', request.leaseId);
    formData.append('Name', request.title);
    request.leaseTemplates.forEach((template) => {
      formData.append('LeaseTemplateIds[]', template.id || '');
    });
    formData.append('File', mergedDocument.document, `${request.title}.pdf`);
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const json = await this.getJsonResponse(response);
    const id = json.id;
    await this.fetchWithAuth(`${url}/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name: request.title,
        signers: this.signersToJson(request.signers),
        annotations: mergedDocument.annotations,
      }),
    });
    return id;
  }

  public async sendForSigning(draftId: string): Promise<DocumentStatus> {
    const url = `${DRAFT_API}/${draftId}/begin-signing`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ leaseDocumentId: draftId }),
    });
    const json = await this.getJsonResponse(response);
    return json.status;
  }

  public async deleteLeaseDraft(leaseDraftId: string): Promise<void> {
    const url = `${DRAFT_API}/${leaseDraftId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  private getSignedUrl(fileId: string, files: any[]): string | undefined {
    const file = files.find((f) => f.fileId === fileId);
    return file ? '/api' + file.signedUrl : undefined;
  }

  private signersToJson(signers: Signer[]): any[] {
    return signers.map((signer) => ({
      id: signer.id,
      name: signer.name,
      type: signer.isPropertyManager ? 'propertyManager' : 'tenant',
      signatureName: signer.signatureName,
      signatureInitials: signer.signatureInitials,
    }));
  }

  private async createMergedAnnotatedDocument(request: LeaseDraftCreationGatewayRequest): Promise<MergedAnnotatedDocument> {
    const mergedPdf = await PDFDocument.create();
    const defaultFontSize = 10;
    mergedPdf.registerFontkit(fontkit);
    const fontBytes = await fetch(RegularFont).then((res) => res.arrayBuffer());
    const font = await mergedPdf.embedFont(fontBytes, { subset: true });
    const annotations: any[] = [];
    let currentPage = 1;
    let startPage = 1;
    for (const template of request.leaseTemplates) {
      const templateSigners = this.getSignersFromTemplate(template);
      const signerMap = this.matchSigners(templateSigners, request.signers);
      startPage = currentPage;
      for (const pdf of template.pdfs) {
        const loadedPDF = await PDFDocument.load(await pdf.getData(), { ignoreEncryption: true });
        const pages = await mergedPdf.copyPages(loadedPDF, loadedPDF.getPageIndices());
        pages.forEach((page: PDFPage) => {
          mergedPdf.addPage(page);
        });
        for (const annotatedPage of template.pdfPages) {
          for (const annotation of annotatedPage.annotations) {
            if (!annotation.signerId || (annotation.signerId && signerMap.map[annotation.signerId])) {
              annotations.push({
                id: annotation?.id,
                signerId: annotation?.signerId ? signerMap.map[annotation.signerId]?.id : undefined,
                page: currentPage,
                x: annotation.x,
                y: annotation.y,
                width: annotation.width,
                height: annotation.height,
                text: annotation.text,
                type: annotation.type,
                dataPath: annotation.dataPathKey,
                fontSize: annotation.fontSize,
              });
            }
          }
          currentPage++;
        }
        if (signerMap.unmatched.length > 0) {
          let newPage = mergedPdf.addPage();
          let yPosition = newPage.getHeight() - 50;
          let pageRangeString = `${startPage}`;
          if (startPage !== currentPage - 1) {
            pageRangeString += ` - ${currentPage - 1}`;
          }
          newPage.drawText(`Additional signatures for ${template.title} pages ${pageRangeString}:`, {
            x: 50,
            y: yPosition,
            size: 14,
            font,
          });
          for (const signer of signerMap.unmatched) {
            yPosition -= 25;
            if (yPosition <= 50) {
              newPage = mergedPdf.addPage();
              yPosition = newPage.getHeight() - 50;
              currentPage++;
            }
            const nameText = `${signer.name}:`;
            newPage.drawText(nameText, { x: 50, y: yPosition, size: defaultFontSize, font });
            const time: string = JSON.stringify(new Date()).replace(/\D/g, '');
            const nameWidth = font.widthOfTextAtSize(nameText, defaultFontSize);
            const signatureX = 50 + nameWidth + 5;
            const signatureY = newPage.getHeight() - yPosition - 11;
            annotations.push({
              id: `${time}${signatureX}${signatureY}${currentPage}`,
              signerId: signer.id,
              page: currentPage,
              x: signatureX / newPage.getWidth(),
              y: signatureY / newPage.getHeight(),
              width: 0.2,
              height: 0.018,
              text: signer.name,
              type: AnnotationType.SIGNATURE,
              fontSize: defaultFontSize,
            });
            yPosition -= 15;
            const dateString = 'Date:';
            newPage.drawText(dateString, { x: 50, y: yPosition, size: defaultFontSize, font });
            const dateWidth = font.widthOfTextAtSize(dateString, defaultFontSize);
            const dateX = 50 + dateWidth + 5;
            const dateY = newPage.getHeight() - yPosition - 11;
            annotations.push({
              id: `${JSON.stringify(new Date()).replace(/\D/g, '')}${dateX}${dateY}${currentPage}`,
              signerId: signer.id,
              page: currentPage,
              x: dateX / newPage.getWidth(),
              y: dateY / newPage.getHeight(),
              width: 0.2,
              height: 0.018,
              text: dateFormat('MM/DD/YYYY hh:mm', new Date()),
              type: AnnotationType.DATE,
              fontSize: defaultFontSize,
            });
          }
          currentPage++;
        }
      }
    }
    return {
      signers: request.signers,
      document: new Blob([await mergedPdf.save()], { type: 'application/pdf' }),
      annotations,
    };
  }
  private getSignersFromTemplate(template: Document): Signer[] {
    const signersMap = new Map<string, Signer>();
    const templateSignersMap = new Map<string, Signer>();
    for (const signer of template.signers) {
      templateSignersMap.set(signer.id, signer);
    }
    for (const page of template.pdfPages) {
      for (const annotation of page.annotations) {
        if (annotation.signerId && !signersMap.has(annotation.signerId)) {
          const templateSigner = templateSignersMap.get(annotation.signerId);
          if (templateSigner) {
            signersMap.set(annotation.signerId, templateSigner);
          } else {
            signersMap.set(annotation.signerId, {
              id: annotation.signerId,
              name: '',
              isPropertyManager: false,
              signatureInitials: '',
              signatureName: '',
            });
          }
        }
      }
    }
    const signersArray = Array.from(signersMap.values());
    signersArray.sort((a, b) => {
      const nameCompare = a.name.localeCompare(b.name);
      if (nameCompare !== 0) return nameCompare;
      if (a.isPropertyManager && !b.isPropertyManager) return 1;
      if (!a.isPropertyManager && b.isPropertyManager) return -1;
      return 0;
    });
    return signersArray;
  }

  private matchSigners(placeholders: Signer[], actuals: Signer[]): SignerMap {
    const map: { [placeholderId: string]: Signer } = {};
    const unmatched: Signer[] = [];
    const usedPlaceholders = new Set<string>();
    const hasPMInPlaceholders = placeholders.some((p) => p.isPropertyManager);
    const pmPlaceholders = placeholders.filter((p) => p.isPropertyManager);
    const nonPmPlaceholders = placeholders.filter((p) => !p.isPropertyManager);

    actuals.forEach((actual) => {
      let matched = false;
      const targetPlaceholders = actual.isPropertyManager && hasPMInPlaceholders ? pmPlaceholders : nonPmPlaceholders;
      for (const placeholder of targetPlaceholders) {
        if (!usedPlaceholders.has(placeholder.id)) {
          map[placeholder.id] = actual;
          usedPlaceholders.add(placeholder.id);
          matched = true;
          break;
        }
      }
      if (!matched) {
        unmatched.push(actual);
      }
    });

    if (!hasPMInPlaceholders) {
      const availableNonPmPlaceholders = nonPmPlaceholders.filter((np) => !usedPlaceholders.has(np.id));
      unmatched.forEach((actual, index) => {
        if (actual.isPropertyManager && availableNonPmPlaceholders.length > 0) {
          const placeholder = availableNonPmPlaceholders.shift();
          if (placeholder) {
            map[placeholder.id] = actual;
            usedPlaceholders.add(placeholder.id);
            unmatched.splice(index, 1);
          }
        }
      });
    }
    return { map, unmatched };
  }

  private getAnnotationsArray(annotatedPdfs: AnnotatedPdfPage[]): any[] {
    return annotatedPdfs.flatMap((page, index) =>
      page.annotations.map((annotation) => ({
        id: annotation.id,
        signerId: annotation.signerId,
        page: index + 1,
        x: annotation.x,
        y: annotation.y,
        width: annotation.width,
        height: annotation.height,
        text: annotation.text,
        type: annotation.type,
        dataPath: annotation.dataPathKey,
        fontSize: annotation.fontSize,
        isInputField: !!annotation.isInputField,
      }))
    );
  }

  private mapToDataPathNode = (node: any): DataPathNode => ({
    key: node.key,
    name: node.name,
    canSelect: node.type !== 'none',
    children: node.children ? node.children.map(this.mapToDataPathNode) : undefined,
  });

  private async createDocumentFromJson(json: any): Promise<Document> {
    const data: Document = {
      pdfs: [],
      pdfPages: [],
      signers: [],
      status: json.status,
      leaseId: json.leaseId,
      signedUrl: json.file.signedUrl,
    };
    data.id = json.id;
    data.category = {
      id: json.leaseTemplateCategoryId,
      name: '',
      isDefault: false,
    };
    data.signers = json.signers.map(
      (signer: any) =>
        ({
          id: signer.id,
          name: signer.name,
          isPropertyManager: signer.type === 'propertyManager',
          signatureInitials: signer.signatureInitials,
          signatureName: signer.signatureName,
          hasSigned: signer.signatureStatus === 'signed',
        } as Signer)
    );
    data.title = json.name;
    const fileUrl = `/api${json.file.signedUrl}`;
    const pdfLoader = new PdfLoader();
    const pdf = await pdfLoader.loadPdf(fileUrl);
    if (!pdf) {
      throw new NotFoundError();
    }
    data.pdfs.push(pdf);
    data.pdfPages = await pdfLoader.getPdfPages(pdf);
    const annotations: any[] = json.annotations;
    annotations.forEach((annotation: any) => {
      const page = data.pdfPages[annotation.page - 1];
      if (!page) {
        return;
      }
      page.annotations.push({
        id: annotation.id,
        x: annotation.x,
        y: annotation.y,
        width: annotation.width,
        height: annotation.height,
        text: annotation.text,
        type: annotation.type,
        dataPathKey: annotation.dataPath,
        signerId: annotation.signerId,
        fontSize: annotation.fontSize,
        isInputField: !!annotation.isInputField,
      });
    });
    return data;
  }

  public async sendReminders(draftId: string): Promise<void> {
    const url = `${DRAFT_API}/${draftId}/resend-notification`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ leaseDocumentId: draftId }),
    });
    await this.getJsonResponse(response);
  }
}

export const leaseDraftRepository = new LeaseDraftRepository();
