import { A, useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { PropertyTitle } from '~/components/properties';
import { Table } from '~/components/ui/Table';
import { useLocalization, useManagementFee } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { currency } from '~/utils/number';

export const ManagementFeeDetail = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const breadcrumbItems = createMemo(() => [
    { label: t('Management fee'), link: '/accounting/company-revenue/management-fees' },
    { label: t('Detail') },
  ]);

  const params = useParams<{ managementFeeId: string }>();
  const { getManagementFee } = useManagementFee();
  const [managementFee, setManagementFee] = createSignal<MagicDoor.Api.HydratedBillDto>();

  onMount(async () => {
    const data = await getManagementFee(params.managementFeeId);
    setManagementFee(data);
  });

  return (
    <div class="relative flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="m-8 grid grid-cols-12 gap-4">
        <Panel class="col-span-full lg:col-span-4" title={t('Management fee detail')}>
          <div class="flex flex-col gap-3 p-4">
            <A
              href={`/portfolios/${managementFee()?.portfolioId}/properties/${managementFee()?.propertyId}`}
              class="flex w-full cursor-pointer justify-center overflow-hidden rounded-lg bg-inputbox-bg p-2">
              <PropertyTitle property={managementFee()?.property ?? undefined} feedbackIconReadonly />
            </A>
            <div class="flex justify-between">
              <span class="text-text-level02"> {t('Total amount')}: </span>
              <span class="font-medium text-title-gray">{currency(managementFee()?.totalAmount)}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-text-level02"> {t('Due')}: </span>{' '}
              <span class="font-medium text-title-gray">{currency(managementFee()?.due)}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-text-level02"> {t('Paid')}: </span>
              <span class="font-medium text-title-gray">{currency(managementFee()?.paid)}</span>
            </div>
          </div>
        </Panel>
        <Panel class="col-span-full lg:col-span-8" title={t('Bill detail')}>
          <Table
            data={managementFee()?.lineItems}
            columns={[
              {
                title: t('Chart of account'),
                render: (item) => <div>{item.chartOfAccount.name}</div>,
              },
              {
                title: t('Description'),
                render: (item) => <div>{item.description}</div>,
              },
              {
                title: t('Paid'),
                render: (item) => <div>{currency(item.paid)}</div>,
              },
              {
                title: t('Amount'),
                render: (item) => <div>{currency(item.amount)}</div>,
              },
            ]}
          />
        </Panel>
      </div>
    </div>
  );
};
