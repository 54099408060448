import { createMemo, createSignal, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { TenantTitle } from '~/components/tenants';
import { ListView, toast } from '~/components/ui';
import { useDepositSlips, useLocalization } from '~/contexts/global';
import { useBankAccountsList } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { getTenant } from '~/pages/deposit-slips/utils';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { FilterItems } from '~/components/ui';
import type { TransactionsFilter } from '~/repositories/depositSlipsRepository';

type FilterValue = {
  bankAccountId?: string;
  startDate?: string;
  endDate?: string;
};

export const AddDepositSlipView = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const breadcrumbItems = createMemo(() => [
    { label: t('Deposit slips'), link: '/accounting/deposit-slips' },
    { label: t('Create new deposit slip') },
  ]);

  const { getTransactions, addDepositSlip } = useDepositSlips();
  const { bankAccounts } = useBankAccountsList();
  const [transactionIds, setTransactionIds] = createSignal<MagicDoor.Api.TransactionDto[]>([]);
  const [transactions, setTransactions] = createSignal<MagicDoor.Api.TransactionsDto>();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});

  const disableNext = createMemo<boolean>(() => !transactionIds().length);

  const bankAccountOptions = createMemo(() =>
    (bankAccounts() || []).map((item) => ({
      label: item.name,
      value: item.id,
    }))
  );

  const filterItems = createMemo(
    () =>
      [
        {
          type: 'select',
          key: 'bankAccountId',
          label: t('Bank account'),
          options: [{ label: t('All'), value: undefined }].concat(bankAccountOptions() as any),
        },
        { type: 'group', label: t('Start date') },
        { type: 'date', key: 'startDate', label: t('Start date') },
        { type: 'group', label: t('End date') },
        { type: 'date', key: 'endDate', label: t('End date') },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const onSelect = (transactions: MagicDoor.Api.TransactionDto[]) => {
    setTransactionIds(transactions);
  };

  const parseFilter = (value: FilterValue): TransactionsFilter => {
    return {
      bankAccountIds: value.bankAccountId ? [value.bankAccountId] : undefined,
      'dateTimeRange.start': value.startDate,
      'dateTimeRange.end': value.endDate,
    };
  };

  const handleAddDepositSlip = async () => {
    try {
      await addDepositSlip({
        transactionIds: transactionIds().map((item) => item.id),
      });
      goBack();
      toast.success(t('{name} has been added successfully', { name: t('Deposit slip') }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getTransactionsMethod = (filter?: TransactionsFilter) => {
    setTransactionIds([]);
    getTransactions(filter).then((res) => {
      setTransactions(res || []);
    });
  };

  onMount(() => {
    getTransactionsMethod();
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="relative">
        <MultiStepsPanel
          onClose={() => goBack()}
          title={t('Create new deposit slip')}
          disableNext={disableNext()}
          onSubmit={handleAddDepositSlip}>
          <ListView
            title={t('Select transactions')}
            data={transactions()?.transactions}
            skeletonSize={10}
            onSelect={onSelect}
            selected={transactionIds()}
            loading={!transactions()}
            filterValue={filterValue()}
            filterItems={filterItems()}
            onChange={({ filter }) => {
              setTransactions();
              getTransactionsMethod({ ...parseFilter(filter) });
            }}
            columns={[
              {
                title: t('Date finalized'),
                render: (row) => <div>{dateFormat(t('MM/DD/YYYY'), row.transactionFinalizedDate)}</div>,
              },

              {
                title: t('Payment method'),
                render: (row) => <div>{row.transactionPaymentMethod || emptyPlaceholder}</div>,
              },
              {
                title: t('Reference'),
                render: (row) => <div>{row.reference || emptyPlaceholder}</div>,
              },
              {
                title: t('Amount'),
                render: (row) => <div>{currency(row.amount)}</div>,
              },
              {
                title: `${t('Memo')}/${t('Description')}`,
                render: (row) => <div>{row.memo || emptyPlaceholder}</div>,
              },

              {
                title: t('Payee owner or tenant'),
                render: (row) => (
                  <Show when={getTenant(transactions()?.tenants, row.tenantId || undefined)} fallback={emptyPlaceholder}>
                    <TenantTitle feedbackIconReadonly tenant={getTenant(transactions()?.tenants, row.tenantId || undefined)} />
                  </Show>
                ),
              },
            ]}
          />
        </MultiStepsPanel>
      </div>
    </div>
  );
};
