import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class ToggleVendorSelectionUseCase extends MagicUseCase {
  public async runLogic(vendorId: string) {
    this.getState().taxInfo.vendorsAndAmounts.forEach((vendor) => {
      if (vendor.vendorId === vendorId) {
        vendor.isSelected = !vendor.isSelected;
      }
    });
  }
}
