import { Show } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

interface TaxYearHeaderProps {
  year: string;
  class?: string;
  shouldShowPrintButton: boolean;
  onPrint?: (year: number) => void;
}

const TaxYearHeader: Component<TaxYearHeaderProps> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="flex w-full justify-between">
      <div class={props.class}>{props.year}</div>
      <Show when={props.shouldShowPrintButton}>
        <Button onClick={() => props?.onPrint?.(Number(props.year))} disabled={false} color={'primary'} variant={'outlined'}>
          <div class="flex items-center">
            <PrintIcon class="mr-1 size-5" />
            {t('Print')}
          </div>
        </Button>
      </Show>
    </div>
  );
};

export default TaxYearHeader;
