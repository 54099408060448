import { useNavigate } from '@solidjs/router';
import { createMemo, onMount, For, Show } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import maintenanceRequestsBg from '~/assets/images/dashboard/maintenanceRequestsBg.svg';
import NoDataImg from '~/assets/images/empty/noData.svg';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { toast, Table, TableActions, confirm, Html } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import Card from '~/pages/dashboard/components/Card';
import { dateFormat, formatAgo } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';
type MaintenanceRequestsProps = {
  class?: string;
};

const MaintenanceRequests: Component<MaintenanceRequestsProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { deleteMaintenanceRequest, maintenanceRequests, getMaintenanceRequests } = useMaintenanceRequests();

  onMount(() => getMaintenanceRequests({ pageSize: 20, new: true }));

  function onDelete(row: MagicDoor.Api.MaintenanceRequestListDto) {
    confirm({
      title: t(`Delete maintenance request`),
      content: (
        <>
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <Html as="p" class="mx-auto w-80 text-text-level03 [&>b]:font-bold [&>b]:text-essential-colour">
            {t('Confirm to delete “<b>{title}</b>” permanently, or “Cancel” if you want to go back. This action can not be undo.', {
              title: row.title,
            })}
          </Html>
        </>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteMaintenanceRequest(row.id);
        toast.success(`"${row.title}" Is successfully deleted.`);
      },
    });
  }

  const columns = createMemo<TableColumns<MagicDoor.Api.MaintenanceRequestListDto>>(() => [
    {
      title: t('Priority'),
      headerClass: 'hidden md:table-cell',
      class: 'hidden md:table-cell text-center',
      render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
    },
    {
      title: t('Title'),
      render: (row) => (
        <div class="whitespace-nowrap">
          <div class="mb-1 font-medium"> {row.title}</div>
          <MaintenanceRequestUrgency class="md:hidden" urgency={row.urgency} />
        </div>
      ),
    },
    {
      title: t('Last updated'),
      render: (row) => (
        <div class="whitespace-nowrap">
          <div class="mb-1 font-medium">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
          <div class="text-gray-400">{formatAgo(row.lastUpdated, 2)}</div>
        </div>
      ),
    },
    {
      title: t('Operation'),
      render: (row) => (
        <TableActions
          actions={[
            {
              label: t('Edit'),
              icon: IconPencil,
              href: `/maintenance/maintenance-request/edit/${row.id}`,
            },
            {
              label: t('Delete'),
              icon: IconDelete,
              onClick: () => onDelete(row),
            },
          ]}
        />
      ),
    },
  ]);
  return (
    <Card
      class={props.class}
      title={t('Maintenance requests')}
      description={t('{count} in total', {
        count: maintenanceRequests.loading ? '-' : maintenanceRequests()?.totalCount.toString() || '0',
      })}
      bgClass="bg-[#F9FFFE]"
      bgImgSrc={maintenanceRequestsBg}
      actions={
        <LinkButton href="/maintenance/maintenance-request" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
          {t('View all')}
          <IconViewArrow class="size-4 text-essential-colour" />
        </LinkButton>
      }>
      <div class="flex flex-col divide-y px-3 md:hidden">
        <For each={maintenanceRequests()?.items}>
          {(row) => (
            <div class="flex items-center justify-between py-3" onClick={() => navigate(`/maintenance/maintenance-request/${row.id}`)}>
              <div>
                <div class="whitespace-nowrap">
                  <div class="mb-1 text-sm font-medium "> {row.title}</div>
                </div>
                <div class="flex items-center gap-1">
                  <MaintenanceRequestUrgency urgency={row.urgency} />
                  <div class="whitespace-nowrap text-xs">
                    <div class="text-gray-400">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
                  </div>
                </div>
              </div>
              <div>
                <TableActions
                  onClick={(e) => e.stopPropagation()}
                  triggerClass="size-[28px] focus:rounded-md focus:bg-light-gray"
                  actions={[
                    {
                      label: t('Edit'),
                      icon: IconPencil,
                      href: `/maintenance/maintenance-request/edit/${row.id}`,
                    },
                    {
                      label: t('Delete'),
                      icon: IconDelete,
                      onClick: () => onDelete(row),
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </For>
      </div>
      <Show when={maintenanceRequests()?.items?.length === 0}>
        <div class="p-10 text-center text-text-level03">
          <img src={NoDataImg} class="mx-auto mb-5 w-44" alt={t('No results found')} />
          {t('No results found')}
        </div>
      </Show>
      <Table
        class="hidden md:table"
        data={maintenanceRequests()?.items}
        columns={columns()}
        rowLink={(item) => `/maintenance/maintenance-request/${item.id}`}
        loading={maintenanceRequests.loading}
      />
    </Card>
  );
};

export default MaintenanceRequests;
