import { useSearchParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import type { Variants } from '~/components/search-select/Context';

export const PropertySelect = () => {
  const { t } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams<{
    portfolioIds?: string;
    propertyIds?: string;
  }>();

  const initialSelected = createMemo(() => {
    if (searchParams.propertyIds) {
      return searchParams.propertyIds.split(',').map((id) => ({ id, __type: CustomLevelSearch.Property }));
    }

    if (searchParams.portfolioIds) {
      return searchParams.portfolioIds.split(',').map((id) => ({ id, __type: CustomLevelSearch.Portfolio }));
    }

    return [];
  });

  const handleChange = (variants: Variants) => {
    const params = {
      portfolioIds: '',
      propertyIds: '',
    };

    if (variants.singleType && variants.singleTypeIds.length > 0) {
      params[`${variants.singleType}Ids` as 'portfolioIds' | 'propertyIds'] = variants.singleTypeIds.toString();
    }

    setSearchParams(params, { replace: true });
  };

  return (
    <LeveledSearchSelect
      multiple
      prefix={t('Protfolios or properties')}
      enabledTypes={[CustomLevelSearch.Portfolio, CustomLevelSearch.Property]}
      initialSelected={initialSelected()}
      singleTypeOnly
      onChange={(_, variants) => handleChange(variants)}
      class="min-w-[500px]"
    />
  );
};
