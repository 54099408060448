import { Form, useForm } from '~/components/common/BetterForm';
import LabeledBankingInput from '~/components/common/Inputs/form-inputs/LabeledBankingInput';
import { Modal } from '~/components/modals';
import { useCompanyPaymentMethods, useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';

export const AddPlaidPaymentAccountModal = () => {
  const { createPaymentAccount, paymentAccountsActions } = useCompanyPaymentMethods();
  const goBack = useGoBack();
  const { t } = useLocalization();

  const form = useForm();

  const handleOk = async () => {
    const { validateStatus } = form.validateForm();

    if (!validateStatus) return;

    await createPaymentAccount(form.formStore);
    goBack();
    paymentAccountsActions.refetch();
  };

  return (
    <Modal title={t('Add Plaid account')} visible={true} onCancel={() => goBack()} loading={createPaymentAccount.loading} onDone={handleOk}>
      <Form defaultForm={form} class="p-6">
        <LabeledBankingInput required />
      </Form>
    </Modal>
  );
};
