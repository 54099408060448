import { Show, For, createMemo } from 'solid-js';
import { useSearchSelectContext } from '~/components/search-select/Context';
import { Empty } from '~/components/search-select/LevelSearchComponents/Empty';
import { ExpandIcon } from '~/components/search-select/LevelSearchComponents/ExpandIcon';
import { SelectArea } from '~/components/search-select/LevelSearchComponents/SelectArea';
import { Popover } from '~/components/ui';
import { CustomLevelSearch, searchLevels } from './SearchLevels';

type SearchItemProps = {
  item: any;
  level?: number;
  parents?: Record<string, any>;
  index?: number;
};

export const SearchItem = (props: SearchItemProps) => {
  const { isSelected, handleSelect, isExpanded, handleExpandClick, isLoading, isDisabled, mergedProps } = useSearchSelectContext();

  const currentLevel = createMemo(() => props.level || 0);

  const config = createMemo(() => searchLevels[currentLevel()]);
  const item = createMemo(() => (config().type === CustomLevelSearch.Tenant ? props.item : props.item[config().type]));

  const childrens = createMemo(() => {
    const childrenKey = config().childItems;
    if (!childrenKey) return [];
    return props.item[childrenKey] ?? [];
  });

  const shouldShowItem = createMemo(() => {
    return childrens().length > 0 || item().__lazy;
  });

  const shouldShowChildren = createMemo(() => {
    return isExpanded(item()) && !isLoading(item());
  });

  const keyPath = createMemo(() => [...(props.parents?.__keyPath ?? []), item().id]);

  return (
    <>
      <Popover.Trigger
        id={`${config().type}-${props.index ?? 0}`}
        as="div"
        class="group my-1 flex cursor-pointer select-none items-center justify-between rounded-md p-2 transition-colors hover:bg-light-pink aria-checked:bg-[#A51CD629]"
        style={{ 'padding-left': `${(currentLevel() + 1) * 16}px` }}
        aria-checked={isSelected(item()) || undefined}
        aria-disabled={isDisabled(config().type) || undefined}
        aria-loading={isLoading(item()) || undefined}
        aria-search-item-expanded={isExpanded(item()) || undefined}
        onClick={(e) => {
          if (mergedProps().multiple || isDisabled(config().type) || isSelected(item())) {
            e.preventDefault();
          }

          if (isDisabled(config().type)) {
            return;
          }

          handleSelect({
            ...props.parents,
            __type: config().type,
            __keyPath: keyPath(),
            ...item(),
          });
        }}>
        <div class="flex flex-1 items-center gap-2">
          <Show when={shouldShowItem()} fallback={<div class="size-6" />}>
            <ExpandIcon
              onClick={(e) => {
                e.stopPropagation();
                handleExpandClick(item(), childrens());
              }}
            />
          </Show>
          <div class="cursor-pointer">{config()?.renderMainSlot?.(item())}</div>
        </div>
        <Show when={!isDisabled(config().type)}>
          <SelectArea item={item} type={config().type} />
        </Show>
      </Popover.Trigger>
      <Show when={shouldShowChildren()}>
        <Show when={childrens()?.length} fallback={<Empty type={config().childItems} />}>
          <For each={childrens()}>
            {(child) => (
              <SearchItem
                item={child}
                level={currentLevel() + 1}
                parents={{
                  ...props.parents,
                  __keyPath: keyPath(),
                  [config().type]: item(),
                }}
              />
            )}
          </For>
        </Show>
      </Show>
    </>
  );
};
