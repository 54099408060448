import { createMemo, createSignal, onMount, Show } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useOwnerContribution, usePayVendorBills } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { paymentMethodOptions } from '~/utils/constant';
import { AddContributionForm } from './components/AddContributionForm';

export const AddContributionCom = () => {
  const { t } = useLocalization();
  const { addOwnerContribution } = useOwnerContribution();
  const { store, getChartOfAccounts, getBankAccounts } = usePayVendorBills();
  const goBack = useGoBack();
  const [submitLoading, setSubmitLoading] = createSignal(false);

  const breadcrumbItems = createMemo(() => [
    { label: t('Owner contribution'), link: '/accounting/owner-funds/contributions' },
    { label: t('Add a contribution') },
  ]);

  const form = useForm();
  const [submitText, setSubmitText] = createSignal<string>('');

  let addContributionFormRef: any;

  const handleAddContribution = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) throw new Error();
    setSubmitLoading(true);

    try {
      setSubmitText(`${t('Add a contribution')}...`);
      const { ownerId = '', ...rest } = { ...form.formStore };
      await addOwnerContribution(rest as MagicDoor.Api.RegisterOwnerContributionDto, ownerId);

      goBack();
      toast.success(t('{name} has been added successfully', { name: t('Owner contribution') }));
    } catch (err) {
      console.error(err);

      throw err;
    } finally {
      setSubmitText('');
      setSubmitLoading(false);
    }
  };

  onMount(() => {
    getChartOfAccounts();
    getBankAccounts();
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="relative">
        <MultiStepsPanel
          contentClass="[&>div]:h-full"
          onClose={() => goBack()}
          title={t('Add a contribution')}
          disableNext={!form.isValidate}
          onSubmit={handleAddContribution}
          loading={true}>
          <AddContributionForm
            paymentMethodOptions={paymentMethodOptions(t)}
            ref={addContributionFormRef}
            form={form}
            class="mx-auto my-10 w-full max-w-[1200px]"
          />
        </MultiStepsPanel>
        <Show when={submitLoading()}>
          <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" showIcon={true} size={100} borderWidth={8} text={submitText()} />
          </div>
        </Show>
      </div>
    </div>
  );
};
