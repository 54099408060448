import { createMemo, onMount, Show } from 'solid-js';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import rentalApplicationsBg from '~/assets/images/dashboard/rentalApplicationsBg.svg';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { getTenantFullName } from '~/components/tenants/utils';
import { Table, Image } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type IncomeStatisticsProps = {
  class?: string;
};

const RentalApplications: Component<IncomeStatisticsProps> = (props) => {
  const { t } = useLocalization();
  const { rentalApplications, getRentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications();
  });

  const data = createMemo<MagicDoor.Api.RentalApplicationListDto[]>(() => rentalApplications()?.filter((item) => item.hasBeenSubmitted));

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Unit name'),
      render: (item) => {
        const unit = () => item.interests?.[0]?.unit;
        return (
          <div class="flex w-full items-center gap-2">
            <Show when={unit()} fallback={<Image class="hidden size-11 rounded-lg md:flex" src={IconTempUnit} />}>
              <UnitImage class="hidden size-11 rounded-lg md:flex" unit={unit() as { id: string; imageId?: string }} />
            </Show>

            <div class="w-36 flex-1 overflow-hidden">
              <Show when={unit()} fallback={<div class="font-medium normal-case text-text-level01">{t('Open to any units')}</div>}>
                <div class="truncate font-medium text-text-level01">{unit()?.name}</div>
                <DisplayAddress class="text-text-level03" address={item.interests?.[0]?.property.address} />
              </Show>
            </div>
          </div>
        );
      },
    },
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex w-full items-center gap-1.5">
          <Show when={item.firstName}>
            <div class="hidden md:flex">
              <Avatar size="default" name={getTenantFullName(item)} />
            </div>
            <span class="w-36 truncate font-medium text-text-level01">{getTenantFullName(item)}</span>
          </Show>
        </div>
      ),
    },
  ]);

  return (
    <Card
      class={props.class}
      title={t('Rental applications')}
      description={t('{count} in total', { count: isLoading() ? '-' : data().length.toString() })}
      bgClass="bg-[#F9FFFE]"
      bgImgSrc={rentalApplicationsBg}
      actions={
        <LinkButton href="/leasing/rental-applications" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
          <div class="md:hidden">{t('All')}</div>
          <div class="hidden whitespace-nowrap md:flex">{t('View all')}</div>
          <IconViewArrow class="size-4 text-essential-colour" />
        </LinkButton>
      }>
      <div>
        <Table data={data()} columns={columns()} rowLink={(item) => `/leasing/rental-applications/${item.id}`} loading={isLoading()} />
      </div>
    </Card>
  );
};

export default RentalApplications;
