import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { chatsSettingsRepository } from '~/repositories/settings/chatsSettingsRepository';
import { createLazyResource } from '~/utils/resource';
import type {
  ChatSettingsDto,
  TextSettingsDtoSettingsWithOverridesDto,
  ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverridesDto,
  ChatAILeaseSettingsDtoSettingsWithOverridesDto,
} from '~/swagger/Api';

export const [ChatsSettingsProvider, useChatsSettings] = createMagicDoorContext('ChatsSettings', () => {
  const repo = new chatsSettingsRepository();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [chatsSettings, chatsSettingActions] = createLazyResource<ChatSettingsDto>(() => repo.getChatsSettings());

  const [allTextSettings, allTextSettingsActions] = createLazyResource<TextSettingsDtoSettingsWithOverridesDto>(() =>
    repo.getAllTextSettings()
  );

  const [allChatAILeaseSettings, allChatAILeaseSettingsActions] = createLazyResource<ChatAILeaseSettingsDtoSettingsWithOverridesDto>(() =>
    repo.getAllChatAILeaseSettings()
  );

  const [allChatAIMaintenanceRequestsSettings, allChatAIMaintenanceRequestsSettingsActions] =
    createLazyResource<ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverridesDto>(() => repo.getAllChatAIMaintenanceRequestsSettings());

  const updateChatSettings = async (payload: MagicDoor.Api.ChatSettingsDto) => {
    setIsLoading(true);
    try {
      await repo.changeChatSettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatsSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.ChatSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateChatsSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        chatsSettingActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChatsSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteChatsSettingsByLevel(level, levelId);
      setTimeout(() => {
        chatsSettingActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  // change update delete text setting like chats
  const updateTextSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.TextSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateTextSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTextSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteTextSettingsByLevel(level, levelId);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateTextSettings = async (payload: MagicDoor.Api.TextSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeTextSettings(payload);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatAILeaseSettingsByLevel = async (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.ChatAILeaseSettingsDto
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateChatAILeaseSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allChatAILeaseSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChatAILeaseSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteChatAILeaseSettingsByLevel(level, levelId);
      setTimeout(() => {
        allChatAILeaseSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatAILeaseSettings = async (payload: MagicDoor.Api.ChatAILeaseSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeChatAILeaseSettings(payload);
      setTimeout(() => {
        allChatAILeaseSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatAIMaintenanceRequestsSettingsByLevel = async (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDto
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateChatAIMaintenanceRequestsSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allChatAIMaintenanceRequestsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChatAIMaintenanceRequestsSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteChatAIMaintenanceRequestsSettingsByLevel(level, levelId);
      setTimeout(() => {
        allChatAIMaintenanceRequestsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatAIMaintenanceRequestsSettings = async (payload: MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeChatAIMaintenanceRequestsSettings(payload);
      setTimeout(() => {
        allChatAIMaintenanceRequestsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    chatsSettingActions.fetch();
    allTextSettingsActions.fetch();
    allChatAILeaseSettingsActions.fetch();
    allChatAIMaintenanceRequestsSettingsActions.fetch();
  });

  return {
    isLoading,
    error,
    updateChatSettings,
    chatsSettings,
    updateChatsSettingsByLevel,
    deleteChatsSettingsByLevel,

    updateTextSettings,
    allTextSettings,
    updateTextSettingsByLevel,
    deleteTextSettingsByLevel,

    updateChatAILeaseSettings,
    allChatAILeaseSettings,
    updateChatAILeaseSettingsByLevel,
    deleteChatAILeaseSettingsByLevel,

    updateChatAIMaintenanceRequestsSettings,
    allChatAIMaintenanceRequestsSettings,
    updateChatAIMaintenanceRequestsSettingsByLevel,
    deleteChatAIMaintenanceRequestsSettingsByLevel,
  };
});
