import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

const DEBUG_URL = '/api/debug';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export class DebugRepository extends BaseRestRepository {
  public async setPlaidStatusSettled(plaidId: string) {
    validateId(plaidId);

    const url = `${DEBUG_URL}/plaid/transfers/${plaidId}/settled`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(plaidId),
    });
    return this.getJsonResponse(response);
  }

  public async setPlaidStatusFailed(plaidId: string) {
    validateId(plaidId);

    const url = `${DEBUG_URL}/plaid/transfers/${plaidId}/failed`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(plaidId),
    });
    return this.getJsonResponse(response);
  }

  public async setPlaidStatusReturned(plaidId: string) {
    validateId(plaidId);

    const url = `${DEBUG_URL}/plaid/transfers/${plaidId}/returned`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(plaidId),
    });
    return this.getJsonResponse(response);
  }
}
