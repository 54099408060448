import { createMagicDoorContext } from '~/contexts/utils';
import { EmailRepository } from '~/repositories/emailRepository';
import { createTriggerResource } from '~/utils/resource';
import type { EmailFilter, SendEmailQuest } from '~/repositories/emailRepository';

const repo = new EmailRepository();
const URL_PREFIX = '/api/emails/';

export const [EmailsProvider, useEmails] = createMagicDoorContext('Emails', () => {
  const [email, getEmail] = createTriggerResource((emailId: string) => repo.getEmail(emailId));

  const getOwnerEmails = async (ownerId: string, filter?: EmailFilter) => {
    return await repo.getOwnerEmails(ownerId, filter);
  };

  const getTenantEmails = async (tenantId: string, filter?: EmailFilter) => {
    return await repo.getTenantEmails(tenantId, filter);
  };

  const getLeaseEmails = async (leaseId: string, filter?: EmailFilter) => {
    return await repo.getLeaseEmails(leaseId, filter);
  };

  const getVendorEmails = async (vendorId: string, filter?: EmailFilter) => {
    return await repo.getVendorEmails(vendorId, filter);
  };

  const sendVendorEmails = async (vendorId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}vendors/${vendorId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendOwnersEmails = async (ownerId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}owners/${ownerId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendLeasesEmails = async (leaseId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}leases/${leaseId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendTenantsEmails = async (tenantId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}tenants/${tenantId}`;
    return await repo.postVendorEmails(url, payload);
  };

  return {
    email,
    getEmail,
    getOwnerEmails,
    getTenantEmails,
    getLeaseEmails,
    getVendorEmails,
    sendVendorEmails,
    sendOwnersEmails,
    sendLeasesEmails,
    sendTenantsEmails,
  };
});
