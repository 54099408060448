import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import { GetTaxDocumentsUseCase } from './getTaxDocumentsUseCase';
import { GetVendorTaxAmountsUseCase } from './getVendorsTaxAmountsUseCase';
import type { TaxDocRegenerationRequest, GetTaxDocumentsRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class RegenerateVendorTaxDocsUseCase extends MagicUseCase {
  public async runLogic(request: TaxDocRegenerationRequest) {
    if (!this.getState().taxInfo.vendorsAndAmounts || this.getState().taxInfo.vendorsAndAmounts.length === 0) {
      await new GetVendorTaxAmountsUseCase().execute(request);
    }
    const selectedVendorTaxAmount = this.getState().taxInfo.vendorsAndAmounts.find((vendor) => vendor.vendorId === request.vendorId);
    if (!selectedVendorTaxAmount) {
      return;
    }
    await taxDocumentsRepository.regenerateVendorTaxDocs({ year: request.year, vendorData: selectedVendorTaxAmount });
    await new GetTaxDocumentsUseCase().execute({
      entityType: TaxEntityTypes.Vendor,
      page: this.getState().taxInfo.paginatedTaxDocuments.currentPage,
      entityId: request.shouldRefetchAllVendors === false ? request.vendorId : undefined,
    } as GetTaxDocumentsRequest);
  }
}
