import type { VendorTaxAmount } from './vendorTaxAmount';

export enum TaxEntityTypes {
  Vendor = 'vendor',
  Owner = 'owner',
}

export interface GetTaxDocumentsRequest {
  entityType: TaxEntityTypes;
  page: number;
  itemsPerPage?: number;
  entityId?: string;
}

export interface NextTaxDocumentBatchRequest {
  entityType: TaxEntityTypes;
  currentIndex: number;
  entityId?: string;
}

export interface VendorTaxDocsGenerationRequest {
  vendorData: VendorTaxAmount[];
  year: number;
}

export interface SingleVendorTaxDocsGenerationRequest {
  vendorData: VendorTaxAmount;
  year: number;
}

export interface TaxYearDocumentRequest {
  year: number;
  vendorId?: string;
}

export interface TaxDocRegenerationRequest {
  year: number;
  vendorId?: string;
  shouldRefetchAllVendors?: boolean;
}

export interface VendorTaxDocumentPrintRequest {
  year: number;
  entityType: TaxEntityTypes;
}
