import { createSignal, For } from 'solid-js';
import LabeledInput from '~/components/common/Inputs/LabeledInput';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { SelectedVendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { UpdateVendorIncomeAmountUseCase } from '~/pdfsigner/usecases/taxes/updateVendorIncomeAmountUseCase';
import { cn } from '~/utils/classnames';

export interface VendorTaxAmountsPageProps {
  class?: string;
}

export const VendorTaxAmountsPage = (props: VendorTaxAmountsPageProps) => {
  const { t } = useLocalization();
  const { execute: updateVendorIncomeAmount, didSucceed } = useUseCase(UpdateVendorIncomeAmountUseCase);
  const { model: vendorTaxAmounts } = usePresenter(SelectedVendorTaxAmountPresenter);
  const [inputValues, setInputValues] = createSignal<Record<string, string | undefined>>({});

  const onInput = async (vendorId: string, value?: string) => {
    setInputValues((prev) => ({ ...prev, [vendorId]: value }));
    const oldAmount = vendorTaxAmounts()?.find((vendor) => vendor.vendorId === vendorId)?.taxAmount;
    await updateVendorIncomeAmount({ vendorId, amount: value });
    if (!didSucceed()) {
      setInputValues((prev) => ({ ...prev, [vendorId]: oldAmount }));
    }
    setInputValues({});
  };

  return (
    <Section title={t('Compensation amounts')} class={cn('px-[96px]', props.class)} titleClass="mt-2 px-0">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 py-4">
          <div class="mb-2 flex flex-row justify-between p-2">
            <div>{t('Vendor')}</div>
            <div>{t('Non-employee compensation')}</div>
          </div>
          <For each={vendorTaxAmounts()} fallback={<div>{t('No vendors found')}</div>}>
            {(vendor) => (
              <div class="mb-2 flex items-center justify-between rounded-lg bg-input p-4 shadow-sm transition-colors ">
                <span class="text-gray-800">{vendor.vendorName}</span>
                <LabeledInput
                  class="!mb-0 w-44"
                  inputContainerClass="rounded-lg bg-white"
                  inputClass="bg-white text-right"
                  prefix="$"
                  onChange={(value) => onInput(vendor.vendorId, value)}
                  value={inputValues()[vendor.vendorId] ?? vendor.taxAmount}
                  placeholder={t('Enter amount')}
                />
              </div>
            )}
          </For>
        </div>
      </div>
    </Section>
  );
};
