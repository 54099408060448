import { UseCase } from '~/framework/useCase';
import { pdfAppState } from '~/pdfsigner/state/pdfAppState';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { TransformRequest } from '~/pdfsigner/usecases/types/transformRequest';

export abstract class MagicUseCase extends UseCase<PdfState> {
  protected isAppStateInitialized = () => {
    return !!this.getState();
  };

  protected async initializeState(): Promise<PdfState> {
    return pdfAppState;
  }

  protected isPdfLoaded(): boolean {
    const document = this.getState().selectedDocument;
    return document !== undefined && document.pdfPages.length > 0;
  }

  protected isTransformationStarted() {
    return this.getState().initialBounds && this.getState().clickPosition;
  }

  protected isSignerRelatedField(type?: AnnotationType): boolean {
    if (type === undefined) {
      return false;
    }
    return (
      type === AnnotationType.NAME || type === AnnotationType.INITIALS || type === AnnotationType.SIGNATURE || type === AnnotationType.DATE
    );
  }

  protected async initializeTransformation(request: TransformRequest) {
    this.setClickPosition(request);
    this.getState().isEditing = false;
    this.setInitialBounds();
  }

  private setInitialBounds() {
    if (!this.getState().selectedAnnotation) {
      return;
    }
    this.getState().initialBounds = {
      x: this.getState().selectedAnnotation?.x || 0,
      y: this.getState().selectedAnnotation?.y || 0,
      width: this.getState().selectedAnnotation?.width || 0,
      height: this.getState().selectedAnnotation?.height || 0,
    };
  }

  protected isPageWithingRange(page: number): boolean {
    const document = this.getState().selectedDocument;
    if (!document) {
      return false;
    }
    return page < 0 || page > document.pdfPages.length;
  }

  private setClickPosition(request: TransformRequest) {
    if (!request.relativePosition) {
      return;
    }
    const position = request.relativePosition.position;
    const boundingBox = request.relativePosition.boundingBox;
    this.getState().clickPosition = {
      x: (position.x - boundingBox.x) / boundingBox.width,
      y: (position.y - boundingBox.y) / boundingBox.height,
    };
  }
}
