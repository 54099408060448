import { NotFoundError } from '@magicdoor/errors';
import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { PropertiesFilter } from '~/repositories/propertiesRepository';

const repo = new PropertiesRepository();

export type PropertyImage = {
  status: 'new' | 'deleted' | 'normal';
  file?: File;
  fileId?: string;
};

export const [PropertiesProvider, useProperties] = createMagicDoorContext('Properties', () => {
  const navigate = useNavigate();

  const [filtered, setFilter, { refetch: refetchFiltered }] = createTriggerResource(
    async (filter: PropertiesFilter) => await repo.getProperties({ active: true, ...filter })
  );

  const [propertyOverview, setPropertyOverview] = createSignal<MagicDoor.Api.PropertyOverviewDto>();

  const [current, setCurrentId, { refetch: refetchProperty, mutate: mutateProperty }] = createTriggerResource(async (id?: string) => {
    if (!id) return undefined;
    try {
      return await repo.getProperty(id);
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(`/portfolios`);
        return;
      }
      throw error;
    }
  });

  const updatePropertyMagicTags = createMutation(async (properyId: string, magicTags: MagicDoor.Api.UpdateUnitMagicTagDto) => {
    const updated = await repo.updatePropertyMagicTags(properyId, magicTags);
    refetchProperty(properyId);
    return updated;
  });

  const addProperty = createMutation(async (payload: MagicDoor.Api.CreatePropertyDto) => {
    const result = await repo.createProperty(payload);
    refetchFiltered();
    return result;
  });

  /** Not preferred. use deactivateProperty instead in most cases */
  const deleteProperty = createMutation(async (id: string) => {
    await repo.deleteProperty(id);
    refetchProperty();
  });

  const deactivateProperty = createMutation(async (id: string, forceDeactivateChildren?: boolean) => {
    await repo.deactivateProperty(id, forceDeactivateChildren);
    refetchProperty();
  });

  const updateProperty = createMutation(async (id: string, payload: MagicDoor.Api.UpdatePropertyDto) => {
    const result = await repo.updateProperty(id, payload);
    refetchProperty();
    return result;
  });

  const removePropertyOwner = createMutation(async (propertyId: string, ownerId: string) => {
    await repo.removePropertyOwner(propertyId, ownerId);
    refetchProperty();
  });

  const addPropertyOwner = createMutation(async (propertyId: string, ownerId: string, ownershipPercentage: number) => {
    await repo.addPropertyOwner(propertyId, ownerId, { ownershipPercentage });
    refetchProperty();
  });

  const updatePropertyOwner = createMutation(async (propertyId: string, ownerId: string, ownershipPercentage: number) => {
    await repo.updatePropertyOwner(propertyId, ownerId, { ownershipPercentage });
    refetchProperty();
  });

  const getPropertyOverview = createMutation(async (propertyId: string) => {
    const data = await repo.getPropertyOverview(propertyId, true);
    setPropertyOverview(data);
  });

  const movePropertyToPortfolio = createMutation(async (propertyId: string, portfolioId: string) => {
    await repo.moveProperty(propertyId, portfolioId);
    refetchProperty();
  });

  return {
    get loading() {
      return () => filtered.loading || current.loading;
    },
    get filtered() {
      return filtered;
    },
    setFilter,
    setCurrentId,
    current,
    propertyOverview,
    getPropertyOverview,
    addProperty,
    deleteProperty,
    deactivateProperty,
    updateProperty,
    mutateProperty,
    removePropertyOwner,
    addPropertyOwner,
    updatePropertyOwner,
    updatePropertyMagicTags,
    movePropertyToPortfolio,
  };
});
