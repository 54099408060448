import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type OptionButtonProps = {
  isActive?: boolean;
  Icon: Component<JSX.SvgSVGAttributes<SVGSVGElement>> | string;
  label: string;
  disabled?: boolean;
  class?: string;
  onClick?: () => void;
};

export const OptionButton: Component<OptionButtonProps> = (props) => {
  return (
    <div
      class={cn(
        'flex cursor-pointer flex-col items-center gap-1 divide-partingline rounded-lg border border-auxiliary-text p-2 text-text-level01 md:flex-row md:divide-x md:p-3',
        { 'border-essential-colour text-essential-colour bg-essential-colour/5': props.isActive },
        { 'bg-partingline cursor-not-allowed text-auxiliary-text': props.disabled },
        props.class
      )}
      onClick={() => props.onClick && props.onClick()}>
      <div class="flex">{typeof props.Icon === 'function' ? <props.Icon class="size-10 md:ml-1 md:mr-3" /> : <img src={props.Icon} />}</div>
      <span class="text-sm font-medium md:ml-3 md:pl-3">{props.label}</span>
    </div>
  );
};
