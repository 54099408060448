import { useParams, useSearchParams } from '@solidjs/router';
import { createMemo, onCleanup, onMount } from 'solid-js';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { UnitSummary } from './UnitSummary';
import type { Component, ComponentProps } from 'solid-js';
import type { TabLinks } from '~/components/ui';

type NavItems = ComponentProps<typeof TabLinks>['items'];
type UnitLeaseNavbarProps = {
  showLeaseSelect?: boolean;
  unitNavItems?: NavItems;
  leaseNavItems?: NavItems;
  source?: 'unit' | 'lease';
};

const LAST_USED_LEASE_KEY = 'last-used-lease';

let clearFlag = false;

export const UnitLeaseNavbar: Component<UnitLeaseNavbarProps> = (props) => {
  const { t } = useLocalization();
  const { unit, clearUnit } = useUnits();
  const { lease, setLease } = useLease();
  const [searchParams] = useSearchParams();
  const params = useParams();

  onCleanup(() => {
    clearFlag = true;
    setTimeout(() => {
      if (clearFlag) {
        sessionStorage.removeItem(LAST_USED_LEASE_KEY);
      }
      clearCurrentUnit();
    }, 1000);
  });

  onMount(() => {
    clearFlag = false;
  });

  const clearCurrentUnit = () => {
    if (!params.unitId && !params.leaseId) {
      clearUnit();
    }
  };

  const currentLeaseId = createMemo(() => {
    const cachedLeaseId = sessionStorage.getItem(LAST_USED_LEASE_KEY);
    if (params.leaseId) {
      if (searchParams.from !== 'unit') {
        sessionStorage.setItem(LAST_USED_LEASE_KEY, params.leaseId);
      }
      return params.leaseId;
    }
    if (cachedLeaseId) return cachedLeaseId;
    const activeLeases = unit()?.activeLeases;
    if (activeLeases?.length) {
      const lease = activeLeases?.find((item) => !item.draft);
      return lease ? lease?.id : activeLeases[0].id;
    }
  });

  const unitNavItems = createMemo(() => {
    if (props.unitNavItems != null) return props.unitNavItems;
    const { id, portfolioId, propertyId } = unit() || {};
    const baseUrl = `/portfolios/${portfolioId}/properties/${propertyId}/units/${id}`;
    return [
      { label: t('Unit overview'), href: baseUrl },
      { label: t('Leases'), href: `${baseUrl}/leases` },
      { label: t('Rental applications'), href: `${baseUrl}/rental` },
      { label: t('Unit information'), href: `${baseUrl}/unit-information` },
      { label: t('Unit files'), href: `${baseUrl}/files` },
      { label: t('Announcements'), href: `${baseUrl}/announcements` },
    ];
  });

  const leaseNavItems = createMemo(() => {
    if (props.leaseNavItems != null) return props.leaseNavItems;
    const baseUrl = `/leasing/leases/${currentLeaseId()}`;
    return [
      { label: t('Lease overview'), href: `${baseUrl}` },
      { label: t('Communications'), href: `${baseUrl}/communications` },
      { label: t('Rent charges'), href: `${baseUrl}/rent-charges` },
      { label: t('Transactions'), href: `${baseUrl}/transactions` },
      { label: t('Deposits'), href: `${baseUrl}/deposits` },
      { label: t('Signings'), href: `${baseUrl}/signings` },
      { label: t('Lease files'), href: `${baseUrl}/files` },
      { label: t('Notes'), href: `${baseUrl}/notes` },
    ];
  });

  return (
    <UnitSummary
      unit={unit()}
      leases={unit()?.activeLeases}
      property={unit()?.property}
      currentLease={lease()}
      showLeaseSelect={props.showLeaseSelect}
      setCurrentLease={setLease}
      source={props.source}
      unitNavbarItems={unitNavItems()}
      leaseNavbarItems={leaseNavItems()}
      leaseNavbarDisabled={currentLeaseId() == null && props.source === 'unit'}
    />
  );
};
