import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { urlWithQuery } from '~/utils/url';

export type GetInvoicesParams = {
  from?: string;
  to?: string;
};

export class CompanyInvoicesRepository extends BaseRestRepository {
  private readonly BASE_URL = '/api/invoices';

  getInvoices = async (params?: GetInvoicesParams): Promise<MagicDoor.Api.CompanyInvoiceDto[]> => {
    const url = this.BASE_URL;
    const response = await this.fetchWithAuth(urlWithQuery(url, params));
    return this.getJsonResponse(response);
  };

  getInvoice = async (invoiceId: string): Promise<MagicDoor.Api.CompanyInvoiceDto> => {
    const url = this.BASE_URL + '/' + invoiceId;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  payInvoice = async ({
    invoiceId,
    paymentAccountId,
  }: {
    invoiceId: string;
    paymentAccountId: string;
  }): Promise<MagicDoor.Api.CompanyInvoiceDto> => {
    const url = this.BASE_URL + '/' + invoiceId + '/pay';
    const response = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify({ paymentAccountId }) });
    return this.getJsonResponse(response);
  };
}

export const companyInvoicesRepository = new CompanyInvoicesRepository();
