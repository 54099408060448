import { Show, createEffect } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import StatusCol from '~/components/rental-application/components/StatusCol';
import { REPORT_STATUS_MAP } from '~/components/rental-application/components/constants';
import { getTenantFullName } from '~/components/tenants/utils';
import {
  IconCircleCheck,
  IconCircleChevronDown,
  IconCirclePlus,
  IconCircleX,
  IconClock,
  IconSettings,
  ListView,
  TableActions,
  toast,
} from '~/components/ui';
import { useLocalPagination } from '~/hooks';
import { ApplicationDecision } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { useRentalApplicationList } from './hooks/useRentalApplicationList';
import type { Component, JSX } from 'solid-js';
import type { RentalApplicationFilter } from '~/repositories/rentalApplicationRepository';
import type { ReportStatus } from '~/swagger/Api';

export const RentalApplicationListView: Component<{ title: JSX.Element; class?: string; filter?: RentalApplicationFilter }> = (props) => {
  const {
    t,
    pagination,
    isLoading,
    rentalApplications,
    filterValue,
    filterItems,
    handleFilterReset,
    updateDecision,
    getRentalApplications,
  } = useRentalApplicationList();
  const { localPage, localPageSize, setPage, setPageSize } = useLocalPagination('RentalApplicationListView');

  const onUpdateDecision = async (id: string, decision: ApplicationDecision) => {
    await updateDecision(id, { decision });
    toast.success(t('Operation successful'));
  };

  createEffect(() => {
    getRentalApplications({ ...props.filter, page: localPage, pageSize: localPageSize });
  });

  return (
    <ListView
      onChange={({ page, pageSize, filter }) => {
        setPage(page);
        setPageSize(pageSize);
        getRentalApplications({ ...props.filter, ...filter, page, pageSize });
      }}
      title={props.title}
      class={props.class}
      totalPages={pagination()?.totalPages}
      page={pagination()?.current}
      pageSize={pagination()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      titleActions={
        <>
          <Button class="text-sm" href="/settings/rental/applicationfee" variant="outlined">
            <IconSettings class="size-4" />
            {t('Application settings')}
          </Button>
          <Button id="rental-applications-add-btn" class="text-sm" href="/leasing/rental-applications/new">
            <IconCirclePlus class="size-4" />
            {t('New rental application')}
          </Button>
        </>
      }
      rowLink={(item) => item.id}
      skeletonSize={10}
      loading={isLoading()}
      data={rentalApplications()}
      empty={<Empty description={t("Haven't applied yet")} />}
      columns={[
        {
          title: t('Applicant'),
          render: (row) => (
            <Show when={row?.firstName && row?.lastName} fallback={<div class="text-gray-400">{emptyPlaceholder}</div>}>
              <div class="flex items-center">
                <Avatar name={getTenantFullName(row)} />
                <div class="ml-2 text-nowrap text-sm font-medium"> {getTenantFullName(row)}</div>
              </div>
            </Show>
          ),
        },
        {
          title: t('AI score'),
          render: (row) => <AIScoreCell score={row.aiApplicationScore} />,
        },
        {
          title: t('Report status'),
          render: (row) => (
            <div class="text-sm text-text-level02">
              <Show when={row.reportStatus} fallback={<span class="text-gray-400">{emptyPlaceholder}</span>}>
                {t(REPORT_STATUS_MAP[row.reportStatus as ReportStatus])}
              </Show>
            </div>
          ),
        },
        {
          title: t('Decision'),
          render: (row) => <StatusCol status={row.applicationDecision} />,
        },
        {
          title: t('Update time'),
          render: (row) => <div class="text-sm">{dateFormat(undefined, row.createdAt)}</div>,
        },
        {
          title: t('Operate'),
          headerClass: 'text-center w-36',
          class: 'flex justify-center',
          render: (item) => (
            <TableActions
              triggerClass="group flex items-center justify-center gap-1 rounded-full border border-link bg-link/5 px-3 py-1 text-xs text-link transition-colors hover:bg-link hover:text-white aria-expanded:bg-link aria-expanded:text-white"
              actions={[
                {
                  icon: () => <IconCircleCheck class="size-3.5 text-success" />,
                  label: t('Accept'),
                  onClick: () => onUpdateDecision(item.id, ApplicationDecision.Approved),
                },
                {
                  icon: () => <IconCircleX class="size-3.5 text-danger" />,
                  label: t('Reject'),
                  onClick: () => onUpdateDecision(item.id, ApplicationDecision.Rejected),
                },
                {
                  icon: () => <IconClock class="size-3.5 text-text-level03" />,
                  label: t('Ignore'),
                  onClick: () => onUpdateDecision(item.id, ApplicationDecision.Ignored),
                },
              ]}>
              {t('Action')}
              <IconCircleChevronDown class="size-3.5 transition-transform group-aria-expanded:rotate-180" />
            </TableActions>
          ),
        },
      ]}
    />
  );
};
