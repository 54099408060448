import { A, useNavigate } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import ImgNoLease from '~/assets/images/empty/noLease.png';
import { TinToggle } from '~/components/TinToggle';
import { Button } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { LeaseDeposits } from '~/components/leases/LeaseDeposits';
import { getUnitFullname } from '~/components/leases/utils';
import { TenantTitle } from '~/components/tenants';
import {
  IconCalendar,
  IconCircleEllipsis,
  IconHash,
  IconHistory,
  IconLanguages,
  IconMail,
  IconPhone,
  IconSquarePen,
  Skeleton,
} from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, useTranslations } from '~/contexts/global';
import { IconLeaseBackground } from '~/pages/tenants/assets';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { TenantPortalStatus } from './TenantPortalStatus';
import type { Component } from 'solid-js';

const InfoSection: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const { languages } = useTranslations();
  const language = createMemo(() => languages()?.find((l) => l.code === props.tenant?.language));

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="space-y-1 px-5 py-4">
        <div class="flex items-center gap-2">
          <TenantTitle feedbackIconMode="col" loading={props.loading} tenant={props.tenant} />
          <A href={`/users/tenants/${props.tenant?.id}/edit`} class="ml-auto">
            <IconSquarePen class="size-5 text-link" />
          </A>
        </div>
      </div>
      <div class="flex flex-wrap gap-5 p-4 *:flex-1 *:basis-56">
        <IconField loading={props.loading} name={t('Phone')} value={props.tenant?.phone} src={IconPhone} />
        <IconField loading={props.loading} name={t('Email')} value={props.tenant?.email} src={IconMail} />
        <IconField
          loading={props.loading}
          name={t('Date of birth')}
          value={props.tenant?.dateOfBirth ? dateFormat('MM/DD/YYYY', props.tenant?.dateOfBirth) : emptyPlaceholder}
          src={IconCalendar}
        />
        <IconField
          loading={props.loading}
          name={t('Social security')}
          value={
            <Show when={props.tenant?.ssn} fallback="--">
              <TinToggle number={props.tenant?.ssn as string} type="ssn" />
            </Show>
          }
          src={IconHash}
        />
        <IconField
          loading={props.loading}
          name={t('Driver license number')}
          value={
            <Show when={props.tenant?.driversLicense?.number} fallback="--">
              <TinToggle number={props.tenant?.driversLicense?.number || ''} type="others" />
            </Show>
          }
          src={IconHash}
        />
        <IconField loading={props.loading || language() == null} name={t('Language')} value={language()?.name} src={IconLanguages} />
        <IconField
          loading={props.loading}
          name={t('Last seen')}
          value={props.tenant?.lastSeen ? dateFormat('MM/DD/YYYY', props.tenant.lastSeen) : emptyPlaceholder}
          src={IconHistory}
        />
      </div>
      <div class="flex items-center justify-between px-5 py-4 text-xs">
        <span class="text-sm font-medium text-title-gray">{t('Portal status')}</span>
        <Show when={!props.loading} fallback={<Skeleton class="w-20 rounded-full" />}>
          <TenantPortalStatus status={props.tenant?.portalStatus} />
        </Show>
      </div>
    </div>
  );
};

const CurrentLeaseSection: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const currentLeases = createMemo(() => {
    return (
      props.tenant?.currentLeases?.map((lease) => {
        const unit = props?.tenant?.units?.find((u) => u.id === lease.unitId);
        const property = props.tenant?.properties?.find((p) => p.id === lease.propertyId);
        return {
          ...lease,
          name: unit?.name,
          unit,
          property,
        };
      }) || []
    );
  });
  const handleRowClick = (e: MouseEvent, leaseId: string) => {
    e.stopPropagation();
    navigate(`/leasing/leases/${leaseId}`);
  };
  let expandableRef: any;
  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="relative flex items-center bg-green px-6 py-5 text-white">
        <IconLeaseBackground class="pointer-events-none absolute inset-y-0 right-0" />
        <div class="flex flex-1 items-center text-lg capitalize">
          <div class="font-semibold">{t('Current lease')}</div>
          <Show when={currentLeases()?.length > 0}>
            <div class="ml-2 flex items-center justify-center rounded-xl bg-[#1DC9A6] px-3 text-sm font-normal">
              {currentLeases().length}
            </div>
          </Show>
        </div>
        <A href="#" class="flex cursor-not-allowed items-center gap-1" title={t('Under construction')}>
          <IconCircleEllipsis class="size-4" />
        </A>
      </div>
      <div class="space-y-2 px-4 py-3 text-sm">
        <Show
          when={!props.loading}
          fallback={
            <>
              <Skeleton class="h-15" />
              <div class="space-y-3">
                <Skeleton />
                <Skeleton />
              </div>
            </>
          }>
          <Show
            when={currentLeases()?.length > 0}
            fallback={
              <div class="flex flex-col items-center">
                <img class="h-20" src={ImgNoLease} alt="ImgNoLease" />
                <h4 class="mt-5 text-sm text-text-level02">{t('No lease yet')}</h4>
                <p class="mt-0.5 text-xs text-text-level03">{t('Click the button below to create one')}</p>
                <Button class="mt-3 w-40" href={`/leasing/leases/new?tenantId=${props.tenant?.id}`}>
                  {t('Set up a lease')}
                </Button>
              </div>
            }>
            <For each={currentLeases()}>
              {(item) => (
                <ExpandableSection
                  ref={expandableRef}
                  headerBorder
                  btnClass="mb-0 items-center py-1 font-normal"
                  defaultOpen={false}
                  class="mb-1 rounded-lg border border-input-border bg-input px-1"
                  title={
                    <div
                      class="flex w-full items-center justify-between gap-2 rounded-lg p-2 text-text-level02"
                      onClick={(e) => handleRowClick(e, item.id)}>
                      <UnitImage class="size-11 shrink-0" unit={item?.unit as { id: string; imageId?: string }} data-slot="image" />
                      <div class="line-clamp-2 flex-1 space-y-1">
                        <div class="text-text-level01">{getUnitFullname(item as any)}</div>
                        <div class="text-title-gray">
                          <Show when={item?.start} fallback={t('-')}>
                            {`${dateFormat('MM/DD/YYYY', item?.start)} - ${
                              item?.end ? dateFormat('MM/DD/YYYY', item?.end) : t('No end date')
                            }`}
                          </Show>
                        </div>
                      </div>
                    </div>
                  }>
                  <div class="p-2">
                    <div class="flex justify-between">
                      <div class="text-text-level02">{t('Monthly rent')}</div>
                      <div class="font-medium text-title-gray">{item?.currentRent != null ? currency(item?.currentRent) : '-'}</div>
                    </div>
                    <LeaseDeposits lease={item} showPrefix={t('Deposit')} class="justify-between text-sm" />
                  </div>
                </ExpandableSection>
              )}
            </For>
          </Show>
        </Show>
      </div>
    </div>
  );
};

export const TenantSummary: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => (
  <div class={cn('flex flex-col gap-4', props.class)}>
    <InfoSection loading={props.loading} tenant={props.tenant} />
    <CurrentLeaseSection loading={props.loading} tenant={props.tenant} />
  </div>
);
