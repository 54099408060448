import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, For, onMount, Show } from 'solid-js';
import IconDate from '~/assets/images/common/company-invoice-date.svg?component-solid';
import IconID from '~/assets/images/common/company-invoice-id.svg?component-solid';
import IconStatus from '~/assets/images/common/company-invoice-status.svg?component-solid';
import IconExternalTransactionType from '~/assets/images/common/company-invoice-transaction-type.svg?component-solid';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { TransactionStatus } from '~/components/transactions';
import { Table } from '~/components/ui';
import { IconLoader } from '~/components/ui/Icons';
import { useCompanyInvoices, useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { isPaid } from '~/pages/company-revenue/company-invoices-tab/invoiceUtils';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component, JSX } from 'solid-js';

const ListItem = (props: { title: string; value: string | JSX.Element; icon: Component }) => {
  return (
    <div class="flex items-center gap-2">
      <props.icon />
      <div class="flex flex-col gap-1">
        <div class="text-sm text-text-level02">{props.title}</div>
        <div class="text-base font-medium text-text-level01">{props.value}</div>
      </div>
    </div>
  );
};

export default function InvoiceDetail() {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { t } = useLocalization();
  const { invoiceId } = useParams();
  const { getInvoice, invoice, invoiceTotalAmount, payInvoice } = useCompanyInvoices();

  onMount(() => {
    getInvoice(invoiceId);
  });

  const listInfo = createMemo(() => {
    return [
      {
        title: t('ID'),
        value: `#${invoice()?.id}`,
        icon: IconID,
      },
      {
        title: t('Date'),
        value: dateFormat('MM/DD/YYYY', invoice()?.created),
        icon: IconDate,
      },
      {
        title: t('Payment status'),
        value: <TransactionStatus status={invoice()?.allocations.at(0)?.transaction?.status} />,
        icon: IconStatus,
      },
      {
        title: t('External transaction type'),
        value: invoice()?.allocations.at(0)?.transaction.externalTransactionType,
        icon: IconExternalTransactionType,
      },
      {
        title: t('External transaction id'),
        value: invoice()?.allocations.at(0)?.transaction.externalTransactionId,
        icon: IconID,
      },
    ];
  });

  const handlePayBill = async () => {
    navigate('./pay');
    return true;
  };

  return (
    <div class="relative flex size-full flex-col">
      <Breadcrumb
        backLink={() => goBack()}
        items={[{ label: t('Invoices'), link: '/accounting/company-revenue/invoices' }, { label: t('Invoice detail') }]}
      />

      <MultiStepsPanel
        onClose={() => goBack()}
        title={t('Invoice detail')}
        confirmation={false}
        submitText={t('Pay')}
        disableNext={!!isPaid(invoice())}
        onStepChange={handlePayBill}
        loading={payInvoice.loading}
        // showPrint
        onPrint={print}>
        <Show when={!invoice.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
          <div class="flex flex-col">
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
              <For each={listInfo()}>{(item) => <ListItem title={item.title} value={item.value} icon={item.icon} />}</For>
            </div>
            <h3 class="mb-4 mt-7 text-base font-semibold">{t('Summaries')}</h3>
            <Table
              theadClass="bg-primary/15 text-primary/80"
              columns={[
                { title: t('Description'), render: (item) => item.service },
                { title: t('Qty'), render: (item) => item.itemCount, class: 'text-right', headerClass: 'text-right' },
                { title: t('Amount'), render: (item) => currency(item.itemTotal), class: 'text-right', headerClass: 'text-right' },
              ]}
              data={invoice()?.summaries}
            />
            <div class="flex items-center justify-end gap-2 px-5">
              <span class="text-xs text-text-level02">{t('Total amount')}:</span>
              <span class="text-base font-medium text-text-level01">{currency(invoiceTotalAmount())}</span>
            </div>
            <h3 class="mb-4 mt-7 text-base font-semibold">{t('Service items')}</h3>
            <Table
              theadClass="bg-primary/15 text-primary/80"
              data={invoice()?.charges}
              columns={[
                { title: t('Description'), render: (item) => item.service },
                { title: t('Amount'), render: (item) => currency(item.amount), class: 'text-right', headerClass: 'text-right' },
                { title: t('Charge time'), render: (item) => dateFormat('MM/DD/YYYY', item.chargeTime) },
                { title: t('Memo'), render: (item) => item.description },
                { title: t('Bill ID'), render: (item) => item.billId, class: 'text-right', headerClass: 'text-right' },
              ]}
            />
          </div>
        </Show>
      </MultiStepsPanel>
    </div>
  );
}
