import { Presenter } from '~/framework/Presenter';
import { TaxDocumentStatus, TaxDocumentType } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import { commaNumber, currency } from '~/utils/number';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type {
  PresentablePdfBlob,
  PresentableTaxAmount,
  PresentableTaxDocument,
  PresentableVendorTaxAmount,
  PresentableVendorTaxDocuments,
  PresentableYearlyVendorTaxDocuments,
} from '~/pdfsigner/ui/types/presentableTaxDocs';
import type { TaxDocument } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type { VendorTaxAmount } from '~/pdfsigner/usecases/types/vendorTaxAmount';

export class YearlyVendorTaxDocPresenter extends Presenter<PresentableYearlyVendorTaxDocuments[]> {
  createModel(state: PdfState): PresentableYearlyVendorTaxDocuments[] {
    const taxDocuments: TaxDocument[] = state.taxInfo.paginatedTaxDocuments.items;
    const yearlyMap: Map<string, Map<string, PresentableTaxDocument[]>> = new Map();
    taxDocuments.forEach((doc, index) => {
      if (doc) {
        const year = doc.calendarYear.toString();
        const presentableDoc: PresentableTaxDocument = {
          index,
          entityId: doc.entityId,
          name: doc.documentName,
          creationDate: new Date(doc.createdAt).toLocaleDateString(),
          documentType: this.getDocumentType(doc.taxDocumentType),
          amount: currency(doc.amount),
          status: this.getStatusText(doc.status),
          statusColor: this.getStatusColor(doc.status),
          statusBgColor: this.getStatusBgColor(doc.status),
          url: doc.url,
          isDocumentReady: doc.status === TaxDocumentStatus.Completed,
        };
        if (!yearlyMap.has(year)) {
          yearlyMap.set(year, new Map());
        }
        const vendorsMap = yearlyMap.get(year)!;
        if (!vendorsMap.has(doc.entityName)) {
          vendorsMap.set(doc.entityName, []);
        }
        vendorsMap.get(doc.entityName)!.push(presentableDoc);
      }
    });
    const yearlyArray: PresentableYearlyVendorTaxDocuments[] = Array.from(yearlyMap.entries()).map(([year, vendorsMap]) => {
      const vendorsArray: PresentableVendorTaxDocuments[] = Array.from(vendorsMap.entries()).map(([vendorName, taxDocuments]) => ({
        vendorName,
        taxDocuments,
      }));
      return {
        year,
        vendors: vendorsArray,
      };
    });
    return yearlyArray;
  }

  private getStatusText(status: TaxDocumentStatus): string {
    switch (status) {
      case TaxDocumentStatus.NotStarted:
        return 'Not Started';
      case TaxDocumentStatus.InProgress:
        return 'In Progress';
      case TaxDocumentStatus.Completed:
        return 'Completed';
      case TaxDocumentStatus.Failed:
        return 'Failed';
      case TaxDocumentStatus.NotFound:
        return 'Not Found';
      default:
        return 'Unknown';
    }
  }

  private getStatusColor(status: TaxDocumentStatus): string {
    switch (status) {
      case TaxDocumentStatus.NotStarted:
        return 'text-gray-500';
      case TaxDocumentStatus.InProgress:
        return 'text-sky-500';
      case TaxDocumentStatus.Completed:
        return 'text-teal-500';
      case TaxDocumentStatus.Failed:
        return 'text-red-500';
      case TaxDocumentStatus.NotFound:
        return 'text-gray-500';
      default:
        return 'text-gray-500';
    }
  }

  private getStatusBgColor(status: TaxDocumentStatus): string {
    switch (status) {
      case TaxDocumentStatus.NotStarted:
        return 'bg-gray-100';
      case TaxDocumentStatus.InProgress:
        return 'bg-sky-100';
      case TaxDocumentStatus.Completed:
        return 'bg-teal-100';
      case TaxDocumentStatus.Failed:
        return 'bg-red-100';
      case TaxDocumentStatus.NotFound:
        return 'bg-gray-100';
      default:
        return 'bg-gray-100';
    }
  }

  private getDocumentType(type: TaxDocumentType): string {
    switch (type) {
      case TaxDocumentType.NEC1099:
        return '1099-NEC';
      case TaxDocumentType.MISC1099:
        return '1099-MISC';
      default:
        return 'Unknown';
    }
  }
}

export class VendorTaxAmountPresenter extends Presenter<PresentableTaxAmount> {
  createModel(state: PdfState): PresentableTaxAmount {
    return {
      years: state.taxInfo.availableYears,
      selectedYear: state.taxInfo.selectedYear,
      vendorTaxAmounts: state.taxInfo.vendorsAndAmounts.map((vendorTaxAmount: VendorTaxAmount) =>
        createPresentableVendorTaxAmount(vendorTaxAmount)
      ),
    };
  }
}

export class SelectedVendorTaxAmountPresenter extends Presenter<PresentableVendorTaxAmount[]> {
  createModel(state: PdfState): PresentableVendorTaxAmount[] {
    return state.taxInfo.vendorsAndAmounts
      .filter((vendorTaxAmount: VendorTaxAmount) => vendorTaxAmount.isSelected)
      .map((vendorTaxAmount: VendorTaxAmount) => createPresentableVendorTaxAmount(vendorTaxAmount));
  }
}

const createPresentableVendorTaxAmount = (vendorTaxAmount: VendorTaxAmount): PresentableVendorTaxAmount => {
  return {
    vendorId: vendorTaxAmount.vendorId,
    vendorName: vendorTaxAmount.vendorName,
    taxAmount: commaNumber(vendorTaxAmount.taxAmount),
    id: vendorTaxAmount.accountId,
    isSelected: vendorTaxAmount.isSelected,
  };
};

export class MergedTaxDocPdfPresenter extends Presenter<PresentablePdfBlob> {
  createModel(state: PdfState): PresentablePdfBlob {
    return { pdf: state.taxInfo.mergedPdf };
  }
}
