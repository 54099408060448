import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { For } from 'solid-js';
import IconReport from '~/assets/images/reports/paper.svg';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';

export const lastMonthRange = {
  start: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
  end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
};

export const ListReports = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const navigateToReport = (path: string, filters: any) => {
    const url = new URL(window.location.origin + path);
    Object.keys(filters).forEach((key) => {
      url.searchParams.set(key, filters[key]);
    });
    navigate(url.pathname + url.search);
  };

  const reports = [
    {
      title: t('Balance sheet'),
      path: '/reports/balance-sheet',
      buttons: [{ label: t('Balance sheet'), filters: {} }],
    },
    {
      title: t('Cash flow'),
      path: '/reports/cash-flow',
      buttons: [
        { label: t('Cash flow'), filters: {} },
        { label: t('Cash flow by month'), filters: { groupBy: 'month' } },
        { label: t('Cash flow by property'), filters: { groupBy: 'property' } },
        { label: t('Cash flow by quarter'), filters: { groupBy: 'quarter' } },
        { label: t('Cash flow by year'), filters: { groupBy: 'year' } },
      ],
    },
    {
      title: t('Income statement'),
      path: '/reports/income-statement',
      buttons: [
        { label: t('Income statement'), filters: { ...lastMonthRange } },
        {
          label: t('Income statement for today'),
          filters: { start: new Date().toISOString().split('T')[0], end: new Date().toISOString().split('T')[0] },
        },
      ],
    },
    {
      title: t('General ledger'),
      path: '/reports/general-ledger',
      buttons: [
        { label: t('General ledger'), filters: {} },
        {
          label: t('General ledger for today'),
          filters: { start: new Date().toISOString().split('T')[0], end: new Date().toISOString().split('T')[0] },
        },
      ],
    },
    {
      title: t('Owner statement'),
      path: '/reports/owner-statement',
      buttons: [{ label: t('Owner statement'), filters: {} }],
    },
    {
      title: t('Rent roll'),
      path: '/reports/rent-roll',
      buttons: [{ label: t('Rent roll'), filters: {} }],
    },
    {
      title: t('Owner directory'),
      path: '/reports/owner-directory',
      buttons: [{ label: t('Owner directory'), filters: {} }],
    },
    {
      title: t('Property directory'),
      path: '/reports/property-directory',
      buttons: [
        { label: t('Property directory'), filters: {} },
        { label: t('Property directory for active'), filters: { active: true } },
        { label: t('Property directory for inactive'), filters: { active: false } },
      ],
    },
    {
      title: t('Tenant directory'),
      path: '/reports/tenant-directory',
      buttons: [
        { label: t('Tenant directory'), filters: {} },
        {
          label: t('Tenant directory includes no active lease'),
          filters: { includeNoActiveLease: true },
        },
        {
          label: t('Tenant directory without no active lease'),
          filters: { includeNoActiveLease: false },
        },
      ],
    },
    {
      title: t('Bank account activity'),
      path: '/reports/bank-account-activity',
      buttons: [{ label: t('Bank account activity'), filters: {} }],
    },
    {
      title: t('Lease unpaid bills'),
      path: '/reports/lease-unpaid-bills',
      buttons: [{ label: t('Lease unpaid bills'), filters: {} }],
    },
    {
      title: t('Delinquency report'),
      path: '/reports/delinquency-report',
      buttons: [{ label: t('Delinquency report'), filters: {} }],
    },
  ];

  return (
    <div class="size-full px-8 py-6 text-sm">
      <div class="flex flex-col gap-4">
        <For each={reports}>
          {(report) => (
            <Panel class="border-0" title={report.title}>
              <div class="grid grid-cols-2 flex-col gap-2 rounded-lg bg-white px-6 pb-5 pt-3 text-start">
                <For each={report.buttons}>
                  {(button) => (
                    <button
                      class="flex items-center gap-1.5 rounded-lg border border-input-border px-4 py-[14px] text-base hover:bg-input/50"
                      onClick={() => navigateToReport(report.path, button.filters)}>
                      <img src={IconReport} />
                      <span>{button.label}</span>
                    </button>
                  )}
                </For>
              </div>
            </Panel>
          )}
        </For>
      </div>
    </div>
  );
};
