import { useParams, useMatch, useLocation } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { CreateFineBillModal } from '~/pages/violations/create-fine-bill-modal';
import { CreateLeaseBillModal } from '~/pages/violations/create-lease-bill-modal';
import { CreateWorkOrderModal } from '~/pages/violations/create-work-order-modal';
import { ViolationSummary } from './components/ViolationSummary';
import { LeaseBillsTab } from './tabs/LeaseBillsTab';
import { ViolationOverviewTab } from './tabs/ViolationOverviewTab';
import type { TabLinkItem } from '~/components/ui';

export const ViolationDetailsPage = () => {
  const { t } = useLocalization();
  const params = useParams<{ violationId: string; workOrderId?: string }>();
  const goBack = useGoBack();
  const location = useLocation();

  const { getViolation, violation, createFineBill } = useViolations();
  const [createFineBillVisible, setCreateFineBillVisible] = createSignal<boolean>(false);
  const [modalType, setModalType] = createSignal<string | undefined>(undefined);

  createEffect(() => getViolation(params.violationId));

  createEffect(() => {
    const path = location.pathname;
    if (path.includes('lease-notification')) {
      setModalType('lease-notification');
    } else if (path.includes('owner-notification')) {
      setModalType('owner-notification');
    } else if (path.includes('vendor-notification')) {
      setModalType('vendor-notification');
    } else {
      setModalType(undefined);
    }
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Violations'), link: '/associations/violations' },
    { label: t('Violation detail') },
  ]);

  const basePath = `/associations/violations/${params.violationId}`;

  const tabs = createMemo<TabLinkItem[]>(() => [
    { label: t('General'), href: basePath },
    { label: t('Lease bills'), href: `${basePath}/lease-bills` },
  ]);

  const isLeaseBillsTab = useMatch(() => `${basePath}/lease-bills`);
  const isCreateWorkOrder = useMatch(() => `${basePath}/create-work-order`);
  const isCreateLeaseBill = useMatch(() => `${basePath}/lease-bills/add`);

  const onCreateFineBillSubmit = async (data: any) => {
    await createFineBill(data);
    setCreateFineBillVisible(false);
  };

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="my-5 flex flex-col-reverse gap-4 px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4 lg:w-3/4">
          <TabLinks boxClass="mb-4 rounded-lg border border-partingline bg-white" items={tabs()} replace />

          <Show when={!isCreateWorkOrder() && !isCreateLeaseBill()}>
            <Show when={isLeaseBillsTab()} fallback={<ViolationOverviewTab />}>
              <LeaseBillsTab />
            </Show>
          </Show>

          <Show when={isCreateWorkOrder()}>
            <CreateWorkOrderModal />
          </Show>

          <Show when={isCreateLeaseBill()}>
            <CreateLeaseBillModal />
          </Show>
        </div>

        <ViolationSummary
          class="w-full min-w-80 shrink-0 lg:w-1/4"
          loading={violation.loading}
          violation={violation()}
          modalType={modalType()}
        />

        <CreateFineBillModal
          visible={createFineBillVisible()}
          onClose={() => setCreateFineBillVisible(false)}
          onSubmit={onCreateFineBillSubmit}
        />
      </div>
    </div>
  );
};
