import { HttpError } from '@magicdoor/errors';
import { For } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { BillRepository } from '~/repositories/billRepository';
import { ChartOfAccountsRepository } from '~/repositories/chartOfAccountsRepository';
import { PayBillType } from '~/swagger/Api';
import { paymentMethodOptions } from '~/utils/constant';
import { uuid } from '~/utils/tool';
import { useBills } from './BillsContext';
import type { Option } from '~/components/common/DropdownMenu';
import type { VendorBillsPayListFilter } from '~/repositories/billRepository';
import type { TransactionPaymentMethod } from '~/swagger/Api';

export interface BillPayment {
  id: string;
  property?: MagicDoor.Api.PropertyDto & { availableBalance?: number };
  paymentBankAccountId?: string;
  receivingBankAccountId?: string;
  paymentMethod?: TransactionPaymentMethod;
  paymentAmount?: number;
  vendor: MagicDoor.Api.VendorDto;
  paymentType: PayBillType;
  chartOfAccountName: string[];
  memo?: string | null;
  externalTransactionId?: string;
  dueDate: string;
  totalAmount: number;
  dueAmount: number;
  vendorMemo?: string | null;
}

interface ProcessedBill extends MagicDoor.Api.BillDto {
  property: MagicDoor.Api.PropertyDto & { availableBalance?: number; defaultBankAccountId?: string | null };
  vendor: MagicDoor.Api.HydratedVendorDto;
}

type BillPaymentSection = {
  sectionId: string;
  vendorId: string;
  billIds: string[];
  propertyDefaultBankAccountId?: string;
  isManualAdded: boolean;
};

interface TBillStore {
  submitLoading: boolean;
  propertiesLoading: boolean;
  chartOfAccountOptions: Option[];
  chartOfAccountMap: Map<string, string>;
  chartOfAccountLoading: boolean;
  bills: ProcessedBill[];
  billsLoading: boolean;
  bankAccountOptions: Option[];
  bankAccountsLoading: boolean;
  billPayments: BillPayment[];
  isAllProperties: boolean;
  isAllVendors: boolean;
  properties: string[];
  vendors: string[];
  paymentDate: string | null;
  vendorBankAccountsMap: Map<string, { label: string; value: string }[]>;
  billPaymentSections: BillPaymentSection[];
  payVendorBillsResult?: MagicDoor.Api.PayBillsResultsDto;
}

const initialStore = (): TBillStore => ({
  submitLoading: false,
  propertiesLoading: false,
  chartOfAccountOptions: [],
  chartOfAccountMap: new Map(),
  chartOfAccountLoading: false,
  bills: [],
  billsLoading: false,
  bankAccountOptions: [],
  bankAccountsLoading: false,
  billPayments: [],
  isAllProperties: true,
  isAllVendors: true,
  properties: [],
  vendors: [],
  paymentDate: new Date().toISOString().split('T')[0],
  vendorBankAccountsMap: new Map(),
  billPaymentSections: [],
});

export const [PayVendorBillsProvider, usePayVendorBills] = createMagicDoorContext('PayVendorBills', () => {
  const ChartOfAccountsRepo = new ChartOfAccountsRepository();
  const BillRepo = new BillRepository();
  const BankAccountRepo = new BankAccountRepository();

  const { t } = useLocalization();
  const { payBill } = useBills();
  const [store, setStore] = createStore<TBillStore>(initialStore());

  const getChartOfAccounts = async () => {
    setStore('chartOfAccountLoading', true);

    const response = await ChartOfAccountsRepo.getChartOfAccounts();
    const options = (response ?? []).map((account) => ({
      label: account.name,
      value: account.id,
    }));
    const accountMap = new Map(response.map((account) => [account.id, account.name]));

    setStore('chartOfAccountOptions', options);
    setStore('chartOfAccountMap', accountMap);
    setStore('chartOfAccountLoading', false);
  };

  const onInitBillPayments = () => {
    const defaultBankAccountId = store.bankAccountOptions?.at(0)?.value;
    const defaultPaymentMethod = paymentMethodOptions(t).at(0)?.value;
    const vendorBankAccountsMap = new Map<string, { label: string; value: string }[]>();
    const billPayments: BillPayment[] = [];

    store.bills?.forEach((bill) => {
      if (!vendorBankAccountsMap.has(bill.vendor.id)) {
        vendorBankAccountsMap.set(
          bill.vendor.id,
          bill.vendor.paymentAccounts.map((item) => ({ label: item.name, value: item.id }))
        );
      }

      billPayments.push({
        id: bill.id,
        property: bill.property,
        paymentBankAccountId: bill.property.defaultBankAccountId || defaultBankAccountId,
        receivingBankAccountId: vendorBankAccountsMap.get(bill.vendor.id)?.at(0)?.value ?? undefined,
        paymentMethod: defaultPaymentMethod,
        paymentAmount: bill.due,
        dueAmount: bill.due,
        vendor: bill.vendor,
        paymentType: PayBillType.PrintCheck,
        chartOfAccountName: bill.lineItems.map((item) => store.chartOfAccountMap?.get(item.chartOfAccountId)).filter(Boolean) as string[],
        memo: bill.memo ?? '',
        externalTransactionId: '',
        dueDate: bill.dueDate,
        totalAmount: bill.totalAmount,
        vendorMemo: bill.vendor.defaultMemo,
      });
    });

    setStore('vendorBankAccountsMap', vendorBankAccountsMap);
    setStore('billPayments', billPayments);
  };

  const onInitBillPaymentsSections = () => {
    const sections: BillPaymentSection[] = [];

    store.billPayments.forEach((item) => {
      const foundIndex = sections.findIndex(
        (section) => section.vendorId === item.vendor.id && section.propertyDefaultBankAccountId === item.paymentBankAccountId
      );

      if (foundIndex < 0) {
        sections.push({
          sectionId: uuid(),
          vendorId: item.vendor.id,
          billIds: [item.id],
          propertyDefaultBankAccountId: item.paymentBankAccountId,
          isManualAdded: false,
        });
      } else {
        sections[foundIndex].billIds.push(item.id);
      }
    });

    setStore('billPaymentSections', sections);
  };

  const getVendorBillsPay = async (filter: VendorBillsPayListFilter): Promise<void> => {
    setStore('billsLoading', true);
    try {
      const response = await BillRepo.getVendorBillsPay(filter);

      if (
        !response ||
        !response.properties ||
        !Array.isArray(response.properties) ||
        !response.vendors ||
        !Array.isArray(response.vendors)
      ) {
        console.error("Invalid response structure. Expected 'properties' and 'vendors' arrays.");
        setStore('bills', []);
        return;
      }

      const vendorMap = new Map(response.vendors.map((vendor) => [vendor.id, vendor]));

      const processedBills: ProcessedBill[] = response.properties
        .flatMap(
          (property) =>
            property.bills
              ?.map((bill) => {
                const vendor = bill.vendorId ? vendorMap.get(bill.vendorId) : undefined;
                if (!vendor) {
                  console.warn(`No vendor found for vendorId: ${bill.vendorId}`);
                  return null;
                }

                if (!property.property) {
                  console.warn(`No property`);
                  return null;
                }

                return {
                  ...bill,
                  property: {
                    ...property.property,
                    availableBalance: property.availableBalance,
                    defaultBankAccountId: property.defaultBankAccountId,
                  },
                  vendor: vendor,
                };
              })
              .filter((bill) => bill !== null) ?? []
        )
        .filter((bill) => bill.due > 0);

      setStore('bills', processedBills);
      onInitBillPayments();
      onInitBillPaymentsSections();
    } catch (error) {
      console.error('Error fetching bills:', error);
      setStore('bills', []);
    } finally {
      setStore('billsLoading', false);
    }
  };

  const getBankAccounts = async () => {
    setStore('bankAccountsLoading', true);

    const response = await BankAccountRepo.getBankAccounts();
    const options = (response ?? []).map((account: any) => ({
      label: account.name,
      value: account.id,
    }));

    setStore('bankAccountOptions', options);
    setStore('bankAccountsLoading', false);
  };

  const onUpdateBillPayments = (data: { vendorId?: string; billIds: string[]; payload: { [key in keyof BillPayment]?: any } }) => {
    setStore(
      produce((state) => {
        const { vendorId, billIds, payload } = data;
        const indexes = state.billPayments
          .map((bill, index) => {
            const isSelected =
              (vendorId ? bill.vendor.id === vendorId : true) && (billIds && billIds.length ? billIds.includes(bill.id) : true);
            return isSelected ? index : -1;
          })
          .filter((index) => index > -1);

        indexes.forEach((index) => {
          if (state.billPayments[index]) {
            state.billPayments[index] = { ...state.billPayments[index], ...payload };
          }
        });
      })
    );
  };

  const onPayVendorBills = async () => {
    try {
      const billsGroups: MagicDoor.Api.PayVendorBillsGroupDto[] = [];
      const filteredBillPayments = store.billPayments.filter((bill) => {
        if (!bill.paymentAmount || bill.paymentAmount <= 0 || !bill.paymentBankAccountId) {
          return false;
        }

        if (bill.paymentType === PayBillType.Ach && !bill.receivingBankAccountId) {
          return false;
        }

        if (bill.paymentType === PayBillType.Manual && !bill.externalTransactionId) {
          return false;
        }

        return true;
      });
      const findCommonData = (section: BillPaymentSection) => {
        const { vendorId, billIds } = section;
        const firstBill = filteredBillPayments.find((bill) => bill.vendor.id === vendorId && billIds.includes(bill.id));

        if (!firstBill) {
          return;
        }

        return {
          vendorId,
          bankAccountId: firstBill.paymentBankAccountId as string,
          paymentType: firstBill.paymentType,
          externalTransactionId: firstBill.externalTransactionId,
          paymentMethod: firstBill.paymentMethod,
          paymentAccountId: firstBill.receivingBankAccountId,
          memo: firstBill.memo,
        };
      };

      store.billPaymentSections.forEach((section) => {
        const commonData = findCommonData(section);
        const bills = section.billIds
          .map((billId) => {
            const data = filteredBillPayments.find((bill) => bill.id === billId && bill.vendor.id === section.vendorId);

            if (!data) {
              return;
            }

            return { billId, amount: data.paymentAmount as number };
          })
          .filter(Boolean) as { billId: string; amount: number }[];

        if (commonData && bills.length) {
          billsGroups.push({ ...commonData, payments: bills });
        }
      });
      const payload = { paymentDate: store.paymentDate, groups: billsGroups };
      const result = await payBill(payload);

      setStore('payVendorBillsResult', result);
      toast.success(t('Bills have been paid successfully'));
    } catch (e) {
      const errors = e instanceof HttpError && e.getErrors();
      const message = errors ? (
        <For each={Object.values(errors).flat()}>{(error) => <p>{error as string}</p>}</For>
      ) : (
        t('Operation failed, please try again later')
      );

      toast.error(message);
      throw e;
    }
  };

  return {
    store,
    getVendorBillsPay,
    setStore,
    getChartOfAccounts,
    getBankAccounts,
    onUpdateBillPayments,
    onPayVendorBills,
  };
});
