import { BaseRestRepository } from '~/repositories/baseRestRepository';

const COMPANY_SETTINGS_URL = '/api/settings/company';

export class companySettingsRepository extends BaseRestRepository {
  public async changeCompanySettings(payload: MagicDoor.Api.CompanySettingsDto) {
    const url = COMPANY_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getCompanySettings(): Promise<MagicDoor.Api.CompanySettingsDto> {
    const url = COMPANY_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

export const companySettingsRepo = new companySettingsRepository();
