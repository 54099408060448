import { useParams } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { Modal } from '~/components/modals/Modal';
import { LabeledTenantSearch } from '~/components/tenants/TenantSearch';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { useGoBack } from '~/hooks';

export const AddTenantModal = () => {
  const goBack = useGoBack();
  const { leaseId } = useParams();
  const { t } = useLocalization();

  const { bindTenantToLease, getLease, lease } = useLease();

  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  const form = useForm();

  const onDone = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    setModalLoading(true);
    try {
      await bindTenantToLease(leaseId, form.formStore.tenantId, { moveIn: form.formStore.moveInDate });
      setModalLoading(false);
      getLease(leaseId);
      goBack();
      toast.success(t('Operation successful'));
    } catch (error) {
      setModalLoading(false);
    }
  };

  return (
    <Modal
      onDone={onDone}
      visible={true}
      title={t('Add tenant')}
      doneText={t('Confirm')}
      loading={modalLoading()}
      onCancel={() => goBack()}
      confirmation={false}>
      <Form defaultForm={form} class="mb-6 p-8">
        <FormItem
          component={LabeledTenantSearch}
          label={t('Tenant')}
          placeholder={t('Search for tenants')}
          formFieldName="tenantId"
          exclude={(item: { id: string }) => lease()?.tenants.some((x) => x.tenant.id === item.id)}
          rules={[
            { required: true, message: t('Tenant is required') },
            { validator: (value) => lease()?.tenants.every((x) => x.tenant.id !== value) ?? true, message: t('Tenant already exists') },
          ]}
        />
        <FormItem class="mt-4" label={t('Move in date')} formFieldName="moveInDate" component={DueDateInputField} />
      </Form>
    </Modal>
  );
};
