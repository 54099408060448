import { createMemo } from 'solid-js';
import { LatestUpdatedAt } from '~/components/ui';
import { useProperties, useLocalization } from '~/contexts/global';
import { MaintenanceRequestsTable } from '~/pages/units/unit-details/overview/MaintenanceRequestsTable';
import { PropertyOwnerShare } from './overview/PropertyOwnerShare';
import { PropertyQuickAction } from './overview/PropertyQuickActions';
import { PropertyRenewal } from './overview/PropertyRenewal';
import { PropertyRevenue } from './overview/PropertyRevenue';
import PropertyExpiringLease from './units/PropertyExpiringLease';

const PropertyOverviewTab = () => {
  const { propertyOverview } = useProperties();
  const { t } = useLocalization();
  const exporingLeasedata = createMemo(() => {
    const { expiringLeaseCounts } = (propertyOverview() as MagicDoor.Api.PropertyOverviewDto) || {};

    return [
      {
        name: t('{count} Days', { count: '<30' }),
        value: expiringLeaseCounts?.lessThanOneMonth ?? 0,
        color: '#FF545D',
      },
      {
        name: t('{count} Days', { count: '31 - 60' }),
        value: expiringLeaseCounts?.oneToTwoMonths ?? 0,
        color: '#FA8511',
      },
      {
        name: t('{count} Days', { count: '61 - 90' }),
        value: expiringLeaseCounts?.twoToThreeMonths ?? 0,
        color: '#36CBAC',
      },
    ];
  });

  return (
    <>
      <LatestUpdatedAt class="px-3 py-2 md:px-8 md:py-0" time={propertyOverview()?.cacheDate ?? ''} />
      <div class="grid grid-cols-3 gap-2 px-3 text-left md:gap-5 md:px-8">
        <PropertyRevenue class="col-span-3 h-full min-h-96 xl:col-span-2" />
        <PropertyQuickAction class="col-span-3 flex max-h-96 flex-col overflow-hidden xl:col-span-1" />
        <PropertyExpiringLease data={exporingLeasedata()} class="col-span-3 flex max-h-96 flex-col overflow-hidden xl:col-span-1" />
        <PropertyRenewal class="col-span-3 max-h-96 xl:col-span-2" />
        <MaintenanceRequestsTable class="col-span-3 max-h-96 xl:col-span-2" />
        <PropertyOwnerShare class="col-span-3 flex max-h-96 flex-col overflow-hidden xl:col-span-1" />
      </div>
    </>
  );
};

export default PropertyOverviewTab;
