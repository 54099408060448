import { Show, createMemo, createSignal, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, confirm, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useWorkOrderSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const WorkOrderSettings = () => {
  const { t } = useLocalization();
  const {
    allWorkOrderSettings,
    getAllWorkOrderSettings,
    isLoading,
    updateWorkOrderSettings,
    addOverrideSettings,
    updateOverrideWorkOrderSettings,
    removeOverrideSettings,
  } = useWorkOrderSettings();
  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'sendTenantVendorCoordinationMessage',
      label: 'Send tenant vendor coordination message',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendVendorsEmailMessageWhenAssigned',
      label: 'Send vendors email message when assigned',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendVendorsTextMessageWhenAssigned',
      label: 'Send vendors text message when assigned',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
  ]);
  const [isShowModal, setIsShowModal] = createSignal(false);

  const onSave = async (data: MagicDoor.Api.WorkOrderSettingsDto, level?: string, levelId?: string) => {
    if (!level && !levelId) {
      await updateWorkOrderSettings(data);
      toast(t('Work order settings saved successfully'), 'success');
      getAllWorkOrderSettings();
      return;
    }

    await updateOverrideWorkOrderSettings(level as string, levelId as string, data);
    toast(t('Work order settings saved successfully'), 'success');
    getAllWorkOrderSettings();
  };

  const onIsShowModalChange = () => {
    setIsShowModal((prev) => !prev);
  };

  const onAddOverrideSettingsConfirm = async (level: string, levelId: string) => {
    await addOverrideSettings(level, levelId, { ...((allWorkOrderSettings()?.settings ?? {}) as MagicDoor.Api.WorkOrderSettingsDto) });
    onIsShowModalChange();
    toast(t('Add work order settings successfully'), 'success');
    getAllWorkOrderSettings();
  };

  const onRemoveOverrideSettings = (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t('Delete override'),
      onResolve: async (confirmed) => {
        if (!confirmed) {
          return;
        }

        await removeOverrideSettings(level, levelId);
        toast(t('Work order settings deleted successfully'), 'success');
        getAllWorkOrderSettings();
      },
    });
  };

  onMount(() => getAllWorkOrderSettings());

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Work order')} icon={IconRentPayment} />}
        topAction={
          <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={onIsShowModalChange}>
            <IconPlus />
            <span class="hidden font-medium md:block">{t('Add new override')}</span>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={allWorkOrderSettings()?.settings || ({} as MagicDoor.Api.WorkOrderSettingsDto)}
                />
              </div>
            </Show>
            <Overrides
              overrides={allWorkOrderSettings()?.overrides ?? []}
              onDelete={onRemoveOverrideSettings}
              title={t('Work order overrides')}>
              {(override) => (
                <div class="flex flex-col gap-4 p-2 text-left">
                  <EditForm
                    sections={sections()}
                    model={(override.settings ?? {}) as MagicDoor.Api.WorkOrderSettingsDto}
                    onOk={(data) => onSave(data, override.level, override.levelId)}
                  />
                </div>
              )}
            </Overrides>
          </div>
        }
      />
      <Show when={isShowModal()}>
        <AddOverridesModal
          overrides={allWorkOrderSettings()?.overrides || []}
          loading={isLoading()}
          onClose={onIsShowModalChange}
          onDone={onAddOverrideSettingsConfirm}
        />
      </Show>
    </>
  );
};
