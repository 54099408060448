import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { PropertyTitle, PropertyType } from '~/components/properties';
import { IconCirclePlus, ListView, LatestUpdatedAt, TableActions } from '~/components/ui';
import { IconMove } from '~/components/ui/Icons';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { PortfolioSummary } from './components/PortfolioSummary';
import type { Component } from 'solid-js';

type FilterValue = {
  types?: MagicDoor.Api.PropertyType[];
  balance?: 'outstanding';
};

export const PortfolioOverview: Component = () => {
  const { isMobileView } = useDeviceDetect();
  const { t } = useLocalization();
  const { current, portfolioOverview, getPortfolioOverview } = usePortfolios();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});
  const { setPage, setPageSize } = useLocalPagination('PortfolioOverviewListView');
  const { filtered, setFilter, loading, setCurrentId } = useProperties();
  const navigate = useNavigate();

  createEffect(() => {
    const portfolioId = current()?.id;
    if (portfolioId == null) return;
    setFilter({ portfolioId });
    getPortfolioOverview(portfolioId);
  });

  return (
    <>
      <LatestUpdatedAt time={portfolioOverview()?.cacheDate ?? ''} />
      <PortfolioSummary loading={getPortfolioOverview.loading} portfolioOverview={portfolioOverview()} />

      <MobileListView
        class="md:hidden"
        searchClass="w-20"
        titleActions={
          <LinkButton
            href={current()?.id ? (isMobileView() ? `/portfolios/${current()?.id}/add-property` : `/portfolios/${current()?.id}/add`) : '#'}
            class="px-2.5 font-medium"
            noScroll>
            <IconCirclePlus class="size-5 text-white" />
            <div class="">{t('Add a property')}</div>
          </LinkButton>
        }
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items || []}
        loading={filtered()?.items == null || loading()}
        searchPlaceholder={t('Search')}
        filterValue={filterValue()}
        renderItem={(item) => {
          return (
            <div class="flex flex-col gap-2 p-3" onClick={() => navigate(`/portfolios/${current()?.id}/properties/${item.id}`)}>
              <div class="flex justify-between">
                <PropertyTitle class="max-w-[60vw]" property={item as MagicDoor.Api.HydratedPropertyDto} />
                <div class="flex items-center gap-2">
                  <div class="rounded-[35px] bg-[#F3F3FC] px-[6px] py-[2px]">
                    <PropertyType type={item.type} align="left" class="size-[14px]" />
                  </div>

                  <TableActions
                    onClick={(e) => e.stopPropagation()}
                    triggerClass="size-[28px] focus:rounded-md focus:bg-light-gray"
                    actions={[
                      {
                        label: t('Edit'),
                        icon: IconPencil,
                        href: `/portfolios/${current()?.id}/properties/${item.id}/edit`,
                      },
                      {
                        label: t('Move portfolio'),
                        icon: IconMove,
                        onClick() {
                          setCurrentId(item.id);
                          navigate(`/portfolios/${current()?.id}/move`);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div class="flex divide-x rounded-lg bg-light-gray py-3">
                <div class="flex-1">
                  <h3
                    class="text-center text-xl font-semibold"
                    classList={{
                      'text-success': item.unitCount - item.occupiedUnits > 0,
                      'text-title-gray': item.unitCount - item.occupiedUnits === 0,
                    }}>
                    {item.unitCount - item.occupiedUnits}
                  </h3>
                  <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Empty units')}</p>
                </div>
                <div class="flex-1">
                  <h3 class="text-center text-xl font-semibold text-title-gray">{item.occupiedUnits}</h3>
                  <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Active units')}</p>
                </div>
                <div class="flex-1">
                  <h3 class="text-center text-xl font-semibold text-title-gray">{item.recentRentalApplications}</h3>
                  <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Rental application')}</p>
                </div>
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Outstanding balances')}:</span>
                <CnMoney money={item.currentBalance} showIcon />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Posted balances')}:</span>
                <CnMoney money={item.postedBalance} showIcon />
              </div>
            </div>
          );
        }}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search, portfolioId: current()?.id });
        }}
        onFilterReset={() => setFilterValue({})}
        footerSummary={
          <Show when={filtered()?.totalPages && (filtered()?.totalPages as number) > 1}>
            <div class="absolute text-text-level03" style={{ 'margin-left': '196px' }}>
              {t('In total')}: {filtered()?.totalCount}
            </div>
          </Show>
        }
      />

      <ListView
        class="hidden md:block"
        title={t('Properties')}
        titleActions={
          <LinkButton href={current()?.id ? `/portfolios/${current()?.id}/add` : '#'} class="px-2.5 font-medium" noScroll>
            <IconCirclePlus class="size-5 text-white" />
            <div class="hidden md:flex">{t('Add a property')}</div>
          </LinkButton>
        }
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered()?.items == null || loading()}
        skeletonSize={10}
        searchPlaceholder={t('Search properties')}
        filterValue={filterValue()}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search, portfolioId: current()?.id });
        }}
        onFilterReset={() => setFilterValue({})}
        columns={[
          {
            title: t('Property'),
            render: (item) => <PropertyTitle class="max-w-[40vw]" property={item as MagicDoor.Api.HydratedPropertyDto} />,
          },
          {
            title: t('Type'),
            headerClass: 'w-32',
            render: (item) => <PropertyType type={item.type} />,
          },
          {
            title: t('Empty units'),
            headerClass: 'w-32',
            class: 'text-success',
            render: (item) => item.emptyUnits,
          },
          {
            key: 'activeUnits',
            title: t('Active units'),
            headerClass: 'w-32',
          },
          {
            title: t('Rental application'),
            headerClass: 'w-44',
            render: (item) => (
              <div class="group flex gap-2 px-2 py-3">
                {item.recentRentalApplications}
                {/* TODO: need API to get recent rental applications */}
                {/* <span class="rounded-full bg-success px-2 text-white opacity-0 transition-opacity group-hover:opacity-100">{t('New')}</span> */}
              </div>
            ),
          },
          {
            title: t('Outstanding balances'),
            headerClass: 'text-right w-44',
            class: 'text-right',
            render: (item) => <CnMoney money={item.currentBalance} showIcon />,
          },
          {
            title: t('Posted balances'),
            headerClass: 'text-right w-44',
            class: 'text-right',
            render: (item) => <CnMoney money={item.postedBalance} showIcon />,
          },
          {
            title: t('Operation'),
            render: (row) => (
              <TableActions
                actions={[
                  {
                    label: t('Edit'),
                    icon: IconPencil,
                    href: `/portfolios/${current()?.id}/properties/${row.id}/edit`,
                  },
                  {
                    label: t('Move portfolio'),
                    icon: IconMove,
                    onClick() {
                      setCurrentId(row.id);
                      navigate(`/portfolios/${current()?.id}/move`);
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        rowLink={(item) => `/portfolios/${item.portfolioId}/properties/${item.id}`}
        footerSummary={
          <Show when={filtered()?.totalPages && (filtered()?.totalPages as number) > 1}>
            <div class="absolute text-text-level03" style={{ 'margin-left': '196px' }}>
              {t('In total')}: {filtered()?.totalCount}
            </div>
          </Show>
        }
      />
    </>
  );
};
