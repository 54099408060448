import { createMagicDoorContext } from '~/contexts/utils';
import { companySettingsRepo } from '~/repositories/settings/companySettingsRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

export const [CompanySettingsProvider, useCompanySettings] = createMagicDoorContext('CompanySettings', () => {
  const [companySettings, companySettingsActions] = createLazyResource(() => companySettingsRepo.getCompanySettings());
  const changeCompanySettings = createMutation(async (payload: MagicDoor.Api.CompanySettingsDto) => {
    await companySettingsRepo.changeCompanySettings({
      ...companySettings(),
      ...payload,
    });
  });

  return {
    changeCompanySettings,
    companySettings,
    companySettingsActions,
  };
});
