import { createContext, useContext } from 'solid-js';
import { createStore, produce, unwrap } from 'solid-js/store';
import {
  compareTwoNamePath,
  getErrorMessage,
  isControlledByYourself,
  namePathToString,
  stringToNamePath,
  transFormNamePath,
} from '~/components/common/BetterForm/utils';
import { cleanData, cloneDeep } from '~/utils/tool';
import type { BetterForm } from '~/components/common/BetterForm/type';

const defaultFormContext = createContext<BetterForm.ContextValue>();

export const getFormContext = () => {
  return defaultFormContext;
};

export const useFormContext = (formContext?: ReturnType<typeof getFormContext>) => {
  const context = useContext(defaultFormContext || formContext);
  if (!context) {
    throw new Error('Form context is not found');
  }
  return context;
};

export const getValueFromStore = (store: BetterForm.FormStore, name?: BetterForm.NamePath) => {
  if (!name) {
    return;
  }
  const _namePath = transFormNamePath(name);
  return _namePath.reduce((acc, cur) => acc?.[cur], store);
};

export const copyStore = (store: BetterForm.FormStore) => {
  return cloneDeep(store);
};

export const useForm = (config?: { validateTriggers?: BetterForm.ValidateTriggers[]; onFieldChange?: () => void }) => {
  const [configStore, setConfigStore] = createStore<BetterForm.ConfigStore>({
    fieldsErrors: {},
    rules: {},
    validateTriggers: config?.validateTriggers,
  });

  const [formStore, setFormStore] = createStore<BetterForm.FormStore>({});

  function setFormError(namePath: BetterForm.NamePath, error: string | undefined) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      setConfigStore(
        produce((draft) => {
          const path = namePathStr.join('.');
          error ? (draft.fieldsErrors[path] = error) : delete draft.fieldsErrors[path];
        })
      );
    }
  }

  function validateForm(name?: BetterForm.NamePath, setError = true) {
    const { rules, fieldsErrors } = unwrap(configStore);
    const _formStore = unwrap(formStore);
    const result: {
      errorFields: any[];
      validateStatus: boolean;
    } = {
      errorFields: [],
      validateStatus: true,
    };
    for (const key in rules) {
      const namePath = stringToNamePath(key);
      if (name && !compareTwoNamePath(namePath, name)) continue;
      const value = getValueFromStore(_formStore, namePath);
      const rule = rules[key];
      if (isControlledByYourself(rule)) {
        if (fieldsErrors[key]) {
          result.validateStatus = false;
          result.errorFields?.push({
            name: namePath,
          });
        }
        continue;
      }
      const { isError, message } = getErrorMessage(value, rule);
      if (isError) {
        result.validateStatus = false;
        result.errorFields?.push({
          name: namePath,
        });
        if (setError || !!getFormFieldError(namePath)) setFormError(namePath, message);
      } else {
        setFormError(namePath, '');
      }
    }

    return result;
  }

  function setFormFieldRules(rules: BetterForm.Rule[], namePath: BetterForm.NamePath) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      setConfigStore('rules', (_rules) => ({ ..._rules, [namePathStr.join('.')]: rules }));
    }
  }

  function getFormFieldError(namePath?: BetterForm.NamePath) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      return configStore.fieldsErrors[namePathStr.join('.')];
    }
  }

  function setFieldValue(name: BetterForm.NamePath | undefined, value: any, needClean = true) {
    if (!name) return;
    const { validateTriggers } = unwrap(configStore);
    const cleanedValue = needClean ? cleanData(value) : value;

    const _namePath = transFormNamePath(name);
    setFormStore(
      produce((draft) => {
        _namePath.reduce((acc, cur, index) => {
          if (index === _namePath.length - 1) {
            if (cleanedValue !== undefined) {
              acc[cur] = cleanedValue;
            } else {
              delete acc[cur];
            }
          } else {
            const defaultValue = Number.isNaN(+_namePath[index + 1]) ? {} : [];
            acc[cur] = acc[cur] || defaultValue;
          }
          return acc[cur];
        }, draft);
      })
    );
    validateForm(name, !!validateTriggers?.includes('onChange'));
  }

  function setFieldsValue(values: any, needClean = true) {
    const meaningfulValues = needClean ? cleanData(values) : values;
    const { validateTriggers } = unwrap(configStore);

    if (formStore) {
      setFormStore(cloneDeep(meaningfulValues));
      validateForm(undefined, !!validateTriggers?.includes('onChange'));
    }
  }

  function getFieldValue(name: BetterForm.NamePath) {
    return getValueFromStore(formStore, name);
  }

  function removeFormField(name: BetterForm.NamePath, opts: BetterForm.RemoveFormFieldOpts = {}) {
    setConfigStore(
      produce((draft) => {
        delete draft.rules[namePathToString(name)];
        delete draft.fieldsErrors[namePathToString(name)];
      })
    );
    if (opts.removeValue) {
      setFormStore(
        produce((draft) => {
          const _namePath = transFormNamePath(name);
          _namePath.reduce((acc, cur, index) => {
            if (index === _namePath.length - 1) {
              delete acc[cur];
            }
            return acc[cur];
          }, draft);
        })
      );
    }
  }

  function resetFields(namePaths?: BetterForm.NamePath[]) {
    if (!namePaths) {
      setFormStore(
        produce((draft) => {
          Object.keys(draft).forEach((key) => {
            draft[key] = undefined;
          });
        })
      );
      return;
    }
    namePaths.forEach((cur) => {
      setFieldValue(cur, undefined);
    });
  }

  function scrollToField(name: BetterForm.NamePath) {
    let id = name;
    if (id instanceof Array) id = id.join('_');
    const targetElement: any = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoViewIfNeeded({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  return {
    get isValidate() {
      return Object.values(configStore.fieldsErrors || {}).every((cur) => !cur);
    },
    formStore,
    setFormStore,
    configStore,
    setConfigStore,

    setFieldValue,
    setFieldsValue,
    getFieldValue,

    resetFields,

    setFormError,
    setFormFieldRules,
    getFormFieldError,
    validateForm,

    removeFormField,
    scrollToField,

    onFieldChange: config?.onFieldChange,
  };
};
