import { Show } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { PropertyTitle } from '~/components/properties/PropertyTitle';
import { TransactionStatus } from '~/components/transactions/TransactionStatus';
import { IconCirclePlus } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useOwnerTransfers } from '~/contexts/local';
import { FundsListView, FundsNavbar } from '~/pages/owner-funds/components';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

export const ContributionsPage = () => {
  const { t } = useLocalization();
  const { ownerTransfers } = useOwnerTransfers();

  const getProperty = (propertyId?: string) => {
    return ownerTransfers()?.properties?.find((property) => property.id === propertyId);
  };

  return (
    <div class="size-full space-y-8 p-8">
      <FundsNavbar />
      <div class="relative">
        <FundsListView
          type="contribution"
          title={t('Owner contribution')}
          titleActions={
            <>
              <PrintTablePageButton
                caption={{
                  title: 'Owner contribution',
                }}
                table={{
                  columns: [
                    {
                      title: t('Date'),
                      render: (item) => dateFormat('MM/DD/YYYY', item.created),
                    },
                    {
                      title: t('Status'),
                      render: (item) => (
                        <Show
                          when={ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)}
                          fallback={emptyPlaceholder}>
                          <TransactionStatus
                            status={ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)?.status}
                          />
                        </Show>
                      ),
                    },
                    {
                      title: t('Property'),
                      render: (item) => (
                        <div class="max-w-60 overflow-hidden">
                          <Show when={getProperty(item.propertyId)} fallback={emptyPlaceholder}>
                            <PropertyTitle
                              class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium"
                              property={getProperty(item.propertyId)}
                            />
                          </Show>
                        </div>
                      ),
                    },
                    {
                      title: t('Memo'),
                      render: (item) => ownerTransfers()?.bills?.find((bill) => bill.id === item.billId)?.memo ?? emptyPlaceholder,
                    },
                    {
                      title: t('Owner'),
                      render: (item) => {
                        const owner = ownerTransfers()?.owners?.find((owner) => owner.id === item.ownerId);
                        return owner?.firstName || owner?.lastName ? `${owner.firstName || ''} ${owner.lastName || ''}` : emptyPlaceholder;
                      },
                    },
                    {
                      title: t('Amount'),
                      render: (item) => (item.amount ? currency(item.amount) : emptyPlaceholder),
                    },
                  ],
                  data: ownerTransfers()?.items ?? [],
                }}
              />
              <LinkButton href="/accounting/owner-funds/contributions/add" noScroll>
                <IconCirclePlus class="size-5" />
                {t('Add contribution')}
              </LinkButton>
            </>
          }
          page={ownerTransfers()?.currentPage}
          pageSize={ownerTransfers()?.pageSize}
          totalPages={ownerTransfers()?.totalPages}
          data={ownerTransfers()?.items}
        />
      </div>
    </div>
  );
};
