import { Route, Routes, useNavigate, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useUnits } from '~/contexts/global';
import {
  DebugProvider,
  LeaseDepositsProvider,
  LeaseProvider,
  LeaseRentChargesProvider,
  LeaseRentTransactionsProvider,
  LeasesListProvider,
  MaintenanceRequestsProvider,
  useLease,
  LeaseAutoPaymentsProvider,
  ServiceBillProvider,
} from '~/contexts/local';
import { EditLeaseView } from '~/pages/leases/edit-lease';
import LeasePreview from '~/pdfsigner/ui/screens/LeasePreview';
import { LAST_DELETED_LEASE_ID } from '~/utils/constant';
import { LeaseDetails } from './LeaseDetails';
import { useUpdates } from './UpdatePagesContext';

const LeaseDetailsWrapper = () => {
  const { lease, getLease } = useLease();
  const params = useParams();
  const { getUnit, unit } = useUnits();
  const { updateSignal } = useUpdates();
  const navigate = useNavigate();

  createEffect(() => {
    updateSignal();

    if (params.leaseId !== sessionStorage.getItem(LAST_DELETED_LEASE_ID)) {
      getLease(params.leaseId);
      return;
    }

    const _unit = unit();
    if (_unit?.activeLeases?.length) {
      navigate(`../${_unit.activeLeases[0].id}`, { replace: true });
    }
  });

  createEffect(() => {
    updateSignal();
    const unitId = lease()?.unitId;
    if (unitId) getUnit(unitId); // for navbar
  });

  return (
    <Routes>
      <Route path="edit-lease" component={EditLeaseView} />
      <Route path="/draft/:draftId" component={LeasePreview} />
      <Route path="*" component={LeaseDetails} />
    </Routes>
  );
};

export const LeaseDetailsView = () => {
  return (
    <DebugProvider>
      <MaintenanceRequestsProvider>
        <ServiceBillProvider>
          <LeasesListProvider>
            <LeaseProvider>
              <LeaseRentChargesProvider>
                <LeaseRentTransactionsProvider>
                  <LeaseDepositsProvider>
                    <LeaseAutoPaymentsProvider>
                      <LeaseDetailsWrapper />
                    </LeaseAutoPaymentsProvider>
                  </LeaseDepositsProvider>
                </LeaseRentTransactionsProvider>
              </LeaseRentChargesProvider>
            </LeaseProvider>
          </LeasesListProvider>
        </ServiceBillProvider>
      </MaintenanceRequestsProvider>
    </DebugProvider>
  );
};
