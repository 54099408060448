import {
  faidToConnectErrorString,
  fallbackErrorString,
  fileTooLargeErrorString,
  HttpError,
  unknownErrorString,
  getErrorMessage,
} from '@magicdoor/errors';
import { useLocalization } from '~/contexts/global';
import type { HttpErrorJson } from '@magicdoor/errors';

export enum PermissionErrorCode {
  MismatchTenantException = 'MismatchTenantException',
  MismatchCurrentPasswordException = 'MismatchCurrentPasswordException',
  Unknown = 'unknown',
}

const permissionErrorTypeMap: Record<PermissionErrorCode, string> = {
  [PermissionErrorCode.MismatchTenantException]: 'NO_PERMISSION',
  [PermissionErrorCode.MismatchCurrentPasswordException]: 'OLD_PASSWORD_IS_INCORRECT',
  [PermissionErrorCode.Unknown]: 'NO_PERMISSION',
};

export const errorCodeMap = {
  ...permissionErrorTypeMap,
  [fileTooLargeErrorString]: 'THE_SIZE_OF_THE_FILE_IS_TOO_LARGE',
  [unknownErrorString]: 'OPS_SOMETHING_WENT_WRONG',
  [fallbackErrorString]: 'OPS_SOMETHING_WENT_WRONG',
  [faidToConnectErrorString]: 'FAILED_TO_CONNECT',
};

export const parseErrorMessage = (error?: Error): string | string[] => {
  const errorMessage = getErrorMessage(error).toString();
  if (error instanceof HttpError) {
    const { t } = useLocalization();
    if (errorMessage in errorCodeMap) {
      return t(errorCodeMap[errorMessage as keyof typeof errorCodeMap]);
    }
    const { detail, errors = {} } = error.getJson<HttpErrorJson>() || {};
    return detail || (Object.values(errors).flat() as string[]);
  }
  return errorMessage;
};
