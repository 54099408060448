import { Route, Routes } from '@solidjs/router';
import { BillsProvider, PayVendorBillsProvider } from '~/contexts/local';
import { AddBillView } from './add-bill';
import BillDetailsPage from './bill-details';
import { ListBillsPage } from './list-bills';
import { PayBillView } from './pay-bill/PayBillView';

const BillsRoutes = () => {
  return (
    <BillsProvider>
      <PayVendorBillsProvider>
        <Routes>
          <Route path="/add" component={AddBillView} />
          <Route path="/pay" component={PayBillView} />
          <Route path="/:billId/:type?/*" component={BillDetailsPage} />
          <Route path="*" component={ListBillsPage} />
        </Routes>
      </PayVendorBillsProvider>
    </BillsProvider>
  );
};

export default BillsRoutes;
