import { useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { PrintPageHeader } from '~/components/print';
import { IconLoader, Select } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { percentFormat } from '~/utils/number';
import { ReportTable, parseColumns } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const PropertyDirectroyReport: Component<{ class?: string; report?: MagicDoor.Api.PropertyDirectoryReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      name: 'Management fee percent',
      id: 'managementFeeRate',
      type: 'string' as const,
    },
    {
      name: 'Minimum fee',
      id: 'minimumManagementFee' as const,
    },
    {
      name: 'Reserve',
      id: 'reserve' as const,
    },
    {
      name: 'Owner(s)-phone numbers',
      id: 'ownerPhoneNumbers',
      type: 'string' as const,
    }, // lack some fields
  ];

  const lines = () => {
    const res: LineItem[] = [];

    props?.report?.items?.forEach((item) => {
      res.push({
        label: item?.property?.name,
        level: 0,
        values: {
          minimumManagementFee: item.minimumManagementFee,
          reserve: item.reserve,
          managementFeeRate: percentFormat(item.managementFeeRate),
          ownerPhoneNumbers: item.owners?.map((x) => `${x.firstName} ${x?.lastName ?? ''}-Phone: ${x?.phone ?? ''}`)?.join(' , '),
        },
      });
    });
    return res;
  };

  return <ReportTable label={t('Property')} lines={lines()} columns={parseColumns(columns)} />;
};

export const PropertyDirectoryReportPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { companies } = useCompanies();

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const [searchParams, setSearchParams] = useSearchParams<{
    active?: string;
  }>();
  const { propertyDirectoryReport, getPropertyDirectoryReport } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Property directory') }]);

  const handleChangeActive = (value: string) => {
    if (value === 'All') {
      setSearchParams({ active: '' }, { replace: true });
    } else {
      setSearchParams({ active: value }, { replace: true });
    }
  };

  createEffect(() => {
    const filter: any = {};
    if (searchParams.active !== '') {
      filter['active'] = searchParams.active;
    }
    getPropertyDirectoryReport({
      ...filter,
    });
  });

  const printHeader = createMemo(() => {
    const active = searchParams.active;

    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <h1 class="text-center text-xl font-semibold text-title-gray">{t('Property directory report')}</h1>
          <p class="text-center text-xs text-[#5E6077]">
            {t('Active')} : {t(active === 'true' ? 'True' : active === 'false' ? 'False' : 'All')}
          </p>
        </div>
      </div>
    );
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Property directory report')}</h1>

          <PrintButton
            color="primary"
            variant="outlined"
            disabled={propertyDirectoryReport.loading}
            printContainer={printContainer()!}
            printWrapClass="w-auto"
            printHeader={printHeader() as Element}
            isReport={true}
            extraStyle="zoom:0.5"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <Select
            class="min-w-64 grow"
            prefix={t('Active')}
            options={[
              { label: t('All'), value: 'All' },
              { label: t('True'), value: 'true' },
              { label: t('False'), value: 'false' },
            ]}
            value={searchParams.active || 'All'}
            onChange={handleChangeActive}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={propertyDirectoryReport.loading} fallback={<PropertyDirectroyReport report={propertyDirectoryReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </div>
  );
};
