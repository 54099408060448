import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { GetTaxDocumentsUseCase } from './getTaxDocumentsUseCase';
import type { NextTaxDocumentBatchRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class GetNextTaxDocumentBatchUseCase extends MagicUseCase {
  public async runLogic(request: NextTaxDocumentBatchRequest) {
    const existingDocuments = this.getState().taxInfo.paginatedTaxDocuments;
    if (request.currentIndex < existingDocuments.totalItems) {
      const indexSet = new Set<number>();
      if (existingDocuments.items[request.currentIndex] === undefined) {
        indexSet.add(request.currentIndex);
      }
      const nextIndex = Math.min(request.currentIndex + 20, existingDocuments.totalItems - 1);
      if (existingDocuments.items[nextIndex] === undefined) {
        indexSet.add(nextIndex);
      }
      const prevIndex = Math.max(request.currentIndex - 20, 0);
      if (existingDocuments.items[prevIndex] === undefined) {
        indexSet.add(prevIndex);
      }
      const requests = Array.from(indexSet).map((index) => {
        const page = this.findPageByIndex(index, existingDocuments.pageSize, existingDocuments.totalItems);
        return new GetTaxDocumentsUseCase().execute({
          entityType: request.entityType,
          entityId: request.entityId,
          page: page,
          itemsPerPage: existingDocuments.pageSize,
        });
      });
      await Promise.all(requests);
    }
  }

  private findPageByIndex(index: number, pageSize: number, totalItems: number): number {
    if (index < 0) {
      return 1;
    }
    if (index >= totalItems) {
      return Math.floor(totalItems / pageSize) + 1;
    }
    return Math.floor(index / pageSize) + 1;
  }
}
