import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';
import type { AnnotationCreationRequest } from '~/pdfsigner/usecases/types/annotationCreationRequest';
import type { BoundingBox } from '~/pdfsigner/usecases/types/boundingBox';
import type { Position } from '~/pdfsigner/usecases/types/position';

export class CreateAnnotationUseCase extends MagicUseCase {
  public async runLogic(request: AnnotationCreationRequest) {
    let isEditing = this.getState().isEditing;
    if (this.getState().selectedAnnotationType === undefined) {
      isEditing = false;
    }
    if (!request || !this.shouldCreateAnnotation(request)) {
      return;
    }
    const annotation = this.createNewAnnotationFromRequest(request, this.getState().selectedAnnotationType!);
    this.getState().selectedDocument?.pdfPages[request.page].annotations.push(annotation);
    this.getState().isEditing = isEditing;
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: annotation.id, page: request.page });
    this.getState().selectedAnnotationType = undefined;
  }

  private shouldCreateAnnotation(request: AnnotationCreationRequest): boolean {
    const selectedDocument = this.getState().selectedDocument;
    if (!request || !selectedDocument) {
      return false;
    }
    const pageBounds = request.relativePosition.boundingBox;
    const mousePosition = request.relativePosition.position;
    return (
      this.isPdfLoaded() &&
      this.getState().selectedAnnotationType != undefined &&
      request.page >= 0 &&
      request.page < selectedDocument.pdfPages.length &&
      this.isMousePositionInsidePageBounds(mousePosition, pageBounds) &&
      pageBounds.width > 0 &&
      pageBounds.height > 0
    );
  }

  private isMousePositionInsidePageBounds(mousePosition: Position, pageBounds: BoundingBox): boolean {
    return (
      mousePosition.x >= pageBounds.x &&
      mousePosition.x <= pageBounds.x + pageBounds.width &&
      mousePosition.y >= pageBounds.y &&
      mousePosition.y <= pageBounds.y + pageBounds.height
    );
  }

  private createNewAnnotationFromRequest(request: AnnotationCreationRequest, type: AnnotationType): Annotation {
    const position = request.relativePosition.position;
    const pageBounds = request.relativePosition.boundingBox;
    const x = (position.x - pageBounds.x) / pageBounds.width;
    const y = (position.y - pageBounds.y) / pageBounds.height;
    const width = (type === AnnotationType.CHECKMARK ? 25 : 120) / pageBounds.width;
    const height = 25 / pageBounds.height;
    const time: string = JSON.stringify(new Date()).replace(/\D/g, '');
    const id = `${time}${request.relativePosition.position.x}${request.relativePosition.position.y}${request.page}`;
    const signerId = this.isSignerRelatedField(this.getState().selectedAnnotationType) ? this.getState().selectedSigner.id : undefined;

    return {
      id,
      x,
      y,
      width,
      height,
      type: this.getState().selectedAnnotationType as AnnotationType,
      text: type === AnnotationType.CHECKMARK ? '✓' : '',
      fontSize: this.getState().selectedFontSize,
      signerId,
      isInputField: this.isInputField(this.getState().selectedAnnotationType),
      dataPathKey:
        this.getState().selectedAnnotationType === AnnotationType.AUTOFILL
          ? this.getState().dataPathManager.getSelectedNodeKey()
          : undefined,
    };
  }

  private isInputField(annotationType?: AnnotationType): boolean {
    return (
      annotationType === AnnotationType.SIGNATURE || annotationType === AnnotationType.INITIALS || annotationType === AnnotationType.DATE
    );
  }
}
