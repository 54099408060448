import { Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import { useLocalization, useRentalApplicationPayment } from '~/contexts/global';
import { PaymentLoading } from '~/pages/rental-applications/add-rental-application/PaymentStatus';

export const SelectOnlinePaymentOrSkipPayment = () => {
  const { t } = useLocalization();
  const { skipPayment, applicationPaymentSettings } = useRentalApplicationPayment();

  return (
    <Show when={applicationPaymentSettings()} fallback={<PaymentLoading />}>
      <div class="flex flex-1 flex-col justify-between divide-y p-6">
        <Section border title={t('Please pay the application fee')}>
          <div class="flex flex-col gap-3 divide-y rounded-lg bg-light-gray px-6 py-4 text-text-level01">
            <div class="flex flex-col gap-2">
              <div class="flex justify-between text-sm ">
                <span>{t('Application fee')}</span>
                <span>$ {applicationPaymentSettings()?.applicationFee}</span>
              </div>
            </div>
            <div class="flex justify-between pt-3 text-base font-medium">
              <span>{t('Total')}</span>
              <span>$ {applicationPaymentSettings()?.applicationFee}</span>
            </div>
          </div>
        </Section>
        <div class="flex justify-end gap-4 pt-6">
          <Button
            id="new-rental-application-skip-payment-btn"
            variant="white"
            onClick={() => {
              skipPayment();
            }}
            loading={skipPayment.loading}>
            {t('Skip payment')}
          </Button>
          <Button id="new-rental-application-online-payment-btn" href="../payment" variant="solid">
            {t('Online payment')}
          </Button>
        </div>
      </div>
    </Show>
  );
};
