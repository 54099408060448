import { useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createResource, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { PrintPageHeader } from '~/components/print';
import { IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { emptyPlaceholder } from '~/utils/constant';
import { PropertySelect } from './components/PropertySelect';
import { ReportTable, parseColumns } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const TenantDirectroyReport: Component<{ class?: string; report?: MagicDoor.Api.TenantDirectoryReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      name: 'Unit',
      id: 'unit',
      type: 'string' as const,
    },
    {
      name: 'Tenant',
      id: 'tenant',
      type: 'string' as const,
    },
    {
      name: 'Phone number',
      id: 'phone',
      type: 'string' as const,
    },
    {
      name: 'Email',
      id: 'email',
      type: 'string' as const,
    },
  ];

  const lines = () => {
    const res: LineItem[] = [];

    props?.report?.items?.forEach((item) => {
      res.push({
        label: item?.leases?.[0]?.property?.name || emptyPlaceholder,
        level: 0,
        values: {
          unit: item?.leases?.[0]?.unit?.name,
          tenant: item?.tenant?.firstName + ' ' + (item?.tenant?.lastName ?? ''),
          phone: item?.tenant?.phone,
          email: item?.tenant?.email,
        },
      });
    });
    return res;
  };

  return <ReportTable label={t('Property')} lines={lines()} columns={parseColumns(columns)} />;
};

export const TenantDirectoryReportPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { companies } = useCompanies();
  const [searchParams] = useSearchParams<{
    portfolioIds?: string;
    propertyIds?: string;
    includeNoActiveLease?: string;
  }>();

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const { getTenantDirectoryReport, tenantDirectoryReport, getFilterDisplay } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Tenant directory') }]);

  createEffect(() => {
    const params: any = {};

    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds.split(',');
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds.split(',');

    if (searchParams.includeNoActiveLease) {
      params.includeNoActiveLease = searchParams.includeNoActiveLease === 'true';
    }
    getTenantDirectoryReport(params);
  });

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds, searchParams.portfolioIds],
    async ([propertyIds, portfolioIds]) => {
      return await getFilterDisplay(propertyIds, portfolioIds);
    }
  );

  const printHeader = createMemo(() => {
    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <h1 class="text-center text-xl font-semibold text-[#3F4156]">{t('Tenant directory report')}</h1>
          <Show when={filterDisplay()?.portfoliosStr || filterDisplay()?.propertiesStr}>
            <p class="text-center text-lg font-medium text-title-gray">
              {filterDisplay()?.portfoliosStr || t('All')} - {filterDisplay()?.propertiesStr || t('All properties')}
            </p>
          </Show>
        </div>
      </div>
    );
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Tenant directory report')}</h1>
          <PrintButton
            color="primary"
            variant="outlined"
            disabled={tenantDirectoryReport.loading}
            printContainer={printContainer()!}
            printWrapClass="w-auto"
            isReport={true}
            printHeader={printHeader() as Element}
            extraStyle="zoom:0.5"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
        </div>

        <div ref={(e) => setPrintContainer(e)}>
          <Show when={tenantDirectoryReport.loading} fallback={<TenantDirectroyReport report={tenantDirectoryReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </div>
  );
};
