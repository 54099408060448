import CnMoney from '~/components/common/CnMoney';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import type { JSX } from 'solid-js';
import type { HydratedCheckDto } from '~/swagger/Api';

const Item = (props: { label: string; value: JSX.Element }) => {
  return (
    <div class="flex items-center gap-2">
      <span class="text-lg text-text-level02">{props.label}:</span>
      <span class="text-text-level02">{props.value}</span>
    </div>
  );
};

export const PrintCheckModal = (props: { check?: HydratedCheckDto; onDone: () => void }) => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  return (
    <Modal
      visible
      onDone={props.onDone}
      doneText={t('Print')}
      class="lg:w-modalSm"
      title={t('Check')}
      onCancel={() => {
        goBack();
      }}>
      <div class="flex flex-col gap-2 p-6 text-base text-text-level02">
        <Item label={t('Check number')} value={`#${props.check?.checkNumber}`} />
        <Item label={t('From bank account')} value={props.check?.bankAccount?.name} />
        <Item label={t('Memo')} value={props.check?.memo} />
        <Item label={t('Amount')} value={<CnMoney money={props.check?.amount} />} />
      </div>
    </Modal>
  );
};
