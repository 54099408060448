import { useParams } from '@solidjs/router';
import { createMemo, onMount } from 'solid-js';
import { useLeaseDeposits } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { LeaseDepositTransactionDetailsModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionDetailsModal';
import type { Component } from 'solid-js';

type LeaseDepositDetailModalProps = {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseDepositDetailModal: Component<LeaseDepositDetailModalProps> = (props) => {
  const goBack = useGoBack();
  const params = useParams<{ leaseId: string; transactionId: string }>();
  const { leaseDeposit, deposit, depositActions } = useLeaseDeposits();

  const currentDeposit = createMemo(() => {
    return leaseDeposit().find((item) => item.id === params.transactionId);
  });

  onMount(() => {
    if (!leaseDeposit().length) depositActions.refetch(params.leaseId);
  });

  return (
    <LeaseDepositTransactionDetailsModal
      currentLease={props.currentLease}
      detailLoading={deposit.loading}
      visible={true}
      showBillDate
      transactionDetail={currentDeposit()!}
      onCancel={() => goBack()}
    />
  );
};
