import { PdfLoader } from '~/pdfsigner/repositories/pdfLoader';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { GetNextTaxDocumentBatchUseCase } from './getNextTaxDocumentBatchUseCase';
import type { VendorTaxDocumentPrintRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class PrintAllVendorTaxDocsUseCase extends MagicUseCase {
  public async runLogic(request: VendorTaxDocumentPrintRequest) {
    const existingDocuments = this.getState().taxInfo.paginatedTaxDocuments.items;
    const pageSize = this.getState().taxInfo.paginatedTaxDocuments.pageSize;
    const missingPages = new Set<number>();
    for (let i = 0; i < existingDocuments.length; i++) {
      if (existingDocuments[i] === undefined) {
        missingPages.add(Math.floor(i / pageSize) + 1);
      }
    }
    if (missingPages.size > 0) {
      const promises = Array.from(missingPages).map((page) =>
        new GetNextTaxDocumentBatchUseCase().execute({
          entityType: request.entityType,
          page,
          itemsPerPage: pageSize,
        })
      );
      await Promise.all(promises);
    }
    const yearlyDocs = this.getState().taxInfo.paginatedTaxDocuments.items.filter(
      (taxDocument) => taxDocument.calendarYear === request.year
    );
    const pdfUrls = yearlyDocs.map((taxDocument) => taxDocument.url);
    const pdfLoader = new PdfLoader();
    this.getState().taxInfo.mergedPdf = await pdfLoader.loadMergedPdf(pdfUrls);
  }
}
