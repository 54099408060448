import { Empty } from '~/components/common/Empty';
import { useLocalization } from '~/contexts/global';

export const ReportNotReady = () => {
  const { t } = useLocalization();
  return (
    <div class="flex size-full items-center justify-center">
      <Empty description={t('Report is processing, please wait')} />
    </div>
  );
};
