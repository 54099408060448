import { A, useNavigate, useSearchParams } from '@solidjs/router';
import { createMemo, createEffect, Show, onMount, createSignal, createResource } from 'solid-js';
import { ChartOfAccountSelect } from '~/components/chart-of-accounts';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { PrintPageHeader } from '~/components/print';
import { getTransactionPaymentMethod } from '~/components/transactions';
import { DatePicker, IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { commaNumber } from '~/utils/number';
import { PropertySelect } from './components/PropertySelect';
import { ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const GeneralLedgerReport: Component<{ class?: string; report?: MagicDoor.Api.GeneralLedgerReportDataDto }> = (props) => {
  const { t } = useLocalization();

  const columns = () => [
    {
      label: t('Date'),
      type: 'date' as const,
      render: ({ values }: LineItem) => values?.date && dateFormat('MM/DD/YYYY', values.date as string),
      style: {
        '--max-width': '150px',
      },
    },
    { label: t('Payee / Payer'), type: 'string' as const, render: ({ values }: LineItem) => values?.payeeOrPayerName },
    {
      label: t('Type'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.paymentMethod && t(getTransactionPaymentMethod(values.paymentMethod as any)),
      style: {
        '--max-width': '100px',
      },
    },
    {
      label: t('Debit'),
      type: 'number' as const,
      render: ({ values }: LineItem) => values && values.debit !== null && commaNumber(values.debit as string),
      style: {
        '--max-width': '100px',
      },
    },
    {
      label: t('Credit'),
      type: 'number' as const,
      render: ({ values }: LineItem) => values && values.credit !== null && commaNumber(values.credit as string),
      style: {
        '--max-width': '100px',
      },
    },
    {
      label: t('Balance'),
      type: 'number' as const,
      render: ({ values }: LineItem) => values && values.balance !== null && commaNumber(values.balance as string),
    },
    {
      label: t('Memo'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.memo,
      style: {
        '--max-width': '500px',
      },
    },
  ];

  const lines = () => {
    if (props.report?.chartOfAccounts == null) return [];

    return props.report.chartOfAccounts.flatMap<LineItem>((item, i) => [
      { label: t(item.chartOfAccount?.name as string), level: 0 },
      { label: t('Starting balance'), level: 1, values: { debit: null, credit: null, balance: item.startingBalance } },
      ...(item.lineItems?.map((inner) => {
        const property = props.report?.properties?.find((p) => p.id === inner.propertyId);
        return {
          label: (
            <A class="text-link hover:underline" href={`/portfolios/${property?.portfolioId}/properties/${property?.id}`}>
              {property?.name}
            </A>
          ),
          level: 1,
          values: inner as any,
        };
      }) ?? []),
      { label: t('Net change'), level: 1, values: { debit: null, credit: null, balance: item.netChange } },
      { label: '', type: 'total', level: 1, values: { debit: item.totalDebit, credit: item.totalCredit, balance: item.endingBalance } },
      ...(i + 1 === props.report?.chartOfAccounts?.length ? [] : [{ type: 'empty' as const }]),
    ]);
  };

  return <ReportTable class="[&_[data-level='1']]:!font-normal" label={t('Property')} lines={lines()} columns={columns() as any} />;
};

export const GeneralLedgerReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { companies } = useCompanies();
  const [searchParams, setSearchParams] = useSearchParams<{
    start: string;
    end: string;
    portfolioIds?: string;
    propertyIds?: string;
    chartOfAccountIds?: string;
    includeNoPropertyBills?: string;
    companyBills?: string;
  }>();

  const { generalLedgerReport, getGeneralLedgerReport, getFilterDisplay } = useReports();
  const [routeLevel, setRouteLevel] = createSignal<number>(-1);

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('General ledger') }]);

  onMount(() => {
    setSearchParams(
      {
        start: searchParams.start ?? new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString().split('T')[0],
        end: searchParams.end ?? new Date().toISOString().split('T')[0],
      },
      { replace: true }
    );
  });

  createEffect(() => {
    if (!searchParams.start || !searchParams.end) return;
    const params: any = {
      dateRange: { start: searchParams.start, end: searchParams.end },
      includeChartOfAccountIds: searchParams.chartOfAccountIds?.split(','),
      includeNoPropertyBills: searchParams.includeNoPropertyBills === 'true',
      companyBills: searchParams.companyBills === 'true',
    };
    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds?.split(',').filter(Boolean);
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds?.split(',').filter(Boolean);

    getGeneralLedgerReport(params);
    setRouteLevel((prevLevel) => prevLevel - 1);
  });

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds, searchParams.portfolioIds],
    async ([propertyIds, portfolioIds]) => {
      return await getFilterDisplay(propertyIds, portfolioIds);
    }
  );

  const printHeader = createMemo(() => {
    const start = searchParams.start;
    const end = searchParams.end;

    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <p class="text-center text-xl font-semibold leading-[30px] text-title-gray">{t('General ledger report')}</p>
          <Show when={filterDisplay()?.portfoliosStr || filterDisplay()?.propertiesStr}>
            <p class="text-center text-lg font-medium text-title-gray">
              {filterDisplay()?.portfoliosStr || t('All')} - {filterDisplay()?.propertiesStr || t('All properties')}
            </p>
          </Show>
          <p class="text-center text-[#5E6077]">{`${start} - ${end}`}</p>
        </div>
      </div>
    );
  });

  const [printContainerWidth, setPrintContainerWidth] = createSignal<string>('2000px');

  createEffect(() => {
    if (!generalLedgerReport.loading) {
      const clientWidth = printContainer()?.children[0]?.children[1]?.clientWidth;
      clientWidth && setPrintContainerWidth(`${clientWidth + clientWidth * 0.2}px`);
    }
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => navigate(routeLevel())} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('General ledger report')}</h1>

          <PrintButton
            color="primary"
            variant="outlined"
            disabled={generalLedgerReport.loading}
            printHeader={printHeader() as Element}
            printWrapClass="w-auto"
            isReport={true}
            printContainer={printContainer()!}
            extraStyle={`width:${printContainerWidth()};zoom:0.5`}
            orientation="landscape"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
          <DatePicker
            prefix={t('Date range start')}
            class="min-w-64 grow"
            value={searchParams.start}
            onChange={(value) => {
              if (value && searchParams.end <= value) {
                const start = new Date(value);
                start.setDate(start.getDate() + 30);
                setSearchParams({ ...searchParams, end: start.toISOString().split('T')[0] }, { replace: true });
              } else {
                setSearchParams({ ...searchParams, start: value }, { replace: true });
              }
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            prefix={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              if (value && searchParams.start >= value) {
                const end = new Date(value);
                end.setDate(end.getDate() - 30);
                setSearchParams({ ...searchParams, start: end.toISOString().split('T')[0] }, { replace: true });
              } else {
                setSearchParams({ ...searchParams, end: value }, { replace: true });
              }
            }}
          />
          <ChartOfAccountSelect
            prefix={t('Chart of account')}
            class="min-w-64 grow"
            placeholder={t('All')}
            value={searchParams.chartOfAccountIds?.split(',')}
            onChange={(value) => setSearchParams({ chartOfAccountIds: value.toString() }, { replace: true })}
            multiple
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include company bills')}</span>}
            checked={searchParams.companyBills === 'true'}
            onChange={(e) => setSearchParams({ ...searchParams, companyBills: e.target.checked }, { replace: true })}
          />

          {/* TODO: BACKEND WILL UPDATE THIS TO A NEW FORMAT, HIDE FOR NOW */}

          {/* <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include No Property Bills')}</span>}
            checked={searchParams.includeNoPropertyBills === 'true'}
            onChange={(e) => setSearchParams({ includeNoPropertyBills: e.target.checked })}
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Company Bills')}</span>}
            checked={searchParams.companyBills === 'true'}
            onChange={(e) => setSearchParams({ companyBills: e.target.checked })}
          /> */}
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={generalLedgerReport.loading} fallback={<GeneralLedgerReport report={generalLedgerReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </div>
  );
};
