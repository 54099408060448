import { createEffect, on } from 'solid-js';
import { Form } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { RentalHistoryList } from '~/components/rental-application/components/RentalHistoryList';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType } from '~/utils/constant';

export const RentalHistoryModal = () => {
  const { applicationStore, setApplicationStore, baseUpdate } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  createEffect(
    on([() => applicationStore.currentUpdateType], () => {
      if (applicationStore.currentUpdateType !== currentUpdateType.rental) return;
      form.setFieldsValue({
        residentialHistory: applicationStore.application?.residentialHistory,
      });
    })
  );

  function onHide() {
    setApplicationStore('currentUpdateType', null);
    form.resetFields();
  }

  const onOk = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await baseUpdate(form.formStore);
    onHide();
  };

  return (
    <BaseModal
      class="w-[820px!important]"
      title={t('Update rental history')}
      visible={applicationStore.currentUpdateType === currentUpdateType.rental}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="thinscroll flex max-h-[50vh] flex-col gap-2 overflow-y-auto p-4">
        <Form defaultForm={form}>
          <RentalHistoryList />
        </Form>
      </div>
      <Footer onOk={onOk} okProps={{ loading: applicationStore.updateLoading }} onCancel={onHide} />
    </BaseModal>
  );
};
