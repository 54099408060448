import { For, Show, createMemo } from 'solid-js';
import IconBusiness from '~/assets/images/settings/company/business.svg';
import { useFormContext } from '~/components/common/BetterForm';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditFormItem } from '~/pages/settings/components/edit-form/EditForm';
import { LegalEntityType } from '~/swagger/Api';
import { addressRequired, ein } from '~/utils/formRules';
import { FileUpload } from './FileUpload';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const CompanyBusinessInformation = () => {
  const { t } = useLocalization();

  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'legalName',
      label: t('Legal name'),
      type: 'string',
      placeholder: t('Please enter legal name'),
    },
    {
      field: 'ein',
      label: t('EIN'),
      type: 'string',
      placeholder: t('Please enter EIN'),
      rules: ein(t),
    },
    {
      field: 'legalEntityType',
      label: t('Legal Entity Type'),
      type: 'select',
      placeholder: t('Please select legal entity type'),
      options: [
        { label: t('Sole Proprietorship'), value: LegalEntityType.SoleProprietorship },
        { label: t('LLC'), value: LegalEntityType.Llc },
        { label: t('Private Company'), value: LegalEntityType.PrivateCompany },
        { label: t('Public Company Unlisted'), value: LegalEntityType.PublicCompanyUnlisted },
        { label: t('Public Company Listed'), value: LegalEntityType.PublicCompanyListed },
        { label: t('Charitable Organization Not For Profit'), value: LegalEntityType.CharitableOrganizationNotForProfit },
        { label: t('Partnership'), value: LegalEntityType.Partnership },
      ],
    },
    {
      field: 'businessAddress',
      label: t('Business address'),
      type: 'address',
      class: 'col-span-full',
      rules: addressRequired(t),
    },
    {
      field: 'businessNameFileId',
      label: t('Business name file'),
      type: FileUpload,
      description: t('Either the Articles of Incorporation for the company or a Recent Certificate of Good Standing'),
      class: 'col-span-full',
    },
    {
      field: 'businessAddressFileId',
      label: t('Business address file'),
      type: FileUpload,
      description: t(
        'A document that proves the business address provided belongs to the business. May be a utility bill or bank statement. Must be from the past 3 months'
      ),
      class: 'col-span-full',
    },
    {
      field: 'irsFileId',
      label: t('EIN'),
      type: FileUpload,
      description: t('IRS-issued EIN Letter (Form CP-575)'),
      class: 'col-span-full',
    },
  ]);

  const form = useFormContext();

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Business information')} icon={IconBusiness} />}
      middle={
        <div class="grid gap-x-5 gap-y-6 p-4 text-left md:grid-cols-2">
          <For each={sections()}>
            {(section) => (
              <Show when={!section.visibleMethod || section.visibleMethod(form.formStore)}>
                <EditFormItem
                  {...section}
                  disabled={typeof section.disabled === 'function' ? section.disabled(form.formStore) : section.disabled}
                  style={typeof section.style === 'function' ? section.style(form.formStore) : section.style}
                />
              </Show>
            )}
          </For>
        </div>
      }
    />
  );
};
