import { createEffect, onCleanup, createSignal, onMount } from 'solid-js';

const MOBILE_BREAKPOINT = 767;

const isMobile = () => {
  if (typeof window === 'undefined') return false;

  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) ||
    window.innerWidth < MOBILE_BREAKPOINT
  );
};

export const useDeviceDetect = () => {
  const [isMobileView, setIsMobileView] = createSignal(false);
  const [isSidebarVisible, setIsSidebarVisible] = createSignal(false);

  const isOverlayPresent = () => {
    const overlayElements = document.querySelectorAll('.overlay');
    if (overlayElements.length > 0) {
      return true;
    }
    return false;
  };

  onMount(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
  });

  onCleanup(() => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  });

  let touchstartX = 0;
  let touchendX = 0;

  const handleTouchStart = (e: TouchEvent): void => {
    touchstartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: TouchEvent): void => {
    const threshold = 75;
    touchendX = e.changedTouches[0].screenX;

    const isOverlay = isOverlayPresent();

    if (touchendX < touchstartX - threshold && isSidebarVisible()) {
      setIsSidebarVisible(false);
    }
    if (touchendX > touchstartX + threshold && !isSidebarVisible() && !isOverlay) {
      setIsSidebarVisible(true);
    }
  };

  createEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setIsMobileView(isMobile());
    });

    resizeObserver.observe(document.body);

    onCleanup(() => resizeObserver.disconnect());
  });

  return { isMobileView, isSidebarVisible, setIsSidebarVisible };
};
