import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount, Show } from 'solid-js';
import { PageCloseGuard } from '~/components/common/PageCloseGuard';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { NEW_RENTAL_APPLICATION_REVIEW_STEP, useNewRentalApplication } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { Employment } from '~/pages/rental-applications/add-rental-application/Employment';
import { Identity } from '~/pages/rental-applications/add-rental-application/Identity';
import { QuestionAndTerms } from '~/pages/rental-applications/add-rental-application/QuestionAndTerms';
import { RentalHistory } from '~/pages/rental-applications/add-rental-application/RentalHistory';
import { TenantProfile } from '~/pages/rental-applications/add-rental-application/TenantProfile';

export const StepsBeforePayment = () => {
  const goBack = useGoBack();
  const { store, onStepChange, setStore, fetchApplication } = useNewRentalApplication();

  const params = useParams<{
    applicationId: string;
    step: string;
  }>();

  const components = createMemo(() => {
    const base = [TenantProfile, RentalHistory, Employment, Identity, QuestionAndTerms];
    return base;
  });

  onMount(() => {
    if (params.applicationId) {
      fetchApplication(params.applicationId);
    }
  });

  createEffect(() => {
    if (params.step) {
      const step = store.steps.findIndex((step) => step.key === params.step);
      if (step !== -1) {
        setStore({ currentStep: step });
      }
    }
  });

  const [isDirty, setIsDirty] = createSignal(false);

  const handleSubmit = async () => {
    await onStepChange(NEW_RENTAL_APPLICATION_REVIEW_STEP);
  };
  const _onStepChange = async (step: number) => {
    if (step === store.currentStep) return;
    await onStepChange(step);
    setIsDirty(false);
  };

  return (
    <Show when={store.currentStep < NEW_RENTAL_APPLICATION_REVIEW_STEP}>
      <PageCloseGuard isDirty={isDirty()}>
        <MultiSteps
          stepper={
            <div class="flex items-center justify-center pb-10">
              <Stepper class="p-9" steps={store.steps.map((step) => step.label)} step={store.currentStep} />
            </div>
          }
          currentStep={store.currentStep}
          contentClass="[&>div]:mx-auto [&>div]:mb-10 [&>div]:max-w-4xl"
          onStepChange={_onStepChange}
          onClose={() => goBack()}
          onSubmit={handleSubmit}
          disableNext={store.disableNext}
          showPrevious
          steps={components()}
          loading={store.stepChangeLoading}
        />
      </PageCloseGuard>
    </Show>
  );
};
