import { ChatAiLease } from '~/pages/settings/chats/ai/ChatAiLease';
import { ChatAiMaintenanceRequests } from '~/pages/settings/chats/ai/ChatAiMaintenanceRequests';

export const ChatsAIWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <ChatAiLease />
      <ChatAiMaintenanceRequests />
    </div>
  );
};
