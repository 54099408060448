import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

const COMPANIES_URL = '/api/companies';

export const validateCompanySubUrl = (model: MagicDoor.Api.CompanySubUrlDto) => {
  if (typeof model?.url !== 'string' || model.url.length < 3) {
    throw new ValidationError('Invalid company sub url');
  }
};

export class CompaniesRepository extends BaseRestRepository {
  public async getCompanies(): Promise<MagicDoor.Api.CompanyDto> {
    const url = COMPANIES_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateSubUrl(model: MagicDoor.Api.CompanySubUrlDto | MagicDoor.Api.CompanyDomainDto): Promise<MagicDoor.Api.CompanyDto> {
    const url = `${COMPANIES_URL}/sub-url`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async updateCustomUrl(model: MagicDoor.Api.CompanyDomainDto | MagicDoor.Api.CompanySubUrlDto): Promise<MagicDoor.Api.CompanyDto> {
    const url = `${COMPANIES_URL}/custom-url`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async validateSubUrl(model: MagicDoor.Api.CompanySubUrlDto): Promise<MagicDoor.Api.ValidateDto> {
    validateCompanySubUrl(model);
    const url = `${COMPANIES_URL}/sub-url/validate`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async createCustomUrl(model: MagicDoor.Api.CompanySubUrlDto): Promise<MagicDoor.Api.CompanyDto> {
    validateCompanySubUrl(model);
    const url = `${COMPANIES_URL}/custom-url`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async validateCustomUrl(model: MagicDoor.Api.CompanyDomainDto): Promise<MagicDoor.Api.ValidateDto> {
    // validateCompanySubUrl(model);
    const url = `${COMPANIES_URL}/custom-url/validate`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async updateCompanyProfile(model: MagicDoor.Api.UpdateCompanyDto): Promise<MagicDoor.Api.CompanyDto> {
    const url = COMPANIES_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async getPortalSettings() {
    const url = `${COMPANIES_URL}/portal-settings`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async uploadFiles(file: File | Blob, name: string, process?: boolean) {
    const url = `/api/files`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    // formData.append('process', process as boolean);
    formData.append('process', process as any);

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async updatePortalSettings(model: MagicDoor.Api.UpdatePortalSettingsDto) {
    const url = `${COMPANIES_URL}/portal-settings`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return await this.getJsonResponse(response);
  }
}

export const companyRepository = new CompaniesRepository();
