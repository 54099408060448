import { useParams } from '@solidjs/router';
import { createEffect, createSignal, Switch, Match, createMemo, Show } from 'solid-js';
import AccountSettingIcon from '~/assets/images/leases/accountSetting.svg?component-solid';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, useForm, FormItem } from '~/components/common/BetterForm';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { PropertyTitle } from '~/components/properties';
import { toast } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useProperties, useUnits } from '~/contexts/global';
import { useServiceBill } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { formConfirmationContent, MAX_INPUT_AMOUNT } from '~/utils/constant';
import { currency, isNumber } from '~/utils/number';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

export type AddServiceBillType = {
  propertyId: string;
  unitId?: string;
  leaseId?: string;
  amount: number;
  propertyBillMemo?: string;
  companyBillMemo?: string;
  propertyChartOfAccountId: string;
  companyChartOfAccountId: string;
};

type AddServiceBillTypeModalProps = Omit<ModalProps, 'title' | 'visible'> & {
  type: 'property' | 'unit' | 'lease';
  currentPropertyId?: string;
  currentUnitId?: string;
  currentLease?: MagicDoor.Api.HydratedLeaseDto;
};

function isInRange(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

export const AddServiceBillTypeModal: Component<AddServiceBillTypeModalProps> = (props) => {
  const { t } = useLocalization();
  const { units, getUnits } = useUnits();
  const { current } = useProperties();
  const { addServiceBill } = useServiceBill();
  const goBack = useGoBack();
  const params = useParams<{ propertyId?: string; unitId?: string }>();
  const form = useForm();

  createEffect(() => {
    if (units() === undefined && props.type === 'unit') getUnits({});
  });

  const unitId = createMemo(() => props.currentUnitId || params.unitId);

  const currentUnit = createMemo(() => {
    return units()?.items?.find((x) => x.id === unitId());
  });

  const formId = 'service_bill_form';

  const [loading, setLoading] = createSignal<boolean>(false);

  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();

    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    setLoading(true);
    try {
      const params: any = {
        ...form.formStore,
      };

      params.propertyId = props.currentPropertyId || current()?.id;

      switch (props.type) {
        case 'unit':
          params.propertyId = currentUnit()?.propertyId;
          params.unitId = unitId();
          break;
        case 'lease':
          params.unitId = props.currentLease?.unitId;
          params.leaseId = props.currentLease?.id;
          break;
      }

      if (unitId()) {
        params.unitId = unitId();
      }

      await addServiceBill(params);

      props.onCancel && props.onCancel();

      toast.success(t('{name} has been added successfully', { name: 'Add service bill' }));
      goBack();
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    const flag = Object.keys(form.formStore).some((key) => form.formStore[key] !== undefined);
    setShowCloseConfirmation(flag);
  });

  const onCancel = () => {
    goBack();
  };

  const isEffectiveAmount = createMemo(() => form?.formStore.amount && isInRange(form?.formStore.amount, 1, MAX_INPUT_AMOUNT));

  return (
    <Modal
      {...props}
      onCancel={onCancel}
      visible={true}
      title={t('Add service bill')}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="thinscroll flex flex-col gap-5 overflow-auto px-8 pb-16 pt-6" id={formId} onSubmit={handleSubmit}>
        <Switch>
          <Match when={props.type === 'property'}>
            <PropertyTitle property={current()} />
          </Match>

          <Match when={props.type === 'unit' && currentUnit()}>
            <UnitTitle unit={currentUnit()} />
          </Match>

          <Match when={props.type === 'lease'}>
            <LeaseOverviewBanner lease={props.currentLease!} />
          </Match>
        </Switch>

        <div>
          <div class="flex gap-2 border-b border-partingline py-3 text-text-level01">
            <AccountSettingIcon />
            <span class="font-semibold">{t('Chart of account settings')}</span>
          </div>
          <div class="">
            <Form defaultForm={form} class="grid grid-cols-2 gap-x-7 gap-y-6 pb-5 pt-4">
              <FormItem
                placeholder={t('Please select')}
                class="col-span-full lg:col-span-1"
                rules={[{ required: true, message: t(`Please select chart of account`) }]}
                label={t('Company chart of account')}
                formFieldName="companyChartOfAccountId"
                component={LabeledChartOfAccountSelect}
              />
              <FormItem
                placeholder={t('Please enter')}
                class="col-span-full lg:col-span-1"
                label={t('Company bill memo')}
                formFieldName="companyBillMemo"
                component={LabeledTextInput}
              />

              <FormItem
                placeholder={t('Please select')}
                class="col-span-full lg:col-span-1"
                rules={[{ required: true, message: t(`Please select chart of account`) }]}
                label={t('Property chart of account')}
                formFieldName="propertyChartOfAccountId"
                component={LabeledChartOfAccountSelect}
              />

              <FormItem
                placeholder={t('Please enter')}
                class="col-span-full lg:col-span-1"
                label={t('Property bill memo')}
                formFieldName="propertyBillMemo"
                component={LabeledTextInput}
              />

              <FormItem
                rules={[
                  { message: t(`Please input the amount`), required: true },
                  {
                    range: [1, MAX_INPUT_AMOUNT],
                    type: 'number',
                    message: t('{name} must be between {min} and {max}', { name: t('Amount'), min: '1', max: MAX_INPUT_AMOUNT }),
                  },
                ]}
                placeholder={t('Please enter')}
                class="col-span-full lg:col-span-2"
                label={t('Amount')}
                formFieldName="amount"
                component={LabeledNumberInput}
                max={MAX_INPUT_AMOUNT}
                isBlur={false}
              />
            </Form>
          </div>
        </div>
      </form>
      <div class="relative flex w-full items-center justify-end bg-pink-100 py-5">
        <img src={ModalBgIcon} class="absolute bottom-0 left-4 h-20 w-52" />
        <div class="mr-4 flex items-center">
          <span class="mr-2 text-xs uppercase lg:text-sm">{t('Total amount')}:</span>
          <Show when={isEffectiveAmount()} fallback={<span class="text-sm font-bold text-text-level03 lg:text-2xl">--</span>}>
            <span class="text-sm font-bold text-essential-colour lg:text-2xl">
              {currency(isNumber(form?.formStore.amount) ? form?.formStore.amount : 0)}
            </span>
          </Show>
        </div>
      </div>
    </Modal>
  );
};
