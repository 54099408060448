import { Show } from 'solid-js';
import IconAllProperties from '~/assets/images/owner-funds/allProperties.svg';
import IconSomeProperties from '~/assets/images/owner-funds/someProperties.svg';
import { Section } from '~/components/common/FormSectionTitle';
import { MutiSelect } from '~/components/common/MutiSelect';
import { PropertyTitle } from '~/components/properties/PropertyTitle';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions, PropertiesCategory } from '~/contexts/local';
import { cn } from '~/utils/classnames';

const PropertiesCategoryOption = (props: { title: string; icon: string; isSelected: boolean; onClick: () => void }) => (
  <div
    onClick={() => props.onClick()}
    class={cn(
      'inline-flex grow cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-primary',
      props.isSelected && 'border-primary bg-[rgba(161,38,236,0.06)] text-primary'
    )}>
    <div class="relative mr-4 pr-3">
      <img src={props.icon} class="w-16" />
      <div class="absolute inset-y-[9px] left-full w-px bg-input-border" />
    </div>
    <span>{props.title}</span>
  </div>
);

export const ChoseProperty = () => {
  const { t } = useLocalization();
  const { store, onChangeSelectedProperties, onChangePropertiesCategory } = useAddOwnerDistributions();

  const onSelectAllProperties = () => {
    onChangePropertiesCategory(PropertiesCategory.ALL);
  };

  const onSelectSomeProperties = () => {
    onChangePropertiesCategory(PropertiesCategory.SOME);
    onChangeSelectedProperties([null]);
  };

  return (
    <Section required title={t('Properties')} border class="px-[96px]">
      <div class="grid grid-cols-2 gap-4">
        <PropertiesCategoryOption
          title={t('All properties')}
          icon={IconAllProperties}
          isSelected={store.selectedCategory === PropertiesCategory.ALL}
          onClick={onSelectAllProperties}
        />
        <PropertiesCategoryOption
          title={t('Some properties')}
          icon={IconSomeProperties}
          isSelected={store.selectedCategory === PropertiesCategory.SOME}
          onClick={onSelectSomeProperties}
        />
        <Show when={store.selectedCategory === PropertiesCategory.SOME}>
          <MutiSelect<MagicDoor.Api.HydratedPropertyDto>
            items={store.selectedProperties.map((item) => item?.id as string)}
            onMutiSelectInput={(_, newProperties) => {
              onChangeSelectedProperties(newProperties);
            }}
            renderSelector={({ exclude, onSelect }) => (
              <LabeledLeveledSearchSelect
                exclude={{
                  [CustomLevelSearch.Property]: exclude,
                }}
                onChange={(selected: any[]) => onSelect(selected[0])}
                placeholder={t('Select properties')}
                class="w-full"
                showInactivePortfolios={true}
                enabledTypes={[CustomLevelSearch.Property]}
              />
            )}
            addButtonText={t('Add a new property')}
            renderSelectedItem={(item) => <PropertyTitle property={item} />}
            initialSelectedItems={store.selectedProperties as MagicDoor.Api.HydratedPropertyDto[]}
          />
        </Show>
      </div>
    </Section>
  );
};
