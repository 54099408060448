import { useNavigate, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, onMount, Show } from 'solid-js';
import { TransactionStatus } from '~/components/transactions';
import { TableActions } from '~/components/ui/DropdownActions';
import { IconCreditCard } from '~/components/ui/Icons';
import { ListView } from '~/components/ui/ListView';
import { useCompanyInvoices, useLocalization } from '~/contexts/global';
import { PayInvoiceModal } from '~/pages/company-revenue/company-invoices-tab/PayInvoiceModal';
import { isPaid } from '~/pages/company-revenue/company-invoices-tab/invoiceUtils';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { FilterItems } from '~/components/ui/Filter';
import type { ListViewArgs } from '~/components/ui/ListView';
import type { TableColumn } from '~/components/ui/Table/Table';
import type { GetInvoicesParams } from '~/repositories/companyInvoices';
import type { CompanyInvoiceDto } from '~/swagger/Api';

export default function InvoiceList() {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { invoicesWithPagination, getInvoices, invoices, invoicesWithPaginationActions, invoicesFilter, refetchInvoices } =
    useCompanyInvoices();

  onMount(() => {
    getInvoices({
      from: dayjs().startOf('month').format('YYYY-MM-DD'),
      to: dayjs().endOf('month').format('YYYY-MM-DD'),
    });
  });

  const columns = createMemo<TableColumn<CompanyInvoiceDto>[]>(() => [
    { title: t('ID'), render: (item) => item.id },
    {
      title: t('Date'),
      render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.created)}</div>,
    },
    {
      title: t('Status'),
      render: (item) => {
        return <TransactionStatus status={item.allocations.at(0)?.transaction?.status} />;
      },
    },
    { title: t('Amount'), render: (item) => currency(item.totalAmount) },
    {
      render: (item) =>
        !isPaid(item) ? (
          <TableActions
            actions={[
              {
                label: t('Pay'),
                icon: IconCreditCard,
                onClick: () => navigate(`./?invoiceId=${item.id}`),
              },
            ]}
          />
        ) : null,
    },
  ]);

  const filterItems = createMemo(
    () =>
      [
        { type: 'group', label: t('Start date') },
        { type: 'date', key: 'from', label: t('Start date') },
        { type: 'group', label: t('End date') },
        { type: 'date', key: 'to', label: t('End date') },
      ] as FilterItems<GetInvoicesParams>
  );

  const handleChange = ({ page, pageSize, filter }: ListViewArgs<any>) => {
    invoicesWithPaginationActions.setPage(page);
    invoicesWithPaginationActions.setPageSize(pageSize);
    if (filter) {
      getInvoices(filter);
    }
  };

  return (
    <>
      <ListView
        loading={invoices.loading}
        onChange={handleChange}
        title={t('Invoices')}
        data={invoicesWithPagination().items}
        totalPages={invoicesWithPagination().totalPages}
        page={invoicesWithPagination().page}
        pageSize={invoicesWithPagination().pageSize}
        columns={columns()}
        rowLink={(item) => `./${item.id}`}
        filterItems={filterItems()}
        filterValue={invoicesFilter()}
      />
      <Show when={searchParams.invoiceId}>
        <PayInvoiceModal
          onPaymentSuccessed={() => {
            refetchInvoices();
          }}
        />
      </Show>
    </>
  );
}
