import { Routes, Route, useNavigate, useLocation } from '@solidjs/router';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { TabLinks, IconCirclePlus, Select } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { LeasesListProvider } from '~/contexts/local';
import { LeasesDraft } from './LeaseDraft';
import { LeaseRenewal } from './LeaseRenewal';
import { LeasesList } from './LeasesList';
import { LeasesOverview } from './LeasesOverview';
import { LeasesPast } from './LeasesPast';

export const ListLeases = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split('/').pop();
  const selectValue = currentPath === 'leases' ? '' : currentPath || '';

  const leaseRouteOptions = [
    { label: t('Overview'), href: '' },
    { label: t('Leases'), href: 'list' },
    { label: t('Expiring leases'), href: 'expiring-leases' },
    { label: t('Draft leases'), href: 'draft' },
    { label: t('Past leases'), href: 'past-leases' },
  ];

  return (
    <div class="flex size-full flex-col gap-y-4 p-3 md:p-8">
      <div class="flex w-full items-center gap-2 bg-white px-2 py-1.5 md:hidden">
        <div class="flex-1">
          <Select
            class="h-10 border-essential-colour text-essential-colour"
            options={leaseRouteOptions.map((option) => ({ label: option.label, value: option.href }))}
            value={selectValue}
            onChange={(value) => navigate(value === '' ? '/leasing/leases' : `/leasing/leases/${value}`)}
          />
        </div>

        <div class="w-fit">
          <LinkButton href="/leasing/leases/new" class="h-10 p-[10px] font-medium" noScroll>
            <IconCirclePlus class="text-white" />
          </LinkButton>
        </div>
      </div>

      <div class="hidden w-full items-center justify-between overflow-hidden rounded-lg border border-partingline bg-white md:flex">
        <TabLinks items={leaseRouteOptions} />
        <LinkButton href="/leasing/leases/new" class="mr-3">
          <PlusIcon />
          <div class="hidden truncate md:flex">{t('Set up a new lease')}</div>
        </LinkButton>
      </div>
      <LeasesListProvider>
        <Routes>
          <Route path="/" component={LeasesOverview} />
          <Route path="/list" component={LeasesList} />
          <Route path="/draft" component={LeasesDraft} />
          <Route path="/past-leases" component={LeasesPast} />
          <Route path="/expiring-leases" component={LeaseRenewal} />
        </Routes>
      </LeasesListProvider>
    </div>
  );
};
