import { createMemo, Show } from 'solid-js';
import Tooltip from '~/components/common/Tooltip';
import { IconAlert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';

export const LeaseDeposits = (props: {
  lease?: {
    securityDepositPaid?: number;
    securityDepositRequested?: number;
    securityDepositDue?: number;
  };
  class?: string;
  children?: (infoLabel: JSX.Element, haveDue: boolean) => JSX.Element;
  showPrefix?: boolean | string;
}) => {
  const haveDue = createMemo(() => !!props.lease?.securityDepositDue);
  const { t } = useLocalization();

  const tooltipMessage = createMemo(() => {
    return (
      <div class="flex gap-4 px-4 py-3">
        <div class="flex flex-col items-center gap-1">
          <div class="text-base font-bold">{currency(props.lease?.securityDepositPaid)}</div>
          <div class="text-xs">{t('Paid')}</div>
        </div>
        <div class="flex flex-col items-center gap-1">
          <div class="text-base font-bold">{currency(props.lease?.securityDepositRequested)}</div>
          <div class="text-xs">{t('Requested')}</div>
        </div>
        <div class="flex flex-col items-center gap-1">
          <div class="text-base font-bold text-danger">{currency(props.lease?.securityDepositDue)}</div>
          <div class="text-xs">{t('Due')}</div>
        </div>
      </div>
    );
  });

  const infoLabel = createMemo(() => {
    return (
      <div class={cn('flex items-center gap-2', props.class)}>
        <Show when={props.showPrefix}>
          <span class="font-normal text-text-level02">{typeof props.showPrefix === 'string' ? props.showPrefix : t('Deposits hold')}</span>
        </Show>
        <div class="flex items-center gap-1">
          <div class="flex items-center gap-1">
            <span class="text-text-level01">{currency(props.lease?.securityDepositPaid)}</span>
            <Show when={props.lease?.securityDepositRequested}>
              <span class="text-text-level03">
                <span>/</span>
                <span>{currency(props.lease?.securityDepositRequested)}</span>
              </span>
            </Show>
          </div>
          <Tooltip message={tooltipMessage()} popupClass="max-w-full">
            <IconAlert class="size-4 text-text-level03" />
          </Tooltip>
        </div>
      </div>
    );
  });

  return (
    <Show when={typeof props.children === 'function'} fallback={infoLabel()}>
      {props.children?.(infoLabel(), haveDue())}
    </Show>
  );
};
