import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { ListView } from '~/components/ui';
import { IconChevronDown } from '~/components/ui/Icons';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization } from '~/contexts/global';
import { useAnnouncements } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { urlWithQuery } from '~/utils/url';
import type { AnnouncementListQuery } from '~/repositories/announcementRepository';
import type { AnnouncementListDto } from '~/swagger/Api';

interface AnnouncementsListViewProps {
  filter?: AnnouncementListQuery;
  hideAddButton?: boolean;
  newAnnouncementsParams?: {
    [key: string]: string;
  };
}

const AnnouncementsListView = (props: AnnouncementsListViewProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('AnnouncementsListView');
  const { isLoading, announcements, fetchAnnouncements } = useAnnouncements();

  const getAnnouncements = async () => {
    await fetchAnnouncements(props.filter);
  };

  const announcementList = createMemo<AnnouncementListDto[]>(() => {
    const data = announcements();

    if (!data || typeof data !== 'object' || !('announcements' in data)) {
      return [];
    }

    return Array.isArray(data.announcements) ? data.announcements : [];
  });

  createEffect(() => {
    getAnnouncements();
  });

  return (
    <div class="relative w-full">
      <MobileListView
        class="md:hidden"
        titleClass="w-fit"
        headerClass="flex-row justify-between"
        renderItem={(row) => {
          return (
            <div
              class="flex justify-between p-3"
              onClick={() => {
                navigate(`/communications/announcements/${row.id}`);
              }}>
              <div class="flex-1">
                <div>
                  <div class="mb-1 font-medium">{row.subject || t('No Subject')}</div>
                  <div class="text-gray-400">{row.chatMessage || row.emailMessage || t('No Message')}</div>
                </div>

                <div class="mt-2.5 flex justify-between">
                  <div class="text-gray-400">
                    {t('Targets count')}: {row.targetsCount}
                  </div>
                  <div class="text-gray-400">
                    {t('Sent at')}: {dateFormat(undefined, row.sentAt)}
                  </div>
                </div>
              </div>
              <div class="w-5 pt-5">
                <IconChevronDown class="size-4 -rotate-90" />
              </div>
            </div>
          );
        }}
        page={page()}
        pageSize={pageSize()}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        title={t('Announcement')}
        titleActions={
          <Show when={!props.hideAddButton}>
            <div class="flex items-center justify-end gap-2">
              <Button
                class="ml-auto px-3 text-sm capitalize"
                onClick={() => {
                  const link = urlWithQuery(`/communications/announcements/add`, props.newAnnouncementsParams);
                  navigate(link);
                }}>
                <IconPlus class="text-white" />
                {t('Add announcement')}
              </Button>
            </div>
          </Show>
        }
        data={announcementList()}
        loading={isLoading()}
      />

      <ListView
        class="hidden md:block"
        page={page()}
        pageSize={pageSize()}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        title={t('Announcement')}
        titleActions={
          <Show when={!props.hideAddButton}>
            <div class="flex items-center gap-2">
              <Button
                class="ml-auto text-sm capitalize"
                onClick={() => {
                  const link = urlWithQuery(`/communications/announcements/add`, props.newAnnouncementsParams);
                  navigate(link);
                }}>
                <IconPlus class="text-white" />
                {t('Add announcement')}
              </Button>
            </div>
          </Show>
        }
        data={announcementList()}
        loading={isLoading()}
        skeletonSize={10}
        rowLink={(item) => `/communications/announcements/${item.id}`}
        columns={[
          {
            title: t('Subject'),
            render: (row: AnnouncementListDto) => (
              <div>
                <div class="mb-1 font-medium">{row.subject || t('No Subject')}</div>
                <div class="text-gray-400">{row.chatMessage || row.emailMessage || t('No Message')}</div>
              </div>
            ),
          },
          {
            title: t('Sent at'),
            render: (row: AnnouncementListDto) => <div class="font-medium">{dateFormat(undefined, row.sentAt)}</div>,
          },
          {
            title: t('Targets count'),
            render: (row: AnnouncementListDto) => <div class="font-medium">{row.targetsCount}</div>,
          },
        ]}
      />
    </div>
  );
};

export default AnnouncementsListView;
