import { useParams } from '@solidjs/router';
import { useForm } from '~/components/common/BetterForm';
import { useVendors } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { AddVendorPayment } from './AddVendorPayment';
import type { Component } from 'solid-js';

export const AddVendorPaymentModal: Component = () => {
  const params = useParams<{ vendorId: `${number}` }>();
  const goBack = useGoBack();

  const form = useForm();
  const { addVendorPaymentAccount, paymentAccountsActions } = useVendors();

  const handleClose = () => {
    goBack();
    return;
  };

  const handleSubmit = async () => {
    await addVendorPaymentAccount(params.vendorId, form.formStore as MagicDoor.Api.CreateVendorPaymentAccountDto);
    goBack();
    paymentAccountsActions.refetch();
  };

  return (
    <>
      <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div class="w-fit rounded-lg bg-white">
          <AddVendorPayment onClose={handleClose} form={form} onSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};
