import { Show, splitProps } from 'solid-js';
import Tooltip from '~/components/common/Tooltip';
import { cn } from '~/utils/classnames';
import { Button } from './Button';
import type { ButtonProps } from './Button';
import type { Component, JSX } from 'solid-js';

type CallToActionButton = ButtonProps & {
  isSkeleton?: boolean;
  tooltipMessage?: string | JSX.Element;
  wrapperClass?: string;
};

const CallToActionButton: Component<CallToActionButton> = (props) => {
  const [, buttonProps] = splitProps(props, ['isSkeleton', 'tooltipMessage', 'wrapperClass']);

  const ButtonComponent = <Button {...buttonProps} />;

  return (
    <div class={cn('relative', props.wrapperClass)}>
      <Show when={props.disabled && !props.isSkeleton} fallback={ButtonComponent}>
        <Tooltip message={props.tooltipMessage}>{ButtonComponent}</Tooltip>
      </Show>
    </div>
  );
};

export default CallToActionButton;
