import { Route, Routes } from '@solidjs/router';
import { BankAccountsListProvider } from '~/contexts/local';
import { CompanyPaymentMethods } from '~/pages/settings/company-settings/company-payment-accounts';
import { CompanyBusiness } from './company-business';
import { CompanyInformation } from './company-information';
const CompanyRoutes = () => {
  return (
    <BankAccountsListProvider>
      <Routes>
        <Route path="/information" element={<CompanyInformation />} />
        <Route path="/payment-accounts/*" element={<CompanyPaymentMethods />} />
        <Route path="/business" element={<CompanyBusiness />} />
      </Routes>
    </BankAccountsListProvider>
  );
};

export default CompanyRoutes;
