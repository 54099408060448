import { useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, createMemo, createSignal, Show, on } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { getDescription } from '~/components/leases/utils';
import { PrintPageHeader } from '~/components/print';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { IconLoader } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useReports, useLeasesList } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { ReportTable, parseColumns } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const PropertyDirectoryReport: Component<{ class?: string; report?: MagicDoor.Api.LeaseUnpaidLedgerReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      name: 'Memo',
      id: 'memo',
      type: 'string' as const,
    },
    {
      name: 'Total amount',
      id: 'totalAmount',
      type: 'number' as const,
    },
    {
      name: 'Amount paid',
      id: 'paid',
      type: 'number' as const,
    },
    {
      name: 'Amount due',
      id: 'due',
      type: 'number' as const,
    },
  ];

  const lines = () => {
    const res: LineItem[] = [];
    const total = { totalAmount: 0, paid: 0, due: 0 };
    props?.report?.unpaidBills?.forEach((item) => {
      total.paid += item?.paid || 0;
      total.due += item?.due || 0;
      total.totalAmount += item?.totalAmount || 0;
      res.push(
        ...[
          {
            label: item.dueDate ? dayjs(item.dueDate).format('MM/DD/YYYY') : '',
            level: 0,
            values: {
              memo: getDescription(item?.memo ? item.memo : item?.lineItems?.[0]?.description),
              totalAmount: item?.totalAmount,
              paid: item?.paid,
              due: item?.due,
            },
          },
        ]
      );
    });
    if (res.length) {
      res.push({
        type: 'total',
        label: t('Total'),
        level: 0,
        values: {
          memo: '',
          totalAmount: total.totalAmount,
          paid: total.paid,
          due: total.due,
        },
      });
    }

    return res;
  };

  return <ReportTable label={t('Due Date')} lines={lines()} columns={parseColumns(columns)} />;
};

export const LeaseUnpaidBillsReportPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { companies } = useCompanies();
  const { getLeaseUnpaidBillsReport, leaseUnpaidBillsReport } = useReports();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();
  const { getLeases, leases, loading: leaseLoading } = useLeasesList();

  const [searchParams, setSearchParams] = useSearchParams<{
    leaseId?: string;
  }>();

  createEffect(() => {
    getLeases({});
  });

  createEffect(() => {
    if (searchParams.leaseId || leases().length === 0) {
      return;
    }

    setSearchParams({ leaseId: leases()[0].id }, { replace: true });
  });

  const selectedLease = createMemo(() => {
    const { leaseId } = searchParams;
    if (leaseId) {
      return leases()?.find((item) => item.id === leaseId);
    }
    return undefined;
  });

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Lease unpaid bills') }]);

  createEffect(
    on(
      () => ({ ...searchParams }),
      () => {
        const { leaseId } = searchParams;

        if (leaseId) {
          getLeaseUnpaidBillsReport({ leaseId });
        }
      }
    )
  );

  const handleSelectType = (lease: MagicDoor.Api.HydratedLeaseDto) => {
    setSearchParams({ leaseId: lease.id }, { replace: true });
  };

  const printHeader = createMemo(() => {
    const leaseName = selectedLease()
      ? `${selectedLease()?.property?.name}, ${selectedLease()?.unit.name} (${selectedLease()?.start}-${selectedLease()?.end ?? ''})`
      : '';

    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <h1 class="text-center text-xl font-semibold text-[#3F4156]">{t('Lease unpaid bills report')}</h1>
          <p class="text-center text-lg font-medium text-title-gray">
            {t('Lease')}: {leaseName}
          </p>
        </div>
      </div>
    );
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="mb-6 flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Lease unpaid bills report')}</h1>

          <PrintButton
            color="primary"
            variant="outlined"
            disabled={leaseUnpaidBillsReport.loading}
            isReport={true}
            printContainer={printContainer()!}
            printWrapClass="w-auto"
            printHeader={printHeader() as Element}
            extraStyle="zoom:0.5"
          />
        </div>

        <div class="my-6 max-w-md">
          <LeveledSearchSelect
            prefix={t('Lease')}
            placeholder={t('All')}
            onChange={(selected: MagicDoor.Api.HydratedLeaseDto[]) => {
              handleSelectType(selected[0]);
            }}
            enabledTypes={[CustomLevelSearch.Lease]}
            initialSelected={[{ id: selectedLease()?.id, __type: CustomLevelSearch.Lease }]}
          />
        </div>

        <div ref={(e) => setPrintContainer(e)}>
          <Show
            when={leaseUnpaidBillsReport.loading || leaseLoading()}
            fallback={<PropertyDirectoryReport report={leaseUnpaidBillsReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </div>
  );
};
