import { useSearchParams } from '@solidjs/router';
import { onMount } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { LabeledVendorSearch } from '~/components/vendors/VendorSearch';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import { priorityTypes } from '~/utils/constant';
import { upperFirst } from '~/utils/tool';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInformation = () => {
  const { t } = useLocalization();

  const { setFieldValue, setFormStore } = useFormContext();
  const { setNewFiles } = useWorkOrders();

  const [searchParams] = useSearchParams();
  const defaultLeaseId = searchParams.leaseId;

  onMount(() => {
    if (defaultLeaseId) {
      setFieldValue('leaseId', defaultLeaseId);
    }
  });

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t('Description')}
        rules={[{ required: true, message: t('Please input description') }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        onFilesListChange={(files: UploaderFile[]) => setNewFiles(files)}
      />
      <FormItem label={t('Title')} formFieldName="title" component={LabeledTextInput} placeholder={t('Please enter')} />

      <FormItem
        label={t(`Property, unit or lease`)}
        rules={[{ required: true, message: t('Please input property、unit or lease') }]}
        formFieldName={'propertyId'}
        component={LabeledLeveledSearchSelect}
        enabledTypes={[LevelSearch.Property, LevelSearch.Unit, LevelSearch.Lease]}
        onChange={(_, variants) => {
          setFormStore({
            leaseId: variants.leveledIds.at(0)?.leaseId,
            unitId: variants.leveledIds.at(0)?.unitId,
            propertyId: variants.leveledIds.at(0)?.propertyId,
          });
        }}
      />

      <FormItem<any>
        class="w-full"
        label={t('Vendor')}
        formFieldName="vendorId"
        component={LabeledVendorSearch}
        placeholder={t('Please select vendor')}
      />

      <div class="grid grid-cols-2 gap-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Priority')}
          rules={[{ required: true, message: t('Please select priority') }]}
          formFieldName="urgency"
          options={priorityTypes.map((item) => ({ value: item.value, label: t(upperFirst(item.label)) }))}
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem class="col-span-full lg:col-span-1" label={t('Due date')} formFieldName="dueDate" component={DueDateInputField} />
      </div>
    </div>
  );
};
