import { useLocalization } from '~/contexts/global';
import type { MinimumPaymentType } from '~/swagger/Api';

export type MinimumPaymentSettings = {
  minimumPaymentType: MinimumPaymentType;
  /**
   * @format double
   * @min 0.01
   * @max 1
   */
  minimumPaymentPercent?: number | null;
  /**
   * @format double
   * @min 1
   * @max 1000
   */
  minimumPaymentAmount?: number | null;

  [key: string]: any;
};

export const getMinimumPaymentSettings = (values: MinimumPaymentSettings) => {
  const { t } = useLocalization();

  return [
    {
      field: 'minimumPaymentType',
      label: 'Minimum payment type',
      type: 'select',
      options: [
        { label: t('Fixed'), value: 'fixed' },
        { label: t('Percent'), value: 'percent' },
        { label: t('PercentOrMinimum'), value: 'percentOrMinimum' },
      ],
      class: 'col-span-2',
      labelClass: 'w-1/2',
      labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
    },
    ['percent', 'percentOrMinimum'].includes(values.minimumPaymentType) && {
      field: 'minimumPaymentPercent',
      label: 'Minimum payment percent',
      type: 'number',
      suffix: '%',
      percent: true,
      rules: [
        {
          validator: (value: number) => value <= 1 && value >= 0.01,
          message: t('The value of minimumPaymentPercent is between 1 and 0.01'),
        },
      ],
    },
    ['fixed', 'percentOrMinimum'].includes(values.minimumPaymentType) && {
      field: 'minimumPaymentAmount',
      label: 'Minimum payment amount',
      type: 'number',
      rules: [
        {
          validator: (value: number) => value <= 1000 && value >= 1,
          message: t('The value of minimumPaymentAmount is between 1 and 1000'),
        },
      ],
    },
  ];
};
