import { useParams } from '@solidjs/router';
import { Show, createEffect, createMemo } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { getLeaseDateName, getUnitFullname } from '~/components/leases/utils';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { toast } from '~/components/ui';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLease, useLeaseAutoPayments, useLeaseRentCharges } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { AutoPaymentDetailsModal } from './AutoPaymentDetailsModal';
import { ChargesPanel } from './ChargesPanel';
import { EnumLeaseStatus } from './ChargesTable';
import { EditChargeModal } from './EditChargeModal';
import { TenantsAutoPaymentsPanel } from './TenantsAutoPaymentsPanel';

export const LeaseRentCharges = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const params = useParams();

  let chargesPanelRef: any;

  const { lease } = useLease();
  const { unit } = useUnits();
  const { charges, getRecurringCharges, loading, error, deleteRecurringCharge } = useLeaseRentCharges();
  const { autoPayments, getAutoPayments, isLoading: isAutoPaymentsLoading, error: autoPaymentsError } = useLeaseAutoPayments();

  const editingCharge = createMemo(() =>
    typeof params.chargeId === 'string' && params.chargeId !== 'add'
      ? charges()?.find((charge) => charge.id === params.chargeId)
      : undefined
  );

  const handleUpsertDone = () => {
    goBack();
    // toast(t(`Recurring charge has been ${editingCharge() == null ? 'created' : 'updated'} successfully`), 'success');
  };

  const handleDeleteCharge = async (chargeId: string) => {
    await deleteRecurringCharge(params.leaseId, chargeId);
    toast.success(t('{name} has been deleted successfully', { name: t('Recurring charge') }));
  };

  const ErrorInfo = (
    <div class="flex h-40 items-center justify-center">
      <span class="text-text-level03">{error()?.message}</span>
    </div>
  );

  const getTotal = () => {
    const data: MagicDoor.Api.HydratedRecurringLeaseChargeDto[] = chargesPanelRef.getData();
    const total = currency(data.reduce((previous, current) => previous + current.amount, 0));
    return total;
  };

  createEffect(() => {
    getRecurringCharges(params.leaseId);
    getAutoPayments(params.leaseId);
  });

  return (
    <Show when={!error() && !autoPaymentsError()} fallback={ErrorInfo}>
      <ChargesPanel
        ref={chargesPanelRef}
        actions={
          <div class="flex">
            <Button href="add" class="mr-2 text-sm">
              {t('Set up recurring charge')}
            </Button>
            <PrintTablePageButton
              table={{
                columns: [
                  {
                    title: t('Description/memo'),
                    render: (item) => <div class="font-medium normal-case text-text-level01">{item.description}</div>,
                  },
                  {
                    title: t('Status'),
                    render: (item) => (
                      <div class="flex items-center gap-1 text-xs">
                        <span
                          class="size-1.5 rounded-full"
                          classList={{
                            'bg-green': item.status === 'active',
                            'bg-error': item.status === 'dueSoon',
                            'bg-disabled': item.status === 'ended',
                          }}
                        />
                        <span
                          class="rounded-full px-2 py-1 capitalize"
                          classList={{
                            'text-green': item.status === 'active',
                            'text-error': item.status === 'dueSoon',
                            'text-disabled': item.status === 'ended',
                          }}>
                          {t(EnumLeaseStatus[item.status as MagicDoor.Api.LeaseRecurringChargeStatus])}
                        </span>
                      </div>
                    ),
                  },
                  {
                    title: t('Chart of account'),
                    render: (item) => <div>{item.chartOfAccount?.name || ''}</div>,
                  },
                  {
                    title: t('Start and expire date'),
                    render: (item) => `${dateFormat('', item.startFrom)}${item.end ? ` - ${dateFormat('', item.end)}` : ''}`,
                  },
                  {
                    title: t('Amount'),
                    class: 'text-right',
                    headerClass: 'text-right',
                    render: (item) => `${currency(item.amount)}`,
                  },
                ],
                data: chargesPanelRef.getData(),
              }}
              caption={{
                title: 'Rent charges',
                extraData: [
                  {
                    label: 'Lease',
                    value: getLeaseDateName(lease()!),
                  },
                  {
                    label: 'Unit',
                    value: getUnitFullname(unit()),
                  },
                  {
                    label: 'Total',
                    value: getTotal(),
                  },
                  {
                    label: 'Date',
                    value: dateFormat(),
                  },
                ],
              }}
            />
          </div>
        }
        loading={loading}
        charges={charges().filter((charge) => charge.status !== 'ended')}
        onDelete={handleDeleteCharge}
        showStatus
      />
      <ChargesPanel
        title={t('Expired rent charges')}
        loading={loading}
        charges={charges().filter((charge) => charge.status === 'ended')}
        onDelete={handleDeleteCharge}
      />
      <TenantsAutoPaymentsPanel autoPayments={autoPayments()} isLoading={isAutoPaymentsLoading()} />
      <Show when={params.chargeId != null}>
        <EditChargeModal
          currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
          updatingCharge={editingCharge}
          visible={params.chargeId != null}
          onCancel={() => goBack()}
          onDone={handleUpsertDone}
        />
      </Show>
      <Show when={params.autoPaymentId}>
        <AutoPaymentDetailsModal lease={lease() as MagicDoor.Api.HydratedLeaseDto} />
      </Show>
    </Show>
  );
};
