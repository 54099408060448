import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { LeaseProvider } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { NewLease } from './components/NewLease';

export const NewLeaseView = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const breadcrumbItems = createMemo(() => [{ label: t('Lease'), link: '/leasing/leases' }, { label: t('Set up a new lease') }]);

  return (
    <LeaseProvider>
      <div class="flex size-full flex-col">
        <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
        <Panel class="m-3 flex h-mobileSection flex-col md:m-8 md:h-section1" title={t('Set up a new lease')}>
          <NewLease />
        </Panel>
      </div>
    </LeaseProvider>
  );
};
