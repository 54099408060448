import { useParams } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import noteBg from '~/assets/images/vendors/note_bg.png';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useNotes } from '~/contexts/global';
import { useGoBack } from '~/hooks';

type NoteProps = {
  type: string;
  id: string;
  action: string;
};
export const NoteModal = (props: NoteProps) => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const [textAreaVal, setTextAreaVal] = createSignal<string>('');
  const { addNote, updateNote, getNote } = useNotes();
  const params = useParams<{ vendorId: `${number}`; noteId: `${number}` }>();

  const saveHandle = async () => {
    if (!textAreaVal().trim()) {
      toast.error(t('Please enter'));
      return;
    }

    if (props.action === 'Add') {
      await addNote({ content: textAreaVal(), entityId: props.id, entityType: props.type });
    } else {
      await updateNote({ content: textAreaVal(), entityId: props.id, entityType: props.type, noteId: params.noteId });
    }
    goBack();
  };
  const getCurrentNote = async () => {
    const currentNote = await getNote({ entityId: props.id, entityType: props.type, noteId: params.noteId });
    setTextAreaVal(currentNote.content);
  };
  createEffect(() => {
    if (props.action === 'Edit') {
      getCurrentNote();
    }
  });

  return (
    <Modal
      visible={true}
      title={t(`${props.action} note`)}
      onDone={saveHandle}
      doneText={t('Save')}
      loading={addNote.loading || updateNote.loading}
      class="lg:w-modalSm"
      onCancel={() => {
        goBack();
      }}>
      <div
        class="h-24"
        style={{
          'background-image': `url(${noteBg})`,
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }}
      />
      <div class="p-6">
        <LabeledTextArea rows={5} onInput={(val) => setTextAreaVal(val)} value={textAreaVal()} placeholder={t('Please enter')} />
      </div>
    </Modal>
  );
};
