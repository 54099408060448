import { createSignal, onMount } from 'solid-js';

const [loadTimes, setLoadTimes] = createSignal(0);

export const ShowErrorFallback = () => {
  setLoadTimes(loadTimes() + 1);

  onMount(() => {
    if (loadTimes() === 1) {
      throw new Error('test');
    }
  });

  return (
    <div class="mx-auto mt-44 max-w-[600px] text-center text-xl font-semibold">
      When you see this, it means the error fallback is working
    </div>
  );
};
