import { createMemo, createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { useGoBack } from '~/hooks';
import { VIOLATION_BASE_URL, ViolationsRepository } from '~/repositories/violationsRepository';
import { createTriggerResource } from '~/utils/resource';
import type { UploaderFile } from '~/components/common/Upload/Interface';

const repo = new ViolationsRepository();
export const [ViolationsProvider, useViolations] = createMagicDoorContext('Violations', () => {
  const [filtered, setFilter, actions] = createTriggerResource((filter: any) => repo.list(filter));
  const { t } = useLocalization();
  const goBack = useGoBack();

  const [createdWorkOrder, setCreatedWorkOrder] = createSignal<Partial<MagicDoor.Api.HydratedWorkOrderDto>>({});

  const [newFiles, setNewFiles] = createSignal<UploaderFile[]>([]);

  const addViolation = async (payload: MagicDoor.Api.CreateAssociationViolationRequestDto) => {
    const response = await repo.add(payload);

    for (const file of newFiles()) {
      if (response.id) {
        await repo.upload(response.id, file);
      }
    }

    return response;
  };

  const [violation, getViolation, violationActions] = createTriggerResource(repo.get.bind(repo));

  const deleteViolation = async (id?: string) => {
    if (!id) return;
    try {
      await repo.delete(id);
      actions.refetch();
      toast(t('Delete success'), 'success');
    } catch (error) {
      toast(t('Delete failed, please try again later'), 'error');
    }
  };

  const handleStatusChange = async (id: string | undefined, status: MagicDoor.Api.AssociationViolationStatus) => {
    if (!id) return;
    try {
      await repo.changeStatus(id, status);
      actions.refetch();
      toast(t('Modify status success'), 'success');
    } catch (error) {
      toast(t('Modify status failed, please try again later'), 'error');
    }
  };

  const updateViolation = async (id: string, payload: MagicDoor.Api.UpdateAssociationViolationRequestDto) => {
    try {
      const response = await repo.update(id, payload);

      for (const file of newFiles()) {
        if (response.id) {
          await repo.upload(response.id, file);
        }
      }
      return response;
    } catch (error) {
      toast(t('Update failed, please try again later'), 'error');
    } finally {
      actions.refetch();
      toast(t('Update success'), 'success');
      goBack();
    }
  };

  const createFineBill = async (data: { dueDate: string }) => {
    try {
      const id = violation()?.id;
      if (!id) return;
      await repo.createFineBill(id, data);
      violationActions.refetch();
      toast(t('Create fine bill success'), 'success');
    } catch (error) {
      toast(t('Create fine bill failed, please try again later'), 'error');
    }
  };

  const createWorkOrder = async (data: MagicDoor.Api.CreateWorkOrderFromAssociationViolationDto) => {
    try {
      const id = violation()?.id;
      if (!id) return;
      const res = await repo.createWorkOrder(id, data);
      setCreatedWorkOrder(res);
      violationActions.refetch();
      toast(t('Create fine bill success'), 'success');
    } catch (error) {
      toast(t('Create fine bill failed, please try again later'), 'error');
    }
  };

  const createLeaseBill = async (data: MagicDoor.Api.AddAssociationViolationLeaseBillDto) => {
    const id = violation()?.id;
    if (!id) return;
    await repo.createLeaseBill(id, data);
    violationActions.refetch();
    toast(t('Create lease bill success'), 'success');
  };

  const createOwnerNotification = async (formData: FormData) => {
    const id = violation()?.id;
    if (!id) return;
    await repo.createOwnerNotification(id, formData);
    violationActions.refetch();
  };

  const createLeaseNotification = async (formData: FormData) => {
    const id = violation()?.id;
    if (!id) return;
    await repo.createLeaseNotification(id, formData);
    violationActions.refetch();
  };

  const deleteFileURL = createMemo(() => `${VIOLATION_BASE_URL}/${violation()?.id}/file`);

  return {
    filtered: filtered,
    setFilter: (filter: any) => setFilter(filter),
    refetch: actions.refetch,
    addViolation,
    getViolation,
    violation,
    violationActions,
    deleteViolation,
    handleStatusChange,
    updateViolation: updateViolation,
    createFineBill,
    createWorkOrder,
    createdWorkOrder,
    createLeaseBill,
    createOwnerNotification,
    createLeaseNotification,
    deleteFileURL,
    newFiles,
    setNewFiles,
  };
});
