import { createEffect, For, onCleanup, onMount, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Dynamic } from 'solid-js/web';
import { useFormContext } from '~/components/common/BetterForm/context';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Radio } from '~/components/common/Inputs/Radio';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication, useRentalApplicationPayment } from '~/contexts/local';
import { TenantTerm } from './terms-of-service/TenantTerm';
import { TransUnionTerm } from './terms-of-service/TransUnionTerm';

export const QuestionAndTerms = () => {
  const { t } = useLocalization();
  const { setStore, store } = useNewRentalApplication();
  const { settings } = useRentalApplicationPayment();
  const form = useFormContext();
  const [localStore, setLocalStore] = createStore<{
    terms: { title: string; checked: boolean }[];
  }>({
    terms: [],
  });

  createEffect(() => {
    if (store.currentStep !== 4) return;
    setStore({ disableNext: !localStore.terms.every((term) => term.checked) });
  });

  onCleanup(() => {
    setStore({ disableNext: false });
  });

  onMount(() => {
    const terms = [{ title: t('MagicDoor Terms And Conditions'), checked: false }];

    if (settings()?.requireScreening) {
      terms.push({ title: t('TransUnion Terms And Conditions'), checked: false });
    }
    setLocalStore({
      terms,
    });
  });

  return (
    <div class="flex flex-col gap-4">
      <For each={settings()?.questions}>
        {(val, index) => {
          return (
            <div class="flex gap-4">
              <span class="flex-1">{val.text}</span>
              <Show
                when={val.options?.length && val.text}
                fallback={
                  <LabeledTextInput
                    id={`new-rental-application-step5-question-${index()}`}
                    value={form?.formStore.questions?.[val.text!] ?? ''}
                    onInput={(value) => form?.setFieldValue(['questions', val.text!], value)}
                  />
                }>
                <Radio
                  id={`new-rental-application-step5-question-${index()}`}
                  items={val.options?.map((o) => ({ label: o, value: o })) ?? []}
                  value={form?.formStore.questions?.[val.text!] ?? ''}
                  onInput={(value) => form?.setFieldValue(['questions', val.text!], value)}
                />
              </Show>
            </div>
          );
        }}
      </For>
      <For each={localStore.terms}>
        {(term, index) => (
          <Show when={index() === 0 || settings()?.requireScreening}>
            <Dynamic
              id={`new-rental-application-step5-terms-${index()}`}
              component={index() === 0 ? TenantTerm : TransUnionTerm}
              title={t(term.title)}
              checked={term.checked}
              onInput={(checked: boolean) => setLocalStore('terms', index(), 'checked', checked)}
            />
          </Show>
        )}
      </For>
    </div>
  );
};
