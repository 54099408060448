import { Route, Routes } from '@solidjs/router';
import { useCompanyInvoices } from '~/contexts/global';
import InvoiceDetail from '~/pages/company-revenue/company-invoices-tab/InvoiceDetail';
import InvoiceList from '~/pages/company-revenue/company-invoices-tab/InvoiceList';
import { PayInvoiceModal } from '~/pages/company-revenue/company-invoices-tab/PayInvoiceModal';

export const InvoicesListRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<InvoiceList />} />
    </Routes>
  );
};

export const InvoiceDetailRoutes = () => {
  const { getInvoiceActions } = useCompanyInvoices();
  return (
    <>
      <Routes>
        <Route path="/*" element={<InvoiceDetail />} />
      </Routes>
      <Routes>
        <Route
          path="/pay"
          element={
            <PayInvoiceModal
              onPaymentSuccessed={() => {
                getInvoiceActions.refetch();
              }}
            />
          }
        />
      </Routes>
    </>
  );
};
