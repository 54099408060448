import { useLocation, useNavigate } from '@solidjs/router';
import { createContext, useContext, createEffect, createSignal, createMemo, on } from 'solid-js';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { getMenuItems } from './menuConfig';
import type { MenuItem } from './types/menuItem';
import type { JSX, Accessor, Setter } from 'solid-js';

export interface MenuContextType {
  menuItems: Accessor<MenuItem[]>;
  subMenuItems: Accessor<MenuItem[]>;
  isCollapsed: Accessor<boolean>;
  isActive: (item: MenuItem) => boolean;
  isSelected: (item: MenuItem) => boolean;
  isManuallyCollapsed: Accessor<boolean>;
  isSidebarOpen: Accessor<boolean>;
  isMobile: Accessor<boolean>;
  setIsSidebarOpen: Setter<boolean>;
  setIsManuallyCollapsed: Setter<boolean>;
  setActiveMenuItem: (item?: MenuItem) => void;
  activeParentMenuTitle: Accessor<string>;
  setActiveMenuItemId: Setter<string | undefined>;
  getMenuItemId: (item: MenuItem, isLinkBtn?: boolean) => string;
  getCurrentTitle: Accessor<string>;
}

const MenuContext = createContext<MenuContextType>();

const getCurrentMenuTitle = (menuItems: MenuItem[], pathname?: string, id?: string): string => {
  if (!pathname && !id) return '';

  const findTitle = (items: MenuItem[]): string => {
    for (const item of items) {
      if (id && item.id === id) return item.text;
      if (!id && item.path === pathname) return item.text;
      if (item.subMenuItems) {
        const subTitle = findTitle(item.subMenuItems);
        if (subTitle) return subTitle;
      }
    }
    return '';
  };

  return findTitle(menuItems);
};

interface ProviderProps {
  children: JSX.Element;
}

export function MenuProvider(props: ProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileView, isSidebarVisible, setIsSidebarVisible } = useDeviceDetect();

  const [activeMenuItemId, setActiveMenuItemId] = createSignal<string | undefined>(undefined);
  const [selectedMenuItems, setSelectedMenuItems] = createSignal<string[]>([]);
  const [isManuallyCollapsed, setIsManuallyCollapsed] = createSignal<boolean>(localStorage.getItem('menuCollapsed') === 'true');
  const [isSidebarOpen, setIsSidebarOpen] = createSignal<boolean>(window.innerWidth > 767);

  const isMobile = createMemo(() => isMobileView());

  createEffect(() => {
    if (isMobileView()) {
      setIsSidebarOpen(isSidebarVisible());
    }
  });

  createEffect(() => {
    setIsSidebarVisible(isSidebarOpen());
  });

  const isActive = (item: MenuItem): boolean => {
    return activeMenuItemId() === item.id;
  };

  const isSelected = (item: MenuItem): boolean => {
    return selectedMenuItems().includes(item.id);
  };

  const getMenuItemId = (item: MenuItem, isLinkBtn?: boolean): string => {
    const id = 'sidebar-' + item.id.toLowerCase().replace(/\s+/g, '-');
    return isLinkBtn ? id + '-link' : id;
  };

  const menuItems = createMemo(getMenuItems);

  const subMenuItems = createMemo((): MenuItem[] => {
    const menuItem = menuItems().find((item) => item.id === activeMenuItemId());
    return menuItem?.subMenuItems ?? [];
  });

  const activeParentMenuTitle = createMemo((): string => {
    const menuItem = menuItems().find((item) => item.id === activeMenuItemId());
    return menuItem?.text ?? '';
  });

  const isCollapsed = createMemo(() => subMenuItems() && subMenuItems().length > 0);

  const getCurrentTitle = createMemo(() => getCurrentMenuTitle(menuItems(), location.pathname, activeMenuItemId()));

  createEffect(() => {
    localStorage.setItem('menuCollapsed', isManuallyCollapsed().toString());
  });

  createEffect(
    on(
      () => location.pathname,
      (path) => {
        const items = getSelectedMenuItems(path, menuItems());
        setSelectedMenuItems(items);
        setActiveMenuItemId(items[0]);
      }
    )
  );

  const response: MenuContextType = {
    isMobile,
    menuItems,
    subMenuItems,
    isCollapsed,
    isActive,
    isSelected,
    isManuallyCollapsed,
    isSidebarOpen,
    setIsSidebarOpen,
    setIsManuallyCollapsed,
    setActiveMenuItem: (item?: MenuItem) => {
      if (item) {
        setActiveMenuItemId(item.id);
        if (item.canNavigate) {
          navigate(item.replace || item.path);
        }
      } else {
        setActiveMenuItemId(selectedMenuItems()[0]);
      }
    },
    activeParentMenuTitle,
    setActiveMenuItemId,
    getMenuItemId,
    getCurrentTitle,
  };

  return <MenuContext.Provider value={response}>{props.children}</MenuContext.Provider>;
}

export const getSelectedMenuItems = (path: string, menuItems: MenuItem[]): string[] => {
  const normalizedPath = normalizePath(path);
  return retrieveElementsInPath(menuItems, normalizedPath);
};

const retrieveElementsInPath = (menuItems: MenuItem[], path: string): string[] => {
  const result: string[] = [];
  let elementsToSearch: MenuItem[] | undefined = menuItems;
  while (elementsToSearch) {
    let didRefreshSearchLists = false;
    for (const menuItem of elementsToSearch) {
      if ((path.includes(menuItem.path) && menuItem.path !== '/') || path === menuItem.path) {
        result.push(menuItem.id);
        elementsToSearch = menuItem.subMenuItems;
        didRefreshSearchLists = true;
        break;
      }
    }
    if (!didRefreshSearchLists) break;
  }
  return result;
};

const normalizePath = (path: string): string => {
  let result = path.replace(/\/+/g, '/');
  if (!result.startsWith('/')) {
    result = '/' + result;
  }
  return result;
};

export function useMenu() {
  return useContext(MenuContext);
}
