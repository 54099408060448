import { Outlet, useNavigate, useParams, useLocation } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { PortfolioTabs } from './components/PortfolioTabs';
import type { Component } from 'solid-js';

export const PortfolioLayout: Component = () => {
  const params = useParams<{ portfolioId?: 'add' | `${number}` }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLocalization();

  const { portfolios, current, setCurrentId } = usePortfolios();

  const shouldShowError = (): boolean => location.query?.from !== 'deactivate' && !location.pathname.includes('/deactivate');

  createEffect(() => {
    if (portfolios.loading) return;

    if (params.portfolioId && /^\d+$/.test(params.portfolioId)) {
      const isExist = portfolios()?.find((p) => p.id === params.portfolioId);
      if (!isExist) {
        if (shouldShowError()) {
          toast.error(t('Portfolio not found, redirecting to another existing portfolio'), {
            timeout: 4000,
          });
        }
        return navigate(`/portfolios`, { replace: true });
      }
      setCurrentId(params.portfolioId);
    } else if (portfolios()?.[0]?.id) {
      const currentId = current()?.id ?? setCurrentId(portfolios()?.[0]?.id);
      params.portfolioId == null && navigate(`/portfolios/${currentId}`, { replace: true });
    }
  });

  return (
    <div class="size-full space-y-2.5  p-3 md:space-y-5 md:p-8 md:pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      <Outlet />
    </div>
  );
};
