import { HttpError } from '@magicdoor/errors';
import { BaseRestRepository } from './baseRestRepository';
import type { AuthResultDto } from '~/swagger/Api';

const AUTH_URL = '/api/auth';

type AuthResult =
  | { success: true; token: string }
  | { success: false; companies?: MagicDoor.Api.CompanyLoginDetailsDto[]; error?: 'invalid_credentials' | 'bad_request' };

export class AuthRepository extends BaseRestRepository {
  public async auth(email: string, password: string, companyId?: string): Promise<AuthResult> {
    try {
      const response = await this.fetchWithoutAuth(
        AUTH_URL,
        {
          method: 'POST',
          body: JSON.stringify({ email, password, companyId }),
        },
        false
      );
      const result = await this.getJsonResponse(response);

      if (response.ok && result.token) {
        return { success: true, token: result.token };
      } else {
        return { success: false, ...result };
      }
    } catch (e) {
      if (e instanceof HttpError) {
        const statusCode = e.getStatusCode();
        if (statusCode === 400) {
          return { success: false, error: 'bad_request' };
        }
        if (statusCode === 401 || statusCode === 403) {
          return { success: false, error: 'invalid_credentials' };
        }
        if (statusCode === 409) {
          return { success: false, companies: e.getJson<AuthResultDto>()?.companies ?? [] };
        }
      }
      throw e;
    }
  }
}
