import { Route, Routes, useParams } from '@solidjs/router';
import { createMemo, createEffect } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { ListingApplicationDetails } from './tabs/ListingApplicationDetails';
import { ListingApplicationsTab } from './tabs/ListingApplicationsTab';
import { UnitInformationTab } from './tabs/UnitInformationTab';
import { UnitListingPhotosTab } from './tabs/UnitListingPhotosTab';

const ListingDetailsPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const params = useParams<{ listingId: `${number}`; portfolioId?: `${number}` }>();
  const { getUnitListingsDetail, unitListingDetail } = useUnitListings();

  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: `/listings/${params.portfolioId}` },
    {
      label: unitListingDetail()
        ? `${unitListingDetail()?.property?.displayName} - ${unitListingDetail()?.unit?.name}`
        : t('Listing details'),
    },
  ]);

  createEffect(() => {
    getUnitListingsDetail(params.listingId);
  });

  return (
    <div class="relative flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="my-3 flex flex-col-reverse gap-4 px-3 md:my-5 md:px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4">
          <TabLinks
            name="listing-details"
            class="rounded-lg border border-partingline bg-white"
            items={[
              { label: t('Unit information'), href: '', end: true },
              { label: t('Photos'), href: 'photos', end: true },
              {
                label: t('Rental applications'),
                href: `units/${unitListingDetail()?.unitId}/rental-applications`,
                end: false,
              },
            ]}
            replace
          />
          <Routes>
            <Route path="/" component={UnitInformationTab} />
            <Route path="/photos" component={UnitListingPhotosTab} />
            <Route path="/units/:unitId/rental-applications/application-details/:applicationId/*" component={ListingApplicationDetails} />
            <Route path="/units/:unitId/rental-applications/*" component={ListingApplicationsTab} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsPage;
