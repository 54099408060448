import { useNavigate } from '@solidjs/router';
import { LinkButton } from '~/components/common/Buttons';
import { IconCirclePlus, IconCalendar } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useOwnerTransfers } from '~/contexts/local';
import { FundsListView, FundsNavbar } from '~/pages/owner-funds/components';

export const DistributionsList = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { ownerTransfers } = useOwnerTransfers();

  const handleRowClick = (item: MagicDoor.Api.OwnerTransferListDto) => {
    if (!item.id) {
      return;
    }

    navigate(`/accounting/owner-funds/distributions/${item.id}`);
  };

  return (
    <div class="size-full space-y-8 p-8">
      <FundsNavbar />
      <div class="relative">
        <FundsListView
          type="distribution"
          page={ownerTransfers()?.currentPage}
          pageSize={ownerTransfers()?.pageSize}
          totalPages={ownerTransfers()?.totalPages}
          onRowClick={handleRowClick}
          title={t('Owner distributions')}
          titleActions={
            <>
              <LinkButton href="/accounting/owner-funds/distributions/add">
                <IconCirclePlus class="size-5" />
                {t('Add distribution')}
              </LinkButton>
              <LinkButton href="/accounting/owner-funds/distributions/calculate" noScroll>
                <IconCalendar class="size-5" />
                {t('Calculate distribution')}
              </LinkButton>
            </>
          }
          data={ownerTransfers()?.items}
        />
      </div>
    </div>
  );
};
