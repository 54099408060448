import { createMemo, createSignal } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PaymentAmount } from '~/components/common/PaymentAmount';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { formConfirmationContent, MAX_INPUT_AMOUNT_2, paymentMethodOptions } from '~/utils/constant';
import { useCreateModel } from '~/utils/hooks';
import { isEmptyData } from '~/utils/tool';
import type { Component, Setter } from 'solid-js';

interface ReceivePaymentModalProps {
  visible?: Setter<boolean>[];
  rentalApplicationId?: string;
}

export const ReceivePaymentModal: Component<ReceivePaymentModalProps> = (props) => {
  const { t } = useLocalization();
  const { receivePayment, fetchApplication, settings } = useRentalApplication();
  const form = useForm();
  const [visible, setVisible] = useCreateModel(props, 'visible', false);
  const MIN_AMOUNT = 1;

  const [loading, setLoading] = createSignal<boolean>(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus || !props.rentalApplicationId) {
      return;
    }
    try {
      setLoading(true);
      await receivePayment(props.rentalApplicationId, form.formStore as MagicDoor.Api.RentalApplicationOfflinePaymentDto);
      fetchApplication(props.rentalApplicationId, false);
      toast.success(t('{name} has been added successfully', { name: t('Receive payment') }));
      handleCancel();
    } finally {
      setLoading(false);
    }
  };

  const showCloseConfirmation = createMemo<boolean>(() => {
    return !isEmptyData(form.formStore);
  });

  return (
    <Modal
      onCancel={handleCancel}
      onDone={handleSubmit}
      visible={visible()}
      title={t('Receive payment')}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <Form
        initialValues={{
          amount: settings()?.paymentAmount,
          paymentMethod: paymentMethodOptions(t)[0].value,
        }}
        defaultForm={form}
        class="grid grid-cols-2 gap-5 p-8">
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="amount"
          label={t('Amount')}
          component={LabeledNumberInput}
          placeholder={t('Please enter')}
          isBlur={false}
          min={MIN_AMOUNT}
          max={MAX_INPUT_AMOUNT_2}
          step={0.01}
          prepend="$"
          rules={[{ required: true, message: t('Please enter') }]}
        />
        <FormItem
          formFieldName="paymentMethod"
          label={t('Payment method')}
          options={paymentMethodOptions(t)}
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
          class="col-span-full sm:col-span-1"
          rules={[{ required: true, message: t('Please select') }]}
        />
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="externalTransactionId"
          label={t('External transaction ID')}
          rules={[{ required: true, message: t('External transaction ID is required') }]}
          component={LabeledTextInput}
          maxlength={150}
          placeholder={t('Please enter')}
        />
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="memo"
          label={t('Memo')}
          component={LabeledTextInput}
          placeholder={t('Please enter')}
        />
      </Form>
      <PaymentAmount label={t('Total amount')} mount={form.formStore.amount} min={MIN_AMOUNT} max={MAX_INPUT_AMOUNT_2} />
    </Modal>
  );
};
