import { createEffect, createMemo, createSignal, onMount, Show } from 'solid-js';
import { Form, useForm } from '~/components/common/BetterForm';
import { confirm, IconLoader, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BusinessInfoStatusBanner } from '~/pages/settings/company-settings/company-business/BusinessInfoStatus';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { companyBusinessInfoRepository } from '~/repositories/companyBusinessInfo';
import { createLazyResource } from '~/utils/resource';
import { deepEqual, isEmptyData } from '~/utils/tool';
import { CompanyBusinessInformation } from './CompanyBusinessInformation';
import { CompanyPrincipalsInformation } from './CompanyPrincipalsInformation';
import { COMPANY_BUSINESS_INFO_STORAGE_KEY } from './storageKey';

export const CompanyBusiness = () => {
  const { t } = useLocalization();
  const [track, setTrack] = createSignal();
  const [isDirty, setIsDirty] = createSignal(false);
  const [initialStore, setInitialStore] = createSignal<MagicDoor.Api.UpdateCompanyBusinessInfoDto>();

  const [status, statusAction] = createLazyResource(companyBusinessInfoRepository.getStatus);

  const form = useForm({
    onFieldChange: () => {
      setTrack({});
    },
  });

  const confirmSubmit = () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) {
      return;
    }
    const data = form.formStore as MagicDoor.Api.UpdateCompanyBusinessInfoDto;

    confirm({
      title: t('Confirm'),
      content: t('After submission, the status will be changed to "Pending"'),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await onSave(data);
      },
    });
  };

  const onSave = async (data: MagicDoor.Api.UpdateCompanyBusinessInfoDto) => {
    await companyBusinessInfoRepository.saveBusinessInfo(data);
    localStorage.setItem(COMPANY_BUSINESS_INFO_STORAGE_KEY, JSON.stringify(data));
    setInitialStore(data);
    toast.success(t('Business information saved successfully'));
    statusAction.refetch();
    return;
  };

  const onCancel = () => {
    const _initialStore = initialStore();
    if (!_initialStore) {
      form.resetFields();
      return;
    }
    form.setFormStore(_initialStore);
  };

  onMount(() => {
    const localData = localStorage.getItem(COMPANY_BUSINESS_INFO_STORAGE_KEY);
    if (localData) {
      setInitialStore(JSON.parse(localData));
      form.setFormStore(JSON.parse(localData));
    }
  });

  createEffect(() => {
    track();
    if (isEmptyData(form.formStore) || deepEqual(form.formStore, initialStore())) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  });

  const content = createMemo(() => {
    if (status()?.status && !initialStore()) {
      return <BusinessInfoStatusBanner status={status()?.status as MagicDoor.Api.CompanyBusinessInfoStatus} />;
    }

    return (
      <Form defaultForm={form} class="flex w-full flex-col gap-3">
        <Show when={status()?.status && initialStore()}>
          <BusinessInfoStatusBanner status={status()?.status as MagicDoor.Api.CompanyBusinessInfoStatus} />
        </Show>
        <CompanyBusinessInformation />
        <CompanyPrincipalsInformation />
        <SaveBtn
          dirty={isDirty()}
          onSave={confirmSubmit}
          onCancel={onCancel}
          class="overflow-hidden rounded-lg border border-partingline bg-white"
        />
      </Form>
    );
  });

  return (
    <Show when={!status.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
      {content()}
    </Show>
  );
};
