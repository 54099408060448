import { createEffect } from 'solid-js';
import { useTenants } from '~/contexts/global';
import { TenantsListView } from './TenantsListView';
import { TenantsSummary } from './TenantsSummary';

export const ListTenantsPage = () => {
  const { filtered } = useTenants();

  createEffect(() => {
    if (filtered.error) throw filtered.error;
  });

  return (
    <div class="size-full min-h-svh space-y-5 p-8" classList={{ 'cursor-wait': filtered.loading }}>
      <TenantsSummary />
      <TenantsListView />
    </div>
  );
};
