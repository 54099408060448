import { useParams, useNavigate } from '@solidjs/router';
import { createMemo, createEffect, Show, For } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { formatTenantNamesWithoutContainer } from '~/components/tenants/utils';
import { Table } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type CurrentLeasesTableProps = {
  class?: string;
  leases: MagicDoor.Api.LeaseDto[];
};

export const OngoingLeasesTable: Component<CurrentLeasesTableProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useLocalization();
  const { filtered, setFilter } = useTenants();

  createEffect(() => {
    setFilter({ page: 1, pageSize: 100, active: true });
  });

  const getTenantNames = (tenants: MagicDoor.Api.LeaseTenantDto[]) => {
    const ids = tenants?.map((tenant) => tenant.tenantId);

    return formatTenantNamesWithoutContainer(filtered()?.items?.filter((tenant) => ids?.includes(tenant.id)));
  };

  const query = () => new URLSearchParams(params);

  const columns = createMemo<TableColumns<MagicDoor.Api.LeaseDto>>(() => [
    {
      title: t('Lease term'),
      render: (item) => (
        <>
          <LeaseStatus lease={item} />
          <LeasePeriod lease={item} />
        </>
      ),
    },
    {
      title: t('Tenants'),
      render: (item) => (
        <div class="flex items-center">
          <div class="ml-2 text-nowrap text-sm font-medium">{getTenantNames(item.tenants as any)}</div>
        </div>
      ),
    },
    {
      title: t('Rent'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => (
        <div>
          <div class="text-sm text-text-level01">{currency(item.currentRent)}</div>
          <div class="mt-1 text-xs text-text-level03">
            {t('Last payment')}:
            <Show when={item.lastPaymentDate} fallback={emptyPlaceholder}>
              {dateFormat('', item.lastPaymentDate)}
            </Show>
          </div>
        </div>
      ),
    },
    {
      title: t('Balances'),
      headerClass: 'text-right',
      render: (item) => <CnMoney money={item.balance} showIcon />,
    },
    {
      title: t('Posted balances'),
      headerClass: 'text-right',
      render: (item) => <CnMoney money={item.postedBalance} showIcon />,
    },
  ]);

  return (
    <Panel
      title={t('Leases')}
      class={props.class}
      actions={
        <div class="flex gap-2">
          <LinkButton href={`/leasing/leases/new?${query()}`} class="ml-auto text-sm">
            <IconPlus /> {t('Set up a new lease')}
          </LinkButton>
        </div>
      }>
      <div class="thinscroll overflow-x-auto">
        <div class="divide-y md:hidden">
          <For each={props.leases}>
            {(item) => {
              return (
                <div class="flex flex-col gap-2 p-3" onClick={() => navigate(`/leasing/leases/${item.id}?from=unit`)}>
                  <div class="flex gap-3 divide-x rounded-lg bg-light-gray p-3">
                    <LeaseStatus lease={item as any} />
                    <LeasePeriod lease={item as any} />
                  </div>

                  <div class="flex items-center gap-1">
                    <span class="text-xs font-light"> {t('Tenants')}:</span>
                    <div class="text-nowrap text-sm font-medium">{getTenantNames(item.tenants as any)}</div>
                  </div>

                  <div class="flex items-center gap-1">
                    <span class="text-xs font-light"> {t('Balance')}:</span>
                    <CnMoney money={item.balance} />
                  </div>

                  <div class="flex items-center gap-1">
                    <span class="text-xs font-light"> {t('Balance')}:</span>
                    <CnMoney money={item.balance} />
                  </div>
                  <div class="flex items-center gap-1">
                    <span class="text-xs font-light"> {t('Rent')}:</span>
                    <div class="text-sm text-text-level01">{currency(item.currentRent)}</div>
                    <Show when={item.lastPaymentDate}>
                      <div class="text-xs text-text-level03">
                        {t('Last payment')}:
                        <Show when={item.lastPaymentDate} fallback={emptyPlaceholder}>
                          {dateFormat('', item.lastPaymentDate)}
                        </Show>
                      </div>
                    </Show>
                  </div>
                  <div class="flex items-center gap-1">
                    <span class="text-xs font-light"> {t('Posted balance')}:</span>
                    <CnMoney money={item.postedBalance} />
                  </div>
                </div>
              );
            }}
          </For>
        </div>
        <Table class="hidden md:table" columns={columns()} data={props.leases} rowLink={(item) => `/leasing/leases/${item.id}?from=unit`} />
      </div>
    </Panel>
  );
};
