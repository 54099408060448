import { createResource, createSignal, untrack } from 'solid-js';
import { sentryCapture } from '~/utils/sentry';
import type {
  InitializedResource,
  InitializedResourceOptions,
  Resource,
  ResourceActions,
  ResourceFetcher,
  ResourceOptions,
  ResourceSource,
} from 'solid-js';

export type Mutation<T extends (...args: any[]) => Promise<any>> = T & {
  pending: boolean;
  loading: boolean;
  error: any | null;
};

export const createMutation = <T extends (...args: any[]) => Promise<any>>(fn: T) => {
  // const [state, setState] = createSignal<'idle' | 'pending' | 'error' | 'success'>('idle');
  const [pending, setPending] = createSignal<boolean>(false);
  const [error, setError] = createSignal<any | null>(null);

  const dispatch = ((...args) => {
    setError(null);
    setPending(true);
    return fn(...args)
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => setPending(false));
  }) as T;

  return Object.defineProperties(dispatch, {
    pending: { get: pending },
    loading: { get: pending },
    error: { get: error },
  }) as Mutation<T>;
};

type LazyResourceActions<T, R = unknown> = ResourceActions<T, R> & { fetch: () => void };

type LazyInitializedResourceReturn<T, R = unknown> = [InitializedResource<T>, LazyResourceActions<T, R>];

type LazyResourceReturn<T, R = unknown> = [Resource<T>, LazyResourceActions<T | undefined, R>];

export function createLazyResource<T, R = unknown>(
  fetcher: ResourceFetcher<true, T, R>,
  options: InitializedResourceOptions<NoInfer<T>, true>
): LazyInitializedResourceReturn<T, R>;
export function createLazyResource<T, R = unknown>(
  fetcher: ResourceFetcher<true, T, R>,
  options?: ResourceOptions<NoInfer<T>, true>
): LazyResourceReturn<T, R>;
export function createLazyResource<T, S, R = unknown>(
  source: ResourceSource<S>,
  fetcher: ResourceFetcher<S, T, R>,
  options: InitializedResourceOptions<NoInfer<T>, S>
): LazyInitializedResourceReturn<T, R>;
export function createLazyResource<T, S, R = unknown>(
  source: ResourceSource<S>,
  fetcher: ResourceFetcher<S, T, R>,
  options?: ResourceOptions<NoInfer<T>, S>
): LazyResourceReturn<T, R>;
export function createLazyResource<T, S, R>(
  pSource: ResourceSource<S> | ResourceFetcher<S, T, R>,
  pFetcher?: ResourceFetcher<S, T, R> | ResourceOptions<T, S>,
  pOptions?: ResourceOptions<T, S> | undefined
): LazyResourceReturn<T, R> {
  const [trigger, setTrigger] = createSignal<boolean>();

  let source: ResourceSource<S>;
  let fetcher: ResourceFetcher<S, T, R>;
  let options: ResourceOptions<T, S>;

  if ((arguments.length === 2 && typeof pFetcher === 'object') || arguments.length === 1) {
    source = trigger as ResourceSource<S>;
    fetcher = pSource as ResourceFetcher<S, T, R>;
    options = (pFetcher || {}) as ResourceOptions<T, S>;
  } else {
    source = pSource as ResourceSource<S>;
    fetcher = pFetcher as ResourceFetcher<S, T, R>;
    options = pOptions || ({} as ResourceOptions<T, S>);
  }

  const [resource, { refetch, mutate }] = createResource(source, fetcher, options);

  const fetch = () => trigger() || setTrigger(true);

  const resourceAutoFetchWhenUse = Object.defineProperties(() => {
    untrack(() => fetch());
    return resource();
  }, Object.getOwnPropertyDescriptors(resource)) as Resource<T>;

  return [resourceAutoFetchWhenUse, { fetch, refetch, mutate }];
}

export const createTriggerResource = <S, T, R = unknown>(pFetcher: ResourceFetcher<S, T, R>) => {
  const fetcher: ResourceFetcher<S, T, R> = async (...params) => {
    try {
      return await pFetcher(...params);
    } catch (error) {
      sentryCapture(error as Error);
      return undefined as T;
    }
  };

  const [trigger, setTrigger] = createSignal<S>();
  const [resource, actions] = createResource(trigger, fetcher);
  const get = (trigger: S) => setTrigger(() => trigger);
  const forceGet = (pTrigger: S) => {
    const triggerValue = untrack(trigger);
    if (pTrigger === triggerValue) {
      actions.refetch();
      return;
    }
    get(pTrigger);
  };
  return [resource, get, Object.assign(actions, { forceGet, trigger })] as const;
};

export type Pagination<T> = {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  items: T[];
};

export const pagianate = <T>(items: T[], page: number, pageSize: number): Pagination<T> => {
  return {
    page,
    pageSize,
    totalCount: items.length,
    totalPages: Math.ceil(items.length / pageSize),
    items: items.slice((page - 1) * pageSize, page * pageSize),
  };
};

export function createPoll<T extends (...args: any[]) => Promise<any>>(fn: T, timeout: number = 5000, maxWait: number = 60000) {
  return (...args: Parameters<T>) => {
    const { resolve, promise, reject } = Promise.withResolvers<any>();
    let startTime: number | undefined = undefined;

    (async function _fn() {
      const timeNow = Date.now();
      if (!startTime) {
        startTime = timeNow;
      }

      if (timeNow - startTime > maxWait) {
        reject(new Error('Poll timeout'));
        return;
      }

      const result = await fn(...args).catch(() => false);
      if (result !== false) {
        resolve(result);
      } else {
        setTimeout(() => _fn(), timeout);
      }
    })();

    return promise;
  };
}
