import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { TaxType } from '~/swagger/Api';
import type {
  TaxDocumentStatus,
  TaxDocumentType,
  PaginatedTaxDocuments,
  TaxDocument,
} from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type {
  GetTaxDocumentsRequest,
  SingleVendorTaxDocsGenerationRequest,
  TaxYearDocumentRequest,
  VendorTaxDocsGenerationRequest,
} from '~/pdfsigner/usecases/types/taxDocumentRequests';
import type { VendorTaxAmount } from '~/pdfsigner/usecases/types/vendorTaxAmount';

const VENDORS_TAX_URL = '/api/vendors/tax-documents';

class TaxDocumentRepository extends BaseRestRepository {
  public async getVendorTaxDocuments(request?: GetTaxDocumentsRequest): Promise<PaginatedTaxDocuments> {
    const page = request?.page ?? 1;
    const itemsPerpage = request?.itemsPerPage ?? 100;
    const vendorId = request?.entityId;
    const baseUrl = vendorId ? `${VENDORS_TAX_URL}/${vendorId}` : VENDORS_TAX_URL;
    const url = `${baseUrl}?Page=${page}&PageSize=${itemsPerpage}`;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    const fileMap = new Map(json.files.map((file: any) => [file.fileId, file]));
    const vendorMap = new Map(json.vendors.map((vendor: any) => [vendor.id, vendor]));
    return {
      currentPage: json.currentPage,
      pageSize: json.pageSize,
      totalItems: json.totalCount,
      totalPages: json.totalPages,
      items: json.items.map((doc: any) => {
        const file: any = fileMap.get(doc.fileId);
        const vendor: any = vendorMap.get(doc.entityId);
        return {
          id: doc.id,
          calendarYear: doc.calendarYear,
          documentName: file?.fileName,
          createdAt: file?.uploaded,
          taxDocumentType: doc.taxType as TaxDocumentType,
          amount: doc.amount,
          status: doc.status as TaxDocumentStatus,
          url: '/api/' + file?.signedUrl,
          entityName: vendor?.name,
          entityId: vendor?.id,
        } as TaxDocument;
      }),
    };
  }

  public async getVendorTaxAmountsForYear(request: TaxYearDocumentRequest): Promise<VendorTaxAmount[]> {
    const baseUrl = request.vendorId
      ? `${VENDORS_TAX_URL}/${request.vendorId}/amounts/${request.year}`
      : `${VENDORS_TAX_URL}/amounts/${request.year}`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    if (!json) {
      return [];
    }
    if (json instanceof Array) {
      return json.map((item: any) => this.getVendorTaxAmountFromJson(item));
    } else {
      const result = [];
      result.push(this.getVendorTaxAmountFromJson(json));
      return result;
    }
  }

  public async generateVendorTaxDocs(data: VendorTaxDocsGenerationRequest): Promise<void> {
    const url = `${VENDORS_TAX_URL}/${TaxType.Nec}/${data.year}`;
    const taxAmounts = data.vendorData.map((vendor) => ({
      accountId: vendor.accountId,
      amount: vendor.taxAmount,
    }));
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({ taxAmounts }),
    });
  }

  public async regenerateVendorTaxDocs(data: SingleVendorTaxDocsGenerationRequest): Promise<void> {
    const url = `${VENDORS_TAX_URL}/${data.vendorData.vendorId}/${TaxType.Nec}/${data.year}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({
        accountId: data.vendorData.accountId,
        amount: data.vendorData.taxAmount,
      }),
    });
  }

  public async getAvailableTaxYears(): Promise<number[]> {
    const baseUrl = `${VENDORS_TAX_URL}/calendar-years`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    return json.calendarYears;
  }

  private getVendorTaxAmountFromJson(json: any): VendorTaxAmount {
    return {
      vendorId: json.vendor.id,
      vendorName: json.vendor.name,
      taxAmount: json.amount,
      accountId: json.accountId,
      isSelected: true,
    };
  }
}

export const taxDocumentsRepository = new TaxDocumentRepository();
