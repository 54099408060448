import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, For } from 'solid-js';
import IconAddServiceBill from '~/assets/images/property/addServiceBill.svg?component-solid';
import IconAddUnit from '~/assets/images/property/addUnit.svg?component-solid';
import IconEdit from '~/assets/images/property/edit.svg?component-solid';
import IconModifyOwner from '~/assets/images/property/modifyOwner.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';

interface Props {
  class: string;
}
export const PropertyQuickAction = (props: Props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams();

  const quickActions = createMemo(() => [
    {
      title: t('Add new unit'),
      icon: IconAddUnit,
      code: 'addNewUnit',
      bgColor: '#FFF5EA',
    },
    {
      title: t('Modify owner'),
      icon: IconModifyOwner,
      code: 'modifyOwner',
      bgColor: '#FFF6F6',
    },

    {
      title: t('Edit property'),
      icon: IconEdit,
      code: 'editProperty',
      bgColor: '#FFFDF0',
    },
    {
      title: t('Add service bill'),
      icon: IconAddServiceBill,
      code: 'addServiceBill',
      bgColor: '#F4FFFD',
    },
  ]);

  const defaultUrl = createMemo(() => `/portfolios/${params.portfolioId}/properties/${params.propertyId}`);

  const handleQuickAction = (code: string) => {
    switch (code) {
      case 'addNewUnit':
        navigate(defaultUrl() + `/units/add`);
        break;
      case 'modifyOwner':
        navigate(defaultUrl() + '/owners');
        break;
      case 'editProperty':
        navigate(defaultUrl() + '/edit');
        break;
      case 'addServiceBill':
        navigate(defaultUrl() + '/addServiceBill');
        break;
      default:
        break;
    }
  };

  return (
    <Card class={props.class} title={t('Quick action')} contentClass="h-full md:mt-[14px]">
      <div class="flex h-full flex-col">
        <For each={quickActions()}>
          {(item, index) => (
            <div
              onClick={() => handleQuickAction(item.code)}
              class={`flex cursor-pointer items-center gap-2 px-3 py-2.5 transition-colors hover:bg-gray-100 md:gap-[20px] md:px-[29px] md:py-4 ${
                index() !== quickActions().length - 1 ? 'border-b border-b-[#EDEFF2]' : ''
              }`}>
              <div class="flex size-9 items-center justify-center rounded-full" style={{ 'background-color': item.bgColor }}>
                <item.icon class="text-gray-600" />
              </div>
              <span class="text-sm font-medium text-gray-800">{item.title}</span>
            </div>
          )}
        </For>
      </div>
    </Card>
  );
};
