import { EmailLink, externalLink } from './TermWithTableOfContents';
import type { TableOfContentsItem, Section } from './TermWithTableOfContents';

export const tenantTableOfContents: TableOfContentsItem[] = [
  { id: 'application-of-terms', title: 'Application of These Terms of Use' },
  { id: 'privacy-policy', title: 'Privacy Policy' },
  { id: 'accessing-site', title: 'Accessing the Site and Services' },
  { id: 'magicdoor-not-party', title: 'MagicDoor Not a Party to Any Transaction' },
  { id: 'screening-process', title: 'Screening Process' },
  { id: 'payments', title: 'Payments' },
  { id: 'intellectual-property', title: 'Intellectual Property Rights' },
  { id: 'your-communications', title: 'Your Communications to the Site' },
  { id: 'electronic-communications', title: 'Electronic Communications' },
  { id: 'permitted-uses', title: 'Permitted Uses' },
  { id: 'user-contributions', title: 'User Contributions' },
  { id: 'content-standards', title: 'Content Standards' },
  { id: 'monitoring-enforcement', title: 'Monitoring and Enforcement; Termination' },
  { id: 'reliance-information', title: 'Reliance on Information Posted' },
  { id: 'third-party-links', title: 'Third-Party Links' },
  { id: 'linking-site', title: 'Linking to the Site and Social Media Features' },
  { id: 'federal-state-laws', title: 'Federal and State Laws' },
  { id: 'minimum-age', title: 'Minimum Age' },
  { id: 'disclaimer-warranties', title: 'Disclaimer of Warranties' },
  { id: 'limitation-liability', title: 'Limitation of Liability' },
  { id: 'indemnification', title: 'Indemnification' },
  { id: 'general-release', title: 'General Release' },
  { id: 'copyright-complaints', title: 'Copyright Complaints' },
  { id: 'injunctive-relief', title: 'Injunctive Relief' },
  { id: 'mandatory-arbitration', title: 'Mandatory Arbitration and Class Action and Jury Trial Waiver' },
  { id: 'other-terms', title: 'Other Terms' },
  { id: 'questions', title: 'Questions' },
];

export const tenantSections: Section[] = [
  {
    id: 'application-of-terms',
    title: '1. Application of These Terms of Use',
    content: [
      'The website (“Site”) on which this Terms of Use (“Terms”) appears is owned and operated by MagicDoor (“we,” “our,” or “us”). For purposes of these Terms, “you” or “your” means the person accessing the Site as a property tenant and any persons that allow others to provide information about themselves to us. The Site may provide information, documents, tools, products, services, accounts, offers, suggestions on the best way to use our products, and/or information on where to purchase our products (collectively, “Services”).',
      'THESE TERMS CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND US. PLEASE READ CAREFULLY THROUGH ALL SECTIONS OF THESE TERMS. YOUR ACCESS TO AND USE OF THE SITE IS SUBJECT TO THESE TERMS AND ALL APPLICABLE LAWS AND WE RESERVE THE RIGHT TO TERMINATE YOUR ACCESS TO THE SITE IF YOU VIOLATE THESE TERMS. BY CLICKING ON LINKS WITHIN THE SITE OR WEBPAGES BEYOND THE SITE’S HOMEPAGE OR BY CLICKING ON A BOX OR ICON YOU AGREE TO THESE TERMS WHETHER OR NOT YOU COMPLETE A TRANSACTION WITH US AND WHETHER OR NOT YOU COMPLETE YOUR TRANSACTION ON THE SITE OR THROUGH OTHER CHANNELS, SUCH AS BY TELEPHONE, EMAIL, FACSIMILE OR OTHERWISE. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE SITE, ANY SERVICES AVAILABLE THROUGH THIS SITE, OR ANY INFORMATION CONTAINED ON THIS SITE.',
      'MANDATORY ARBITRATION NOTICE AND CLASS ACTION AND JURY TRIAL WAIVER. These Terms contain a mandatory (binding) arbitration provision and class action and jury trial waiver clauses. Except for certain types of disputes described in the arbitration section below or where prohibited by applicable law, you agree that disputes between you and us regarding your use of the Site or Services will be resolved by binding, individual arbitration and you waive your right to participate in a class action lawsuit or class-wide arbitration, including as a class representative. The arbitrator’s decision will be subject to very limited review by a court. You will be entitled to a fair hearing, but the arbitration procedures are simpler and more limited than rules applicable in Court. For more details, see below.',
      'We may make changes to the content available on the Site at any time. We can change, update, add, or remove provisions of these Terms at any time by posting the updated Terms on the Site. We will make commercially reasonable efforts to notify you of any material changes to these Terms however we are not obligated to. You waive any right you may have to receive specific notice of such changes to these Terms except for changes to our agreement to arbitration, which is discussed more fully below. By using the Site after we have updated the Terms, you are agreeing to the then-current Terms. You are responsible for regularly reviewing these Terms.',
      'In addition to these Terms, your use of certain Services may be governed by additional agreements.',
    ],
  },
  {
    id: 'privacy-policy',
    title: '2. Privacy Policy',
    content: [
      <p>
        Besides these Terms, we also publish a Privacy Policy available {externalLink('https://magicdoor.com/privacy-policy/', 'here')}.
        Although it is not part of these Terms, we encourage you to read it to better understand how you can update, manage, access, and
        delete your information.
      </p>,
    ],
  },
  {
    id: 'accessing-site',
    title: '3. Accessing the Site and Services',
    content: [
      'We reserve the right to withdraw or amend this Site, and any Services or Materials (defined below) we provide on the Site, in our sole discretion and without notice. We will not be liable if, for any reason, all or any part of the Site is unavailable at any time or for any period. From time to time, in our sole discretion and without notice, we may restrict access to some parts of the Site, or the entire Site, to users, including registered users.',
      'You are responsible for both:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Making all arrangements necessary for you to have access to the Site.</li>
        <li class="pl-2">
          Ensuring that all persons who access the Site through your internet connection are aware of these Terms and comply with them.
        </li>
      </ul>,
      'To access the Site or certain of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete, and that you have the authority to provide such information to us.',
      'If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Site or portions of it using your username, password, or other security information. You shall bear the entire risk for any use thereof, whether you have authorized such use and whether or not you are negligent. If you permit other persons to use your computer or mobile device, login information or any other means to access the Services, you will be held responsible for any transactions they undertake, and we will not be liable for any damages resulting to you.',
      'You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you sign out of or exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.',
      'We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any reason, including if, in our opinion, you have violated any provision of these Terms.',
    ],
  },
  {
    id: 'magicdoor-not-party',
    title: '4. MagicDoor Not a Party to Any Transaction',
    content: [
      'The Site and Services act as a platform to allow property owners or managers (each, a “Property Owner”) to manage their properties (each a “Property”) and offer you with a method to manage your rent and payment methods. MagicDoor does not own or manage any Property listed on the Site, does not enter into a lease or other transaction with you and any Property Owner, is not a party to a rental agreement or other transaction, and provides no insurance relating to any transaction. Any rental agreements that you enter into through the Site are for services provided by a Property Owner, not MagicDoor. Although MagicDoor provides the Site as a platform for the Services through which you can execute rental or lease agreements and enter into binding contacts with third parties, MagicDoor is not involved in, is not liable for, and is not a party to those transactions. The terms of those transactions are determined solely by you and the third party with whom you are entering into said contract. You acknowledge that you will address any issues or concerns with such agreements with said third parties directly. MagicDoor also does not serve as a judge or mediator for any dispute between you and a Property Owner.',
    ],
  },
  {
    id: 'screening-process',
    title: '5. Screening Process',
    content: [
      <p>
        As part of our Services, we offer you the ability to obtain a background or credit check (“Consumer Report”) from a third-party
        service provider when you submit a rental application for a Property (“Screening Process”). Information that you submit through the
        Site for the purpose of such Consumer Report shall be treated in accordance with our Privacy Policy. Your submission of the
        information requested for a Consumer Report shall constitute your consent to the Screening Process. As part of the Screening
        Process, we use TransUnion to run your Consumer Report, which includes information about your credit history, credit score, and
        standing, and criminal and eviction history. In connection with the Screening Process, we: (a) do not receive, process, or store
        Consumer Reports; (b) do not control how a Property Owner uses a Consumer Report in evaluating its applications; (c) are not
        responsible for any part of the Screening Process; and (d) do not guarantee any acceptance by a Property Owner of any application.
        You authorize and direct TransUnion to share any Consumer Reports with the Property Owner to whom you submit a rental application.
        You acknowledge that you will address any issues or concerns with any Consumer Report, including the results of any Consumer
        Reports, by contacting the appropriate Property Owner. MagicDoor shall not become involved in Consumer Report related issues except
        where the issue is solely attributable to a malfunction or error occurring on the Site or in connection with the Services. If you
        experience issues with your Consumer Report, please contact TransUnion customer service at 800-916-8800. You may dispute the
        accuracy of your Consumer Report by visiting:{' '}
        {externalLink(
          'https://www.transunion.com/credit-disputes/dispute-your-credit',
          'https://www.transunion.com/credit-disputes/dispute-your-credit'
        )}{' '}
        or by calling 800-916-8800.
      </p>,
    ],
  },
  {
    id: 'payments',
    title: '6. Payments',
    content: [
      'In conjunction with a third-party payment processor, we offer web-based Services to facilitate payment of rental amounts, and other sums due, between you and Property Owners (the “Payment Portal”). You may initiate one-time and recurring payments through the Payment Portal.',
      'When scheduling a payment, you may be asked to supply certain relevant information, such as your debit or credit card number and its expiration date, checking account information, and/or billing address. By submitting such information, you (a) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT TO USE ANY PAYMENT METHOD THAT YOU SUBMIT THROUGH THE PAYMENT PORTAL, and (b) grant us the right to provide such information to third parties for purposes of facilitating your scheduled transactions. Verification of information may be required prior to the acknowledgment or completion of any payment transaction. You agree to your personal and financial information being transferred, stored, and processed by our third-party payment processors.',
      <p>
        By agreeing to use the Payment Portal or by sending or accepting payments through the Payment Portal, you understand and agree that
        we will create an account on your behalf with our payment processor to facilitate the transmission and receipt of payments, which
        are consummated outside of MagicDoor. You also agree to be bound by the terms and conditions of the third-party processor, which may
        be updated from time to time, at:{' '}
        {externalLink('https://stripe.com/connect-account/legal', 'https://stripe.com/connect-account/legal')}. Any authorization you
        provide to initiate repeating automatic payments using the Payment Portal will remain in effect until canceled.
      </p>,
      'MagicDoor does not hold any money you transfer through the Payment Portal and is not responsible for unsuccessful transmission, sourcing, or use of any funds. If your bank or card issuer initiates a reversal, chargeback, or payment dispute of a payment made through the Payment Portal, you authorize MagicDoor and our third-party payment processors to reverse or otherwise claim the funds from your account. MagicDoor may also initiate a reversal, or take other actions we determine to be appropriate, if, in MagicDoor’s reasonable opinion, fraud or abuse of the Payment Portal has occurred.',
    ],
  },
  {
    id: 'intellectual-property',
    title: '7. Intellectual Property Rights',
    content: [
      'Unless otherwise specified in these Terms, all information and screens appearing on this Site are the sole property of us or our subsidiaries and affiliates, and other parties. We provide content through the Site that is copyrighted or contains protectable trademarks of us or our third-party licensors and suppliers (collectively, the “Materials”). Materials may include documents, services, software, site design, text, graphics, logos, video, images, icons, and other content, as well as the arrangement thereof.',
      'Subject to these Terms, we hereby grant to you a revocable, limited, personal, non-exclusive, and non-transferable license to use, view, print, display, and download the Materials for the sole purpose of viewing them on a stand-alone personal computer or mobile device and to use this Site solely for your personal use. Except for the foregoing license and as otherwise required or limited by applicable law, you have no other rights in the Site or any Materials and you may not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance, or in any way exploit any of the Site or Materials in any manner or for any purpose that would constitute infringement of our, our licensors’, or the Site’s other user’s intellectual property rights. All rights not expressly granted herein are reserved.',
      'If you breach any of these Terms, the above license will terminate automatically and you must immediately destroy any downloaded or printed Materials.',
    ],
  },
  {
    id: 'your-communications',
    title: '8. Your Communications to the Site',
    content: [
      'By forwarding any content or communications to us through the Site or by other electronic means, you thereby grant us a perpetual, royalty-free, fully paid-up, world-wide, irrevocable, non-exclusive, freely transferable, and freely sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, redistribute, and display such content and communications in any form for the purposes of providing the Services and any purpose tangentially related to the Services. No compensation will be paid to you with respect to our or our sublicensees’ use of your communications. By providing or submitting content, you represent and warrant that you own or otherwise control all of the rights to your submitted content and communications as described in this section, including all the rights necessary for you to submit the content and communications and grant the license above.',
    ],
  },
  {
    id: 'electronic-communications',
    title: '9. Electronic Communications',
    content: [
      'By using the Site and/or the Services, you consent to receiving electronic communications, including electronic notices, from us. These electronic communications may include notices about applicable fees and charges, transactional information and other information concerning or related to the Site and/or Materials. These electronic communications are part of your relationship with us. You agree that any notices, agreements, disclosures or other communications that we send you electronically will satisfy any legal communication requirements, including that such communications be in writing.',
    ],
  },
  {
    id: 'permitted-uses',
    title: '10. Permitted Uses',
    content: [
      'By accessing or using the Site, you agree that:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Your use of the Site is subject to and governed by these Terms;</li>
        <li class="pl-2">
          You will only access or use the Site and transact business with us if you are at least eighteen (18) years old;
        </li>
        <li class="pl-2">You will use the Site solely for its Services offered in the normal course of business;</li>
        <li class="pl-2">You will always act in accordance with the law and custom, and in good faith;</li>
        <li class="pl-2">
          You will comply with and be bound by these Terms as they appear on the Site each time you access and use the Site;
        </li>
        <li class="pl-2">Each use of the Site by you indicates and confirms your agreement to be bound by these Terms; and</li>
        <li class="pl-2">These Terms are a legally binding agreement between you and us that will be enforceable against you.</li>
      </ul>,
      'You further agree to not use the Site in any way that:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Changes or alters the Site or content or Services that may appear on the Site;</li>
        <li class="pl-2">Impairs in any way the integrity or operation of the Site;</li>
        <li class="pl-2">Interferes with or induces a breach of the contractual relationships between us and our employees;</li>
        <li class="pl-2">Is in any way unlawful or prohibited, or that is harmful or destructive to anyone or their property;</li>
        <li class="pl-2">
          Transmits any advertisements, solicitations, schemes, spam, flooding, or other unsolicited email and commercial communications;
        </li>
        <li class="pl-2">Transmits any harmful or disabling computer codes or viruses;</li>
        <li class="pl-2">Harvests email addresses from the Site;</li>
        <li class="pl-2">Transmits unsolicited email to the Site or to anyone whose email address includes the domain name of the Site;</li>
        <li class="pl-2">Interferes with our network services;</li>
        <li class="pl-2">Attempts to gain unauthorized access to our network services;</li>
        <li class="pl-2">Suggests an express or implied affiliation or relationship with us without our express written permission;</li>
        <li class="pl-2">Impairs or limits our ability to operate the Site or any other person’s ability to access and use the Site;</li>
        <li class="pl-2">Unlawfully impersonates or otherwise misrepresents your affiliation with any person or entity;</li>
        <li class="pl-2">
          Transmits or uploads violent, obscene, sexually explicit, discriminatory, hateful, threatening, abusive, defamatory, offensive,
          harassing, or otherwise objectionable content or images;
        </li>
        <li class="pl-2">Dilutes or depreciates our or any of our affiliates’ name and reputation;</li>
        <li class="pl-2">
          Transmits or uploads content or images that infringe upon any third party’s intellectual property rights or right to privacy; or
        </li>
        <li class="pl-2">Unlawfully transmits or uploads any confidential, proprietary or trade secret information.</li>
      </ul>,
      'We have no obligation, but maintain the right, to monitor the Site. This list of prohibited activities provides examples and is not complete or exclusive. We reserve the right to terminate access to your account and your ability to use this Site (or the Materials) with or without cause and with or without notice, for any reason or no reason, or for any action that we determine is inappropriate or disruptive to this Site or to any other user of this Site and/or Materials. We may report to law enforcement authorities any actions that may be illegal, and any reports we receive of such conduct. When legally required or at our discretion, we will cooperate with law enforcement agencies in any investigation of alleged illegal activity on this Site or on the Internet, which may include disclosing any information we obtain. In addition, we may disclose information we obtain as necessary or appropriate to operate or improve the Site, to protect us and/or our Site users, or for any other purpose that the law permits.',
    ],
  },
  {
    id: 'user-contributions',
    title: '11. User Contributions',
    content: [
      'The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, blogs, community areas, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Site.',
      'All User Contributions must comply with the Content Standards set out in these Terms.',
      'Any User Contribution you post to the Site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Site, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose.',
      'You represent and warrant that:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">
          You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our
          respective licensees, successors, and assigns.{' '}
        </li>
        <li class="pl-2">All of your User Contributions do and will comply with these Terms.</li>
      </ul>,
      'You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not us, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.',
      'We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Site.',
    ],
  },
  {
    id: 'content-standards',
    title: '12. Content Standards',
    content: [
      'These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">
          Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or
          otherwise objectionable.
        </li>
        <li class="pl-2">
          Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality,
          disability, sexual orientation, or age.
        </li>
        <li class="pl-2">
          Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
        </li>
        <li class="pl-2">
          Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to
          any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and our
          Privacy Policy.
        </li>
        <li class="pl-2">Be likely to deceive any person.</li>
        <li class="pl-2">Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
        <li class="pl-2">
          Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.
        </li>
        <li class="pl-2">Impersonate any person or misrepresent your identity or affiliation with any person or organization.</li>
        <li class="pl-2">
          Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.
        </li>
        <li class="pl-2">
          Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
        </li>
      </ul>,
    ],
  },
  {
    id: 'monitoring-enforcement',
    title: '13. Monitoring and Enforcement; Termination',
    content: [
      'We have the right to:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
        <li class="pl-2">
          Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if
          we believe that such User Contribution violates the Terms, including the Content Standards, infringes any intellectual property
          right or other right of any person or entity, threatens the personal safety of users of the Site or the public, or could create
          liability for us.
        </li>
        <li class="pl-2">
          Disclose your identity or other information about you to any third party who claims that material posted by you violates their
          rights, including their intellectual property rights or their right to privacy.
        </li>
        <li class="pl-2">
          Take appropriate legal action, including referral to law enforcement, for any illegal or unauthorized use of the Site.
        </li>
        <li class="pl-2">
          Terminate or suspend your access to all or part of the Site for any reason, including any violation of these Terms.
        </li>
      </ul>,
      'Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS US AND OUR AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.',
      'However, we cannot review all material before it is posted on the Site, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.',
    ],
  },
  {
    id: 'reliance-information',
    title: '14. Reliance on Information Posted',
    content: [
      'The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.',
      'This Site includes content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by us, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.',
    ],
  },
  {
    id: 'third-party-links',
    title: '15. Third-Party Links',
    content: [
      'This Site may link to other websites that are not sites controlled or operated by us (collectively, “Third-Party Sites”). Certain areas of the Site may allow you to interact and/or conduct transactions with such Third-Party Sites, and, if applicable, allow you to configure your privacy settings in your Third-Party Site account to permit your activities on this Site to be shared with your contacts in your Third-Party Site account and, in certain situations, you may be transferred to a Third-Party Site through a link but it may appear that you are still on this Site. In any case, you acknowledge and agree that the Third-Party Sites may have different privacy policies and terms and conditions and/or user guides and business practices than us, and you further acknowledge and agree that your use of such Third-Party Sites is governed by the respective Third-Party Site privacy policy and terms and conditions and/or user guides. We provide links to the Third-Party Sites to you as a convenience, and we do not verify, make any representations or take responsibility for such Third-Party Sites, including the truthfulness, accuracy, quality or completeness of the content, services, links displayed and/or any other activities conducted on or through such Third-Party Sites. YOU AGREE THAT WE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY SITES AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR ANY DAMAGES OR LOSSES CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD PARTY. Any reference on the Site to any product, service, publication, institution, or organization of any third-party entity or individual does not constitute or imply our endorsement or recommendation.',
    ],
  },
  {
    id: 'linking-site',
    title: '16. Linking to the Site and Social Media Features',
    content: [
      'You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.',
      'This Site may provide certain social media features that enable you to:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Link from your own or certain third-party websites to certain content on this Site.</li>
        <li class="pl-2">Send emails or other communications with certain content, or links to certain content, on this Site.</li>
        <li class="pl-2">
          Cause limited portions of content on this Site to be displayed or appear to be displayed on your own or certain third-party
          websites.
        </li>
      </ul>,
      'You may use these features solely as they are provided by us and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">Establish a link from any website that is not owned by you.</li>
        <li class="pl-2">
          Cause the Site or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep
          linking, or in-line linking.
        </li>
        <li class="pl-2">
          Otherwise take any action with respect to the materials on this Site that is inconsistent with any other provision of these Terms.
        </li>
      </ul>,
      'The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms.',
      'You agree to cooperate with us to stop any unauthorized framing or linking immediately. We reserve the right to withdraw linking permission without notice.',
      'We may disable all or any social media features and any links at any time in our sole discretion and without notice.',
    ],
  },
  {
    id: 'federal-state-laws',
    title: '17. Federal and State Laws',
    content:
      'The Site is operated from the U.S. and is intended for U.S. residents only. The Site is not approved for distribution outside of the U.S. and non-U.S. residents should not rely or act upon the information contained within. When using the Site, on the Site, or when using any content provided by us, you must obey all applicable U.S. federal, state, and local laws.',
  },
  {
    id: 'minimum-age',
    title: '18. Minimum Age',
    content:
      'We do not allow persons under the age of eighteen (18) to use the Site. By using the Site, you represent and warrant that you are eighteen (18) years of age or over.',
  },
  {
    id: 'disclaimer-warranties',
    title: '19. Disclaimer of Warranties',
    content: [
      'Your use of this Site is at your own risk. The Materials have not been verified or authenticated in whole or in part by us, and they may include inaccuracies or typographical or other errors. We do not warrant the accuracy or timeliness of the Materials contained on this Site. We have no liability for any errors or omissions in the Materials, whether provided by us, our licensors or suppliers or other users.',
      'TO THE FULLEST EXTENT PROVIDED BY LAW AND EXCEPT AS OTHERWISE PROVIDED HEREIN OR ON THE SITE, THE INFORMATION AND SERVICES OFFERED ON OR THROUGH THE SITE AND ANY REFERENCED THIRD-PARTY SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. ANY THIRD-PARTY GOODS OR SERVICES PROVIDED ARE SUPPLIED AS A CONVENIENCE TO YOU AND DO NOT CONSTITUTE SPONSORSHIP, AFFILIATION, PARTNERSHIP, OR ENDORSEMENT. TO THE FULLEST EXTENT ALLOWED BY LAW, WE DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.',
      'TO THE FULLEST EXTENT ALLOWED BY LAW, WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITE, THE MATERIALS, ANY CONTENT, OR OTHER POSTED MATERIALS ON THE SITE IN TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.',
      'BY PROVIDING THE SERVICES ON THE SITE, WE DO NOT IN ANY WAY PROMISE THAT THE SERVICES WILL REMAIN AVAILABLE TO YOU. WE ARE ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE SITE AT ANY TIME, IN OUR SOLE DISCRETION WITHOUT NOTICE TO YOU.',
    ],
  },
  {
    id: 'limitation-liability',
    title: '20. Limitation of Liability',
    content: [
      'WE CANNOT GUARANTEE THE SITE WILL BE AVAILABLE ONE HUNDRED PERCENT (100%) OF THE TIME BECAUSE PUBLIC NETWORKS, SUCH AS THE INTERNET, OCCASIONALLY EXPERIENCE DISRUPTIONS. ALTHOUGH WE STRIVE TO PROVIDE THE MOST RELIABLE WEBSITE REASONABLY POSSIBLE, INTERRUPTIONS AND DELAYS IN ACCESSING THE SITE ARE UNAVOIDABLE AND WE DISCLAIM ANY LIABILITY FOR DAMAGES RESULTING FROM SUCH PROBLEMS.',
      'NOTWITHSTANDING THE FOREGOING, OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, EMPLOYEES, AGENTS, REPRESENTATIVES AND THIRD-PARTY SERVICE PROVIDERS WITH RESPECT TO ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE SITE, THE MATERIALS, AND ANY CONTENT OR SERVICES OBTAINED THROUGH THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED, IN THE AGGREGATE, FIFTY DOLLARS ($50).',
      'IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THE SITE, OR ON ANY OTHER HYPERLINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA OR OTHERWISE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
    ],
  },
  {
    id: 'indemnification',
    title: '21. Indemnification',
    content: [
      'You agree to indemnify, defend and hold harmless us and, to the extent applicable, our subsidiaries and affiliates, and each of their and our respective directors, officers, shareholders, employees, agents, representatives, clients, contractors and third-party service providers, for any and all losses, claims, demands, actions, liability, fines, penalties and expenses (including reasonable legal fees) that may arise from any of your acts through the use of the Site. Such acts may include: (a) providing content to or communicating with us or, to the extent applicable, our subsidiaries or affiliates; (b) unauthorized use of material obtained through the Site; (c) engaging in a prohibited activity; or (d) any other action that breaches these Terms. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, which shall not excuse your indemnity obligations.',
    ],
  },
  {
    id: 'general-release',
    title: '22. General Release',
    content: [
      'MAGICDOOR IS NOT INVOLVED IN TRANSACTIONS BETWEEN YOU AND ANY PROPERTY OWNER OR ANY OTHER USER DEALINGS. IF A DISPUTE ARISES BETWEEN YOU AND ANY OTHER USE AND/OR PROPERTY OWNER, YOU RELEASE MAGICDOOR (AND ITS RESPECTIVE AGENTS, EMPLOYEES, AND AFFILIATES) FROM CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUPECTED, DISCLOSED AND UNDISLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. YOU FURTHER RELASE MAGICDOOR FOR AND FROM ANY AND ALL CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING FROM, RELATED TO, OR IN CONNECTION WITH (A) THE USE OF THE SITE AND SERVICES, (B) ACTIONS, INACTIONS, AND/OR OMISSIONS OF OTHER SITE USERS, (C) THE PROMOTION OR ADVERTISING OF PROPERTY ON THE SITE AND SERVICES, (D) THE USE OF THE SITE AND SERVICES TO FACILIATE THE RENT OF A PROPERTY, OR (E) THE RENTAL OF ANY PROPERTY.',
    ],
  },
  {
    id: 'copyright-complaints',
    title: '23. Copyright Complaints',
    content: [
      'We respect the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our copyright agent with the following information.',
      <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2">
          An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;{' '}
        </li>
        <li class="pl-2">Description of the copyrighted work that you claim has been infringed; </li>
        <li class="pl-2">The location on the Site of the material that you claim is infringing; </li>
        <li class="pl-2">Your address, telephone number and e-mail address;</li>
        <li class="pl-2">A statement that your claim of infringement is based on a good faith belief; and </li>
        <li class="pl-2">
          A statement made under penalty of perjury that the information you have provided is accurate and that you are the copyright owner
          or authorized to act on the copyright owner’s behalf.
        </li>
      </ul>,
      'Our agent for notice of claims of copyright infringement on the Site can be reached as follows:',
      <ul class="space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2 font-bold">MagicDoor</li>
        <li class="pl-2">4245 S Grand Canyon Dr., Suite 200</li>
        <li class="pl-2">Las Vegas, NV 89147</li>
        <li class="pl-2">
          <EmailLink email="contact@magicdoor.com" />
        </li>
      </ul>,
    ],
  },
  {
    id: 'injunctive-relief',
    title: '24. Injunctive Relief',
    content: [
      'You acknowledge that we may be irreparably damaged if these Terms are not specifically enforced, and damages at law would be an inadequate remedy. Therefore, in the event of a breach or threatened breach of any provision of these Terms by you, we shall be entitled, without prejudice to any other rights and remedies that may be sought under the mandatory arbitration provision of these Terms, to an injunction restraining such breach or threatened breach, without being required to show any actual damage or to post an injunction bond, and/or to a decree for specific performance of the provisions of these Terms. For purposes of this Section, you agree that any action or proceeding with regard to such injunction restraining such breach or threatened breach shall be brought in the state or federal courts located in Delaware. You consent to the jurisdiction of such court and waive any objection to the laying of venue of any such action or proceeding in such court. You agree that service of any court paper may be effected on such party by mail or in such other manner as may be provided under applicable laws, rules of procedure or local rules.',
    ],
  },
  {
    id: 'mandatory-arbitration',
    title: '25. Mandatory Arbitration and Class Action and Jury Trial Waiver',
    content: [
      'Most concerns can be resolved quickly and to your satisfaction by contacting us as set forth in the “Questions” section below.',
      'In the event that we are not able to resolve a dispute, and with the exception of the claims for injunctive relief by us as described above and to the extent allowed by law, you hereby agree that either you or we may require any dispute, claim, or cause of action (“Claim”) between you and us or any third parties arising out of use of the Site, the Services, and any other actions with us (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory) to be arbitrated on an individual (non-class) basis. Claims also include, except as otherwise provided herein, disputes related to the coverage, applicability, arbitrability, enforceability, formation, scope, or validity of these Terms, including this Arbitration provision, all of which shall be subject to the sole power of the arbitrator as described herein. Notwithstanding anything else herein, the enforceability of the Class Action Waiver shall be determined by a court. In addition, both parties retain the right to seek relief in a small claims court (or a state court equivalent) for a Claim within the scope of its jurisdiction so long as the small claims action does not seek to certify a class, combine the claims of multiple persons, recover damages in excess of the limit for a small claim under applicable state law or is not transferred, removed, or appealed from small claims court to any different court. Additionally, if you are a California resident, you retain the right to obtain public injunctive relief from any court with proper jurisdiction.',
      'THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS VERY LIMITED. ADDITIONALLY, ANY ARBITRATION OF A CLAIM WILL BE ON AN INDIVIDUAL BASIS, AND, THEREFORE, YOU UNDERSTAND AND AGREE THAT YOU ARE WAIVING THE RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER IN A CLASS ACTION LAWSUIT. AS PART OF THIS WAIVER, YOU AGREE THAT YOU WAIVE THE RIGHT TO ACT AS A PRIVATE ATTORNEY GENERAL IN AN ARBITRATION; THAT EXCEPT AS OTHERWISE PROVIDED IN THIS ARBITRATION AGREEMENT, CLAIMS BROUGHT BY OR AGAINST YOU MAY NOT BE JOINED OR CONSOLIDATED WITH CLAIMS BROUGHT BY OR AGAINST ANY OTHER PERSON; AND THE ARBITRATOR SHALL HAVE NO AUTHORITY TO CONDUCT A CLASS-WIDE ARBITRATION, PRIVATE ATTORNEY GENERAL ARBITRATION OR MULTIPLE-PARTY ARBITRATION.',
      'You and we agree that your use of the Services involves interstate commerce, and that this arbitration agreement shall be interpreted and enforced in accordance with the Federal Arbitration Act (FAA) set forth in Title 9 of the U.S. Code to the fullest extent possible, notwithstanding any state law to the contrary, regardless of the origin or nature of the Claims at issue. The arbitrator must follow, to the extent applicable: (a) the substantive law of the state in which we entered into the transaction giving rise to this arbitration agreement; (b) the applicable statutes of limitations; and (c) claims of privilege recognized at law. The arbitrator will not be bound by federal, state or local rules of procedure and evidence or by state or local laws concerning arbitration proceedings.',
      'If either you or we elect to arbitrate a Claim, the dispute shall be resolved by binding arbitration administered under the applicable rules of the American Arbitration Association (“AAA”). Either you or we may elect to resolve a particular Claim through arbitration, even if the other party has already initiated litigation in court related to the Claim, by (i) making written demand for arbitration upon the other party, (ii) initiating arbitration against the other party, or (iii) filing a motion to compel arbitration in court.',
      <p>
        If this is a consumer-purpose transaction, the applicable rules will be the AAA’s Consumer Arbitration Rules. The applicable AAA
        rules and other information about arbitrating a claim under AAA, including how to submit a dispute to arbitration, may be obtained
        by visiting its website at {externalLink('https://www.adr.org/', 'https://www.adr.org/')} or by calling 1-800-778-7879. If AAA will
        not serve as the administrator of the arbitration, and you and we cannot then agree upon a substitute arbitrator, you and we shall
        request that a court with proper jurisdiction appoint an arbitrator. However, we will abide by the applicable AAA rules regardless
        of the forum. Arbitration shall be conducted in the county and state where you accepted these Terms, you reside, or another
        reasonably convenient place to you as determined by the arbitrator, unless applicable laws require another location. Judgment on the
        award rendered by the arbitrator may be entered in any court having jurisdiction thereof. Except as provided in applicable statutes,
        the arbitrator’s award is not subject to review by the court and it cannot be appealed. The parties will have the option to request
        and receive a statement of reasons for the arbitration award.
      </p>,
      'If you elect to file the arbitration, and this is a consumer-purpose transaction, you will pay the filing fee to the extent required by AAA’s Consumer Arbitration Rules but not to exceed the cost of filing a lawsuit. Any amount above what it would cost you to file a lawsuit, we will pay. All other arbitration fees and expenses shall be allocated to us according to AAA rules. Except for the arbitration fees and expenses, each party shall pay its own costs and fees incurred (including attorneys’ fees), unless the arbitrator allocates them differently in accordance with applicable law. This paragraph applies only if this is a consumer-purpose transaction.',
      'Additional Procedures for Mass Arbitration. If twenty-five (25) or more similar Claims (including yours) are asserted against us by the same or coordinated counsel or are otherwise coordinated (“Mass Arbitration”), you and we agree that these Additional Procedures for Mass Arbitration (in addition to the other provisions of this arbitration agreement) shall apply. You agree to this process even though resolution of your Claim may be delayed and ultimately proceed in court. The parties agree that as part of these procedures, their counsel shall meet and confer in good faith in an effort to resolve the Claims, streamline procedures, address the exchange of information, modify the number of Claims to be adjudicated, and conserve the parties’ and the AAA’s resources. If your Claim is part of a Mass Arbitration, any applicable limitations periods (including statutes of limitations) shall be tolled for your Claim from the time that your Claim is first submitted to the AAA until your Claim is selected to proceed as part of a staged process or is settled, withdrawn, otherwise resolved, or opted out of arbitration pursuant to this provision.',
      'Stage One: In Stage One, if at least fifty (50) Claims are submitted as part of the Mass Arbitration, claimants’ counsel and MagicDoor will each select an equal number of Claims to be filed in arbitration and resolved individually by different arbitrators. For example, claimant and MagicDoor will each select 25 Claims (50 Claims total). The number of Claims to be selected to proceed in Stage One can be modified by agreement of counsel for the parties provided that, if there are fewer than 50 Claims, all shall proceed individually in Stage One. The remaining Claims shall not be filed or deemed filed in arbitration nor shall any arbitration fees be assessed or collected in connection with those claims. If a case is withdrawn before the issuance of an arbitration award, another Claim shall be selected to proceed as part of the first stage. After this initial set of proceedings, the parties must engage in a single mediation of all remaining Claims, and we will pay the mediation fee.',
      'Stage Two: If the parties cannot agree how to resolve the remaining Claims (if any) after mediation, claimants’ counsel and MagicDoor will each select an equal number of Claims per side—not to exceed 50 Claims total—to be filed and to proceed as cases in individual arbitrations as part of Stage Two. The number of Claims to be selected to proceed as part of Stage Two can be modified by agreement of counsel for the parties provided that if there are fewer than 50 Claims remaining, all shall proceed individually in Stage Two. The remaining Claims shall not be filed or deemed filed in arbitration nor shall any arbitration fees be assessed or collected in connection with those claims. If a case is withdrawn before the issuance of an arbitration award, another Claim shall be selected to proceed as part of the second stage. After Stage Two is completed, the parties must engage in a single mediation of all remaining Claims, and we will pay the mediation fee.',
      'Upon the completion of the mediation in Stage Two, each remaining Claim (if any) that is not settled or not withdrawn shall be opted out of arbitration and may proceed in a court of competent jurisdiction consistent with the remainder of these Terms. Notwithstanding the foregoing, counsel for the parties may mutually agree in writing to proceed with the adjudication of some or all of the remaining Claims in individual arbitrations consistent with the process set forth in Stage Two (except Claims shall be randomly selected and mediation shall be elective by agreement of counsel) or through another mutually agreeable process. A court of competent jurisdiction shall have the authority to enforce the Procedures for Mass Arbitration, including the power to enjoin the filing or prosecution of arbitrations and the assessment or collection of arbitration fees. The Procedures for Mass Arbitration and each of its requirements are essential parts of this arbitration agreement. If, after exhaustion of all appeals, a court of competent jurisdiction decides that the Procedures for Mass Arbitration apply to your Claim and are not enforceable, then your Claim shall not proceed in arbitration and shall only proceed in a court of competent jurisdiction consistent with the remainder of these Terms.',
      'Notwithstanding anything to the contrary in these Terms, and except as otherwise set forth in this paragraph, the agreement to arbitration may be amended by us only upon advance notice to you. If we make any amendment to this agreement to arbitration (other than renumbering the agreement to align with any other amendment to the Terms) in the future, that amendment shall not apply to any claim that was filed in a legal proceeding or action against us prior to the effective date of the amendment. The amendment shall apply to all other Claims governed by this agreement to arbitration that have arisen or may arise between you and us. However, we may amend this agreement to arbitration and not provide you notice; in that case, the amendments will not apply to you and the agreement to arbitration contained in these Terms to which you agreed will continue to apply to you and us as if no amendments were made.',
      'If any part of this arbitration provision is invalid, all other parts of it remain valid. However, if the class action limitation is invalid, then this arbitration provision is invalid in its entirety, provided that the remaining Terms shall remain in full force and effect. This arbitration provision will survive the termination of your use of the Site, the Services, and any other actions with us.',
      <p>
        You may reject this arbitration provision within thirty (30) days of accepting the Terms by emailing us at{' '}
        <EmailLink email="contact@magicdoor.com" /> and including in the subject line “Rejection of Arbitration Provision.”
      </p>,
    ],
  },
  {
    id: 'other-terms',
    title: '26. Other Terms',
    content: [
      'Merger. These Terms (which hereby incorporate by reference any other provisions applicable to use of the Site) constitutes the entire agreement between you and us and it supersedes all prior or contemporaneous communications, promises and proposals, whether oral, written or electronic, between you and us with respect to the Sites and information, software, products and services associated with it.',
      'Severability. If any term or provision in these Terms is found to be void, against public policy, or unenforceable by a court of competent jurisdiction and such finding or order becomes final with all appeals exhausted, then the offending provision shall be deemed modified to the extent necessary to make it valid and enforceable. If the offending provision cannot be so modified,then the same shall be deemed stricken from these Terms in its entirety and the remainder of these Terms shall survive with the said offending provision eliminated.',
      'Governing Law and Venue. These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, excluding its conflicts of law rules, and the United States of America. Except as set forth in the agreement to arbitration and without waiving it, you agree that any dispute arising from or relating to the subject matter of these Terms (including but not limited to if you opt out of the agreement to arbitration) shall be governed by the exclusive jurisdiction and venue of the state and federal courts of Delaware, except where the jurisdiction and venue are mandated by applicable assignment.',
      <>
        Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, in any way (by operation
        of law or otherwise) without our prior written consent. We may freely assign our obligations and rights under these Terms, including
        all personal information in our possession that we have collected during your use of the Site as further described in our{' '}
        {externalLink('https://magicdoor.com/privacy-policy/', 'Privacy Policy')}.
      </>,
      'No Waiver. ​No failure, omission or delay on the part of us in exercising any right under these Terms will preclude any other further exercise of that right or other right under these Terms.',
      'Headings. Provision and section headings are for convenience of reference only and shall not affect the interpretation of these Terms.',
      'Typographical Errors. Information on the Site may contain technical inaccuracies or typographical errors. We attempt to make the Site’s postings as accurate as possible, but we do not warrant the content of the Site is accurate, complete, reliable, current, or error-free.',
    ],
  },
  {
    id: 'questions',
    title: '27. Questions',
    content: [
      <p>
        If you have any questions or comments about these Terms or this Site, please contact us by email at{' '}
        <EmailLink email="contact@magicdoor.com" />. You also may write to us at:
      </p>,
      <ul class="space-y-2 pb-2 pl-6 text-gray-600">
        <li class="pl-2 font-bold">MagicDoor</li>
        <li class="pl-2">4245 S Grand Canyon Dr., Suite 200</li>
        <li class="pl-2">Las Vegas, NV 89147</li>
      </ul>,
    ],
  },
];
