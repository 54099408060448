import { useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import { Table, TableActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { upperFirst } from '~/utils/tool';
import type { Component } from 'solid-js';

export interface Payment {
  payDate?: string;
  memo?: string;
  vendor?: string;
  paymentMethod?: string;
  paymentAmount?: string;
  rawPaymentAmount?: number;
  status?: string;
  transactionId?: string;
}
interface PaymentTableProps {
  data: Payment[];
}
export const PaymentTable: Component<PaymentTableProps> = (props) => {
  const { t } = useLocalization();
  const { billId } = useParams();

  const { deleteBillItem } = useBills();

  const onDeleteBillItem = async (id: string, transactionId: string) => {
    await deleteBillItem(id, transactionId, DeleteBillPaymentMethod.AdjustTransaction);
    toast.success(t('{name} has been deleted successfully', { name: 'Payment' }));
  };

  const columns = createMemo(() => [
    {
      title: t('Pay date'),
      render: (item: Payment) => item.payDate,
    },
    {
      title: t('Status'),
      render: (item: Payment) => statusCol(item.status as `${MagicDoor.Api.TransactionStatus}`),
    },
    {
      title: t('Memo'),
      render: (item: Payment) => item.memo,
    },
    {
      title: t('Vendor'),
      render: (item: Payment) => item.vendor,
    },
    {
      title: t('Payment method'),
      render: (item: Payment) => item.paymentMethod,
    },
    {
      title: t('Payment amount'),
      class: 'text-right',
      render: (item: Payment) => item.paymentAmount,
    },
    {
      headerClass: 'w-[6%]',
      render: (item: Payment) => (
        <TableActions
          actions={[{ label: t('Delete'), icon: IconDelete, onClick: () => onDeleteBillItem(billId, item.transactionId || '') }]}
        />
      ),
    },
  ]);

  const statusCol = (status: `${MagicDoor.Api.TransactionStatus}`) => {
    const colors = {
      pending: 'bg-[#FFF4D7] text-light-warning',
      failed: 'bg-danger-light text-light-danger',
      success: 'bg-green-level01 text-light-green',
      cancelled: 'text-text-level04 bg-gray-level02',
      returned: 'text-text-level04 bg-gray-level02',
    };
    return <div class={cn('w-18 rounded-xl py-1 text-center text-xs', (colors as any)[status])}>{t(upperFirst(status))}</div>;
  };

  return <Table columns={columns()} data={props.data} />;
};
