import { Show } from 'solid-js';
import { emptyPlaceholder } from '~/utils/constant';

export const getMaskString = (accountMask?: null | string) => {
  if (!accountMask) return emptyPlaceholder;
  return `**** **** **** ${accountMask.slice(-4)}`;
};

export const BankAccountMask = (props: { accountMask?: string | null }) => {
  return (
    <Show when={props.accountMask} fallback={emptyPlaceholder}>
      {getMaskString(props.accountMask)}
    </Show>
  );
};
