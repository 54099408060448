import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENTAL_APPLICATION_PAYMENT_URL = '/api/settings/rental-application/payment';

export class RentalApplicationPaymentSettingRepository extends BaseRestRepository {
  public async getRentalApplicationPaymentSettings(): Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentalApplicationPaymentSettings() {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async updateRentalApplicationPaymentSettings(
    payload: MagicDoor.Api.RentalApplicationPaymentSettingsDto
  ): Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

export const rentalApplicationPaymentSettingRepository = new RentalApplicationPaymentSettingRepository();
