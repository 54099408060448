import { useParams } from '@solidjs/router';
import { FileListView } from '~/components/file-attachments';
import { useProperties } from '~/contexts/global';
import { AttachmentsProvider } from '~/contexts/local';
import type { Component } from 'solid-js';

export const PropertyFiles: Component = () => {
  const params = useParams();
  const { current } = useProperties();
  return (
    <div class="mt-3 px-3 text-left md:mt-0 md:px-8">
      <AttachmentsProvider>
        <FileListView entryType={'property' as MagicDoor.Api.EntityType} entryId={current()?.id || params.propertyId} />
      </AttachmentsProvider>
    </div>
  );
};
