import { createSignal, For, Show } from 'solid-js';
import { Image, Popover } from '~/components/ui';
import { IconCheck } from '~/components/ui/Icons';

interface Option {
  id: string;
  value: string;
  label: string;
  img?: string;
  alt?: string;
}

interface DropdownProps {
  options: Option[];
  onOptionSelected: (value: Option) => void;
  initialOption?: Option;
}

const Dropdown = (props: DropdownProps) => {
  const [selectedOption, setSelectedOption] = createSignal(props.initialOption);

  const handleOptionClicked = (option: Option) => {
    setSelectedOption(option);
    props.onOptionSelected(option);
  };

  return (
    <Popover>
      <Popover.Trigger class="flex items-center gap-1 rounded-full border border-partingline bg-[#fafafa] px-4 py-3 text-sm font-semibold uppercase text-text-level02 transition-colors hover:bg-essential-colour/5 aria-expanded:border-essential-colour aria-expanded:bg-essential-colour/5">
        <Show when={selectedOption()?.img}>
          <Image class="h-3 md:h-5" src={selectedOption()?.img} alt={selectedOption()?.alt || ''} />
        </Show>
        {selectedOption()?.value}
      </Popover.Trigger>
      <Popover.Content class="my-1 min-w-52 space-y-0.5 rounded-md border border-partingline bg-white p-2 text-sm text-text-level02 shadow-lg">
        <For each={props.options}>
          {(option) => (
            <Popover.Trigger
              class="flex w-full cursor-pointer items-center gap-2 rounded-md border border-transparent p-2 transition-all hover:border-essential-colour hover:bg-essential-colour/5 hover:font-medium hover:text-essential-colour"
              onClick={() => handleOptionClicked(option)}
              aria-selected={selectedOption()?.id === option.id}>
              <Show when={option.img}>
                <Image src={option.img} alt={option.alt || ''} />
              </Show>
              {option.label}
              <Show when={selectedOption()?.id === option.id}>
                <IconCheck class="ml-auto size-4 text-essential-colour" />
              </Show>
            </Popover.Trigger>
          )}
        </For>
      </Popover.Content>
    </Popover>
  );
};

export default Dropdown;
