import type { Component } from 'solid-js';

interface ProgressPieChartProps {
  progress: number;
  size?: number;
  strokeWidth?: number;
  backgroundColor?: string;
  progressColor?: string;
}

export const ProgressPieChart: Component<ProgressPieChartProps> = (props) => {
  const normalizedProgress = () => Math.min(100, Math.max(0, props.progress));
  const center = () => (props.size || 100) / 2;
  const radius = () => ((props.size || 100) - (props.strokeWidth || 8)) / 2;
  const circumference = () => 2 * Math.PI * radius();
  const offset = () => circumference() - (normalizedProgress() / 100) * circumference();

  return (
    <svg width={props.size || 100} height={props.size || 100}>
      <circle
        cx={center()}
        cy={center()}
        r={radius()}
        fill="none"
        stroke={props.backgroundColor || '#e6e6e6'}
        stroke-width={props.strokeWidth || 8}
      />
      <circle
        cx={center()}
        cy={center()}
        r={radius()}
        fill="none"
        stroke={props.progressColor || '#9431E3'}
        stroke-width={props.strokeWidth || 8}
        stroke-dasharray={circumference()?.toString()}
        stroke-dashoffset={offset()}
        transform={`rotate(-90 ${center()} ${center()})`}
        stroke-linecap="round"
      />
    </svg>
  );
};
