import { createSignal, Switch, Match, createMemo } from 'solid-js';
import IconBalance from '~/assets/images/common/balance.svg?component-solid';
import IconCoin from '~/assets/images/property/coin.png';
import IconRevenueBg from '~/assets/images/property/revenueBg.png';
import IconMoneyBag from '~/assets/images/transaction/moneyBag.png';
import IconNotebook from '~/assets/images/transaction/notebook.png';
import { ListView, BarChart } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import { currency, formatBigNumber } from '~/utils/number';
type PropertyRevenueProps = {
  class?: string;
};

export const PropertyRevenue = (props: PropertyRevenueProps) => {
  const { t } = useLocalization();
  const [tab, setTab] = createSignal<'outstanding' | 'overview'>('outstanding');
  const { propertyOverview, getPropertyOverview } = useProperties();

  const ChartData = createMemo(() => {
    const chart: any = {
      data: [],
      max: 0,
    };
    Object.entries(propertyOverview()?.revenue?.group || {}).forEach(([key, value]) => {
      chart.data.push({ date: key, income: value.income, expense: value.expenses });
      chart.max = Math.max(chart.max, value?.income || 0, value?.expenses || 0);
    });
    return chart;
  });

  return (
    <Card title={t('Property revenue overview')} class={`col-span-3 xl:col-span-2 ${props.class || ''}`} contentClass="h-auto">
      <div class="relative">
        <div class="relative flex flex-col border-b border-partingline px-4 text-center text-sm text-text-level03 sm:flex-row sm:px-6">
          <div class="relative flex w-full py-2 sm:w-2/3">
            <div
              class="flex-1 cursor-pointer hover:text-text-level02"
              classList={{ 'font-medium text-text-level01': tab() === 'outstanding' }}
              onClick={() => setTab('outstanding')}>
              {t('Outstanding balance')}
            </div>
            <div
              class="flex-1 cursor-pointer hover:text-text-level02"
              classList={{ 'font-medium text-text-level01': tab() === 'overview' }}
              onClick={() => setTab('overview')}>
              {t('Overview')}
            </div>
            <div
              class="absolute bottom-0 h-0.5 w-12 -translate-x-1/2 rounded-t-sm bg-link"
              classList={{ 'left-1/4': tab() === 'outstanding', 'left-3/4': tab() === 'overview' }}
            />
          </div>
        </div>
        <div class="flex min-h-0 w-full flex-col gap-x-4 px-3 sm:h-[272px] sm:flex-row md:px-4">
          <Switch>
            <Match when={tab() === 'outstanding'}>
              <div class="flex w-full shrink-0 flex-col gap-3 py-3 sm:w-1/3 ">
                <div
                  class="relative flex h-3/5 w-full flex-col items-start	 rounded-md bg-[#F64852] bg-left-bottom bg-no-repeat text-white"
                  style={{ 'background-image': `url(${IconRevenueBg})` }}>
                  <div class="p-4">
                    <div class="text-2xl font-semibold">{currency(propertyOverview()?.summary?.currentBalance)}</div>
                    <div class="text-sm text-white/60">{t('Outstanding balance')}</div>
                  </div>

                  <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
                    <img src={IconCoin} class="h-20 w-auto" />
                  </div>
                </div>

                <div class="flex h-2/5 flex-col rounded-md border  border-partingline  p-4">
                  <div class="text-lg font-semibold">
                    <span class="text-danger">{propertyOverview()?.outstandingBalanceLeases?.length ?? 0}</span> {t('Units')}
                  </div>
                  <div class="text-sm text-text-level03">{t('With outstanding balance')} </div>
                </div>
              </div>
              <div class="flex min-h-0 w-full flex-col overflow-auto py-3 sm:w-2/3">
                <ListView
                  class="w-full flex-1"
                  skeletonSize={10}
                  hideFooter
                  data={propertyOverview()?.outstandingBalanceLeases}
                  loading={getPropertyOverview.loading}
                  columns={[
                    {
                      title: t('Units'),
                      render: (item) => {
                        const target = propertyOverview()?.units?.find((x) => x.id === item.unitId);
                        return target?.name;
                      },
                    },
                    {
                      title: t('Balances'),
                      render: (item) => {
                        return (
                          <div class="flex">
                            <IconBalance />
                            <span class="text-sm text-danger">{currency(item.balance)}</span>
                          </div>
                        );
                      },
                    },
                    {
                      title: t('Posted balances'),
                      render: (item) => {
                        return (
                          <div class="flex">
                            <IconBalance />
                            <span class="text-sm text-danger">{currency(item.postedBalance)}</span>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </Match>
            <Match when={tab() === 'overview'}>
              <div class="flex w-full shrink-0 flex-col gap-3 py-3 sm:w-1/3 md:px-4  ">
                <div class="relative flex h-1/2 w-full flex-col items-start rounded-md bg-gradient-to-br from-[#7FEAD4] to-[#C9FFF4]	 bg-left-bottom  bg-no-repeat text-title-gray">
                  <div class="w-full overflow-hidden p-4">
                    <div class=" truncate text-2xl font-semibold">{currency(propertyOverview()?.revenue?.income)}</div>
                    <div class="text-sm text-title-gray/60">{t('Income')}</div>
                  </div>
                  <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
                    <img src={IconMoneyBag} class="h-20 w-auto" />
                  </div>
                </div>

                <div class="relative flex h-1/2 w-full flex-col items-start rounded-md bg-gradient-to-r from-[#FEC690] to-[#FFEAD6]	 bg-left-bottom  bg-no-repeat text-title-gray">
                  <div class="w-full overflow-hidden p-4">
                    <div class=" truncate text-2xl font-semibold">{currency(propertyOverview()?.revenue?.expenses)}</div>
                    <div class="text-sm text-title-gray/60">{t('Expenses')}</div>
                  </div>

                  <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
                    <img src={IconNotebook} class="h-20 w-auto" />
                  </div>
                </div>
              </div>
              <div class="flex min-h-0 w-full flex-col items-center overflow-auto py-3 sm:w-2/3">
                <BarChart
                  data={ChartData().data}
                  xKey="date"
                  yKeys={['income', 'expense']}
                  colors={['#37CBAC', '#FA8511']}
                  maxValue={ChartData().max + 1000}
                  width={window.innerWidth < 640 ? 300 : 500}
                  height={window.innerWidth < 640 ? 150 : 200}
                  visibleBars={window.innerWidth < 640 ? 4 : 6}
                  formatYAxis={(value) => formatBigNumber(value)}
                  formatTooltip={(value) => formatBigNumber(value, 3)}
                />
              </div>
            </Match>
          </Switch>
        </div>
      </div>
    </Card>
  );
};
