import { Switch, Match, For, createSignal, createMemo, createEffect } from 'solid-js';
import { produce } from 'solid-js/store';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { RingLoader } from '~/components/common/Loaders';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { CreditDebitDirection } from '~/swagger/Api';
import { emptyPlaceholder, MAX_INPUT_AMOUNT_2 } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { isNull, isUndefined } from '~/utils/tool';
import type { MixedPayOutBankAccountDto } from '~/contexts/global';

const BillItem = (props: { bills: MixedPayOutBankAccountDto[]; onChange?: (arg: unknown) => void }) => {
  const { t } = useLocalization();
  const [activeIndex, setActiveIndex] = createSignal<number[]>([0]);
  const { payoutForm } = usePayOuts();

  const changeActive = (index: number) => {
    const bisIndex = activeIndex().findIndex((item) => item === index);
    setActiveIndex((prev) => {
      if (bisIndex === -1) {
        return [...prev, index];
      }
      return prev.toSpliced(bisIndex, 1);
    });
  };
  const isOpen = (index: number) => {
    return activeIndex().includes(index);
  };

  const getChartOfAccountName = (chartOfAccountId?: string) => {
    const chartOfAccount = payoutForm?.chartOfAccounts?.find((item) => item.id === chartOfAccountId);
    return chartOfAccount?.name;
  };

  return (
    <div class="my-3">
      <For each={props.bills}>
        {(item, index) => (
          <div class="mb-4 w-full rounded-lg border">
            <div
              onClick={() => changeActive(index())}
              class="flex cursor-pointer items-center justify-between rounded-t-lg border-b bg-input px-3 py-4 text-xs text-text-level01">
              {item.bankAccount?.name}
              <div class="flex items-center justify-start">
                <span class="text-xs uppercase text-text-level02">{t('Payment amount')}</span>：
                <span
                  class="text-xs text-text-level01"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <LabeledNumberInput
                    class="!mb-0 w-32"
                    inputContainerClass="bg-white"
                    inputClass=" text-right"
                    prepend="$"
                    {...props}
                    placeholder={t('Please enter')}
                    value={item.amount}
                    onInput={(val) => {
                      props.onChange?.({
                        val,
                        index: index(),
                      });
                    }}
                    min={1}
                    max={MAX_INPUT_AMOUNT_2}
                  />
                </span>
                <CircleArrowIcon
                  class="ml-5 mr-1"
                  classList={{
                    'text-text-level02': !isOpen(index()),
                    'text-primary rotate-180': isOpen(index()),
                  }}
                />
              </div>
            </div>
            <div
              class="w-full px-5 transition-all"
              classList={{
                hidden: !isOpen(index()),
                block: isOpen(index()),
              }}>
              <div class="mt-6 text-xs font-semibold text-text-level01">{t('Bills')}</div>
              <div class="mt-2 rounded-lg border text-xs text-text-level01">
                <table class="w-full table-auto border-collapse">
                  <thead>
                    <tr class="bg-[#F3C8FF66] text-[#A126ECCC]">
                      <th class="border-b p-3.5 text-left">{t('Due date')}</th>
                      <th class="border-b  p-3.5 text-left">{t('Memo')}</th>
                      <th class="border-b  p-3.5 text-left">{t('Chart of account')}</th>
                      <th class="border-b  p-3.5 text-left">{t('Cash in')}</th>
                      <th class="border-b  p-3.5 text-left">{t('Cash out')}</th>
                      <th class="border-b  p-3.5 text-left">{t('Balance')}</th>
                    </tr>
                  </thead>
                  <tbody class="overflow-hidden bg-input text-text-level01">
                    <For each={item.lineItems}>
                      {(lineItem) => {
                        return (
                          <>
                            <tr class="last-child-tr">
                              <td class="border-slate-100 p-3">{dateFormat('', lineItem.bill?.dueDate)}</td>
                              <td class="border-slate-100 p-3 ">{lineItem.bill?.memo || emptyPlaceholder}</td>
                              <td class="border-slate-100 p-3 ">
                                {getChartOfAccountName(lineItem.bill?.lineItems.at(0)?.chartOfAccountId)}
                              </td>
                              <td class="border-slate-100 p-3">
                                {lineItem.bill?.direction === CreditDebitDirection.Credit && currency(lineItem.amount)}
                              </td>
                              <td class="border-slate-100 p-3">
                                {lineItem.bill?.direction === CreditDebitDirection.Debit && currency(lineItem.amount)}
                              </td>
                              <td class="border-slate-100 p-3">{currency(lineItem.balance)}</td>
                            </tr>
                          </>
                        );
                      }}
                    </For>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </For>
    </div>
  );
};

const AllocationsForm = () => {
  const { t } = useLocalization();

  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const { payoutForm, setPayoutForm, calculatePayOutsLoading: isLoading, setCanNext } = usePayOuts();

  const isValidPayment = createMemo(() => {
    const errors: string[] = [];
    const isValid = payoutForm?.bankAccountPayOuts?.every((payment) => {
      const amount = Number(payment.amount);

      if (isUndefined(amount) || isNull(amount)) {
        errors.push(t('All bills must have an amount entered'));
        return false;
      }
      if (isNaN(amount) || amount <= 0) {
        errors.push(t('All amounts must be valid positive numbers'));
        return false;
      }
      return true;
    });
    setValidationErrors(errors);
    return isValid;
  });

  createEffect(() => {
    setCanNext(!!isValidPayment());
  });

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <Switch>
        <Match when={!isLoading() && payoutForm?.bankAccountPayOuts?.length !== 0}>
          <div>
            <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Payouts')}</h4>
            <BillItem
              bills={payoutForm?.bankAccountPayOuts || []}
              onChange={(arg: any) => {
                const { val, index } = arg;
                setPayoutForm(
                  produce((state) => {
                    let value = Number(val);
                    if (isNaN(value)) {
                      value = 0;
                    }
                    // @ts-expect-error must not be undefined
                    state.bankAccountPayOuts[index].amount = value;
                  })
                );
              }}
            />
          </div>
        </Match>
        <Match when={isLoading()}>
          <div class="flex items-center justify-center">
            <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
          </div>
        </Match>
        <Match when={payoutForm?.bankAccountPayOuts?.length === 0 && !isLoading()}>
          <Empty description={t('No payouts found')} />
        </Match>
      </Switch>
      {!isValidPayment() && (
        <div class="mt-4 text-red-500">
          <For each={validationErrors()}>{(error) => <div>{error}</div>}</For>
        </div>
      )}
    </div>
  );
};

export default AllocationsForm;
