import { useNavigate } from '@solidjs/router';
import { onMount } from 'solid-js';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus, LeaseTitle } from '~/components/leases';
import { formatTenantNames } from '~/components/tenants/utils';
import { ListView } from '~/components/ui';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
export const LeasesDraft = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { getLeases, leases, pagination, loading } = useLeasesList();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('LeaseDraftListView');

  const necessaryParams = { draft: true };
  onMount(() => getLeases({ page: page(), pageSize: pageSize(), ...necessaryParams }));

  return (
    <div class="relative w-full">
      <MobileListView
        class="overflow-hidden md:hidden"
        titleClass="w-full"
        searchClass="w-[80vw]"
        renderItem={(item) => {
          return (
            <div class="flex flex-col gap-2 p-3" onClick={() => navigate(`/leasing/leases/${item.id}`)}>
              <div class="flex justify-between">
                <LeaseTitle lease={item as any} />
              </div>
              <div class="flex gap-3 divide-x rounded-lg bg-light-gray p-3">
                <LeaseStatus lease={item as any} />
                <LeasePeriod lease={item as any} />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Balance')}:</span>
                <CnMoney money={item.balance} />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Posted balance')}:</span>
                <CnMoney money={item.postedBalance} />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Tenants')}:</span>
                <span class="text-sm font-medium text-text-level01">{formatTenantNames(item.tenants)}</span>
              </div>
            </div>
          );
        }}
        totalPages={pagination()?.totalPages}
        page={pagination()?.currentPage}
        pageSize={pagination()?.pageSize}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getLeases({ page, pageSize, search, ...necessaryParams });
        }}
        title={t('Draft leases')}
        searchPlaceholder={t('Search draft leases')}
        empty={<Empty description={t('No draft leases')} />}
        data={leases()}
        loading={loading()}
      />

      <ListView
        class="hidden md:block"
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getLeases({ page, pageSize, search, ...necessaryParams });
        }}
        title={t('Draft leases')}
        loading={loading()}
        totalPages={pagination()?.totalPages}
        page={pagination()?.currentPage}
        pageSize={pagination()?.pageSize}
        searchPlaceholder={t('Search draft leases')}
        data={leases()}
        rowLink={(item) => `/leasing/leases/${item.id}`}
        empty={<Empty description={t('No draft leases')} />}
        columns={[
          {
            title: t('Lease'),
            render: (item) => <LeaseTitle lease={item} />,
          },
          {
            title: t('Lease term'),
            headerClass: 'w-56',
            render: (item) => (
              <>
                <LeaseStatus lease={item} />
                <LeasePeriod lease={item} />
              </>
            ),
          },
          {
            title: t('Tenants'),
            headerClass: 'w-64',
            render: (item) => <span class="text-sm font-medium text-text-level01">{formatTenantNames(item.tenants)}</span>,
          },
          {
            title: t('Balance'),
            headerClass: 'w-36 text-right',
            class: 'text-right',
            render: (item) => <CnMoney money={item.balance} />,
          },
          {
            title: t('Posted balance'),
            headerClass: 'w-36 text-right',
            class: 'text-right',
            render: (item) => <CnMoney money={item.postedBalance} />,
          },
        ]}
      />
    </div>
  );
};
