import IconWarning from '~/assets/images/common/warning.svg?component-solid';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type WarningProps = {
  text: string;
  class?: string;
};

export const Warning: Component<WarningProps> = (props) => {
  return (
    <div class={cn('-mb-1 flex items-center rounded-t-lg bg-[#f5e2db] p-4', props.class)}>
      <IconWarning class="mx-2" />
      <span class="text-sm text-[#ff5b29]">{props.text}</span>
    </div>
  );
};
