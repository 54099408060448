import { createMemo, createSignal, mergeProps, Show } from 'solid-js';
import IconUpload from '~/assets/images/bill/upload.svg';
import { LinkButton, Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/Section';
import { ListView, IconDownload, IconTrash, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { downloadFile } from '~/utils/file';
import { FilePreviewModal } from './FilePreviewModal';
import { FileTitle } from './FileTitle';
import { FileUploadModal } from './FileUploadModal';
import type { Component } from 'solid-js';

export const BillFileListView: Component<{
  billId?: string;
  class?: string;
  onlyUpload?: boolean;
  autoUpload?: boolean;
}> = (originProps) => {
  const props = mergeProps(
    {
      autoUpload: true,
    },
    originProps
  );

  const { t } = useLocalization();
  const { current, addBillFile, deleteBillFile, setFileList, fileList } = useBills();

  const [preview, setPreview] = createSignal<MagicDoor.Api.BillFileDto>();
  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);
  const [selected, setSelected] = createSignal<MagicDoor.Api.BillFileDto[]>([]);

  const loading = () => current.loading;

  const data = createMemo(() => (props.autoUpload ? current()?.files : (fileList() as unknown as MagicDoor.Api.BillFileDto[])));

  const handleDownloadSelect = async () => {
    const files = selected();
    if (files.length === 0) return;
    files.forEach((file) => downloadFile(file.signedUrl, file.fileName));
  };

  const onUploadConfirm = async (categoryId: string, files: File[], name: string, description?: string) => {
    const newFiles = files.map((f) => ({ type: f.type, fileName: f.name, description, file: f }));
    const billId = props.billId;
    if (props.autoUpload && billId) {
      for (const file of newFiles) {
        await addBillFile(billId, file.file, description);
      }
    } else {
      setFileList((prev) => [...prev, ...newFiles]);
    }
  };

  const deleteFile = async (item: MagicDoor.Api.BillFileDto, index: number) => {
    if (props.autoUpload) {
      confirm({
        title: t('Delete file'),
        content: t('Are you sure you want to delete this file?'),
        async onResolve(confirmed) {
          if (!confirmed || props.billId == null) return;
          await deleteBillFile(props.billId, item.fileId);
        },
      });
      return;
    } else {
      setFileList((prev) => prev.toSpliced(index, 1));
    }
  };

  const tableActions = (item: MagicDoor.Api.BillFileDto, index: number) => {
    const actions = [
      {
        label: t('Download'),
        icon: IconDownload,
        onClick: () => downloadFile(item.signedUrl, item.fileName),
      },
      {
        label: t('Delete'),
        icon: IconTrash,
        onClick: () => deleteFile(item, index),
      },
    ];
    return props.autoUpload ? actions : actions.toSpliced(0, 1);
  };

  return (
    <>
      <Show
        when={data()?.length}
        fallback={
          <Section title={t('Bill invoice')} class="border-0" titleClass="px-0 py-3">
            <div class="flex w-full flex-col items-center rounded-[10px] border border-dashed border-primary bg-white py-[34px]">
              <Button variant="solid" rounded="full" color="primary" class="px-5 py-1.5" onClick={() => setShowUploadModal(true)}>
                {t('Upload your file')}
              </Button>
            </div>
          </Section>
        }>
        <ListView
          theadClass="bg-primary/15 text-primary/80"
          headerClass="px-0 pt-0 [&>h2]:text-base"
          title={t('Bill invoice')}
          class="border-0"
          hideFooter
          titleActions={
            <>
              <Show when={!props.onlyUpload}>
                <LinkButton
                  href=""
                  onClick={handleDownloadSelect}
                  variant="outlined"
                  rounded="full"
                  disabled={selected().length === 0}
                  class="gap-1 border-link px-2.5 py-1 text-xs text-link hover:bg-link/10 [&_span]:whitespace-nowrap">
                  <IconDownload class="size-4" />
                  <span>{t('Download')}</span>
                </LinkButton>
              </Show>
              <LinkButton
                href=""
                variant="outlined"
                rounded="full"
                onClick={() => setShowUploadModal(true)}
                class="gap-1 border-link px-2.5 py-1 text-xs text-link hover:bg-link/10 [&_span]:whitespace-nowrap">
                <img src={IconUpload} />
                <span>{t('Upload file')}</span>
              </LinkButton>
            </>
          }
          selected={props.onlyUpload ? undefined : selected()}
          onSelect={setSelected}
          onRowClick={setPreview}
          loading={loading()}
          data={data()}
          columns={[
            {
              title: t('File'),
              headerClass: 'py-[9px]',
              render: (item) => <FileTitle file={item} />,
            },
            {
              title: t('From'),
              headerClass: 'w-48 py-[9px]',
              class: 'space-y-1 text-xs',
              render: (item) => (
                <>
                  <Show when={item?.uploaded}>
                    <p class="text-text-level03">
                      {t('by')} {item?.uploaded && dateFormat('', item?.uploaded)}
                    </p>
                  </Show>
                </>
              ),
            },
          ]}
          actions={(item, index) => tableActions(item, index)}
        />
      </Show>

      <FileUploadModal
        pending={addBillFile.pending}
        entryType={undefined}
        entryId={undefined}
        open={showUploadModal()}
        onClose={() => setShowUploadModal(false)}
        onConfirm={onUploadConfirm}
      />

      <FilePreviewModal file={preview() as any as MagicDoor.Api.HydratedFileAttachmentDto} onClose={setPreview} />
    </>
  );
};
