import { useParams } from '@solidjs/router';
import { createEffect, createSignal, Switch, Match, createMemo, Show } from 'solid-js';
import AccountSettingIcon from '~/assets/images/leases/accountSetting.svg?component-solid';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, useForm, FormItem } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseOverviewBanner } from '~/components/leases';
import { PropertyTitle } from '~/components/properties';
import { toast } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useProperties, useUnits } from '~/contexts/global';
import { useServiceBill } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { MAX_INPUT_AMOUNT } from '~/utils/constant';
import { currency, isNumber } from '~/utils/number';
import type { Component } from 'solid-js';
import type { PropertyDto } from '~/swagger/Api';

export type AddServiceBillType = {
  propertyId: string;
  unitId?: string;
  leaseId?: string;
  amount: number;
  propertyBillMemo?: string;
  companyBillMemo?: string;
  propertyChartOfAccountId: string;
  companyChartOfAccountId: string;
};

type AddServiceBillTypePageProps = {
  type: 'property' | 'unit' | 'lease';
  currentPropertyId?: string;
  currentUnitId?: string;
  currentLease?: MagicDoor.Api.HydratedLeaseDto;
};

function isInRange(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

export const AddServiceBillTypePage: Component<AddServiceBillTypePageProps> = (props) => {
  const { t } = useLocalization();
  const { units, getUnits } = useUnits();
  const { current } = useProperties();
  const { addServiceBill } = useServiceBill();
  const goBack = useGoBack();
  const params = useParams<{ propertyId?: string; unitId?: string }>();
  const form = useForm();

  createEffect(() => {
    if (units() === undefined && props.type === 'unit') getUnits({});
  });

  const unitId = createMemo(() => props.currentUnitId || params.unitId);

  const currentUnit = createMemo(() => {
    return units()?.items?.find((x) => x.id === unitId());
  });

  const formId = 'service_bill_form';

  const [loading, setLoading] = createSignal<boolean>(false);

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    setLoading(true);
    try {
      const params: any = {
        ...form.formStore,
      };

      params.propertyId = props.currentPropertyId || current()?.id;

      switch (props.type) {
        case 'unit':
          params.propertyId = currentUnit()?.propertyId;
          params.unitId = unitId();
          break;
        case 'lease':
          params.unitId = props.currentLease?.unitId;
          params.leaseId = props.currentLease?.id;
          break;
      }

      if (unitId()) {
        params.unitId = unitId();
      }

      await addServiceBill(params);

      toast.success(t('{name} has been added successfully', { name: 'Add service bill' }));
      goBack();
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    goBack();
  };

  const isEffectiveAmount = createMemo(() => form?.formStore.amount && isInRange(form?.formStore.amount, 1, MAX_INPUT_AMOUNT));

  const breadcrumbItems = createMemo(() => [{ label: t('Add service bill') }]);

  return (
    <>
      <div class="md:hidden">
        <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      </div>

      <div class="m-3 mt-0 rounded-lg bg-white md:mt-3">
        <form class="thinscroll flex flex-col gap-5 overflow-auto px-3 pb-16 pt-6 md:px-8" id={formId}>
          <Switch>
            <Match when={props.type === 'property'}>
              <PropertyTitle property={current() as PropertyDto} />
            </Match>

            <Match when={props.type === 'unit' && currentUnit()}>
              <UnitTitle unit={currentUnit()} />
            </Match>

            <Match when={props.type === 'lease'}>
              <LeaseOverviewBanner lease={props.currentLease!} />
            </Match>
          </Switch>

          <div>
            <div class="flex gap-2 border-b border-partingline py-3 text-text-level01">
              <AccountSettingIcon />
              <span class="font-semibold">{t('Chart of account settings')}</span>
            </div>
            <div class="">
              <Form defaultForm={form} class="grid grid-cols-1 gap-x-7 gap-y-6 pb-5 pt-4 md:grid-cols-2">
                <FormItem
                  placeholder={t('Please select')}
                  class="col-span-full lg:col-span-1"
                  rules={[{ required: true, message: t(`Please select chart of account`) }]}
                  label={t('Company chart of account')}
                  formFieldName="companyChartOfAccountId"
                  component={LabeledChartOfAccountSelect}
                />
                <FormItem
                  placeholder={t('Please enter')}
                  class="col-span-full lg:col-span-1"
                  label={t('Company bill memo')}
                  formFieldName="companyBillMemo"
                  component={LabeledTextInput}
                />

                <FormItem
                  placeholder={t('Please select')}
                  class="col-span-full lg:col-span-1"
                  rules={[{ required: true, message: t(`Please select chart of account`) }]}
                  label={t('Property chart of account')}
                  formFieldName="propertyChartOfAccountId"
                  component={LabeledChartOfAccountSelect}
                />

                <FormItem
                  placeholder={t('Please enter')}
                  class="col-span-full lg:col-span-1"
                  label={t('Property bill memo')}
                  formFieldName="propertyBillMemo"
                  component={LabeledTextInput}
                />

                <FormItem
                  rules={[
                    { message: t(`Please input the amount`), required: true },
                    {
                      range: [1, MAX_INPUT_AMOUNT],
                      type: 'number',
                      message: t('{name} must be between {min} and {max}', { name: t('Amount'), min: '1', max: MAX_INPUT_AMOUNT }),
                    },
                  ]}
                  placeholder={t('Please enter')}
                  class="col-span-full lg:col-span-2"
                  label={t('Amount')}
                  formFieldName="amount"
                  component={LabeledNumberInput}
                  max={MAX_INPUT_AMOUNT}
                  isBlur={false}
                />
              </Form>
            </div>
          </div>

          <div class="relative flex w-full items-center justify-end bg-pink-100 py-5">
            <img src={ModalBgIcon} class="absolute bottom-0 left-4 h-20 w-52" />
            <div class="mr-4 flex items-center">
              <span class="mr-2 text-xs uppercase lg:text-sm">{t('Total amount')}:</span>
              <Show when={isEffectiveAmount()} fallback={<span class="text-sm font-bold text-text-level03 lg:text-2xl">--</span>}>
                <span class="text-sm font-bold text-essential-colour lg:text-2xl">
                  {currency(isNumber(form?.formStore.amount) ? form?.formStore.amount : 0)}
                </span>
              </Show>
            </div>
          </div>
        </form>

        <div class="fixed inset-x-0 bottom-0 flex justify-end gap-2 bg-white p-3">
          <Button variant="outlined" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button class="flex flex-1 justify-center" loading={loading()} onClick={handleSubmit}>
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </>
  );
};
