import { For } from 'solid-js';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import { SelectedVendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';

export const TaxDocGenerationSummary = () => {
  const { t } = useLocalization();
  const { model: vendorTaxAmounts } = usePresenter(SelectedVendorTaxAmountPresenter);

  return (
    <Section title={t('Summary')} class="px-[96px]" titleClass="mt-2 px-0">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
          <For each={vendorTaxAmounts()} fallback={<div>{t('No vendors found')}</div>}>
            {(vendor) => (
              <div class="mb-2 flex items-center justify-between rounded-lg bg-input p-4 shadow-sm transition-colors ">
                <span class="text-gray-800">{vendor.vendorName}</span>
                <div>{vendor.taxAmount}</div>
              </div>
            )}
          </For>
        </div>
      </div>
    </Section>
  );
};
