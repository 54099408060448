import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export const Section: Component<{
  class?: string;
  titleClass?: string;
  contentClass?: string;
  title: JSX.Element;
  children: JSX.Element;
}> = (props) => {
  return (
    <div class={cn('overflow-hidden rounded-lg border border-partingline bg-white', props.class)}>
      <Show when={props.title}>
        <h2 class={cn('border-b border-[#edeff2] px-6 py-4 text-lg font-semibold capitalize text-title-gray', props.titleClass)}>
          {props.title}
        </h2>
      </Show>
      <div class={props.contentClass}>{props.children}</div>
    </div>
  );
};
