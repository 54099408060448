export function printDocument(printContainer: HTMLDivElement, printWrapClass?: string, printHeader?: Element, extraStyle = '') {
  const content = printContainer?.innerHTML;
  const printWindow = window.open('', '_blank');
  if (printWindow) {
    let styles = '';
    const linkTags = document.getElementsByTagName('link');
    for (let i = 0; i < linkTags.length; i++) {
      if (linkTags[i].rel === 'stylesheet') {
        styles += linkTags[i].outerHTML;
      }
    }
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      styles += styleTags[i].outerHTML;
    }
    const allElements = document.getElementsByTagName('*');
    for (let i = 0; i < allElements.length; i++) {
      window.getComputedStyle(allElements[i]);
    }
    printWindow.document.write('<html><head>');
    printWindow.document.write(styles);
    printWindow.document.write('<style type="text/css">[data-slot="report"] [data-slot="label"] { min-width: 1px !important; }</style>');
    printWindow.document.write('</head><body onload="this.print();" style="-webkit-print-color-adjust: exact;print-color-adjust: exact">');
    printWindow.document.write(`<div class="${printWrapClass}" style="${extraStyle}">${printHeader?.innerHTML || ''} ${content}</div>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  }
}

export const printUrl = async (fileUrl?: string): Promise<HTMLIFrameElement | undefined> => {
  if (!fileUrl) {
    return;
  }
  const response = await fetch(fileUrl);
  const pdfBlob = await response.blob();
  return printFileBlob(pdfBlob);
};

export const printFileBlob = (blob?: Blob): HTMLIFrameElement | undefined => {
  if (!blob) {
    return;
  }
  try {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.src = blobUrl;
    iframe.onload = function () {
      try {
        iframe.contentWindow?.print();
      } catch (error) {
        console.error('Error printing document', error);
      }
    };
    return iframe;
  } catch (error) {
    console.error('Error printing document', error);
  }
};

export const resetPrintSystem = (iFrame?: HTMLIFrameElement) => {
  if (iFrame) {
    document.body.removeChild(iFrame);
  }
  if (iFrame?.src) {
    URL.revokeObjectURL(iFrame.src);
  }
};
