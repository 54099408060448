import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import AddTenant from '~/pages/tenants/components/AddTenant';

export const AddTenantPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();

  const breadcrumbItems = createMemo(() => [{ label: t('Tenants'), link: '/users/tenants' }, { label: `${t('Add a new tenant')}` }]);

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <AddTenant />
    </div>
  );
};
