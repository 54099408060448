import { Outlet } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onCleanup, onMount, Show } from 'solid-js';
import IconAccountTab from '~/assets/images/settings/tabs/accountTab.svg?component-solid';
import IconCompanyTab from '~/assets/images/settings/tabs/companyTab.svg?component-solid';
import IconPortalTab from '~/assets/images/settings/tabs/portalTab.svg?component-solid';
// import IconPreferencesTab from '~/assets/images/settings/tabs/preferencesTab.svg?component-solid';
import IconPropertyTab from '~/assets/images/settings/tabs/propertyTab.svg?component-solid';
import IconRentalTab from '~/assets/images/settings/tabs/rentalApplcation.svg?component-solid';
import { Tabs } from '~/components/common/Tabs';
import { useLocalization } from '~/contexts/global';

const Layout = () => {
  const { t } = useLocalization();

  const [isSidebarVisible, setIsSidebarVisible] = createSignal<boolean>(window.innerWidth > 1023);
  const [isMobileView, setIsMobileView] = createSignal<boolean>(window.innerWidth <= 1023);

  let touchstartX = 0;
  let touchendX = 0;

  const handleTouchStart = (e: any): void => {
    touchstartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: any): void => {
    const threshold = 75;

    touchendX = e.changedTouches[0].screenX;
    if (touchendX < touchstartX - threshold && isSidebarVisible()) setIsSidebarVisible(false);
    if (touchendX > touchstartX + threshold && !isSidebarVisible()) setIsSidebarVisible(true);
  };

  onMount(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
  });

  onCleanup(() => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  });

  createEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const newIsMobileView = window.innerWidth <= 1023;
      setIsMobileView(newIsMobileView);
      if (!newIsMobileView) setIsSidebarVisible(true);
    });

    resizeObserver.observe(document.body);

    onCleanup(() => resizeObserver.disconnect());
  });

  createEffect(() => {
    if (isMobileView()) setIsSidebarVisible(false);
  });

  const handleTabsVisibility = () => {
    const flag = !isSidebarVisible();
    setIsSidebarVisible(flag);
  };

  const accountNavItems = createMemo(() => [
    {
      name: t('Information'),
      path: 'account/information',
    },
    // {
    //   name: t('Activity logs'),
    //   path: 'account/activities',
    // },
    {
      name: t('Login & Password'),
      path: 'account/security',
    },
  ]);

  const companyNavItems = createMemo(() => [
    {
      name: t('Company information'),
      path: 'company/information',
    },
    {
      name: t('Business information'),
      path: 'company/business',
    },
    {
      name: t('Payment accounts'),
      path: 'company/payment-accounts',
    },
  ]);

  const propertyNavItems = createMemo(() => [
    {
      name: t('Property'),
      path: 'property/general',
    },
    {
      name: t('Rent notifications'),
      path: 'property/rent-notifications',
    },
    {
      name: t('Rent payment'),
      path: 'property/rent-payment',
    },
    {
      name: t('Deposit payment'),
      path: 'property/deposit-payment',
    },
    {
      name: t('Work order'),
      path: 'property/work-order',
    },
    {
      name: t('Rent payment processing fees'),
      path: 'property/rent-payment-processing-fees',
    },
    {
      name: t('Late fees'),
      path: 'property/late-fees',
    },
    {
      name: t('Management fees'),
      path: 'property/management-fees',
    },
    {
      // TODO Leave it aside for now
      name: t('Maintenance request'),
      path: 'property/settings-maintenance',
    },
    {
      name: t('Contributions'),
      path: 'property/contributions',
    },
  ]);

  const chatsNavItems = createMemo(() => [
    {
      name: t('Chat'),
      path: 'chats/chat',
    },
    {
      name: t('Text'),
      path: 'chats/text',
    },
    {
      name: t('AI'),
      path: 'chats/ai',
    },
  ]);

  const portalNavItems = createMemo(() => [
    // {
    //   name: t('Tenant portal'),
    //   path: 'portal/tenant-portal',
    // },
    // {
    //   name: t('Owner portal'),
    //   path: 'portal/owner-portal',
    // },
    {
      name: t('Portal invite & url'),
      path: 'portal/invite-url',
    },
    {
      name: t('Portal invite & text'),
      path: 'portal/invite-text',
    },
    {
      name: t('Portal settings'),
      path: 'portal/portal-settings',
    },
  ]);

  // const preferencesNavItems = createMemo(() => [
  //   {
  //     name: t('Preferences'),
  //     path: 'preferences',
  //   },
  // ]);

  const rentalApplicationNavItems = createMemo(() => [
    {
      name: t('Application fee'),
      path: 'rental/applicationfee',
    },
    {
      name: t('Applicant information'),
      path: 'rental/information',
    },
    {
      name: t('Chart of account'),
      path: 'rental/chart-of-account',
    },
    {
      name: t('General settings'),
      path: 'rental/general-settings',
    },
    {
      name: t('Standard questions'),
      path: 'rental/standard-questions',
    },
  ]);

  return (
    <div class="flex size-full gap-4 p-8">
      <div
        onClick={() => {
          if (isMobileView() && isSidebarVisible()) handleTabsVisibility();
        }}
        class="flex flex-col gap-4 divide-y capitalize transition-all"
        classList={{
          'translate-x-0': isSidebarVisible(),
          '-translate-x-full w-0 overflow-hidden': !isSidebarVisible(),
          'w-full': isSidebarVisible() && isMobileView(),
        }}>
        <Tabs items={accountNavItems()} icon={IconAccountTab} title={t('Account')} />
        <Tabs items={companyNavItems()} icon={IconCompanyTab} title={t('Company')} />
        <Tabs items={propertyNavItems()} icon={IconPropertyTab} title={t('Property')} />
        <Tabs items={rentalApplicationNavItems()} icon={IconRentalTab} title={t('Rental application')} />
        <Tabs items={portalNavItems()} icon={IconPortalTab} title={t('Portal management')} />
        <Tabs items={chatsNavItems()} icon={IconPortalTab} title={t('Chats')} />
        {/* <Tabs items={preferencesNavItems()} icon={IconPreferencesTab} title={t('Portal management')} /> */}
      </div>
      <Show when={isMobileView() && !isSidebarVisible()}>
        <button
          onClick={handleTabsVisibility}
          class="fixed left-0 top-16 z-10 translate-x-0 rounded-r-md bg-link p-2 text-left text-white transition-transform duration-300 ease-out md:left-64">
          {t('All settings')}
        </button>
      </Show>
      <main class="relative flex-1">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
