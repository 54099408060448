import { useParams, useNavigate } from '@solidjs/router';
import { createEffect, For, createSignal, Show } from 'solid-js';
import IconFeatures from '~/assets/images/identity/robotBox.png';
import { Pagination } from '~/components/ui/Pagination';
import { usePortfolios, useUnitListings, useLocalization } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import ListingCard from '~/pages/listing/components/ListingCard';
import { PortfolioTabs } from '~/pages/listing/components/PortfolioTabs';
import type { UnitListingFilter } from '~/repositories/unitListingsRepository';

type ListInterface =
  | {
      property: MagicDoor.Api.PropertyDto;
      portfolio: MagicDoor.Api.PortfolioDto;
    }
  | MagicDoor.Api.UnitListingDto
  | null;
export const ListListingPage = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { portfolios, current, currentId, setCurrentId } = usePortfolios();
  const { unitListings, getUnitListings } = useUnitListings();
  const params = useParams<{ portfolioId?: `${number}` }>();

  const [filter, setFilter] = createSignal<UnitListingFilter>();
  const [listings, setListings] = createSignal<ListInterface[]>([]);
  const [totalPages, setTotalPages] = createSignal<number>(0);
  const { page, pageSize, setPage } = useLocalPagination('UnitListings');

  const handleChange = (page: number) => {
    setPage(page);
    setFilter({ ...(filter() as UnitListingFilter), page: page });
    getUnitListings(filter() as UnitListingFilter);
  };

  createEffect(() => {
    if (params.portfolioId && /^\d+$/.test(params.portfolioId)) {
      setCurrentId(params.portfolioId);
    } else if (portfolios()?.[0]?.id) {
      const currentId = current()?.id ?? setCurrentId(portfolios()?.[0]?.id);
      params.portfolioId == null && navigate(`/listings/${currentId}`, { replace: true });
      setPage(1);
    }
    setFilter({ page: page(), pageSize: pageSize(), portfolioId: currentId() });
    getUnitListings(filter() as UnitListingFilter);
  });

  createEffect(() => {
    const arrTemp = [] as ListInterface[];
    setTotalPages(unitListings()?.totalPages || 0);
    unitListings()?.items.forEach((item) => {
      const property = unitListings()?.properties?.find((pro) => pro.id === item.propertyId);
      const portfolio = unitListings()?.portfolios?.find((portfolio) => portfolio.id === item.portfolioId);
      const listingTemp = { ...item, property, portfolio };
      arrTemp.push(listingTemp);
    });
    setListings(arrTemp);
  });

  return (
    <div class="size-full space-y-2 p-3 md:space-y-5 md:p-8 md:pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      {/* <PortfolioSummary /> */}
      <Show
        when={listings().length}
        fallback={
          <div class="flex h-section1 w-full flex-col items-center justify-center bg-white">
            <img src={IconFeatures} class="size-40" />
            <div class="text-center text-xs text-text-level03">
              <p>{t('Listing is not set up yet.')}</p>
              <p>{t('Click in the upper right corner to set up a new listing.')}</p>
            </div>
          </div>
        }>
        <div class="grid w-full grid-cols-2 gap-1 md:gap-3 lg:grid-cols-3 xl:grid-cols-4">
          <For each={listings()}>{(listing, index) => <ListingCard id={`listing-${index()}`} listing={listing} class="col-span-1" />}</For>
        </div>
        <Show when={totalPages()}>
          <div class="flex justify-center">
            <Pagination total={totalPages()} value={page()} onChange={(payload) => handleChange(payload)} />
          </div>
        </Show>
      </Show>
    </div>
  );
};
