import { useParams } from '@solidjs/router';
import { Show, createMemo, onMount, createSignal } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useGoBack } from '~/hooks';
import { LeaseDraftPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { LeaseDraftPreviewContainer } from '~/pdfsigner/ui/components/LeaseDraftPreviewContainer';
import { LeaseSigningContainer } from '~/pdfsigner/ui/components/LeaseSigningContainer';
import { PdfDocument } from '~/pdfsigner/ui/components/PdfDocument';
import { LoadLeaseDraftUseCase } from '~/pdfsigner/usecases/pdfEditing/loadLeaseDraftUseCase';
import { SaveLeaseDraftUseCase } from '~/pdfsigner/usecases/pdfEditing/saveLeaseDraftUseCase';
import { SendDraftForSigningUseCase } from '~/pdfsigner/usecases/pdfEditing/sendDraftForSigningUseCase';
import { SendRemindersUseCase } from '~/pdfsigner/usecases/pdfEditing/sendRemindersUseCase';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { JSX } from 'solid-js';

const LeasePreview = (): JSX.Element => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { draftId, leaseId } = useParams();
  const { execute: save, isLoading: isSaving } = useUseCase(SaveLeaseDraftUseCase);
  const { execute: sendForSigning, isLoading: isSendingForSigning } = useUseCase(SendDraftForSigningUseCase);
  const { execute: onSendReminders } = useUseCase(SendRemindersUseCase);
  const { execute: loadDraft, isLoading: isLoadingDraft } = useUseCase(LoadLeaseDraftUseCase);
  const { model: presentablePdf } = usePresenter(LeaseDraftPresenter);
  const breadcrumbItems = createMemo(() => [{ label: t('Files'), link: `/leasing/leases/${leaseId}/files` }, { label: draftId }]);
  const [isChange, setIsChange] = createSignal<boolean>(false);

  onMount(() => {
    loadDraft(draftId);
  });

  const isDraft = (): boolean => {
    return presentablePdf()?.status === DocumentStatus.Draft;
  };

  const sendReminders = () => {
    confirm({
      class: 'w-[396px]',
      title: t('Send reminders'),
      content: t('Are you sure you want to resend the document to the people who have not yet signed?'),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await onSendReminders();
        toast.success('Operation successful');
      },
    });
  };

  const handleStartSigning = () => {
    if (isChange()) {
      toast.error(t('Annotations added to the document have not been saved. Please save them by clicking the Save button.'));
      return;
    }
    sendForSigning();
  };

  const handleSave = () => {
    save();
    setIsChange(false);
  };

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <Show
        when={!isLoadingDraft() && !isSaving() && !isSendingForSigning()}
        fallback={
          <div class="flex h-[calc(100vh_-_125px)] w-full items-center justify-center">
            <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
          </div>
        }>
        <Show
          when={isDraft()}
          fallback={
            <LeaseSigningContainer onSendReminders={sendReminders}>
              <PdfDocument pages={presentablePdf()?.pdf || []} />
            </LeaseSigningContainer>
          }>
          <LeaseDraftPreviewContainer
            presentablePdf={presentablePdf()?.pdf || []}
            onPdfChange={() => setIsChange(true)}
            onSave={handleSave}
            onStartSigning={handleStartSigning}
          />
        </Show>
      </Show>
    </div>
  );
};

export default LeasePreview;
