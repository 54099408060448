import { useChartOfAccounts, useLocalization } from '~/contexts/global';
import { ChartOfAccountsListView } from './list-components/ChartOfAccountsListView';

const ListChartOfAccounts = () => {
  const { t } = useLocalization();
  const { chartOfAccounts } = useChartOfAccounts();

  return (
    <div class="flex size-full flex-col gap-5 p-8 text-sm">
      <div class="flex w-full items-center justify-between rounded-lg border border-partingline bg-white p-4">
        <div class="text-xl font-semibold">
          <span class="text-essential-colour">{chartOfAccounts()?.length} </span>
          {t('Chart of accounts')}
        </div>
      </div>
      <ChartOfAccountsListView />
    </div>
  );
};

export default ListChartOfAccounts;
