import { createSignal } from 'solid-js';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { useForm, Form, FormItem } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Radio } from '~/components/common/Inputs/Radio';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import type { Component } from 'solid-js';
import type { AccountType } from '~/pages/chart-of-accounts/list-chart-of-accounts/list-components/ChartOfAccountsTable';

export const CreateLeaseBillModal: Component = () => {
  const goBack = useGoBack();

  const form = useForm();
  const { t } = useLocalization();
  const { createLeaseBill } = useViolations();

  const [loading, setLoading] = createSignal<boolean>(false);

  const onDone = async () => {
    try {
      setLoading(true);
      const { validateStatus } = form.validateForm();
      if (!validateStatus) return;

      await createLeaseBill({
        ...(form.formStore as MagicDoor.Api.AddAssociationViolationLeaseBillDto),
      });
      goBack();
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    goBack();
  };

  return (
    <Modal title={t('Create lease bill')} visible={true} onCancel={onClose} onDone={onDone} loading={loading()}>
      <Form
        initialValues={{
          companyCharge: false,
        }}
        defaultForm={form}
        class="grid grid-cols-2 gap-x-7 gap-y-6 px-8   pb-5  pt-6">
        <FormItem
          types={'revenue' as AccountType}
          placeholder={t('Please select')}
          groupClass="col-span-full lg:col-span-1"
          rules={[{ required: true, message: t(`Please select chart of account`) }]}
          label={t('Chart of account')}
          formFieldName="chartOfAccountId"
          component={LabeledChartOfAccountSelect}
        />

        <FormItem
          rules={[{ message: t(`Please input the amount`), required: true }]}
          placeholder={t('Please enter')}
          class="col-span-full lg:col-span-1"
          label={t('Amount')}
          type="number"
          formFieldName="amount"
          component={LabeledTextInput}
        />

        <FormItem
          rules={[{ message: t(`Please input`), required: true }]}
          class="col-span-full"
          placeholder={t('Please enter')}
          label={t('Description')}
          formFieldName="description"
          component={LabeledTextInput}
        />
        <FormItem
          component={Radio}
          formFieldName="companyCharge"
          label={t('Charge Type')}
          class="col-span-full flex w-full flex-col gap-4 pr-20 sm:flex-row sm:justify-between [&>*]:w-full [&>*]:sm:w-auto"
          items={[
            { label: t('This is a property bill'), value: false },
            { label: t('This is a company bill'), value: true },
          ]}
        />
      </Form>
    </Modal>
  );
};
