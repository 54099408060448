import { useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, Show, createMemo, createSignal, onMount, on, For, untrack, createResource } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { OwnerSearch } from '~/components/owner/OwnerSearch';
import { PrintPageHeader } from '~/components/print';
import { PropertySearch } from '~/components/properties';
import { DatePicker, IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useOwners, useReports } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { compareTwoDateRange } from '~/pages/reports/utils';
import { commaNumber, currency } from '~/utils/number';
import { parseColumns, ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';
import type { OwnerStatementReportPropertyDto } from '~/swagger/Api';

const OwnerStatementReport: Component<{ class?: string; report?: MagicDoor.Api.OwnerStatementReportDto }> = (props) => {
  const { t } = useLocalization();

  function getPropertyCashSummaryLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    res.push({ label: t('Beginning cash'), level: 0, values: property.beginningCash as never });
    res.push({ label: t('Cash in'), level: 1, values: property.cashIn as never });
    res.push({ label: t('Cash out'), level: 1, values: property.cashOut as never });
    res.push({ label: t('Owner contributions'), level: 1, values: property.ownerContributions as never });
    res.push({ label: t('Owner distributions'), level: 1, values: property.ownerDistributions as never });
    res.push({ label: t('Ending cash balance'), level: 0, values: property.endingCash as never });
    res.push({ label: t('Unpaid bills'), level: 1, values: property.unpaid as never });
    res.push({ label: t('Property reserve'), level: 1, values: property.propertyReserve as never });
    res.push({ label: t('Net owner funds'), level: 0, values: property.netOwnerFunds as never });

    if (!(property.ownerContributions === 0 || property.ownerDistributions === 0 || property.companyCredits === 0)) {
      const index = res.findIndex((item) => item.label === t('Owner contributions'));
      res.splice(index + 1, 0, { label: t('Company credits'), level: 1, values: property.companyCredits as never });
    }
    return res;
  }

  function getTransactionLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    res.push({
      label: '',
      level: 0,
      values: {
        payeeOrPayerName: ' ',
        description: t('Beginning cash balance as of {date}', { date: dayjs(props.report?.dateRange?.start).format('MM/DD/YYYY') }),
        cashIn: ' ',
        cashOut: ' ',
        balance: props.report?.properties?.reduce((acc, item) => acc + (item.beginningCash || 0), 0),
      },
    });

    property?.transactions?.forEach((transaction) => {
      res.push({
        label: dayjs(transaction.paymentDate).format('MM/DD/YYYY'),
        level: 0,
        values: {
          ...transaction,
          property: property.property?.name,
        },
      });
    });

    res.push({
      label: '',
      level: 0,
      values: {
        payeeOrPayerName: ' ',
        description: t('Ending cash balance'),
        cashIn: ' ',
        cashOut: ' ',
        balance: props.report?.properties?.reduce((acc, item) => acc + (item.endingCash || 0), 0),
      },
    });

    res.push({
      label: t('Total'),
      type: 'total',
      level: 0,
      values: {
        ...property?.transactionsTotal,
        balance: '',
      },
    });

    return res;
  }

  function getDueDateLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    property?.billsDue?.forEach((bill) => {
      res.push({
        label: dayjs(bill.dueDate).format('MM/DD/YYYY'),
        level: 0,
        values: bill as unknown as Record<string, string | number | null | undefined>,
      });
    });

    res.push({
      label: t('Total'),
      type: 'total',
      level: 0,
      values: property?.billsDueTotal as unknown as Record<string, string | number | null | undefined>,
    });

    return res;
  }

  return (
    <Show
      when={props.report?.properties && props.report?.properties.length}
      fallback={
        <div class="flex w-full items-center justify-center">
          <Empty />
        </div>
      }>
      <For each={props.report?.properties}>
        {(item) => (
          <>
            <div class="mb-4 text-lg font-medium text-title-gray">{<DisplayAddress {...item.property} />}</div>
            <div class="mb-4 border-b border-[#C5C5DF] pb-1.5 text-lg font-medium text-title-gray">{t('Property cash summary')}</div>
            <ReportTable
              label={t('Cash summary')}
              lines={getPropertyCashSummaryLines(item)}
              class="mb-1"
              columns={[
                {
                  label: '',
                  type: 'number',
                  render(item) {
                    return commaNumber((item.values as never) ?? 0);
                  },
                },
              ]}
            />
            <Show when={item.netOwnerFunds && item.netOwnerFunds < 0}>
              <div class="mb-8 flex justify-between">
                <span>{t('Please remit balance due')}:</span>
                <span class="px-1.5 font-semibold">{currency(Math.abs(item.netOwnerFunds ?? 0))}</span>
              </div>
            </Show>

            <div class="mb-4 border-b border-[#C5C5DF] pb-1.5 text-lg font-medium text-title-gray">{t('Transactions')}</div>

            <ReportTable
              label={t('Date')}
              lines={getTransactionLines(item)}
              class="mb-5"
              columns={parseColumns([
                {
                  name: 'Property',
                  id: 'property',
                  type: 'string',
                },
                {
                  name: 'Payee/Payer',
                  id: 'payeeOrPayerName',
                  type: 'string',
                },
                {
                  name: 'Description',
                  id: 'description',
                  type: 'string',
                },
                {
                  name: 'Cash in',
                  id: 'cashIn',
                },
                {
                  name: 'Cash out',
                  id: 'cashOut',
                },
                {
                  name: 'Balance',
                  id: 'balance',
                },
              ])}
            />

            <div class="mb-4 border-b border-[#C5C5DF] pb-1.5 text-lg font-medium text-title-gray">{t('Bills due')}</div>
            <ReportTable
              label={t('Due date')}
              lines={getDueDateLines(item)}
              class="mb-5"
              columns={parseColumns([
                {
                  name: 'Description',
                  id: 'description',
                  type: 'string',
                },
                {
                  name: 'Amount',
                  id: 'amount',
                },
              ])}
            />
          </>
        )}
      </For>
    </Show>
  );
};

export const OwnerStatementReportPage = () => {
  const goBack = useGoBack();
  const { t } = useLocalization();
  const { companies } = useCompanies();
  const { filtered, setFilter } = useOwners();
  const { ownerStatementReport, getOwnerStatementReport, getFilterDisplay } = useReports();

  const [searchParams, setSearchParams] = useSearchParams<{
    propertyIds?: string;
    ownerId?: string;
    start: string;
    end: string;
    includeAllUnpaidBills?: string;
  }>();

  const [selectedOwner, setSelectedOwner] = createSignal<MagicDoor.Api.OwnerDto | undefined>(undefined);
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();
  const [initialLoad, setInitialLoad] = createSignal(true);

  const saveOwnerIdToLocalStorage = (ownerId: string) => {
    localStorage.setItem('ownerStatementLastSelectedOwnerId', ownerId);
  };

  const getOwnerIdFromLocalStorage = (): string | null => {
    return localStorage.getItem('ownerStatementLastSelectedOwnerId');
  };

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Owner statement') }]);

  const handlePropertyChange = (value: string[]) => {
    if (value.includes('All')) {
      setSearchParams({ propertyIds: '' }, { replace: true });
    } else {
      setSearchParams({ propertyIds: value.toString() }, { replace: true });
    }
  };

  const handleOwnerChange = (value?: MagicDoor.Api.OwnerDto) => {
    if (!value) {
      setSearchParams(
        {
          ownerId: undefined,
          propertyIds: undefined,
        },
        { replace: true }
      );
      setSelectedOwner(undefined);
    } else {
      setSearchParams({ ownerId: value.id, propertyIds: undefined }, { replace: true });
      setSelectedOwner(value);
      saveOwnerIdToLocalStorage(value.id);
    }
  };

  function shouldNotUpdate(params: any, preParams: any) {
    return (
      params?.ownerId === preParams?.ownerId &&
      params?.propertyIds === preParams?.propertyIds &&
      compareTwoDateRange([params, untrack(ownerStatementReport)?.dateRange]) &&
      params?.includeAllUnpaidBills === preParams?.includeAllUnpaidBills
    );
  }

  createEffect(
    on(
      () => ({ ...searchParams }),
      (params, preParams) => {
        if (shouldNotUpdate(params, preParams)) return;
        const { propertyIds, ownerId } = searchParams;

        if (ownerId) {
          getOwnerStatementReport({
            propertyIds: propertyIds?.split(','),
            ownerId,
            dateRange: { start: searchParams.start, end: searchParams.end },
            includeAllUnpaidBills: searchParams.includeAllUnpaidBills === 'true',
          });
        }
      }
    )
  );

  createEffect(() => {
    if (searchParams.includeAllUnpaidBills === undefined) {
      setSearchParams({ includeAllUnpaidBills: 'true' }, { replace: true });
    }
  });

  createEffect(() => {
    const report = ownerStatementReport();
    if (report?.dateRange == null) return;
    setSearchParams({ start: report.dateRange.start, end: report.dateRange.end }, { replace: true });
  });

  onMount(() => {
    setFilter({});
  });

  createEffect(() => {
    const currentOwnerId = searchParams.ownerId;
    if (currentOwnerId) {
      const currentOwner = filtered()?.items.find((item: MagicDoor.Api.OwnerDto) => item.id === currentOwnerId);
      if (currentOwner) {
        setSelectedOwner(currentOwner);
      }
    }
  });

  createEffect(
    on(filtered, () => {
      const _items = filtered()?.items;
      if (_items && _items.length > 0) {
        let ownerToSelect;

        if (initialLoad()) {
          const lastSelectedOwnerId = searchParams.ownerId || getOwnerIdFromLocalStorage();
          if (lastSelectedOwnerId) {
            ownerToSelect = _items.find((item: MagicDoor.Api.OwnerDto) => item.id === lastSelectedOwnerId);
          }
          if (!ownerToSelect) {
            ownerToSelect = _items[0];
          }
          setInitialLoad(false);
        } else {
          const currentOwnerId = searchParams.ownerId;
          if (currentOwnerId) {
            ownerToSelect = _items.find((item: MagicDoor.Api.OwnerDto) => item.id === currentOwnerId);
          }
          if (!ownerToSelect) {
            ownerToSelect = _items[0];
          }
        }

        if (!searchParams.ownerId || searchParams.ownerId !== ownerToSelect.id) {
          setSearchParams({ ownerId: ownerToSelect.id }, { replace: true });
          setSelectedOwner(ownerToSelect);
          saveOwnerIdToLocalStorage(ownerToSelect.id);
        } else {
          setSelectedOwner(ownerToSelect);
        }
      }
    })
  );

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds],
    async ([propertyIds]) => {
      return await getFilterDisplay(propertyIds, '');
    }
  );

  const printHeader = createMemo(() => {
    const owner = `${selectedOwner()?.firstName} ${selectedOwner()?.lastName ?? ''}` || 'All';
    const start = searchParams.start;
    const end = searchParams.end;

    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <p class="text-center text-xl font-semibold leading-[30px] text-title-gray">Owner Statement</p>
          <p class="text-center text-lg font-medium text-title-gray">{filterDisplay()?.propertiesStr || t('All properties')}</p>
          <p class="text-center text-lg font-medium text-title-gray">
            {t('Owner')}: {owner}
          </p>
          <p class="text-center text-[#5E6077]">{`${start} - ${end}`}</p>
        </div>
      </div>
    );
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Owner statement report')}</h1>
          <PrintButton
            color="primary"
            variant="outlined"
            disabled={ownerStatementReport.loading}
            printContainer={printContainer()!}
            printHeader={printHeader() as Element}
            isReport={true}
            printWrapClass="w-auto"
            extraStyle="width:1590px;zoom:0.6;"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <OwnerSearch
            prefix={t('Owner')}
            selected={selectedOwner()}
            onSelect={(owner) => {
              handleOwnerChange(owner.id ? owner : undefined);
            }}
          />
          <Show when={selectedOwner()}>
            <PropertySearch
              multiple
              prefix={t('Properties')}
              placeholder=""
              filter={{ ownerId: searchParams.ownerId }}
              defaultSelectedIds={searchParams.propertyIds ? searchParams.propertyIds.split(',') : []}
              onSelect={(property) => handlePropertyChange(property?.map((item) => item.id))}
            />
          </Show>
          <DatePicker
            prefix={t('Date range start')}
            value={searchParams.start}
            onChange={(value) => {
              if (value !== null && searchParams.end <= value) {
                const start = new Date(value);
                start.setDate(start.getDate() + 30);
                setSearchParams({ ...searchParams, end: start.toISOString().split('T')[0] }, { replace: true });
              } else {
                setSearchParams({ ...searchParams, start: value }, { replace: true });
              }
            }}
          />
          <DatePicker
            prefix={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              if (value !== null && searchParams.start >= value) {
                const end = new Date(value);
                end.setDate(end.getDate() - 30);
                const newStart = end.toISOString().split('T')[0];
                setSearchParams({ ...searchParams, start: newStart, end: value }, { replace: true });
              } else {
                setSearchParams({ ...searchParams, end: value }, { replace: true });
              }
            }}
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include all unpaid bills')}</span>}
            checked={searchParams.includeAllUnpaidBills === 'true'}
            onChange={(e) => {
              setSearchParams(
                {
                  ...searchParams,
                  includeAllUnpaidBills: e.target.checked ? 'true' : 'false',
                },
                { replace: true }
              );
            }}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={ownerStatementReport.loading} fallback={<OwnerStatementReport report={ownerStatementReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </div>
  );
};
