import { onMount, createSignal, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconBalanceDue from '~/assets/images/settings/property/balanceDue.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useTenantBalanceDueNotification } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const TenantBalanceDueNotification = () => {
  const { t } = useLocalization();

  const {
    isLoading,
    getTenantBalanceDueNotificationSettings,
    tenantBalanceDueNotificationSettings,
    updateTenantBalanceDueNotificationSettings,
    updateTenantBalanceDueNotificationSettingsByLevel,
    deleteTenantBalanceDueNotificationSettingsByLevel,
  } = useTenantBalanceDueNotification();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  const config = [
    {
      field: 'notifyOnBalanceDue',
      label: 'Send notification to tenant when they have an outstanding balance',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendBalanceDueNotificationsToEvictionLeases',
      label: 'Send balance notification to leases that is under eviction',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendBalanceDueNotificationsToEndedLeases',
      label: 'Send balance notification to leases that has ended',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendBalanceDueNotificationsAsText',
      label: 'Send balance due notification as text message',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'sendBalanceDueNotificationsAsEmail',
      label: 'Send balance due notification as email',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'notifyBalanceDueEveryXDays',
      label: 'Send notification every x days',
      type: 'number',
      rules: [
        {
          validator: (value: number) => value <= 100 && value >= 0,
          message: t('The value of notifyBalanceDueEveryXDays is between 0 and 1000'),
        },
      ],
    },
  ];

  onMount(() => {
    getTenantBalanceDueNotificationSettings();
  });

  const handleChangeData = async (data: any, level?: string, levelId?: string) => {
    if (level && levelId) {
      updateTenantBalanceDueNotificationSettingsByLevel(level, levelId, {
        ...data,
      } as MagicDoor.Api.TenantBalanceDueNotificationSettingsDto);
    } else {
      updateTenantBalanceDueNotificationSettings({
        ...data,
      } as MagicDoor.Api.TenantBalanceDueNotificationSettingsDto);
    }

    toast(t('Balance due notifications has been updated successfully'), 'success');
    setTimeout(() => {
      getTenantBalanceDueNotificationSettings();
    }, 1000);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onDone = async (level: string, levelId: string) => {
    await updateTenantBalanceDueNotificationSettingsByLevel(level, levelId, {
      ...(tenantBalanceDueNotificationSettings()?.settings as MagicDoor.Api.TenantBalanceDueNotificationSettingsDto),
    });
    setShowModal(false);
    toast(t('Balance due notifications has been created successfully'), 'success');
    setTimeout(() => {
      getTenantBalanceDueNotificationSettings();
    }, 1000);
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteTenantBalanceDueNotificationSettingsByLevel(level, levelId);
        toast(t('Balance due notifications has been deleted successfully'), 'success');
        getTenantBalanceDueNotificationSettings();
      },
    });
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Balance due notifications')} icon={IconBalanceDue} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm<MagicDoor.Api.TenantBalanceDueNotificationSettingsDto>
                sections={config}
                onOk={(data) => handleChangeData(data)}
                model={tenantBalanceDueNotificationSettings().settings || ({} as MagicDoor.Api.TenantBalanceDueNotificationSettingsDto)}
              />
            </div>

            <Overrides<MagicDoor.Api.TenantBalanceDueNotificationSettingsDtoSettingsWithOverrideDto>
              overrides={tenantBalanceDueNotificationSettings()?.overrides || []}
              onDelete={onDelete}
              title={t('Auto payment failure notification')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm<MagicDoor.Api.TenantBalanceDueNotificationSettingsDto>
                      sections={config}
                      onOk={(data) => handleChangeData(data, override.level, override.levelId)}
                      model={override.settings as MagicDoor.Api.TenantBalanceDueNotificationSettingsDto}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={tenantBalanceDueNotificationSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={isLoading()}
        />
      </Show>
    </>
  );
};
