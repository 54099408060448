import { useNavigate } from '@solidjs/router';
import { Show, createSignal } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import { Radio } from '~/components/common/Inputs/Radio';
import { ListView, toast, confirm } from '~/components/ui';
import { useAuth, useLocalization } from '~/contexts/global';
import { usePropertyManagers } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { ChangeManagerPasswordModal } from '~/pages/property-managers/forms/ChangeManagerPassword';
import { getImageUrl } from '~/utils/file';

export const ListPropertyManagers = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('PropertyManagersListView');

  const [showModal, setShowModal] = createSignal<boolean>(false);
  const [selectedManager, setSelectedManager] = createSignal<string>('');
  const { me } = useAuth();

  const {
    setCurrentManager,
    propertyManagers,
    deletePropertyManager,
    refetch,
    changePropertyManagerActiveStatus,
    changePropertyManagerPassword,
  } = usePropertyManagers();

  const statusTypes = [
    {
      label: t('Active'),
      value: true,
    },
    {
      label: t('Inactive'),
      value: false,
    },
  ];

  const onDelete = async (item: MagicDoor.Api.PropertyManagerDto) => {
    confirm({
      content: <p class="mt-4 px-6">{t('Confirm to delete the manager')} ?</p>,
      title: t(`Delete property manager`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        const isCurrentUser = me()?.email === item.email;
        const isLastActiveManager = propertyManagers()?.filter((manager) => manager.active).length === 1;
        if (isCurrentUser) {
          toast.error(t('You cannot delete yourself'));
          return;
        }
        if (isLastActiveManager) {
          toast.error(t('You cannot delete the last active property manager'));
          return;
        }

        try {
          await deletePropertyManager(item.id);
        } catch (error) {
          console.error(error);
        }
        refetch();
        toast(t('Property manager deleted successfully'), 'success');
      },
    });
  };

  const handleChangePassword = async (id: string, currentPassword: string, newPassword: string) => {
    await changePropertyManagerPassword(id, currentPassword, newPassword);
    toast.success(t('Password changed successfully'));
  };

  const handleStatus = async (id: string, status: boolean) => {
    await changePropertyManagerActiveStatus(id, status);
    refetch();
    toast.success(t('Operation successful'));
  };

  const isHideDelete = (item: MagicDoor.Api.PropertyManagerDto) => {
    const isCurrentUser = me()?.email === item.email;
    const isLastActiveManager = propertyManagers()?.filter((manager) => manager.active).length === 1;
    return isCurrentUser || isLastActiveManager;
  };

  return (
    <div class="size-full space-y-5 p-8">
      <ListView
        skeletonSize={10}
        title={t('Property managers overview')}
        onRowClick={(item) => {
          setCurrentManager(item);
          navigate(`/users/property-managers/${item.id}/edit`);
        }}
        titleActions={
          <LinkButton class="ml-auto text-sm capitalize" href="/users/property-managers/add" noScroll>
            <IconPlus class="text-white" />
            {t('Add property manager')}
          </LinkButton>
        }
        page={page()}
        pageSize={pageSize()}
        data={propertyManagers()}
        loading={propertyManagers.loading || changePropertyManagerActiveStatus.loading || changePropertyManagerPassword.loading}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        columns={[
          {
            title: t('Manager'),
            render: (item) => (
              <div class="flex items-center justify-start gap-2">
                <Avatar class="size-9 shrink-0" imgUrl={getImageUrl('avatar', item.id, item.imageId)} name={item?.name ?? ''} />
                <div class="flex flex-col text-sm text-text-level01">{item.name}</div>
              </div>
            ),
          },
          {
            title: t('Email'),
            render: (item) => <div>{item.email}</div>,
          },
          {
            title: t('Active'),
            render: (item) => (
              <Show when={item.active} fallback={<div class=" text-light-gray-level01">{t('Inactive')}</div>}>
                <div class="text-green  ">{t('Active')}</div>
              </Show>
            ),
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit'),
            icon: IconPencil,
            onClick: () => {
              setCurrentManager(item);
            },
            href: `/users/property-managers/${item.id}/edit`,
            noScroll: true,
          },
          {
            label: t('Change password'),
            icon: IconPencil,
            onClick: () => {
              setSelectedManager(item.id);
              setShowModal(true);
            },
          },
          {
            label: t('Change active status'),
            icon: IconPencil,
            children: (
              <Radio
                direction="column"
                class="w-28"
                items={statusTypes}
                value={item?.active}
                onInput={(value) => handleStatus(item.id, value as boolean)}
              />
            ),
          },
          {
            label: t('Delete'),
            icon: IconDelete,
            onClick: () => onDelete(item),
            hideSelection: isHideDelete(item),
          },
        ]}
      />
      <Show when={showModal()}>
        <ChangeManagerPasswordModal
          loading={changePropertyManagerPassword.loading}
          onClose={() => setShowModal(false)}
          onDone={handleChangePassword}
          id={selectedManager()}
        />
      </Show>
    </div>
  );
};
