import { Show, createMemo } from 'solid-js';
import type { Component } from 'solid-js';

interface CheckboxComponentProps {
  value?: string;
  checked?: boolean;
  onClick?: (isMouseDown: boolean) => void;
  label: string;
  error?: string | undefined;
}

const CheckboxComponent: Component<CheckboxComponentProps> = (props) => {
  const isInvalid = createMemo((): boolean => !!props.error);

  return (
    <div class="relative">
      <label class="flex h-[38px] w-fit cursor-pointer items-center rounded-md border px-5 text-xs  hover:border-essential-colour">
        <input
          class="hidden"
          type="radio"
          value={props.value}
          checked={props.checked}
          onClick={(event) => {
            if (props.onClick) {
              const input = event.target as HTMLInputElement;
              props.onClick(input.checked);
            }
          }}
        />
        <div class="flex items-center gap-2">
          <div class="flex items-center justify-center rounded-full border border-gray-300 bg-inputbox-bg">
            <div
              classList={{
                'bg-essential-colour': props.checked,
                'rounded-full': props.checked,
                'text-essential-colour': props.checked,
                'border-essential-colour': props.checked,
              }}
              class="size-4"
            />
          </div>
          <span>{props.label}</span>
        </div>
      </label>
      <Show when={isInvalid()}>
        <span class="absolute mt-2 text-xs text-red-500">{props.error}</span>
      </Show>
    </div>
  );
};

export default CheckboxComponent;
