import { createMemo } from 'solid-js';
import IconBalanceBg from '~/assets/images/units/balancesBg.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type UnitBalancesProps = {
  class?: string;
  balance?: number;
};

export const UnitBalances: Component<UnitBalancesProps> = (props) => {
  const { t } = useLocalization();

  const isDangerColor = createMemo(() => !!props.balance && props.balance > 0);
  const isSuccessColor = createMemo(() => !!props.balance && props.balance < 0);
  const isInfoColor = createMemo(() => !props.balance || props.balance == 0);

  return (
    <>
      <div class="col-span-full flex rounded-lg bg-white md:hidden">
        <div class="w-full p-3">
          <div
            class="flex h-full items-center justify-between rounded-lg px-[14px] py-4"
            classList={{
              'bg-danger-light': isDangerColor(),
              'bg-success-light': isSuccessColor(),
              'bg-[#F3F2F9]': isInfoColor(),
            }}>
            <span class="text-sm font-semibold">{t('Balances')}</span>
            <span
              class="text-base font-bold"
              classList={{
                'text-red-500': isDangerColor(),
                'text-[#1FC09F]': isSuccessColor(),
                'text-[#8D9BBD]': isInfoColor(),
              }}>
              {currency(props.balance)}
            </span>
          </div>
        </div>
      </div>

      <Panel title={t('Balances')} class={cn('hidden flex-col md:flex ', props.class)}>
        <div class="flex-1 px-6 pb-4 pt-3">
          <div
            class="relative flex h-full items-center justify-center rounded-lg"
            classList={{
              'bg-danger-light': isDangerColor(),
              'bg-success-light': isSuccessColor(),
              'bg-[#F3F2F9]': isInfoColor(),
            }}>
            <span
              class="text-xl font-bold"
              classList={{
                'text-red-500': isDangerColor(),
                'text-[#1FC09F]': isSuccessColor(),
                'text-[#8D9BBD]': isInfoColor(),
              }}>
              {currency(props.balance)}
            </span>
            <IconBalanceBg
              class="absolute right-0 top-0"
              classList={{
                'text-success': isSuccessColor(),
                'text-danger': isDangerColor(),
              }}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};
