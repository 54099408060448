import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import { useMenu } from '~/components/layouts/Menu/MenuContext';
import { cn } from '~/utils/classnames';
import type { MainMenuItemProps } from '~/components/layouts/Menu/types/menuItem';
export const MainMenuButton = (props: MainMenuItemProps) => {
  const menu = useMenu();

  const MenuContent = () => (
    <>
      <img
        src={props.item.icon}
        class={cn('size-5', {
          hidden: menu?.isSelected(props.item),
        })}
        alt={props.item.text + ' Icon'}
      />
      <img
        src={props.item.selectedIcon}
        class={cn('size-5', {
          hidden: !menu?.isSelected(props.item),
        })}
        alt={props.item.text + ' Icon'}
      />
      <Show when={!menu?.isManuallyCollapsed() && !menu?.isCollapsed()}>{props.item.text}</Show>
    </>
  );

  const commonClasses = 'flex w-full items-center gap-2 text-left';

  return (
    <div class="flex items-center">
      <Show
        when={props.item.canNavigate}
        fallback={
          <div
            class={commonClasses}
            classList={{
              'font-medium': menu?.isSelected(props.item),
            }}>
            <MenuContent />
          </div>
        }>
        <A id={menu?.getMenuItemId(props.item, true)} href={props.item.replace || props.item.realPath || props.item.path}>
          <button
            class={commonClasses}
            classList={{
              'font-medium': menu?.isSelected(props.item),
            }}>
            <MenuContent />
          </button>
        </A>
      </Show>
    </div>
  );
};
