import { Show, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import { getOwnerName } from '~/components/owner/utils';
import { ListView, IconSquarePen } from '~/components/ui';
import { useLocalization, useOwners } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { emptyPlaceholder } from '~/utils/constant';

export const ListOwner = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('OwnerListView');
  const { filtered, setFilter } = useOwners();

  onMount(() => {
    setFilter({ page: page(), pageSize: pageSize() });
  });

  return (
    <div class="size-full space-y-5 p-8">
      <ListView
        skeletonSize={10}
        title={t('Owners overview')}
        titleActions={
          <LinkButton class="ml-auto text-sm capitalize" href="/users/owners/add" noScroll>
            <IconPlus class="text-white" />
            {t('Add owner')}
          </LinkButton>
        }
        page={filtered()?.currentPage}
        totalPages={filtered()?.totalPages}
        pageSize={filtered()?.pageSize}
        searchPlaceholder={t('Search owners')}
        data={filtered()?.items}
        loading={filtered.loading}
        rowLink={(item) => `/users/owners/${item.id}`}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search });
        }}
        columns={[
          {
            title: t('Name'),
            render: (item) => (
              <div class="flex items-center justify-start gap-2">
                <Avatar class="size-9 shrink-0" name={getOwnerName(item)} />
                <div class="flex flex-col text-sm text-text-level01">{getOwnerName(item)}</div>
              </div>
            ),
          },
          {
            title: t('Phone'),
            render: (item) => <div>{item.phone || emptyPlaceholder}</div>,
          },
          {
            title: t('Email'),
            render: (item) => <div>{item.email || emptyPlaceholder}</div>,
          },
          {
            title: t('Active'),
            render: (item) => (
              <Show when={item.active} fallback={<div class=" text-light-gray-level01">{t('Inactive')}</div>}>
                <div class="text-green">{t('Active')}</div>
              </Show>
            ),
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit owner'),
            icon: IconSquarePen,
            href: `/users/owners/${item.id}/edit`,
          },
        ]}
      />
    </div>
  );
};
