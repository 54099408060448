import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { LeaseDeposits } from '~/components/leases/LeaseDeposits';
import { getLeaseDateName, getUnitFullname } from '~/components/leases/utils';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLease, useLeaseDeposits } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { IconAddDeposit, IconReleaseDeposit, IconTower } from '~/pages/leases/lease-details/components/Icons';
import { QuickActionPanel } from '~/pages/leases/lease-details/components/QuickActionPanel';
import { LeaseDepositTransactionTable } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionTable';
import { LeaseTransactionUpdateModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseTransactionUpdateModal';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { QuickActionItem } from '~/pages/leases/lease-details/components/QuickActionPanel';

interface LeaseDepositsTableProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
}

export const LeaseDepositsTable = (props: LeaseDepositsTableProps) => {
  const { t } = useLocalization();
  const { updateSignal } = useUpdates();

  const navigate = useNavigate();
  const params = useParams();
  const { lease } = useLease();
  const { unit } = useUnits();
  const { leaseDeposit, deposit, depositActions } = useLeaseDeposits();
  const [status, setStatus] = createSignal<string>('');
  const [showModal, setShowModal] = createSignal<string>('');
  const [depositData, setDepositData] = createSignal<MagicDoor.Api.HydratedLeaseTransactionDto | undefined>(undefined);

  const handleDetailClick = (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    navigate(`${trans.id}`);
  };

  const handleUpdateClick = (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    setDepositData(transaction);
    setShowModal('update');
  };

  let leaseDepositRef: any;

  const getTotal = () => {
    const data: MagicDoor.Api.HydratedLeaseTransactionDto[] = leaseDepositRef.getData();
    const total = currency(data.reduce((previous, current) => previous + current.amount, 0));
    return total;
  };

  const onMarkAsReturnedClick = (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    navigate(`${trans.id}/returned`);
  };

  const handleLink = (code: string) => {
    navigate(`/leasing/leases/${params.leaseId}/deposits/${code}`);
  };

  const quickActions = createMemo<QuickActionItem[]>(() => {
    return [
      {
        title: t('Receive payment'),
        icon: IconTower,
        code: 'receive-payment',
        bgColor: '#DEF6E5',
      },
      {
        title: t('Release deposit'),
        icon: IconReleaseDeposit,
        code: 'release-deposit',
        bgColor: '#FAE8FF',
      },
      {
        title: t('Add deposit'),
        icon: IconAddDeposit,
        code: 'add-deposit',
        bgColor: '#E8EFFF',
      },
    ];
  });

  const PanelActions = () => (
    <>
      <PrintTablePageButton
        variant="outlined"
        table={{
          columns: leaseDepositRef.getColumns(),
          data: leaseDepositRef.getData(),
        }}
        caption={{
          title: 'Deposits',
          extraData: [
            {
              label: 'Lease',
              value: getLeaseDateName(lease()!),
            },
            {
              label: 'Unit',
              value: getUnitFullname(unit()),
            },
            {
              label: 'Total',
              value: getTotal(),
            },
            {
              label: 'Date',
              value: dateFormat(),
            },
          ],
        }}
      />
    </>
  );

  createEffect(() => {
    updateSignal();
    depositActions.refetch();
  });

  return (
    <>
      <div class="mb-5 grid grid-cols-3 gap-5 text-left">
        <QuickActionPanel data={quickActions()} onActionClick={handleLink} class="col-span-full lg:col-span-2" />
        <LeaseDeposits lease={props.lease} class="text-xl font-bold">
          {(infoLabel, haveDue) => (
            <Panel title={t('Deposits')}>
              <div class="h-[calc(100%-60px)] px-6 py-3">
                <div
                  class={cn('flex h-full items-center justify-center rounded-lg bg-[#E8F2FC]', {
                    'bg-danger-light': haveDue,
                  })}>
                  {infoLabel}
                </div>
              </div>
            </Panel>
          )}
        </LeaseDeposits>
      </div>
      <LeaseDepositTransactionTable
        ref={leaseDepositRef}
        data={leaseDeposit()}
        actions={depositActions.refetch}
        loading={deposit.loading}
        status={status()}
        setStatus={setStatus}
        onDetailClick={handleDetailClick}
        onUpdateClick={handleUpdateClick}
        onDeleteClick={(trans) => {
          setDepositData(trans);
          setShowModal('deleteTransaction');
        }}
        onMarkAsReturnedClick={onMarkAsReturnedClick}
        PanelActions={<PanelActions />}
        showModal={showModal()}
        setShowModal={setShowModal}
        transactionData={depositData()}
        tableTitle={t('Deposits')}
        updateModal={
          <LeaseTransactionUpdateModal
            lease={props.lease}
            visible={showModal() === 'update'}
            transactionDetail={depositData()}
            onCancel={() => setShowModal('')}
            onSuccess={() => depositActions.refetch()}
          />
        }
      />
    </>
  );
};
