import { Show } from 'solid-js';
import bankCardBlueBg from '~/assets/images/bank-account/bankCardBlueBg.png';
import bankCardGreenBg from '~/assets/images/bank-account/bankCardGreenBg.png';
import bankCardYellowBg from '~/assets/images/bank-account/bankCardYellowBg.png';
import plaidLogo from '~/assets/third-parties/plaid.svg';
import stripeLogo from '~/assets/third-parties/stripe.svg';
import { getMaskString } from '~/components/common/BankAccountMask';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { PaymentAccountProvider } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { createMutation } from '~/utils/resource';

const bankCardBg = [bankCardBlueBg, bankCardGreenBg, bankCardYellowBg];

const accountType: Record<PaymentAccountProvider, string> = {
  [PaymentAccountProvider.Stripe]: stripeLogo,
  [PaymentAccountProvider.Plaid]: plaidLogo,
  [PaymentAccountProvider.None]: '',
};

const getBankCardIndex = (account: MagicDoor.Api.PaymentAccountDto): number => {
  const maskSum = (account.accountMask || '4242')
    .split('')
    .map(Number)
    .filter((num) => !isNaN(num))
    .reduce((acc, curr) => acc + curr, 0);

  if (account.stripe?.expMonth && account.stripe?.expYear) {
    const combined = maskSum + Number(account.stripe.expMonth) + Number(account.stripe.expYear);
    return combined % 3;
  }

  return maskSum % 3;
};

export const BankAccountCard = (props: { account: MagicDoor.Api.PaymentAccountDto; onRemove?: () => Promise<void> }) => {
  const { t } = useLocalization();
  const handleRemove = createMutation(() => (props.onRemove ? props.onRemove() : Promise.resolve()));
  return (
    <div class="flex flex-col items-start gap-2">
      <div
        class="flex h-[132px] max-h-full w-[210px] max-w-full flex-col justify-between bg-cover px-[22px] py-[14px] xxs:h-[157px] xxs:w-[250px]"
        style={{ 'background-image': `url(${bankCardBg[getBankCardIndex(props.account)]})` }}>
        <img src={accountType[props.account.accountProvider]} alt={props.account.accountProvider} class="size-10" />

        <div class="flex flex-col">
          <div class="text-sm font-medium text-white">{getMaskString(props.account.accountMask)}</div>

          <Show when={props.account.accountProvider === PaymentAccountProvider.Stripe}>
            <div class="flex items-center gap-1 text-xs text-white opacity-80">
              <span>{t('Expires')}</span>
              <span>
                {props.account.stripe?.expMonth}/{props.account.stripe?.expYear}
              </span>
            </div>
          </Show>

          <Show when={props.account.accountProvider === PaymentAccountProvider.Plaid}>
            <div class="flex items-center gap-1 text-xs text-white opacity-80">
              <span>{props.account.plaid?.plaidAccountName}</span>
              <span>{props.account.plaid?.plaidAccountType}</span>
            </div>
          </Show>
        </div>
      </div>
      <Button rounded="full" variant="outlined" class={cn('px-3 py-1 text-xs')} onClick={handleRemove} loading={handleRemove.loading}>
        {t('Remove')}
      </Button>
    </div>
  );
};
