import { useNavigate, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { Show, createEffect, createMemo, createSignal, onMount, untrack } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplication, useTenants, useUnits } from '~/contexts/global';
import { ProrateChargeProvider, useLease } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { BasicProfile } from './BasicProfile';
import { Deposits } from './Deposits';
import { Rents } from './Rents';
import { Review } from './Review';
import type { CreateLeaseModel } from './types';

export const NewLease = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { unit, getUnit, clearUnit } = useUnits();
  const { addLease } = useLease();
  const { tenantConvert } = useRentalApplication();
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [newLease, setNewLease] = createSignal<MagicDoor.Api.LeaseDto>();
  const [query] = useSearchParams();
  const { current: currentTenant, setCurrentId } = useTenants();

  const steps = createMemo(() => [t('Basic profile'), t('Rents'), t('Deposits')]);
  const [stepBtnIsClick, setStepBtnIsClick] = createSignal<boolean[]>([false, false, false, false]);

  const [model, setModel] = createStore<CreateLeaseModel>({
    isFixedTerm: true,
    rent: {},
    draft: true,
    monthToMonth: true,
  } as CreateLeaseModel);

  const [initTenant, setInitTenant] = createSignal<MagicDoor.Api.HydratedTenantDto>();

  createEffect(() => {
    setModel((prev) => ({
      ...prev,
      portfolioId: query.portfolioId,
      propertyId: query.propertyId,
      unitId: query.unitId,
      start: query.startDate || dayjs().endOf('month').add(1, 'day').startOf('month').format('YYYY-MM-DD'),
    }));
  });

  const updateStepBtnClick = (step: number) => {
    setStepBtnIsClick((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[step] = true;
      return newStatus;
    });
  };

  const disableNext = () => {
    const _model = untrack(() => model);
    if (currentStep() === 0) {
      const { propertyId, unitId, tenantIds, start, end, isFixedTerm } = _model;
      if ((isFixedTerm && !end) || (isFixedTerm && start && end && dayjs(end).isBefore(dayjs(start)))) return true;
      return !propertyId || !unitId || !tenantIds || tenantIds.length === 0 || start === undefined;
    } else if (currentStep() === 2) {
      const { deposit } = _model;
      return deposit !== undefined && !deposit;
    } else if (currentStep() === 1) {
      const { rent, otherCharges } = _model;
      if (!rent?.amount || !rent?.chartOfAccountId || !rent?.description || !rent?.chargeFrequency || !rent.startFrom) return true;
      if (otherCharges) {
        for (let i = 0; i < otherCharges.length; ++i) {
          if (!otherCharges[i].amount || !otherCharges[i].description || !otherCharges[i].chartOfAccountId) return true;
        }
      }
    }
    return false;
  };

  const updateModel = (updates: Partial<CreateLeaseModel>) => {
    setModel(
      produce((state) => {
        for (const key in updates) {
          (state as any)[key] = updates[key as keyof CreateLeaseModel];
        }
      })
    );
    setStepBtnIsClick(() => [false, false, false, false]);
  };

  const REVIEW_STEP = 3;
  const handleSubmit = async () => {
    const lease = await addLease({
      ...model,
      end: model.isFixedTerm ? model.end : null,
    });
    setNewLease(lease);
    navigate(`/leasing/leases/${lease.id}`, { replace: true });
    toast.success(t('{name} has been added successfully', { name: t('Lease') }));
  };

  createEffect(() => {
    if (currentTenant() && currentTenant()?.id === query.tenantId) {
      setInitTenant(currentTenant());
      setModel('tenantIds', (prev) => {
        return [...(prev || []), currentTenant()?.id as string];
      });
    }
  });

  onMount(async () => {
    if (typeof query.unitId === 'string' && /^\d+$/.test(query.unitId)) {
      getUnit(query.unitId);
    } else {
      clearUnit();
    }
    if (query.rentalApplicationId && query.convertTenant) {
      const data = await tenantConvert(query.rentalApplicationId);
      setModel('tenantIds', (prev) => {
        return [...(prev || []), data.id];
      });
      setInitTenant(data);
    }
    if (query.tenantId) {
      setCurrentId(query.tenantId);
    }
  });

  return (
    <ProrateChargeProvider model={model} setModel={setModel}>
      <Show when={currentStep() < REVIEW_STEP} fallback={<Review leaseId={newLease()?.id} />}>
        <MultiSteps
          stepper={
            <div class="flex items-center justify-center pb-10">
              <Stepper class="lg:p-9" steps={steps()} step={currentStep()} />
            </div>
          }
          currentStep={currentStep()}
          contentClass="[&>div]:mx-auto [&>div]:mb-10 [&>div]:max-w-3xl"
          submitText={t('Finish')}
          onStepChange={(step, isBack) => {
            if (!isBack) {
              if (!disableNext()) {
                setCurrentStep(step);
              } else {
                updateStepBtnClick(currentStep());
              }
              return disableNext();
            } else {
              setCurrentStep(step);
            }
          }}
          onClose={() => goBack()}
          onSubmit={handleSubmit}
          disableNext={stepBtnIsClick()[currentStep()] ? disableNext() : false}
          showPrevious>
          <BasicProfile model={model} initUnit={unit()} initTenant={initTenant()} isSubmit={stepBtnIsClick()[0]} onUpdate={updateModel} />
          <Rents isSubmit={stepBtnIsClick()[1]} model={model} onUpdate={updateModel} setModel={setModel} />
          <Deposits isSubmit={stepBtnIsClick()[2]} model={model} onUpdate={updateModel} />
        </MultiSteps>
      </Show>
    </ProrateChargeProvider>
  );
};
