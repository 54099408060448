import { Link, useParams, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { Modal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useCompanyInvoices, useCompanyPaymentMethods, useLocalization, useCompanySettings } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { createMutation } from '~/utils/resource';

export const PayInvoiceModal = (props: { onPaymentSuccessed?: () => void }) => {
  const goBack = useGoBack();
  const { t } = useLocalization();
  const { handlePayInvoice } = useCompanyInvoices();
  const { validatedPaymentAccountsOptions } = useCompanyPaymentMethods();
  const { companySettings } = useCompanySettings();
  const form = useForm();
  const { invoiceId } = useParams();
  const [searchParams] = useSearchParams();

  const _invoiceId = createMemo(() => invoiceId || searchParams.invoiceId);

  const handlePayBill = createMutation(async (invoiceId: string) => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    await handlePayInvoice(invoiceId, form.formStore.paymentAccountId);
    props.onPaymentSuccessed?.();
    toast.success(t('Payment successful'));
    goBack();
  });

  createEffect(() => {
    const _companySettings = companySettings();
    if (_companySettings) {
      form.setFieldValue('paymentAccountId', _companySettings.invoicePaymentAccountId);
    }
  });

  return (
    <Modal
      title="Pay invoice"
      visible={true}
      onCancel={() => goBack()}
      onDone={() => handlePayBill(_invoiceId())}
      loading={handlePayBill.loading}>
      <Form class="p-4" defaultForm={form}>
        <FormItem
          label={t('Invoice payment account')}
          formFieldName="paymentAccountId"
          component={LabeledSelect}
          rules={[{ required: true, message: t('Please select payment account') }]}
          options={validatedPaymentAccountsOptions()}
          placeholder={t('Please select payment account')}
        />
      </Form>

      <div class="mb-4 text-center text-sm text-gray-500">
        {t(`You can add new payment account in the `)}
        <Link class="text-primary underline" href="/settings/company/payment-accounts">
          {t(`payment accounts`)}
        </Link>
      </div>
    </Modal>
  );
};
