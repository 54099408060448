import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import IconActicateLease from '~/assets/images/units/acticateLease.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { LeaseDeposits } from '~/components/leases/LeaseDeposits';
import { formatTenantNames } from '~/components/tenants/utils';
import { Table, TableActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeasesList } from '~/contexts/local';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type DraftLeasesProps = {
  class?: string;
  draftLeases: MagicDoor.Api.HydratedLeaseDto[];
  isLoading: boolean;
};

export const DraftLeases: Component<DraftLeasesProps> = (props) => {
  const { t } = useLocalization();

  const { setLease, activateDraft } = useLease();
  const { refetchLeases } = useLeasesList();
  const navigate = useNavigate();

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Units'),
      render: (item) => (
        <>
          <div class="text-sm font-medium text-text-level01">{item.unit.name}</div>
          <div class="text-text-level03">
            <DisplayAddress address={item.property.address} />
          </div>
        </>
      ),
    },
    {
      title: t('Lease term'),
      render: (item) => (
        <div>
          <LeaseStatus lease={item} />
          <LeasePeriod lease={item} />
        </div>
      ),
    },
    {
      title: t('Tenants'),
      render: (item) => <div class="flex items-center">{formatTenantNames(item.tenants)}</div>,
    },
    {
      title: t('Rent'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <div class="text-sm text-text-level01">{currency(item.currentRent)}</div>,
    },
    {
      title: t('Deposit'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <LeaseDeposits lease={item} class="justify-end text-sm" />,
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <TableActions
          actions={[
            {
              icon: IconActicateLease,
              label: t('Activate lease'),
              onClick: async () => {
                if (item?.id == null) return;
                await activateDraft(item.id);
                toast.success(t('Operation successful'));
                refetchLeases();
              },
            },
            {
              icon: IconDelete,
              label: t('Delete lease'),
              onClick: () => {
                setLease(item);
                navigate('delete-lease', { scroll: false });
              },
            },
          ]}
        />
      ),
    },
  ]);

  return (
    <Panel title={t('Draft leases')} class={props.class}>
      <div class="thinscroll overflow-x-auto">
        <Table
          columns={columns()}
          loading={props.isLoading}
          data={props.draftLeases}
          rowLink={(item) => `/leasing/leases/${item.id}`}
          empty={<Empty description={t('There are no draft leases under this unit')} />}
        />
      </div>
    </Panel>
  );
};
