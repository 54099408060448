import IconGID from '~/assets/images/rental-application/gid-trigger.svg?component-solid';
import IconPayStub from '~/assets/images/rental-application/paystub-trigger.svg?component-solid';
import { FormItem } from '~/components/common/BetterForm';
import { LabeledSingleFilePreUploader } from '~/components/common/Inputs/LabeledSingleFilePreUploader';
import { useLocalization } from '~/contexts/global';
import { FileType } from '~/swagger/Api';
import type { Component } from 'solid-js';
import type { UploadedFile } from '~/components/common/Inputs/LabeledSingleFilePreUploader';
import type { RentalApplicationFileDto, UpdateRentalApplicationFileDto } from '~/swagger/Api';

export const RentalApplicationDocuments: Component<{
  onInput?: (files: UpdateRentalApplicationFileDto[]) => void;
  value?: (RentalApplicationFileDto | UpdateRentalApplicationFileDto)[] | null;
  beforeUpload?: (file: File) => void;
  afterUpload?: (uploadResult: UploadedFile) => void;
  id?: string;
}> = (props) => {
  const { t } = useLocalization();

  function getInitialFile(type: FileType) {
    return props.value?.find((f) => f.type === type);
  }

  async function onAfterUpload(file: UploadedFile, type: FileType) {
    if (file.fileId) {
      props.onInput?.(
        Array.from(
          new Map(
            (props.value ?? [])
              ?.concat({
                fileId: file.fileId!,
                type,
                fileName: file.name,
              })
              .map((f) => [f.type, f])
          ).values()
        )
      );
    }
    props.afterUpload?.(file);
  }

  function onClean(type: FileType) {
    props.onInput?.(props.value?.filter((f) => f.type !== type) ?? []);
  }

  return (
    <div class="grid grid-cols-2 gap-5 ">
      <FormItem
        id={`${props.id}-${FileType.Identification}`}
        label={t('Government ID')}
        component={LabeledSingleFilePreUploader}
        trigger={
          <>
            <IconGID />
            <span>{t(`Drag & drop or click to add government ID`)}</span>
          </>
        }
        type={FileType.Identification}
        initialFile={getInitialFile(FileType.Identification) as any}
        accept="image/*,application/pdf"
        groupClass="h-full flex-1"
        onAfterUpload={(file) => onAfterUpload(file, FileType.Identification)}
        onClean={() => onClean(FileType.Identification)}
        onBeforeUpload={(file) => props.beforeUpload?.(file)}
      />
      <FormItem
        id={`${props.id}-${FileType.EmploymentVerification}`}
        label={t('Pay Stub')}
        component={LabeledSingleFilePreUploader}
        trigger={
          <>
            <IconPayStub />
            <span>{t(`Drag & drop or click to add pay stub`)}</span>
          </>
        }
        type={FileType.EmploymentVerification}
        initialFile={getInitialFile(FileType.EmploymentVerification) as any}
        accept="image/*,application/pdf"
        groupClass="h-full flex-1"
        onAfterUpload={(file) => onAfterUpload(file, FileType.EmploymentVerification)}
        onClean={() => onClean(FileType.EmploymentVerification)}
      />
    </div>
  );
};
