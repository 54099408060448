import dayjs from 'dayjs';
import { useLocalization } from '~/contexts/global';

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const emailRegex =
  /^[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const validatePhone = (input: unknown): input is string => typeof input === 'string' && phoneRegex.test(input);

export const validateEmail = (input: unknown): input is string => typeof input === 'string' && emailRegex.test(input);

export const arrayRequiredValidator = (value: any, name: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  const newValue = value ? value : [];

  if (newValue.length === 0 && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  return undefined;
};

export const verifyRequiredValidator = (value: string | undefined | null, name: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);

  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  return undefined;
};

export const requiredValidator = (value: string | undefined | null, name: string, length: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);

  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length > length) {
    return t('{name} must be {length} characters or less', { name: translatedName, length: length.toString() });
  }
  return undefined;
};

export const minLengthValidator = (value: string | undefined | null, name: string, minLength: number, isRequired = false) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length < minLength) {
    return t('{name} must be at least {minLength} characters', { name: translatedName, minLength: minLength.toString() });
  }
  return undefined;
};

const regexValidator = (value: string | undefined | null, pattern: RegExp, errorMessage: string) => {
  const { t } = useLocalization();
  if (value && !pattern.test(value)) {
    return t(errorMessage);
  }
  return undefined;
};

const rangeValidator = (value: string | undefined | null, name: string, min: number, max: number, isRequired = false) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && (value.length < min || value.length > max)) {
    return t('{name} must be between {min} and {max} characters', { name: translatedName, min: min.toString(), max: max.toString() });
  }
  return undefined;
};

export const equalLengthValidator = (value: string | undefined, name: string, length: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length !== length) {
    return t('{name} must be {length} characters', { name: translatedName, length: length.toString() });
  }
  return undefined;
};

export const dateFormatValidator = (value: string | undefined | null, name: string, formatString: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (!dayjs(value, formatString).isValid()) {
    return t('{name} date string is incorrect', { name: translatedName });
  }
  return undefined;
};

export const minNumberValidator = (value: number | string | undefined, name: string, min: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired && !isNaN(value as number)) {
    return t('{name} is required', { name: translatedName });
  }
  if (typeof value === 'string' || isNaN(value as number)) {
    const num = parseFloat(value as string);
    if (isNaN(num) || !isFinite(num)) {
      return t('{name} is not a number', { name: translatedName });
    }
    value = num;
  }
  if (typeof value !== 'number') {
    return t('{name} is not a number', { name: translatedName });
  }
  if (value < min) {
    return t('{name} at least greater than {min}', { name: translatedName, min: min.toString() });
  }
  return undefined;
};

export const getStreetAddress1ValidationError = (streetAddress1: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(streetAddress1, 'Street address 1', 150, isRequired);
};

export const getStreetAddress2ValidationError = (streetAddress2: string | undefined | null, isRequired: boolean) => {
  return (
    requiredValidator(streetAddress2, 'Street address 2', 150, isRequired) || minLengthValidator(streetAddress2, 'Street address 2', 2)
  );
};

export const getCityValidationError = (city: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(city, 'City', 150, isRequired) || minLengthValidator(city, 'City', 2);
};

export const getStateValidationError = (state: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(state, 'State', 2, isRequired) || regexValidator(state, /^[A-Za-z]{2}$/, 'State must be 2 characters');
};

export const getZipCodeValidationError = (zipCode: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(zipCode, 'Zip code', 5, isRequired) || regexValidator(zipCode, /^\d{5}$/, 'Zip code must be 5 digits');
};

export const getCountryValidationError = (country: string | undefined | null, isRequired: boolean) => {
  return rangeValidator(country, 'Country', 3, 25, isRequired);
};

export const getUrlValidator = (url: string | undefined | null) => {
  if (url) {
    return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(url);
  } else {
    return true;
  }
};
