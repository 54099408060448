import IconAddFile from '~/assets/images/settings/company/iconAddFile.svg?component-solid';
import { LabeledSingleFilePreUploader } from '~/components/common/Inputs/LabeledSingleFilePreUploader';
import { useLocalization } from '~/contexts/global';

export const FileUpload = (props: any) => {
  const { t } = useLocalization();

  return (
    <LabeledSingleFilePreUploader
      {...props}
      trigger={
        <>
          <span class="flex gap-2 rounded-lg bg-essential-colour px-5 py-2 text-sm text-white ">
            <IconAddFile />
            {t('Upload file')}
          </span>
          <span>{t('Drag & drop or click to add files')}</span>
        </>
      }
      accept="image/*,application/pdf"
      onAfterUpload={(file) => props.onInput?.(file.fileId)}
      onClean={() => props.onInput?.(undefined)}
    />
  );
};
