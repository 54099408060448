import { useParams } from '@solidjs/router';
import { createSignal, For, Show } from 'solid-js';
import IconAllProperties from '~/assets/images/owner-funds/allProperties.svg';
import IconSomeProperties from '~/assets/images/owner-funds/someProperties.svg';
import { Section } from '~/components/common/FormSectionTitle';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { VendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { ChangeTaxYearUseCase } from '~/pdfsigner/usecases/taxes/changeTaxYearUseCase';
import { ToggleVendorSelectionUseCase } from '~/pdfsigner/usecases/taxes/toggleVendorSelectionUseCase';
import { cn } from '~/utils/classnames';

const VendorCategoryOption = (props: { title: string; icon: string; isSelected: boolean; onClick: () => void }) => (
  <div
    onClick={() => props.onClick()}
    class={cn(
      'inline-flex grow cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-primary',
      props.isSelected && 'border-primary bg-[rgba(161,38,236,0.06)] text-primary'
    )}>
    <div class="relative mr-4 pr-3">
      <img src={props.icon} class="w-16" />
      <div class="absolute inset-y-[9px] left-full w-px bg-input-border" />
    </div>
    <span>{props.title}</span>
  </div>
);

export const ChooseVendors = () => {
  const { t } = useLocalization();
  const { vendorId } = useParams();
  const [isForAllVendors, setIsForAllVendors] = createSignal(true);
  const { execute: toggleVendorSelection } = useUseCase(ToggleVendorSelectionUseCase);
  const { execute: changeTaxYear } = useUseCase(ChangeTaxYearUseCase);
  const { model: vendorTaxAmounts } = usePresenter(VendorTaxAmountPresenter);

  const onSelectAllVendors = () => {
    setIsForAllVendors(true);
  };

  const onSelectSomeVendors = () => {
    setIsForAllVendors(false);
  };

  const getDescriptionText = () => {
    if (vendorId) {
      return t('This will generate tax documents for the selected vendor. Existing tax documents will NOT be overwritten!');
    }
    return t(
      "This will generate tax documents only for vendors that have not had their tax documents created yet. Existing tax documents will NOT be overwritten! If you wish to regenerate tax documents for certain vendors, please do so from each vendor's details page."
    );
  };

  return (
    <div class="flex grow flex-col px-[96px]">
      <div class="mb-4 text-center normal-case text-text-level03">{getDescriptionText()}</div>
      <div class="mb-4 flex flex-row items-center justify-center">
        <label for="year-select" class="mr-2 block font-semibold text-gray-700">
          {t('Year')}:
        </label>
        <select
          id="year-select"
          value={vendorTaxAmounts()?.selectedYear}
          onChange={(e) => changeTaxYear(e.target.value)}
          class="block w-20 rounded-md border-gray-300 text-lg font-semibold shadow-sm focus:border-cyan-500 focus:ring-cyan-500">
          <For each={vendorTaxAmounts()?.years}>{(year) => <option value={year}>{year}</option>}</For>
        </select>
      </div>
      <Show
        when={vendorTaxAmounts() && vendorTaxAmounts()!.vendorTaxAmounts.length > 0}
        fallback={
          <div class="flex size-full grow items-center justify-center border-partingline bg-white p-6 text-center text-lg text-gray-600">
            {t('Cannot generate tax documents, as there are no bills for the selected year')}
          </div>
        }>
        <Show when={!vendorId}>
          <Section required title={t('Vendors')} border>
            <div class="grid grid-cols-2 gap-4">
              <VendorCategoryOption
                title={t('All vendors')}
                icon={IconAllProperties}
                isSelected={isForAllVendors()}
                onClick={onSelectAllVendors}
              />
              <VendorCategoryOption
                title={t('Some vendors')}
                icon={IconSomeProperties}
                isSelected={!isForAllVendors()}
                onClick={onSelectSomeVendors}
              />
              <Show when={!isForAllVendors()}>
                <div>{t('Vendors with bills in {year}', { year: vendorTaxAmounts()?.selectedYear })}</div>
                <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
                  <For each={vendorTaxAmounts()?.vendorTaxAmounts} fallback={<div>{t('No vendors found')}</div>}>
                    {(vendor, index) => (
                      <div
                        class={cn(
                          'flex cursor-pointer items-center p-2 transition-colors hover:bg-gray-100',
                          index() % 2 === 0 ? 'bg-gray-50' : 'bg-white',
                          vendor.isSelected && 'bg-cyan-100'
                        )}
                        onClick={() => toggleVendorSelection(vendor.vendorId)}>
                        <input
                          type="checkbox"
                          checked={vendor.isSelected}
                          class="mr-2"
                          onChange={() => toggleVendorSelection(vendor.vendorId)}
                        />
                        <span class="text-gray-800">{vendor.vendorName}</span>
                      </div>
                    )}
                  </For>
                </div>
              </Show>
            </div>
          </Section>
        </Show>
      </Show>
    </div>
  );
};
