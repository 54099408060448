import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';

export class GenerateVendorTaxDocsUseCase extends MagicUseCase {
  public async runLogic(vendorId: string) {
    const selectedVendorsAndAmounts = this.getState().taxInfo.vendorsAndAmounts.filter((vendor) => vendor.isSelected);
    if (vendorId) {
      await taxDocumentsRepository.regenerateVendorTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        vendorData: selectedVendorsAndAmounts[0],
      });
      this.navigate(`/maintenance/vendors/${vendorId}/tax-documents`);
    } else {
      await taxDocumentsRepository.generateVendorTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        vendorData: selectedVendorsAndAmounts,
      });
      this.navigate('/maintenance/vendors/tax-documents');
    }
  }
}
