import { useNavigate } from '@solidjs/router';

export const useGoBack = (fallbackRoute = '../') => {
  const navigate = useNavigate();
  const history = window.history;

  const goBack = () => {
    if (history.length > 1) {
      navigate(-1);
    } else {
      navigate(fallbackRoute);
    }
  };
  return goBack;
};
