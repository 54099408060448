import { For, onCleanup } from 'solid-js';
import type { Component, JSX } from 'solid-js';

interface SectionedTableViewProps {
  class?: string;
  sectionClass?: string;
  numberOfSections: () => number;
  numberOfRowsInSection: (section: number) => number;
  headerForSection: (section: number) => JSX.Element | undefined;
  rowForSection: (section: number, row: number) => JSX.Element;
}

const SectionedTableView: Component<SectionedTableViewProps> = (props) => {
  const observerMap = new Map<HTMLElement, IntersectionObserver>();

  onCleanup(() => {
    observerMap.forEach((observer) => observer.disconnect());
  });

  const getSectionIndexArray = () => {
    return Array.from({ length: props.numberOfSections() }, (_, section) => section);
  };

  const getRowIndexArray = (sectionIndex: number) => {
    return Array.from({ length: props.numberOfRowsInSection(sectionIndex) }, (_, section) => section);
  };

  const renderSection = (section: number) => {
    return (
      <div class={props.sectionClass}>
        <div> {props.headerForSection(section)}</div>
        <For each={getRowIndexArray(section)}>{(rowIndex) => <>{props.rowForSection(section, rowIndex)}</>}</For>
      </div>
    );
  };

  return (
    <div class={props.class}>
      <For each={getSectionIndexArray()}>{(sectionIndex) => renderSection(sectionIndex)}</For>
    </div>
  );
};

export default SectionedTableView;
