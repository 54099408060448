import { createMemo, createSignal, For, Show } from 'solid-js';
import IconDragHandler from '~/assets/images/common/dragDots.svg?component-solid';
import IconInformation from '~/assets/images/common/info.svg?component-solid';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import Tooltip from '~/components/common/Tooltip';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { PayBillType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { EditingDistribution } from '~/contexts/local';

interface OwnerPaymentMethodSectionProps {
  ownerId: string;
  distributions: EditingDistribution[];
  isOtherSectionDragging: boolean;
  groupId: string;
  onInsertPropertyIntoGroup: (event: DragEvent, targetGroupId: string) => void;
  onSetDraggingGroupId: (groupId: string) => void;
}

export const OwnerPaymentMethodSection = (props: OwnerPaymentMethodSectionProps) => {
  const { t } = useLocalization();
  const { store, updateOwnerDistributionRecord, ownerDistributions, bankAccountOptions } = useAddOwnerDistributions();
  const [isDragging, setIsDragging] = createSignal(false);
  const [isShowDropSpace, setIsShowDropSpace] = createSignal(false);
  const processedPaymentTypeOptions = createMemo(() => {
    return paymentTypeOptions(t).map((option) => {
      if (option.value === PayBillType.Ach && !store.ownerReceiveBankAccountsMap.get(props.ownerId)?.length) {
        return { ...option, disabled: true };
      }
      return { ...option };
    });
  });

  const commonData = createMemo(() => {
    const first = props.distributions.at(0);

    if (!first) {
      return undefined;
    }

    return {
      paymentBankAccountId: first?.paymentBankAccountId,
      receivingBankAccountId: first?.receivingBankAccountId,
      paymentType: first?.paymentType,
      paymentMethod: first?.paymentMethod,
      externalTransactionId: first?.externalTransactionId,
      commonMemo: first.commonMemo,
    };
  });
  const columns = [
    {
      title: t('Property'),
      headerClass: 'w-[40%]',
      class: 'w-[40%]',
      render: (item: EditingDistribution) => {
        const propertyData = ownerDistributions()?.find((record) => record.property?.id === item.propertyId)?.property;

        return (
          <PropertyTitle
            property={propertyData}
            class={cn('h-9 max-w-screen-xxs [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium', {
              'max-w-[232px]': propertyData?.icons.length,
            })}
          />
        );
      },
    },
    {
      title: t('Description'),
      headerClass: 'w-[30%]',
      class: 'w-[30%]',
      render: (item: EditingDistribution) => (
        <LabeledTextInput
          value={item.memo}
          inputContainerClass="bg-white"
          placeholder={t('Memo')}
          onInput={(value) =>
            updateOwnerDistributionRecord({ ownerId: props.ownerId, propertyId: item.propertyId, payload: { memo: value } })
          }
        />
      ),
    },
    {
      title: t('Percentage'),
      headerClass: 'w-[15%]',
      class: 'w-[15%]',
      render: (item: EditingDistribution) => (
        <span class="text-xs text-text-level01">{`${((item.ownershipPercentage ?? 0) * 100).toFixed(2)}%`}</span>
      ),
    },
    {
      title: t('Amount'),
      headerClass: 'w-[15%] text-right',
      class: 'w-[15%] text-right',
      render: (item: EditingDistribution) => <span class="text-xs text-text-level01">{currency(item.distributionAmount ?? 0)}</span>,
    },
  ];

  const onUpdateCommonData = (key: keyof EditingDistribution, value: any) => {
    props.distributions.forEach((distribution) => {
      updateOwnerDistributionRecord({ ownerId: props.ownerId, propertyId: distribution.propertyId, payload: { [key]: value } });
    });
  };

  const onDragStart = (event: DragEvent, propertyId: string) => {
    if (!event.dataTransfer) {
      return;
    }

    setIsDragging(true);
    props.onSetDraggingGroupId(props.groupId);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', JSON.stringify({ ownerId: props.ownerId, propertyId, sourceGroupId: props.groupId }));
  };

  const onDragEnd = (event: DragEvent) => {
    const propertyRow = event.target as HTMLDivElement;

    if (!propertyRow) {
      return;
    }

    setIsDragging(false);
    props.onSetDraggingGroupId('');
    propertyRow.draggable = false;
  };

  const onTriggerDrag = (event: MouseEvent) => {
    const dragHandler = event.target as HTMLDivElement;
    const propertyRow = dragHandler.closest('.property-row') as HTMLDivElement;

    if (!propertyRow) {
      return;
    }

    propertyRow.draggable = true;
  };

  const onDragEnterPlaceholder = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    target.style.transform = 'scale(1.02)';
    target.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.1)';
  };

  const onDragLeavePlaceholder = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    e.stopPropagation();
    target.style.transform = '';
    target.style.boxShadow = '';
  };

  const onDropProperty = (event: DragEvent) => {
    try {
      const data = event.dataTransfer?.getData('text') as string;
      const { ownerId, propertyId } = JSON.parse(data);

      if (ownerId !== props.ownerId) {
        return;
      }

      props.onInsertPropertyIntoGroup(event, props.groupId);

      if (!commonData()) {
        return;
      }

      updateOwnerDistributionRecord({ ownerId, propertyId, payload: { ...commonData() } });
    } catch {
      // do nothing...
    } finally {
      setIsShowDropSpace(false);
    }
  };

  const onDragEnterTable = () => {
    if (isDragging() || !props.isOtherSectionDragging) {
      return;
    }

    setIsShowDropSpace(true);
  };

  const onDragLeaveTable = () => {
    setIsShowDropSpace(false);
  };

  return (
    <div class="overflow-hidden rounded-lg border-input-border bg-white">
      <div class="border-b border-input-border bg-[#FAE9FF] p-3">
        <div class={cn('mb-4 grid grid-cols-3 gap-3', { 'grid-cols-2': commonData()?.paymentType === PayBillType.PrintCheck })}>
          <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
            <DropdownMenu
              label={t('Payment Account')}
              labelClass="mb-0"
              buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
              disabled={!commonData()}
              value={commonData()?.paymentBankAccountId}
              options={bankAccountOptions() ?? []}
              placeholder={t('Please select')}
              onChange={(value) => onUpdateCommonData('paymentBankAccountId', value)}
            />
          </div>
          <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
            <DropdownMenu
              labelJSX={
                <Tooltip message={t('The ACH option is disabled when the owner does not have a receiving account setup.')}>
                  <div class="flex items-center gap-1">
                    <span>{t('Payment Type')}</span>
                    <IconInformation class="text-text-level02" />
                  </div>
                </Tooltip>
              }
              labelClass="mb-0"
              buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
              placeholder={t('Please select')}
              disabled={!commonData()}
              value={commonData()?.paymentType}
              options={processedPaymentTypeOptions()}
              onChange={(value) => onUpdateCommonData('paymentType', value)}
            />
          </div>
          <Show when={commonData()?.paymentType === PayBillType.Manual}>
            <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
              <DropdownMenu
                label={t('Payment Method')}
                labelClass="mb-0"
                buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
                disabled={!commonData()}
                value={commonData()?.paymentMethod}
                options={paymentMethodOptions(t)}
                placeholder={t('Please select')}
                onChange={(value) => onUpdateCommonData('paymentMethod', value)}
              />
            </div>
          </Show>
          <Show when={commonData()?.paymentType === PayBillType.Ach}>
            <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
              <DropdownMenu
                label={t('Receiving account')}
                labelClass="mb-0"
                buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
                disabled={!commonData()}
                value={commonData()?.receivingBankAccountId}
                options={store.ownerReceiveBankAccountsMap.get(props.ownerId) ?? []}
                error={commonData()?.receivingBankAccountId ? undefined : t('Receiving account is required')}
                placeholder={t('Please select')}
                onChange={(value) => onUpdateCommonData('receivingBankAccountId', value)}
              />
            </div>
          </Show>
        </div>
        <div class="grid grid-cols-3 gap-3">
          <Show when={commonData()?.paymentType === PayBillType.Manual}>
            <div class="col-span-1">
              <LabeledTextInput
                label={t('External transaction ID')}
                inputContainerClass={cn('!border-none bg-white !ring-0', { 'bg-white/50': !commonData() })}
                placeholder={t('Enter transaction ID')}
                disabled={!commonData()}
                maxlength={1000}
                value={commonData()?.externalTransactionId}
                error={commonData()?.externalTransactionId ? undefined : t('External transaction ID is required')}
                onInput={(value) => onUpdateCommonData('externalTransactionId', value)}
              />
            </div>
          </Show>
          <div class={cn('col-span-2', { 'col-span-3': commonData()?.paymentType !== PayBillType.Manual })}>
            <LabeledTextInput
              label={t('Memo')}
              inputContainerClass={cn('!border-none bg-white !ring-0', { 'bg-white/50': !commonData() })}
              placeholder={t('Enter memo')}
              disabled={!commonData()}
              maxlength={1000}
              value={commonData()?.commonMemo}
              onInput={(value) => onUpdateCommonData('commonMemo', value)}
            />
          </div>
        </div>
      </div>
      <div class="relative flex flex-col px-3 pb-3" onDragEnter={onDragEnterTable} onDragLeave={onDragLeaveTable}>
        <div
          class="flex items-center pl-[26px]"
          onDragLeave={(event) => {
            event.stopImmediatePropagation();
          }}>
          <For each={columns}>
            {(column) => <div class={cn('px-3 py-2 text-xs text-text-level03', column.headerClass)}>{column.title}</div>}
          </For>
        </div>
        <Show
          when={props.distributions.length}
          fallback={
            <div
              class="flex flex-col items-center gap-0.5 rounded-lg bg-input py-2.5 duration-150"
              onDragEnter={onDragEnterPlaceholder}
              onDragLeave={onDragLeavePlaceholder}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={onDropProperty}>
              <p class="text-xs font-medium text-title-gray">{t('No Property Yet')}</p>
              <p class="text-xs text-text-level03">{t('Drag Property Over Here')}</p>
            </div>
          }>
          <div
            class="flex flex-col gap-3 pl-[26px]"
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(event) => {
              event.stopPropagation();
            }}>
            <For each={props.distributions}>
              {(distribution) => (
                <div
                  class="property-row relative flex items-center rounded-lg bg-input"
                  draggable="false"
                  onDragStart={(event) => onDragStart(event, distribution.propertyId)}
                  onDragEnd={onDragEnd}>
                  <div
                    class="absolute left-0 flex -translate-x-full cursor-grab items-center justify-center bg-transparent p-4"
                    onMouseDown={onTriggerDrag}>
                    <IconDragHandler class="text-text-level03" />
                  </div>
                  <For each={columns}>{(column) => <div class={cn('p-3', column.class)}>{column.render(distribution)}</div>}</For>
                </div>
              )}
            </For>
          </div>
        </Show>
        <Show when={isShowDropSpace()}>
          <div
            class="absolute inset-0 flex items-center justify-center rounded-lg bg-white/40 text-xs text-primary backdrop-blur-lg"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={onDropProperty}>
            {t('Drop the property to insert into this section')}
          </div>
        </Show>
      </div>
    </div>
  );
};
