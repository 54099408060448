import { createSignal, createMemo } from 'solid-js';
import { createStore } from 'solid-js/store';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { rentalApplicationRepo, RentalApplicationRepository } from '~/repositories/rentalApplicationRepository';
import { ReportStatus, TransunionReportType } from '~/swagger/Api';
import { REPORT_LOAD_FAILED_MARK } from '~/utils/constant';
import { createLazyResource, createMutation, createPoll } from '~/utils/resource';
import { pick } from '~/utils/tool';
import type { RentalApplicationFilter } from '~/repositories/rentalApplicationRepository';
import type { currentUpdateType } from '~/utils/constant';

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  totalPages: number;
}

export type applicationStore = {
  initLoadingDetail?: boolean;
  applications: MagicDoor.Api.RentalApplicationDto[];
  loading: boolean;
  application?: MagicDoor.Api.RentalApplicationDto;
  selectedPotentialUnitIds: Map<string, boolean>;
  addPotentialUnit: boolean;
  updateLoading: boolean;
  currentPreviewReportType?: MagicDoor.Api.TransunionReportType;
  currentUpdateType?: currentUpdateType | null;
};

const repo = new RentalApplicationRepository();

const preloadReport = async (report: string) => {
  const dp = new DOMParser();
  const doc = dp.parseFromString(report, 'text/html');

  const linkElements = Array.from(doc.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]'));

  await Promise.allSettled(
    linkElements.map(async (link) => {
      const href = link.href;
      const content = await fetch(href).then((res) => res.text());
      const style = document.createElement('style');
      style.dataset.insertedBy = 'preloadReport';
      style.textContent = content;
      link.parentNode?.appendChild(style);
      link.parentNode?.removeChild(link);
    })
  );

  return doc.body.firstChild?.cloneNode(true);
};

export const [RentalApplicationProvider, useRentalApplication] = createMagicDoorContext('RentalApplication', () => {
  const { t } = useLocalization();
  const [rentalApplications, setRentalApplications] = createSignal<MagicDoor.Api.RentalApplicationListDto[]>([]);
  const [isLoading, setIsLoading] = createSignal<boolean>(true);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [settings, settingsActions] = createLazyResource(() => rentalApplicationRepo.getApplicationSettings());

  const [pagination, setPagination] = createSignal<Pagination>({
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });

  const [applicationStore, setApplicationStore] = createStore<applicationStore>({
    applications: [],
    loading: false,
    selectedPotentialUnitIds: new Map(),
    addPotentialUnit: false,
    updateLoading: false,
  });

  const onTogglePotentialUnitIdsSelect = (id?: string) => {
    if (!id) {
      return;
    }
    setApplicationStore('selectedPotentialUnitIds', (selected) => {
      if (selected.has(id)) {
        selected.delete(id);
      } else {
        selected.set(id, true);
      }
      return new Map(selected);
    });
  };

  const onAddPotentialUnit = () => {
    setApplicationStore('addPotentialUnit', true);
  };

  const fetchApplication = async (applicationId = applicationStore.application?.id, updateInBackend = false) => {
    if (!applicationId) {
      return;
    }
    try {
      if (!updateInBackend) {
        setApplicationStore({ initLoadingDetail: true });
      }
      const response = await repo.getApplication(applicationId);
      setApplicationStore({
        application: response,
      });
    } finally {
      setApplicationStore({ initLoadingDetail: false });
    }
  };

  const baseUpdate = async (delta: Partial<MagicDoor.Api.CreateOrUpdateRentalApplicationDto>) => {
    const _delta = { ...delta };
    try {
      setApplicationStore('updateLoading', true);

      await repo.updateApplication(applicationStore.application?.id as string, {
        ...(pick(applicationStore.application as MagicDoor.Api.RentalApplicationDto, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'ssn',
          'dateOfBirth',
          'applyingWith',
          'desiredMoveInDate',
          'maritalStatus',
          'driversLicense',
          'incomeInformation',
          'pets',
          'residentialHistory',
          'emergencyContact',
          'employment',
          'questions',
          'comments',
          'draft',
        ]) as any),
        tenantId: applicationStore.application?.tenant?.id,
        interestedUnitIds: applicationStore.application?.interests?.map((interest) => interest.unit.id) as string[],
        decision: applicationStore.application?.applicationDecision,
        ..._delta,
      });
      fetchApplication(applicationStore.application?.id as string, true);
      setApplicationStore('selectedPotentialUnitIds', new Map());
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setApplicationStore('updateLoading', false);
    }
  };

  const updatePotentialUnits = async (
    type: 'add' | 'remove',
    deltaIds: string[] = Array.from(applicationStore.selectedPotentialUnitIds.keys())
  ) => {
    try {
      const currentIds = applicationStore.application?.interests?.map((interest) => interest.unit.id) as string[];
      const nextIds = type === 'add' ? [...currentIds, ...deltaIds] : currentIds.filter((id) => !deltaIds.includes(id));
      await baseUpdate({
        interestedUnitIds: nextIds,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const requestReport = createMutation(async () => {
    if (!applicationStore.application?.id) {
      return;
    }
    await repo.requestReport(applicationStore.application?.id);
    toast.success(t('Operation successful, please waiting for report.'));
    setApplicationStore('application', 'reportStatus', ReportStatus.Requested);
    pollReportStatus();
  });

  const getReport = async (type: MagicDoor.Api.TransunionReportType) => {
    if (!applicationStore.application?.id) {
      return;
    }
    return await repo
      .getApplicationReport(applicationStore.application?.id, type)
      .then(preloadReport)
      .catch(() => REPORT_LOAD_FAILED_MARK);
  };

  const previewReport = createMutation(async (type: MagicDoor.Api.TransunionReportType) => {
    setApplicationStore('currentPreviewReportType', type);
    return await getReport(type);
  });

  const pollReportStatus = createPoll(async () => {
    const creditReport = await getReport(TransunionReportType.Credit);
    if (!creditReport || creditReport === REPORT_LOAD_FAILED_MARK) return false;
    setApplicationStore('application', 'reportStatus', ReportStatus.Available);
  });

  const isNewApplicant = createMemo(() => {
    return !applicationStore.application?.tenant;
  });

  const getRentalApplications = async (params?: RentalApplicationFilter) => {
    setIsLoading(true);
    try {
      const response = await repo.getRentalApplications(params);
      setRentalApplications(response.items);
      setPagination({
        current: response.currentPage,
        pageSize: response.pageSize,
        total: response.totalCount,
        totalPages: response.totalPages,
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDecision = async (rentalApplicationId: string, payload: MagicDoor.Api.UpdateRentalApplicationDecisionDto) => {
    console.log('updateDecision', rentalApplicationId, payload);
    try {
      const response = await repo.updateDecision(rentalApplicationId, payload);

      setRentalApplications((prev) => {
        return prev.map((item) => ({
          ...item,
          applicationDecision: item.id === rentalApplicationId ? response.applicationDecision : item.applicationDecision,
        }));
      });

      return response;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const tenantConvert = async (rentalApplicationId: string) => {
    try {
      return await repo.tenantConvert(rentalApplicationId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  const paymentRefund = async (rentalApplicationId: string, amount: number, reason: string) => {
    try {
      return await repo.paymentRefund(rentalApplicationId, { amount, reason });
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const receivePayment = async (rentalApplicationId: string, payload: MagicDoor.Api.RentalApplicationOfflinePaymentDto) => {
    try {
      await repo.receivePayment(rentalApplicationId, payload);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const [counts, countsActions] = createLazyResource(() => repo.getRentalApplicationCounts());

  return {
    getRentalApplications,
    rentalApplications,
    isLoading,
    error,
    pagination,
    updateDecision,
    tenantConvert,
    paymentRefund,
    applicationStore,
    fetchApplication,
    onTogglePotentialUnitIdsSelect,
    onAddPotentialUnit,
    setApplicationStore,
    updatePotentialUnits,
    baseUpdate,
    previewReport,
    requestReport,
    isNewApplicant,
    get counts() {
      countsActions.fetch();
      return counts;
    },
    get settings() {
      settingsActions.fetch();
      return settings;
    },
    receivePayment,
  };
});
