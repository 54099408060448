import { UnauthorizedError } from '@magicdoor/errors';
import { useAuth } from '~/contexts/global';

const UnauthorizedPage = () => {
  const { signOut } = useAuth();
  signOut();

  console.warn('Test behavior: UnauthorizedError');
  throw new UnauthorizedError();
};

export default UnauthorizedPage;
