import { useParams, useNavigate } from '@solidjs/router';
import { Show, createSignal, createEffect, For, createMemo } from 'solid-js';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { LeaseItemWithTenants } from '~/components/leases';
import { IconBan, IconCirclePlus, ListView, TableActions, MobileListView } from '~/components/ui';
import { IconChevronDown } from '~/components/ui/Icons';
import { UnitTitle } from '~/components/units';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { DeactivateUnit } from '~/pages/units/components/DeactivateUnit';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';

export const UnitsListView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { units, getUnits } = useUnits();
  const [expandedUnits, setExpandedUnits] = createSignal<Set<number>>(new Set());
  const [showAll, setShowAll] = createSignal(false);
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { localPageSize, setPage, setPageSize } = useLocalPagination('UnitsListView');
  const [deactivating, setDeactivating] = createSignal<MagicDoor.Api.HydratedUnitDto>();

  const depositContent = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto) => {
    const paid = createMemo(() => currency(lease.securityDepositPaid));
    const requested = createMemo(() => currency(lease.securityDepositRequested));

    return (
      <>
        <Show when={paid() !== requested()} fallback={<span class="text-sm font-medium text-text-level01">{requested()}</span>}>
          <span class="text-sm font-medium text-text-level01">{paid()}</span>
          <span class="text-xs font-light text-text-level04">/{requested()}</span>
        </Show>
      </>
    );
  };

  createEffect(() => getUnits({ pageSize: localPageSize, propertyId: params.propertyId, portfolioId: params.portfolioId }));

  const handleShowAll = (e: TouchEvent | MouseEvent, index: number) => {
    setShowAll(!showAll());
    e.stopPropagation();
    setExpandedUnits((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const renderItem = (unit: MagicDoor.Api.HydratedUnitDto, index: number) => {
    const showLeases = createMemo(() => (expandedUnits().has(index) ? unit.activeLeases : unit.activeLeases.slice(0, 1)));
    const redirectUrl = location.pathname + '/' + unit.id;
    return (
      <div class="p-3" onClick={() => navigate(redirectUrl)}>
        <div class="flex justify-between pr-3">
          <UnitTitle class="min-w-40" unit={unit} />
          <div class="flex justify-end">
            <TableActions
              onClick={(e) => e.stopPropagation()}
              triggerClass="size-[28px] focus:rounded-md focus:bg-light-gray"
              actions={[
                {
                  label: t('Edit'),
                  icon: IconPencil,
                  href: `edit/${unit.id}`,
                  noScroll: true,
                },
                {
                  label: t('Deactivate'),
                  icon: IconBan,
                  onClick: () => setDeactivating(unit),
                },
                {
                  label: t('Add service bill'),
                  icon: IconCirclePlus,
                  href: `addServiceBill/${unit.id}`,
                  noScroll: true,
                },
              ]}
            />
          </div>
        </div>
        <div class="mt-2 space-y-2">
          <For each={showLeases()}>
            {(lease) => (
              <div class="bg-[#F5FCFA] p-3 py-2">
                <LeaseItemWithTenants lease={lease} />
                <div class="mt-2 flex flex-wrap gap-x-4 gap-y-2">
                  <div class="flex basis-[calc(50%-8px)] gap-[6px]">
                    <div class="text-text-level03">{t('Rent')}:</div>
                    <div class="">{currency(lease.currentRent)}</div>
                  </div>

                  <div class="flex basis-[calc(50%-8px)] gap-[6px]">
                    <div class="text-text-level03">{t('Balances')}:</div>
                    <CnMoney money={lease.balance} showIcon />
                  </div>

                  <div class="flex basis-[calc(50%-8px)] gap-[6px]">
                    <div class="text-text-level03">{t('Deposit')}:</div>
                    <span class="text-sm">{depositContent(lease)}</span>
                  </div>
                  <div class="flex basis-[calc(50%-8px)] gap-[6px]">
                    <div class="whitespace-nowrap text-text-level03">{t('Posted balances')}:</div>
                    <CnMoney money={lease.postedBalance} showIcon />
                  </div>
                </div>
              </div>
            )}
          </For>
          <Show when={!unit?.activeLeases?.length}>
            <div class="p-3 text-xs text-danger">{t('Not leased')} </div>
          </Show>

          <Show when={unit?.activeLeases?.length > 1}>
            <div onClick={(e) => handleShowAll(e, index)} class="flex items-center justify-center gap-2 pt-2 text-center text-text-level03">
              <Show
                when={!expandedUnits().has(index)}
                fallback={
                  <>
                    {t('Collapse leases')}
                    <IconChevronDown class="size-4 rotate-180" />
                  </>
                }>
                <>
                  {t('View all Lease +{number}', { number: unit.activeLeases.length })}
                  <IconChevronDown class="size-4" />
                </>
              </Show>
            </div>
          </Show>
        </div>
      </div>
    );
  };

  return (
    <>
      <MobileListView
        renderItem={renderItem}
        class="mx-3 md:hidden"
        title={t('Units')}
        data={units()?.items ?? []}
        page={units()?.currentPage}
        pageSize={units()?.pageSize}
        totalPages={units()?.totalPages}
        loading={units.loading}
        searchPlaceholder={t('Search')}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getUnits({ ...params, page, pageSize, search });
        }}
        titleActions={
          <LinkButton href="add" noScroll>
            <IconCirclePlus class="size-5" />
            <span>{t('Add unit')}</span>
          </LinkButton>
        }
      />

      <ListView
        class="mx-8 hidden md:block"
        title={t('Units')}
        data={units()?.items}
        page={units()?.currentPage}
        pageSize={units()?.pageSize}
        totalPages={units()?.totalPages}
        viewAllSuffix="leases"
        loading={units.loading}
        searchPlaceholder={t('Search units')}
        getTotalRowCount={(data) => {
          return data?.activeLeases?.length ?? 0;
        }}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getUnits({ ...params, page, pageSize, search });
        }}
        rowLink={(item) => item.id}
        titleActions={
          <LinkButton href="add" noScroll>
            <IconCirclePlus class="size-5" />
            <span class="hidden lg:block">{t('Add unit')}</span>
          </LinkButton>
        }
        columns={[
          {
            title: t('Units'),
            render: (unit: MagicDoor.Api.HydratedUnitDto) => <UnitTitle class="min-w-40" unit={unit} />,
          },
          {
            title: t('Lease'),
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="my-2 flex flex-col divide-y">
                <Show when={leases?.length} fallback={<div class="text-xs text-danger">{t('Not leased')} </div>}>
                  <For each={leases}>
                    {(lease) => (
                      <div class="py-2">
                        <LeaseItemWithTenants lease={lease} />
                      </div>
                    )}
                  </For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Rent'),
            headerClass: 'text-right',
            class: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <span class="text-sm">{currency(lease.currentRent)}</span>}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Deposit'),
            headerClass: 'text-right',
            class: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <span class="text-sm">{depositContent(lease)}</span>}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Balances'),
            class: 'text-right',
            headerClass: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.balance} showIcon />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Posted balances'),
            class: 'text-right',
            headerClass: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.postedBalance} showIcon />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Operation'),
            headerClass: 'text-right',
            class: 'relative',
            render: (unit) => (
              <div class="flex justify-end">
                <TableActions
                  actions={[
                    {
                      label: t('Edit'),
                      icon: IconPencil,
                      href: `edit/${unit.id}`,
                      noScroll: true,
                    },
                    {
                      label: t('Deactivate'),
                      icon: IconBan,
                      onClick: () => setDeactivating(unit),
                    },
                    {
                      label: t('Add service bill'),
                      icon: IconCirclePlus,
                      href: `addServiceBill/${unit.id}`,
                      noScroll: true,
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <DeactivateUnit unit={deactivating()} onClose={setDeactivating} />
    </>
  );
};
