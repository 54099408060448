import { Route, Routes } from '@solidjs/router';
import { BillsProvider, OwnerContributionProvider, OwnerTransfersContextProvider, PayVendorBillsProvider } from '~/contexts/local';
import { AddTransferModal } from '~/pages/owner-funds/distributions/components/AddTransferModal';
import { ContributionsPage } from './contributions';
import { AddContribution } from './contributions/add-contribution';
import { DistributionsPage } from './distributions';

export const OwnerFundsRoutes = () => (
  <BillsProvider>
    <PayVendorBillsProvider>
      <OwnerContributionProvider>
        <OwnerTransfersContextProvider>
          <Routes>
            <Route path="/distributions/*" component={DistributionsPage} />
            <Route path="/contributions/*" component={ContributionsPage} />
            <Route path="/contributions/add" component={AddContribution} />
          </Routes>
          <Routes>
            <Route path="/:prefix/transfer" component={AddTransferModal} />
          </Routes>
        </OwnerTransfersContextProvider>
      </OwnerContributionProvider>
    </PayVendorBillsProvider>
  </BillsProvider>
);
