import { Route, Routes } from '@solidjs/router';
import { ChatsSettingsProvider } from '~/contexts/local';
import { ChatsAIWrap } from '~/pages/settings/chats/ai';
import { ChatWrap } from '~/pages/settings/chats/chat';
import { ChatsTextWrap } from '~/pages/settings/chats/text';

const ChatsRoutes = () => {
  return (
    <div class="flex w-full gap-6">
      <ChatsSettingsProvider>
        <Routes>
          <Route path="/chat" element={<ChatWrap />} />
          <Route path="/text" element={<ChatsTextWrap />} />
          <Route path="/ai" element={<ChatsAIWrap />} />
        </Routes>
      </ChatsSettingsProvider>
    </div>
  );
};

export default ChatsRoutes;
