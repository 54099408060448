import { useParams } from '@solidjs/router';
import { createMemo, createSignal } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseDeposits, useLeaseRentTransactions } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { formConfirmationContent } from '~/utils/constant';

const formId = 'lease-transaction-mark-as-returned';
const formFields = ['reason'] as const;

export const LeaseTransactionMarkAsReturnedModal = (props: { isDeposit?: boolean }) => {
  const { t } = useLocalization();
  const { markAsReturned, getLeaseRentTransactions } = useLeaseRentTransactions();
  const { depositActions } = useLeaseDeposits();
  const params = useParams<{ leaseId: string; transactionId: string }>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const goBack = useGoBack();
  const form = useForm();

  const isFormDirty = createMemo(() => {
    return formFields.some((field) => form.formStore[field]);
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await markAsReturned(params.leaseId, params.transactionId, form.formStore as MagicDoor.Api.ReturnLeasePaymentDto);
      toast.success(t('Operation successful'));
      if (props.isDeposit) {
        depositActions.refetch();
      } else {
        getLeaseRentTransactions(params.leaseId);
      }
      goBack();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      confirmation={isFormDirty() ? formConfirmationContent(t) : false}
      visible
      title={t('Mark as returned')}
      doneText={t('Submit')}
      submitId={formId}
      disabled={loading()}
      loading={loading()}
      onCancel={() => goBack()}>
      <Form class="thinscroll overflow-auto p-8" id={formId} onFormSubmit={handleSubmit} defaultForm={form}>
        <FormItem label={t('Reason')} maxlength={150} placeholder={t('Please enter')} component={LabeledTextInput} formFieldName="reason" />
      </Form>
    </Modal>
  );
};
