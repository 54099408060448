import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type BtnProps = {
  onCancel: () => void;
  onSave: () => void;
  name?: string;
  class?: string;
};
export const BottomBtns: Component<BtnProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class={cn('flex items-center justify-center gap-2 border-t border-input-border bg-white px-6 py-5 md:justify-end', props.class)}>
      <Button id={`${props.name}-cancel-btn`} class="md:ml-auto" variant="outlined" onClick={props.onCancel}>
        {t('Cancel')}
      </Button>
      <Button id={`${props.name}-save-btn`} onClick={props.onSave} class="flex-1 justify-center md:flex-none">
        {t('Save changes')}
      </Button>
    </div>
  );
};
