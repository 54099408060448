import { BaseRestRepository } from '~/repositories/baseRestRepository';

export type GetInvoicesParams = {
  from?: string;
  to?: string;
};

export class CompanyBusinessInfoRepository extends BaseRestRepository {
  private readonly BASE_URL = '/api/companies/business-info';

  getStatus = async (): Promise<MagicDoor.Api.CompanyBusinessInfoStatusDto> => {
    const url = this.BASE_URL + '/status';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  saveBusinessInfo = async (data: MagicDoor.Api.UpdateCompanyBusinessInfoDto): Promise<MagicDoor.Api.CompanyBusinessInfoStatusDto> => {
    const url = this.BASE_URL;
    const response = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(data) });
    return this.getJsonResponse(response);
  };
}

export const companyBusinessInfoRepository = new CompanyBusinessInfoRepository();
