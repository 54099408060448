import { createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useGoBack } from '~/hooks';
import { TitlePresenter } from '~/pdfsigner/presenters/TitlePresenter';
import Composer from '~/pdfsigner/ui/screens/Composer';
import { ResetSelectedDocumentUseCase } from '~/pdfsigner/usecases/pdfEditing/loadLeaseTemplateUseCase';

export const LeaseTemplateDetails = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { model: presentableTitle } = usePresenter(TitlePresenter);
  const { execute: resetSelectedDocument } = useUseCase(ResetSelectedDocumentUseCase);

  const breadcrumbItems = createMemo(() => [
    { label: t('Lease templates'), link: '/leasing/lease-templates' },
    { label: presentableTitle()?.title || t('Loading') },
  ]);

  onMount(() => {
    resetSelectedDocument();
  });
  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <Composer />
    </div>
  );
};
