import { useParams } from '@solidjs/router';
import { SegmentForm, SegmentFormScrollSpy, useForm } from '~/components/common/BetterForm';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { PropertyInformationSection } from '~/pages/properties/form-sections/PropertyInformationSection';
import { DeactivateProperty } from './DeactivateProperty';
export const EditProperty = (props: { onClose: () => void }) => {
  const { updateProperty } = useProperties();
  const { portfolioId, propertyId } = useParams();
  const form = useForm();

  const { t } = useLocalization();

  const performSave = async () => {
    // edit
    const model = {
      ...(form.formStore as MagicDoor.Api.UpdatePropertyDto),
      portfolioId: portfolioId,
    };

    const updated = await updateProperty(propertyId, model);
    props.onClose();
    toast.success(t('{name} has been edited successfully', { name: updated.name || t('Property') }));
  };

  return (
    <SegmentForm
      defaultForm={form}
      onFormSubmit={performSave}
      class="m-3 flex h-mobileSection flex-col rounded-md bg-white text-sm md:m-8 md:h-section1">
      <div class="flex flex-1 overflow-hidden">
        <div class="relative hidden w-1/4 px-6 py-7 text-text-level03 xs:block">
          <div class="pb-2 text-left text-lg font-semibold capitalize text-text-level01">{t('Edit property')}</div>
          <SegmentFormScrollSpy />
        </div>
        <div class="thinscroll flex w-full flex-col gap-10 overflow-auto pb-20 xs:w-3/4 md:border-l">
          <PropertyInformationSection />
        </div>
      </div>
      <div class="flex items-center justify-between border-t p-4 md:p-6">
        <DeactivateProperty />
        <div class="flex justify-end gap-2 md:w-1/3">
          <CancelWithConfirmation
            onConfirm={props.onClose}
            buttonsDisabled={updateProperty.pending}
            message={t('Are you sure you want to cancel? The changes will not be saved.')}
          />
          <CallToActionButton
            loading={updateProperty.pending}
            disabled={updateProperty.pending || !form.isValidate}
            type="submit"
            tooltipMessage={t('Please fill out all the required fields that are in red')}>
            {t('Save changes')}
          </CallToActionButton>
        </div>
      </div>
    </SegmentForm>
  );
};
