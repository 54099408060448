import { BaseRestRepository } from '~/repositories/baseRestRepository';

export class PaymentAccountsRepository extends BaseRestRepository {
  private readonly BASE_URL = '/api/payment-accounts';

  getPaymentAccounts = async (): Promise<MagicDoor.Api.PaymentAccountsDto> => {
    const url = this.BASE_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  createPaymentAccount = async (payload: MagicDoor.Api.CreateCompanyPaymentAccountDto): Promise<MagicDoor.Api.PaymentAccountDto> => {
    const url = this.BASE_URL;
    const response = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) }, false);
    return this.getJsonResponse(response);
  };

  checkout = async (): Promise<MagicDoor.Api.StripeCheckoutDto> => {
    const url = this.BASE_URL + '/checkout';
    const response = await this.fetchWithAuth(url, { method: 'POST' });
    return this.getJsonResponse(response);
  };

  removePaymentAccount = async (id: string): Promise<MagicDoor.Api.PaymentAccountDto> => {
    const url = this.BASE_URL + '/' + id;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  };
}

export const paymentAccountsRepository = new PaymentAccountsRepository();
