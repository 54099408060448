import { createMemo, onCleanup, onMount, Show } from 'solid-js';
import { BaseModal } from '~/components/modals/BaseModal';
import { IconLoader, toast } from '~/components/ui';
import { useCompanyPaymentMethods, useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';

export const AddPaymentAccountModal = () => {
  const { getStripe, getStripeCheckoutInfo, paymentAccountsActions, pollCheckoutResult } = useCompanyPaymentMethods();
  const goBack = useGoBack();
  let checkoutInstance: any = null;
  const { t } = useLocalization();

  const isReady = createMemo(() => !getStripe.loading && !getStripeCheckoutInfo.loading);

  const initCheckout = async () => {
    const _stripe = await getStripe();
    const _checkout = await getStripeCheckoutInfo();
    if (_stripe) {
      checkoutInstance = await _stripe.initEmbeddedCheckout({
        clientSecret: _checkout.clientSecret,
        onComplete: async () => {
          try {
            await pollCheckoutResult(_checkout.sessionId);
            goBack();
            paymentAccountsActions.refetch();
          } catch (error) {
            toast.error((error as Error).message);
          }
        },
      });

      checkoutInstance.mount('#stripe-checkout-form');
    }
  };

  onMount(() => {
    initCheckout();
  });

  onCleanup(() => {
    checkoutInstance?.unmount();
    checkoutInstance?.destroy();
  });

  return (
    <BaseModal title={t('Checkout form')} visible={true} onClose={() => goBack()} showCloseButton>
      <Show when={isReady()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
        <div id="stripe-checkout-form" />
      </Show>
    </BaseModal>
  );
};
