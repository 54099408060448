import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { LabeledVendorSearch } from '~/components/vendors/VendorSearch';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { AssociationViolationStatus, LevelSearch } from '~/swagger/Api';
import { enumToOptions } from '~/utils/constant';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInformation = () => {
  const { t } = useLocalization();

  const { setNewFiles } = useViolations();

  const { setFieldsValue } = useFormContext();

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        onFilesListChange={(files: UploaderFile[]) => {
          setNewFiles(files);
        }}
      />

      <FormItem
        class="w-full"
        label={t('Property, unit or lease')}
        formFieldName="propertyId"
        component={LabeledLeveledSearchSelect}
        rules={[{ required: true, message: t(`Please select property, unit or lease`) }]}
        placeholder={t('Select property, unit or lease')}
        enabledTypes={[LevelSearch.Property, LevelSearch.Unit, LevelSearch.Lease]}
        onChange={(_, variants) => {
          setFieldsValue(
            {
              propertyId: variants.leveledIds.at(0)?.propertyId,
              unitId: variants.leveledIds.at(0)?.unitId,
              leaseId: variants.leveledIds.at(0)?.leaseId,
            },
            false
          );
        }}
      />

      <FormItem<any>
        class="w-full"
        label={t(`HOA`)}
        formFieldName="hoaVendorId"
        component={LabeledVendorSearch}
        placeholder={t('Please select vendor')}
        rules={[{ required: true, message: t('Please select HOA vendor') }]}
      />

      <div class="grid grid-cols-2 gap-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Violation date')}
          placeholder={t('Please select')}
          formFieldName="violationDate"
          component={DueDateInputField}
          rules={[{ required: true, message: t('Please select violation date') }]}
        />
        <FormItem
          label={t('Violation receive date')}
          placeholder={t('Please select')}
          component={DueDateInputField}
          formFieldName="violationReceivedDate"
          class="col-span-full lg:col-span-1"
          rules={[{ required: true, message: t('Please select violation receive date') }]}
        />
        <FormItem
          label={t('Fix by date')}
          placeholder={t('Please select')}
          component={DueDateInputField}
          formFieldName="fixByDate"
          class="col-span-full lg:col-span-1"
        />
        <FormItem
          label={t('Status')}
          options={enumToOptions(AssociationViolationStatus)}
          placeholder={t('Please select')}
          component={LabeledSelect}
          formFieldName="status"
          rules={[{ required: true, message: t('Please select status') }]}
        />
        {/* TODO: BACKEND SAID REMOVE FOR NOW
        <FormItem
          label={t('Association fine')}
          placeholder={t('Please enter')}
          component={LabeledTextInput}
          prepend="$"
          formFieldName="associationFine"
          getValueFromEvent={(e) => +e}
        /> */}
        <FormItem
          class="col-span-full"
          label={t('HOA violation reference #')}
          placeholder={t('Please enter')}
          component={LabeledTextInput}
          formFieldName="reference"
        />
      </div>
    </div>
  );
};
