import { loadStripe } from '@stripe/stripe-js';
import { createMemo } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { paymentAccountsRepository } from '~/repositories/paymentAccounts';
import { PaymentAccountProvider } from '~/swagger/Api';
import { createLazyResource, createMutation } from '~/utils/resource';
import { getStripeKey } from '~/utils/stripeKey';

export const [CompanyPaymentMethodsProvider, useCompanyPaymentMethods] = createMagicDoorContext('companyPaymentMethods', () => {
  const [paymentAccounts, paymentAccountsActions] = createLazyResource(paymentAccountsRepository.getPaymentAccounts);
  const getStripeCheckoutInfo = createMutation(paymentAccountsRepository.checkout);
  const removePaymentAccount = createMutation(paymentAccountsRepository.removePaymentAccount);

  const createPaymentAccount = createMutation(paymentAccountsRepository.createPaymentAccount);

  const getStripe = createMutation(() => loadStripe(getStripeKey()));

  const pollCheckoutResult = (stripeSessionId: string) => {
    const startAt = Date.now();
    const maxWaitTime = 1000 * 60 * 2;
    const { resolve, reject, promise } = Promise.withResolvers();

    (async function check() {
      const elapsed = Date.now() - startAt;
      if (elapsed > maxWaitTime) {
        reject(new Error('Checkout not completed'));
        return;
      }
      const result = await paymentAccountsRepository.createPaymentAccount({ stripeSessionId }).catch(() => null);
      if (result?.id) {
        resolve(result);
        return;
      }
      setTimeout(check, 1000);
    })();

    return promise;
  };

  const validatedPaymentAccounts = createMemo(() => paymentAccounts()?.paymentAccounts.filter((account) => account.mapped));

  const validatedPaymentAccountsOptions = createMemo(
    () =>
      validatedPaymentAccounts()?.map((account) => {
        let label: string = account.accountProvider;

        if (account.accountProvider === PaymentAccountProvider.Plaid) {
          label += ` - ${account.plaid?.plaidAccountType.toLowerCase()} - ${account.accountMask}`;
        } else if (account.accountProvider === PaymentAccountProvider.Stripe) {
          label += ` - ${account.stripe?.expMonth}/${account.stripe?.expYear} - ${account.accountMask}`;
        }

        return {
          label,
          value: account.id,
        };
      }) ?? []
  );

  return {
    paymentAccounts,
    paymentAccountsActions,
    getStripe,
    getStripeCheckoutInfo,
    pollCheckoutResult,
    validatedPaymentAccounts,
    validatedPaymentAccountsOptions,
    removePaymentAccount,
    createPaymentAccount,
  };
});
