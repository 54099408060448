import { createMemo } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { useLocalPagination } from '~/hooks';
import { companyInvoicesRepository } from '~/repositories/companyInvoices';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { Accessor } from 'solid-js';
import type { Pagination } from '~/hooks';

const mockPagination = <T,>(params: {
  data: () => T[];
  name: string;
  initialPagination?: Partial<Pagination>;
}): [
  Accessor<{ items: T[]; page: number; pageSize: number; totalPages: number; totalCount: number }>,
  { setPage: (newPage: number) => void; setPageSize: (newPageSize: number) => void }
] => {
  const { page, pageSize, setPage, setPageSize } = useLocalPagination(params.name, params.initialPagination);
  const _res = createMemo(() => {
    const _data = params.data();
    const _page = page();
    const _pageSize = pageSize();
    const items = _data ? _data?.slice((_page - 1) * _pageSize, _page * _pageSize) : [];
    const totalPages = _data ? Math.ceil(_data.length / _pageSize) : 0;
    const totalCount = _data ? _data.length : 0;
    return {
      items,
      page: _page,
      pageSize: _pageSize,
      totalPages,
      totalCount,
    };
  });
  return [_res, { setPage, setPageSize }];
};

export const [CompanyInvoicesProvider, useCompanyInvoices] = createMagicDoorContext('companyInvoices', () => {
  const [invoices, getInvoices, { trigger: invoicesFilter, refetch: refetchInvoices }] = createTriggerResource(
    companyInvoicesRepository.getInvoices
  );
  const [invoice, getInvoice, getInvoiceActions] = createTriggerResource(companyInvoicesRepository.getInvoice);
  const payInvoice = createMutation(companyInvoicesRepository.payInvoice);

  const invoiceTotalAmount = createMemo(() => {
    return invoice()?.summaries.reduce((acc, curr) => acc + curr.itemTotal, 0);
  });

  const [invoicesWithPagination, invoicesWithPaginationActions] = mockPagination({
    data: () => invoices() || [],
    name: 'CompanyInvoicesListView',
  });

  const handlePayInvoice = async (invoiceId: string, paymentAccountId: string) => {
    await payInvoice({ invoiceId, paymentAccountId });
  };

  return {
    invoices,
    invoicesFilter,
    getInvoices,
    refetchInvoices,
    invoice,
    getInvoice,
    getInvoiceActions,
    payInvoice,
    invoiceTotalAmount,
    invoicesWithPagination,
    invoicesWithPaginationActions,
    handlePayInvoice,
  };
});
