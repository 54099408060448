import { Show, createMemo } from 'solid-js';
import docxIcon from '~/assets/images/leases/files/docx.png';
import jpegIcon from '~/assets/images/leases/files/jpeg.png';
import jpgIcon from '~/assets/images/leases/files/jpg.png';
import otherIcon from '~/assets/images/leases/files/other.png';
import pdfIcon from '~/assets/images/leases/files/pdf.png';
import pngIcon from '~/assets/images/leases/files/png.png';
import xlsxIcon from '~/assets/images/leases/files/xlsx.png';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { getFileSuffix, decodeFileName } from '~/utils/file';
import type { Component } from 'solid-js';

const fileInfoMap = {
  pdf: { icon: pdfIcon, type: 'PDF' },
  docx: { icon: docxIcon, type: 'Document' },
  jpeg: { icon: jpegIcon, type: 'Image' },
  jpg: { icon: jpgIcon, type: 'Image' },
  png: { icon: pngIcon, type: 'Image' },
  xlsx: { icon: xlsxIcon, type: 'Spreadsheet' },
  other: { icon: otherIcon, type: 'Unknown' },
};

export const FileIcon: Component<{ class?: string; filename: string }> = (props) => {
  const { t } = useLocalization();
  const type = createMemo(() => {
    const suffix = getFileSuffix(props.filename);
    return suffix in fileInfoMap ? fileInfoMap[suffix as keyof typeof fileInfoMap] : fileInfoMap.other;
  });
  return <Image class={props.class} src={type()?.icon} alt={t(type()?.type)} />;
};

export const FileTitle: Component<{ class?: string; file: { fileName: string; description?: string | null } }> = (props) => {
  const decodedFileName = createMemo(() => decodeFileName(props.file.fileName));

  return (
    <div class={cn('flex items-center gap-2 text-xs text-title-gray', props.class)}>
      <FileIcon class="size-8" filename={decodedFileName()} />
      <div class="flex-1 space-y-0.5 overflow-hidden">
        <h4 class="truncate font-medium">{decodedFileName()}</h4>
        <Show when={props.file.description}>
          <p class="normal-case opacity-80">{props.file.description}</p>
        </Show>
      </div>
    </div>
  );
};
