import { Show } from 'solid-js';
import AllPropertiesIcon from '~/assets/images/bill/allProperties.svg';
import AllVendorsIcon from '~/assets/images/bill/allVendors.svg';
import SomePropertiesIcon from '~/assets/images/bill/someProperties.svg';
import SomeVendorsIcon from '~/assets/images/bill/someVendors.svg';
import { MutiSelect } from '~/components/common/MutiSelect';
import { OptionButton } from '~/components/common/OptionButton';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { LabeledVendorSearch } from '~/components/vendors';
import { VendorTitle } from '~/components/vendors/VendorTitle';
import { useLocalization } from '~/contexts/global';
import { usePayVendorBills } from '~/contexts/local';
import { LevelSearch } from '~/swagger/Api';
import type { Variants } from '~/components/search-select/Context';

export const ChooseBillsForm = () => {
  const { t } = useLocalization();
  const { store, setStore } = usePayVendorBills();

  const onPropertiesChange = (_: unknown, variants: Variants) => {
    setStore('properties', variants.singleTypeIds);
  };

  const onVendorsChange = (val: string[]) => {
    setStore('vendors', val);
  };

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Properties')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllPropertiesIcon}
            label={t('All properties')}
            isActive={store.isAllProperties}
            onClick={() => {
              setStore('isAllProperties', true);
              setStore('properties', []);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomePropertiesIcon}
            label={t('Some properties')}
            isActive={!store.isAllProperties}
            onClick={() => {
              setStore('isAllProperties', false);
              setStore('properties', []);
            }}
          />
          <Show when={!store.isAllProperties}>
            <LabeledLeveledSearchSelect
              multiple={true}
              enabledTypes={[LevelSearch.Property]}
              placeholder={t('Select properties')}
              onChange={onPropertiesChange}
            />
          </Show>
        </div>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Vendors')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllVendorsIcon}
            label={t('All vendors')}
            isActive={store.isAllVendors}
            onClick={() => {
              setStore('isAllVendors', true);
              setStore('vendors', []);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomeVendorsIcon}
            label={t('Some vendors')}
            isActive={!store.isAllVendors}
            onClick={() => {
              setStore('isAllVendors', false);
              setStore('vendors', ['']);
            }}
          />
          <Show when={!store.isAllVendors}>
            <MutiSelect<MagicDoor.Api.HydratedVendorDto>
              as={LabeledVendorSearch}
              items={store.vendors ?? []}
              onMutiSelectInput={(val) => onVendorsChange(val)}
              addButtonText={t('Add a new vendor')}
              renderSelectedItem={(item) => <VendorTitle vendor={item} />}
            />
          </Show>
        </div>
      </div>
    </div>
  );
};
