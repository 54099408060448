import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import fallbackListingImage from '~/assets/images/units/listing/defaultListing.jpg';
import DisplayAddress from '~/components/common/DisplayAddress';
import Tooltip from '~/components/common/Tooltip';
import { propertyTypes } from '~/components/properties/PropertyType';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ListingIs } from '~/pages/listing/components/ListingIs';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import { getFileAllUrl } from '~/utils/file';
import type { Component } from 'solid-js';

type PropertyDistributionProps = {
  class?: string;
  listing?: any;
  id?: string;
};

const ListingCard: Component<PropertyDistributionProps> = (props) => {
  const params = useParams<{ portfolioId?: `${number}` }>();
  const navigate = useNavigate();

  const { t } = useLocalization();

  const imgSrc = createMemo(() => {
    if (props.listing === null || !props.listing?.primaryImage || props.listing?.primaryImage?.length === 0) return fallbackListingImage;
    return getFileAllUrl(props.listing?.primaryImage?.signedUrl);
  });

  return (
    <div
      id={props.id}
      class={cn('cursor-pointer overflow-hidden rounded-lg border bg-[#F6F6F6] shadow-lg hover:border-essential-colour', props.class)}
      onClick={() => navigate(`/listings/${params.portfolioId}/details/${props.listing.id}`)}>
      <div class="bg-white p-1 md:p-2">
        <div class="relative">
          <Image class="h-[92px] w-full rounded-lg object-cover md:h-[143px]" src={imgSrc()} />
          <div class="absolute right-2 top-2 flex items-center gap-1 text-nowrap rounded-full bg-[#8712CF] px-[6px] py-[2px] text-xs text-white md:px-3 md:py-1">
            <Dynamic component={propertyTypes[props.listing?.property?.type as MagicDoor.Api.PropertyType].icon} class="size-4" />
            <span class="hidden md:block">{t(propertyTypes[props.listing?.property?.type as MagicDoor.Api.PropertyType].name)}</span>
          </div>
        </div>
        <div class="mt-2 flex items-center text-lg font-semibold text-text-level01 md:mt-4">
          <span class="truncate text-sm md:text-lg">{props.listing?.title}</span>
        </div>
        <Tooltip message={<DisplayAddress class="text-white" address={props.listing?.property?.address} />} align="bottom">
          <DisplayAddress
            class="mt-1 hidden truncate text-xs leading-5 text-text-level03 md:block"
            address={props.listing?.property?.address}
          />
        </Tooltip>
        <DisplayAddress
          class="mt-[2px] block truncate text-nowrap text-xs leading-5 text-text-level03 md:mt-1 md:hidden"
          address={props.listing?.property?.address}
        />

        <div class="my-2 h-[0.5px] w-full bg-[#ECEEF4] md:my-3 md:bg-[#BABABA]" />
        <div class="mb-1 flex items-center justify-between">
          <div class="flex items-center gap-2">
            <ListingIs listed={props.listing?.listed} />
            <div class="text-xxs text-text-level03 md:text-xs">
              {t('Updated')} {dateFormat('', props.listing?.lastUpdated)}
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full items-center gap-2 p-2">
        <div class="text-xs text-text-level03">{t('Recent rental applications')}: </div>
        <div class="text-xs text-text-level01 md:text-sm">{props.listing?.recentRentalApplications}</div>
      </div>
    </div>
  );
};

export default ListingCard;
