import { useParams } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import IconDate from '~/assets/images/leases/date.svg';
import IconPaymentType from '~/assets/images/payment/paymentType.svg';
import { typeMap, getAutoPaymentStatus, getAutoPaymentAmount } from '~/components/auto-payments';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import { useLeaseAutoPayments } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { printHtml } from '~/utils/printHtml';
import type { Component, JSX } from 'solid-js';

export interface AutoPaymentDetailsModalProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
}

export const FieldItem: Component<{ label: string; icon?: string; value: JSX.Element; class?: string }> = (props) => (
  <div class={`space-y-1.5 ${props.class}`}>
    <h5 class="text-xs uppercase text-[#464f74]">{props.label}</h5>
    <div class="flex items-center gap-2 text-sm">
      <Show when={props.icon}>
        <img src={props.icon} class="shrink-0" />
      </Show>
      <div class="w-full truncate">{props.value}</div>
    </div>
  </div>
);

export const AutoPaymentDetailsModal = (props: AutoPaymentDetailsModalProps) => {
  const params = useParams();
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { autoPayments } = useLeaseAutoPayments();
  const data = createMemo(() => autoPayments().find((autoPayment) => autoPayment.id === params.autoPaymentId));
  let printContainer: HTMLDivElement | undefined;
  const doneText = (
    <div class="flex items-center">
      <PrintIcon />
      {t('Print')}
    </div>
  );

  const onDone = () => {
    printHtml(printContainer);
  };

  const onCancel = () => {
    goBack();
  };

  return (
    <Show when={data() && props.lease}>
      <Modal
        size="md"
        title={t('Tenant Auto Payment Details')}
        visible
        confirmation={false}
        onDone={onDone}
        onCancel={onCancel}
        doneText={doneText}>
        <div ref={printContainer}>
          <div class="space-y-6 px-8 py-6">
            <LeaseOverviewBanner lease={props.lease} />
            <div class="grid grid-cols-2 gap-3.5 text-title-gray">
              <FieldItem
                label={t('Payment type')}
                icon={IconPaymentType}
                value={typeMap[data()?.type as MagicDoor.Api.TenantAutoPayType]}
              />
              <FieldItem label={t('Status')} value={t(getAutoPaymentStatus(data() as MagicDoor.Api.HydratedTenantAutoPayDto).label)} />
              <FieldItem label={t('Amount')} value={getAutoPaymentAmount(t, data() as MagicDoor.Api.HydratedTenantAutoPayDto)} />
              <FieldItem
                label={t('Payment date')}
                icon={IconDate}
                value={`${t('Next payment')}: ${dateFormat('M/D/YY', data()?.nextPayment)}`}
              />
              <FieldItem label={t('Memo')} class="col-span-2" value={<p class="block w-full rounded-lg bg-light-gray px-4 py-2.5">--</p>} />
            </div>
          </div>
        </div>
      </Modal>
    </Show>
  );
};
