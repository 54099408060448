export enum TaxDocumentType {
  MISC1099 = 'misc',
  NEC1099 = 'nec',
}

export enum TaxDocumentStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
  NotFound = 'notFound',
}

export interface TaxDocument {
  id: string;
  calendarYear: number;
  entityId: string;
  entityName: string;
  amount: number;
  taxDocumentType: TaxDocumentType;
  url: string;
  documentName: string;
  taskId: string;
  status: TaxDocumentStatus;
  taskErrorMessage: string;
  createdAt: string;
  updatedAt: string;
}

export interface PaginatedTaxDocuments {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalItems: number;
  items: TaxDocument[];
}
