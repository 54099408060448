import { createEffect, createSignal, onCleanup, onMount, Show } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import SectionedTableView from '~/components/common/SectionedTableView';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import TaxYearHeader from '~/pages/vendors/components/TaxYearHeader';
import VendorTaxDocumentsCell from '~/pages/vendors/components/VendorTaxDocumentsCell';
import { MergedTaxDocPdfPresenter, YearlyVendorTaxDocPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { DeleteMergedPdfUseCase } from '~/pdfsigner/usecases/taxes/deleteMergedPdfUseCase';
import { GetNextTaxDocumentBatchUseCase } from '~/pdfsigner/usecases/taxes/getNextTaxDocumentBatchUseCase';
import { GetTaxDocumentsUseCase } from '~/pdfsigner/usecases/taxes/getTaxDocumentsUseCase';
import { GetVendorTaxAmountsUseCase } from '~/pdfsigner/usecases/taxes/getVendorsTaxAmountsUseCase';
import { PrintAllVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/printAllVendorTaxDocsUseCase';
import { RegenerateVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/regenerateVendorTaxDocsUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { printFileBlob, resetPrintSystem } from '~/utils/print';
import { VendorTaxAmountsPage } from './VendorTaxAmountsPage';
import type { PresentableTaxDocument } from '~/pdfsigner/ui/types/presentableTaxDocs';
import type {
  GetTaxDocumentsRequest,
  NextTaxDocumentBatchRequest,
  TaxYearDocumentRequest,
} from '~/pdfsigner/usecases/types/taxDocumentRequests';

export const TaxDocumentsPage = () => {
  const { t } = useLocalization();
  const { execute: getTaxDocuments, isLoading } = useUseCase(GetTaxDocumentsUseCase);
  const { execute: getNextTaxDocBatch } = useUseCase(GetNextTaxDocumentBatchUseCase);
  const { execute: regenerateTaxDoc, isLoading: isRegenerating, didSucceed } = useUseCase(RegenerateVendorTaxDocsUseCase);
  const { execute: printTaxDocs, isLoading: isPrinting } = useUseCase(PrintAllVendorTaxDocsUseCase);
  const { execute: deleteMergedPdf } = useUseCase(DeleteMergedPdfUseCase);
  const { execute: getVendorTaxAmount } = useUseCase(GetVendorTaxAmountsUseCase);
  const { model: yearlyVendorTaxDocuments } = usePresenter(YearlyVendorTaxDocPresenter);
  const { model: mergedPdf } = usePresenter(MergedTaxDocPdfPresenter);
  const [printIFrame, setPrintIFrame] = createSignal<HTMLIFrameElement>();
  const [yearDocument, setYearDocument] = createSignal<TaxYearDocumentRequest>();

  const onPrint = async (year: number) => {
    await printTaxDocs({ year, entityType: TaxEntityTypes.Vendor });
  };

  createEffect(() => {
    const mergedPdfBlob = mergedPdf()?.pdf;
    if (mergedPdfBlob) {
      const newIframe = printFileBlob(mergedPdfBlob);
      setPrintIFrame(newIframe);
      onCleanup(() => {
        resetPrintSystem(printIFrame());
      });
    } else {
      setPrintIFrame(undefined);
    }
  });

  onMount(() => getTaxDocuments({ entityType: TaxEntityTypes.Vendor, page: 1 } as GetTaxDocumentsRequest));

  onCleanup(() => {
    deleteMergedPdf();
  });

  const onCellScrolledIntoView = async (index: number) => {
    await getNextTaxDocBatch({ entityType: TaxEntityTypes.Vendor, currentIndex: index } as NextTaxDocumentBatchRequest);
  };

  const onRedoClicked = async (year: string, document: PresentableTaxDocument) => {
    await getVendorTaxAmount({ year, vendorId: document.entityId });
    setYearDocument({ year: Number(year), vendorId: document.entityId });
  };

  const onRegenerateClicked = async () => {
    await regenerateTaxDoc(yearDocument()!);
  };

  createEffect(() => {
    if (didSucceed()) {
      setYearDocument(undefined);
    }
  });

  return (
    <>
      <div class="flex size-full flex-col">
        <div class="mb-5 flex w-full items-center justify-between gap-4 rounded-lg border border-partingline bg-white px-6 py-3 text-lg font-semibold">
          {t('Statements')}
          {/* <div>
            <LinkButton href="/maintenance/vendors/tax-documents/generate" noScroll>
              <IconCirclePlus class="size-5" />
              {t('Generate')}
            </LinkButton>
          </div> */}
        </div>
        <Show
          when={!isLoading()}
          fallback={
            <div class="flex size-full flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" size={100} showIcon={true} />
            </div>
          }>
          <Show
            when={yearlyVendorTaxDocuments() && yearlyVendorTaxDocuments()!.length > 0}
            fallback={
              <div class="flex size-full grow items-center justify-center rounded-lg border border-partingline bg-white p-6 text-lg text-gray-600">
                {t('No statements available')}
              </div>
            }>
            <SectionedTableView
              class="min-w-min rounded-lg"
              sectionClass="mb-4 min-w-min rounded-lg border border-partingline bg-white p-4"
              numberOfSections={() => yearlyVendorTaxDocuments()?.length || 0}
              numberOfRowsInSection={(section) => yearlyVendorTaxDocuments()?.[section].vendors.length || 0}
              headerForSection={(section) => (
                <TaxYearHeader
                  class="pl-2 text-2xl font-bold text-gray-800"
                  year={yearlyVendorTaxDocuments()?.[section].year || ''}
                  shouldShowPrintButton={true}
                  onPrint={onPrint}
                />
              )}
              rowForSection={(section, row) => {
                return (
                  <VendorTaxDocumentsCell
                    year={yearlyVendorTaxDocuments()?.[section].year || ''}
                    class="m-4 rounded-md border border-gray-300 bg-white p-4"
                    vendorName={yearlyVendorTaxDocuments()?.[section].vendors[row].vendorName || ''}
                    taxDocuments={yearlyVendorTaxDocuments()?.[section].vendors[row].taxDocuments || []}
                    onRowDidScrollIntoView={onCellScrolledIntoView}
                    onRedo={onRedoClicked}
                  />
                );
              }}
            />
          </Show>
        </Show>
      </div>
      <Show when={yearDocument()}>
        <Modal
          visible
          class="lg"
          title={`${t('Redo tax document')}`}
          onCancel={() => setYearDocument(undefined)}
          onDone={onRegenerateClicked}
          showFooter={!isRegenerating()}>
          <Show
            when={!isRegenerating()}
            fallback={
              <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
                <RingLoader color="#a126ec" size={100} showIcon={true} />
              </div>
            }>
            <VendorTaxAmountsPage class="px-5" />
          </Show>
        </Modal>
      </Show>
      <Show when={isPrinting()}>
        <Modal visible showFooter={false} class="lg" title={`${t('Printing')}...`}>
          <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" size={100} showIcon={true} />
          </div>
        </Modal>
      </Show>
    </>
  );
};
