import { Route, Routes } from '@solidjs/router';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { OverviewTab } from './tabs/OverviewTab';
import { OwnerChecksTab } from './tabs/OwnerChecksTab';
import { VendorChecksTab } from './tabs/VendorChecksTab';

export const ChecksTabRoutes = () => {
  const { t } = useLocalization();

  return (
    <div class="relative size-full h-section p-8">
      <TabLinks
        class="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          { label: t('Overview'), href: '' },
          { label: t('Vendor checks'), href: `vendor` },
          { label: t('Owner checks'), href: `owner` },
        ]}
      />
      <Routes>
        <Route path="/" component={OverviewTab} />
        <Route path="/vendor" component={VendorChecksTab} />
        <Route path="/owner" component={OwnerChecksTab} />
      </Routes>
    </div>
  );
};
