import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

interface LabeledInputProps extends LabeledGroupProps {
  placeholder?: string;
  value?: string;
  onInput?: (e?: string) => void;
  onChange?: (e?: string) => void;
  onClick?: (isMouseDown: boolean) => void;
  prefix?: string;
  prefixClass?: string;
  suffix?: string;
  suffixClass?: string;
  inputClass?: string;
  isBlur?: boolean;
  disabled?: boolean;
}

const LabeledInput: Component<LabeledInputProps> = (props) => {
  let inputRef: undefined | HTMLInputElement;

  const handleInput = (e?: string) => {
    props.onInput?.(e);
  };

  const handleChange = (e?: string) => {
    props.onChange?.(e);
  };

  return (
    <LabeledGroup {...props} isInvalid={false} error={props.error}>
      <div class="flex w-full items-center rounded-md border bg-inputbox-bg focus-within:ring-1 focus-within:ring-essential-colour">
        <Show when={props.prefix}>
          <span class={cn('flex items-center border-r border-[#5551] px-3 text-text-level03', props.prefixClass)}>{props.prefix}</span>
        </Show>
        <input
          id={props.id}
          ref={inputRef}
          class={cn(
            'w-full rounded-md border border-transparent bg-inputbox-bg px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text',
            { 'rounded-r-none': !!props.suffix },
            props.inputClass
          )}
          value={props.value}
          onChange={(e) => handleChange(e.target.value)}
          onInput={(e) => handleInput(e.target.value)}
          placeholder={props.placeholder}
          onMouseDown={() => props.onClick && props.onClick(true)}
          onMouseUp={() => props.onClick && props.onClick(false)}
          disabled={props.disabled}
        />
        <Show when={props.suffix}>
          <div class={cn('mr-2 w-10 bg-inputbox-bg text-center text-text-level03', props.suffixClass)}>{props.suffix}</div>
        </Show>
      </div>
    </LabeledGroup>
  );
};
export default LabeledInput;
