import { createSignal, Show, For } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { Modal } from '~/components/modals/Modal';
import { PaymentStatusIndicator } from '~/components/rental-application/components/StatusIndicators';
import { paymentStatusIndicatorsArray } from '~/components/rental-application/components/constants';
import { TransactionStatus } from '~/components/transactions';
import { IconAlert } from '~/components/ui/Icons';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { printDocument } from '~/utils/print';
import type { Component } from 'solid-js';
import type { RentalApplicationProps } from '~/components/rental-application/components/constants';

export const PaymentStatus: Component<RentalApplicationProps> = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();
  const [paymentModalVisible, setPaymentModalVisible] = createSignal<boolean>(false);

  const handlePrint = () => {
    printDocument(printContainer()!);
  };
  const printBtn = () => {
    return (
      <div class="flex items-center">
        <PrintIcon class="mr-1 size-5" />
        {t('Print')}
      </div>
    );
  };
  const modelItems = [
    {
      label: t('MagicDoor transaction id'),
      value: applicationStore.application?.id,
    },
    {
      label: t('External transaction type'),
      value: applicationStore.application?.rentalApplicationTransactionDto?.externalTransactionType,
    },
    {
      label: t('External transaction ID'),
      value: applicationStore.application?.rentalApplicationTransactionDto?.externalTransactionId,
    },
    {
      label: t('Payment date'),
      value: dateFormat('DD/MM/YYYY', applicationStore.application?.rentalApplicationTransactionDto?.paymentDate),
    },
    {
      label: t('Amount'),
      value: currency(applicationStore.application?.rentalApplicationTransactionDto?.transactionAmount),
    },
    {
      label: t('Status'),
      value: <TransactionStatus status={applicationStore.application?.rentalApplicationTransactionDto?.transactionStatus} />,
    },
  ];

  return (
    <div class="relative h-fit grow rounded-lg border border-partingline capitalize sm:w-full lg:my-6 lg:w-auto ">
      <div class="flex h-11 items-center justify-between border-b p-3 text-sm font-medium capitalize text-text-level01">
        <div class="flex items-center justify-between">
          {t('Payment status')}
          <div class="pl-6">
            <PaymentStatusIndicator
              {...(paymentStatusIndicatorsArray.find(
                (item) => item.label === applicationStore.application?.paymentStatus
              ) as (typeof paymentStatusIndicatorsArray)[0])}
            />
          </div>
        </div>
        <div class="flex cursor-pointer items-center text-text-level03" onClick={() => setPaymentModalVisible(true)}>
          <IconAlert class="size-4" />
          <span class="ml-1 text-xs">{t('Transaction details')}</span>
        </div>
      </div>

      <div class="min-h-20 divide-y">
        <div class="space-y-2 p-4">
          <div class="flex items-center justify-between">
            <span class="text-sm text-gray-600">{t('Payment amount')}</span>
            <Show when={applicationStore.application?.rentalApplicationTransactionDto?.transactionAmount} fallback={emptyPlaceholder}>
              <span class="text-sm font-medium text-gray-900">
                {currency(applicationStore.application?.rentalApplicationTransactionDto?.transactionAmount)}
              </span>
            </Show>
          </div>

          <Show when={applicationStore.application?.paymentStatus === 'refunded'}>
            <div class="flex items-center justify-between">
              <span class="text-sm text-gray-600">{t('Refund amount')}</span>
              <span class="text-sm font-medium text-red-500">
                {!applicationStore.application?.refundAmount ? emptyPlaceholder : currency(applicationStore.application?.refundAmount)}
              </span>
            </div>
          </Show>
        </div>
      </div>
      <Modal
        visible={paymentModalVisible()}
        onCancel={() => setPaymentModalVisible(false)}
        title={t('Rental application payment details')}
        onDone={handlePrint}
        doneText={printBtn()}>
        <div class="grid grid-cols-2 px-8 py-7 capitalize" ref={(e) => setPrintContainer(e)}>
          <For each={modelItems}>
            {(item) => (
              <div class="mb-8">
                <div class="mb-2 text-xs text-text-level02">{item.label}</div>
                <div class="text-sm font-medium text-title-gray">{item.value}</div>
              </div>
            )}
          </For>
        </div>
      </Modal>
    </div>
  );
};
