import { createSignal, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconGeneral from '~/assets/images/settings/rental/general.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChatsSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const ChatAiMaintenanceRequests = () => {
  const { t } = useLocalization();
  const {
    allChatAIMaintenanceRequestsSettings,
    updateChatAIMaintenanceRequestsSettings,
    updateChatAIMaintenanceRequestsSettingsByLevel,
    deleteChatAIMaintenanceRequestsSettingsByLevel,
  } = useChatsSettings();
  const [showModal, setShowModal] = createSignal<boolean>(false);

  const onClose = () => {
    setShowModal(false);
  };

  const onDone = async (level: string, levelId: string) => {
    await updateChatAIMaintenanceRequestsSettingsByLevel(level, levelId, {
      ...(allChatAIMaintenanceRequestsSettings()?.settings ?? {
        enabled: false,
        additionalInstructions: '',
      }),
    });
    setShowModal(false);
  };

  const sections = [
    {
      field: 'enabled',
      label: 'Enable chat AI maintenance requests',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
      rules: [],
    },
    {
      field: 'additionalInstructions',
      label: 'Additional instructions',
      type: 'textarea',
      class: 'col-span-2',
      description:
        'This AI monitors chat messages for new maintenance requests. You can provide it with instructions specifying when it should not create a maintenance request and any additional questions it should ask before doing so.',
      labelContainerClass: 'flex-col gap-2',
      labelClass: 'w-full',
      rows: 3,
      rules: [
        { length: 4000, message: t('{name} must be {length} characters or less', { name: 'Additional instructions', length: '4000' }) },
      ],
    },
  ];

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteChatAIMaintenanceRequestsSettingsByLevel(level, levelId);
        toast(t('Chat AI maintenance requests has been deleted successfully'), 'success');
      },
    });
  };

  const handleChangeData = async (data: any, level?: string, levelId?: string) => {
    if (level && levelId) {
      updateChatAIMaintenanceRequestsSettingsByLevel(level, levelId, {
        ...data,
      });
    } else {
      updateChatAIMaintenanceRequestsSettings({
        ...data,
      });
    }
    toast(t('Settings have been updated successfully'), 'success');
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Chat AI maintenance requests')} icon={IconGeneral} />}
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm<MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDto>
                sections={sections}
                onOk={(data) => handleChangeData(data)}
                model={
                  allChatAIMaintenanceRequestsSettings()?.settings ?? {
                    enabled: false,
                    additionalInstructions: '',
                  }
                }
              />
            </div>

            <Overrides<MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverrideDto>
              overrides={allChatAIMaintenanceRequestsSettings()?.overrides || []}
              onDelete={onDelete}
              title={t('Chat AI maintenance requests')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm<MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDto>
                      sections={sections}
                      onOk={(data) => handleChangeData(data, override.level, override.levelId)}
                      model={override.settings as MagicDoor.Api.ChatAIMaintenanceRequestsSettingsDto}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />

      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allChatAIMaintenanceRequestsSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={allChatAIMaintenanceRequestsSettings.loading}
        />
      </Show>
    </>
  );
};
