import { InvalidTaxAmountError } from '~/errors/invalidTaxAmountError';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { VendorIncomeUpdateRequest } from '~/pdfsigner/usecases/types/vendorTaxAmount';

export class UpdateVendorIncomeAmountUseCase extends MagicUseCase {
  public async runLogic(request: VendorIncomeUpdateRequest) {
    const amountNum = parseFloat(request.amount);
    if (isNaN(amountNum) || amountNum < 0) {
      throw new InvalidTaxAmountError();
    }
    this.setVendorTaxAmount(request.vendorId, amountNum);
  }

  private setVendorTaxAmount(vendorId: string, amount: number) {
    this.getState().taxInfo.vendorsAndAmounts.forEach((vendor) => {
      if (vendor.vendorId === vendorId) {
        vendor.taxAmount = amount;
      }
    });
  }
}
