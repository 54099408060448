import { useUnits } from '~/contexts/units';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { LeaseTenantRepository } from '~/repositories/leaseTenantRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { SuggestedEndDateResponse } from '~/repositories/leaseRepository';

export const [LeaseProvider, useLease] = createMagicDoorContext('Lease', () => {
  const { refetchUnit, refetchUnitsIf, unit } = useUnits();

  const [lease, _, { mutate: setLease, forceGet: getLease }] = createTriggerResource((leaseId: string) => repo.getLease(leaseId));

  const repo = new LeaseRepository();
  const tenantRepo = new LeaseTenantRepository();

  const addLease = async (payload: MagicDoor.Api.CreateLeaseDto) => {
    const added = await repo.addLease(payload);
    refetchUnit(added.unitId);
    return added;
  };

  const moveoutTenant = async (leaseId: string, tenantId: string) => {
    await tenantRepo.deleteLeaseTenant(leaseId, tenantId);
    if (lease()) {
      setLease(
        (prev) =>
          ({
            ...prev,
            tenants: prev?.tenants?.filter((t) => t.tenant?.id !== tenantId),
          } as MagicDoor.Api.HydratedLeaseDto)
      );
    }
  };

  const editLease = async (leaseId: string, payload: MagicDoor.Api.UpdateLeaseDto) => {
    const updated = await repo.editLease(leaseId, payload);
    getLease(leaseId);
    refetchUnit(updated.unitId);
    return updated;
  };

  const deleteLease = async (leaseId: string) => {
    await repo.deleteLease(leaseId);
    (async function refetchUnitsAndSetLease() {
      await refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
      const nextLeaseId = unit()?.activeLeases?.[0]?.id;
      if (nextLeaseId) getLease(nextLeaseId);
    })();
  };

  const eviction = async (leaseId: string, payload: MagicDoor.Api.UpdateLeaseEvictionStatusDto) => {
    await repo.eviction(leaseId, payload);
    refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
  };

  const endLease = async (leaseId: string) => {
    await repo.endLease(leaseId);
    refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
  };

  const reactivateLease = async (leaseId: string) => {
    await repo.reactivateLease(leaseId);
    refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
  };

  const suggestLeaseEndDate = (startDate: string): Promise<SuggestedEndDateResponse> => {
    return repo.suggestedLeaseEndDate(startDate);
  };

  const getLeaseOverlapping = (unitId: string, startTime: string, endTime?: string) => {
    return repo.getLeaseOverlapping(unitId, startTime, endTime);
  };

  const bindTenantToLease = async (leaseId: string, tenantId: string, data: MagicDoor.Api.MoveInDto) => {
    return repo.bindTenantToLease(leaseId, tenantId, data);
  };

  const activateDraft = createMutation(async (leaseId: string) => {
    await repo.activateDraft(leaseId);
    if (lease()?.id === leaseId) getLease(leaseId);
    refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
  });

  const getNextChargeDate = async (leaseStartDate: string) => {
    const res = await repo.getNextChargeDate(leaseStartDate);
    if (res.date) {
      return res.date;
    }
  };

  return {
    lease,
    loading: () => lease.loading,
    addLease,
    getLease,
    moveoutTenant,
    editLease,
    deleteLease,
    eviction,
    suggestLeaseEndDate,
    setLease,
    getLeaseOverlapping,
    endLease,
    reactivateLease,
    bindTenantToLease,
    activateDraft,
    getNextChargeDate,
  };
});
