import { FormItem } from '~/components/common/BetterForm';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RentalApplicationDocuments } from '~/components/rental-application/rental-application-details/renter-report-tab/documents/DocumentsForm';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { ssn } from '~/utils/formRules';

export const Identity = () => {
  const { t } = useLocalization();
  const { setStore } = useNewRentalApplication();
  return (
    <>
      <Section required border title={t('Income verification')}>
        <div class="flex flex-col gap-y-8">
          <FormItem
            id="new-rental-application-step4-ssn"
            placeholder={t('Social security number')}
            label={t('Social security number (ssn)')}
            formFieldName={['ssn']}
            component={LabeledTextInput}
            rules={ssn(t)}
          />
          <FormItem
            id="new-rental-application-step4-files"
            formFieldName="files"
            component={RentalApplicationDocuments}
            beforeUpload={() => setStore({ disableNext: true })}
            afterUpload={() => setStore({ disableNext: false })}
          />
        </div>
      </Section>
    </>
  );
};
