import { For, Show, createMemo } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconPrincipals from '~/assets/images/settings/company/principals.svg';
import { useFormContext } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { ExpandablePrincipalItem, PrincipalItem } from './PrincipalItem';

export const CompanyPrincipalsInformation = () => {
  const { t } = useLocalization();
  const form = useFormContext();

  const otherPrincipals = createMemo(() => {
    return form.formStore.principals?.slice(1);
  });

  const handleAddPrincipal = () => {
    // eslint-disable-next-line solid/reactivity
    form.setFormStore('principals', (prev: MagicDoor.Api.UpdateCompanyPrincipalDto[]) => [...prev, {}]);
  };

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Principals')} icon={IconPrincipals} />}
      topAction={
        <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={handleAddPrincipal}>
          <IconPlus />
          {t('Add another one')}
        </Button>
      }
      middle={
        <div>
          <PrincipalItem principal={form.formStore.principals[0]} index={0} />

          <Show when={otherPrincipals()?.length}>
            <div class="my-4 px-4 font-semibold text-text-level01">{t('Another principals')}</div>
            <div class="flex flex-col gap-2">
              <For each={otherPrincipals()}>
                {(principal, index) => <ExpandablePrincipalItem principal={principal} index={index() + 1} />}
              </For>
            </div>
          </Show>
        </div>
      }
    />
  );
};
