import { createSignal } from 'solid-js';
import startEvictionBg from '~/assets/images/units/startEvictionBg.png';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeasesList } from '~/contexts/local';
import { useGoBack } from '~/hooks';

export const LeaseStartEvictionModal = () => {
  const goBack = useGoBack();

  const { t } = useLocalization();
  const { lease, eviction, getLease } = useLease();
  const { refetchLeases } = useLeasesList();

  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  const startEviction = async () => {
    const leaseId = lease()?.id;
    if (!leaseId) return;
    setModalLoading(true);
    try {
      await eviction(leaseId, {
        eviction: true,
      });
      getLease(leaseId);
      refetchLeases();
      goBack();
      toast.success(t('Operation successful'));
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      onDone={startEviction}
      visible={true}
      title={t('Start eviction')}
      doneText={t('Confirm')}
      loading={modalLoading()}
      onCancel={() => goBack()}
      confirmation={false}>
      <div>
        <img class="size-full h-24" src={startEvictionBg} alt="endLeaseBg" />
        <div class="my-2 ml-8">{t('Please confirm to start eviction')}</div>
      </div>
    </Modal>
  );
};
