import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import { ChangeTaxYearUseCase } from './changeTaxYearUseCase';

export class GetAvailableTaxYearsUseCase extends MagicUseCase {
  public async runLogic(vendorId?: string) {
    this.getState().taxInfo.availableYears = await taxDocumentsRepository.getAvailableTaxYears();
    await new ChangeTaxYearUseCase().execute({
      year: this.getState().taxInfo.availableYears[0],
      vendorId,
    });
  }
}
