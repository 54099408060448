import { createMemo, For, Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import CheckboxComponent from '~/components/common/Inputs/CheckboxComponent';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { BankAccountType } from '~/swagger/Api';
import { bankAccountName, bankAccountNumber, bankAccountRoutingNumber, bankAccountType } from '~/utils/formRules';
import { isEmptyData } from '~/utils/tool';
import type { Component, JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { InputProps } from '~/components/common/Inputs/LabeledTextInput';
interface LabeledBankingInputProps extends LabeledGroupProps {
  hasError?: boolean;
  onClick?: (isMouseDown: boolean) => void;
  fieldName?: string;
  required?: boolean;
}

export const LabeledBankingAccountType: Component<{
  value?: string;
  options: { label: string; value: string }[];
  error?: string;
  onInput?: (val: string) => void;
  onClick: InputProps['onClick'];
  labelJSX?: JSX.Element;
}> = (props) => {
  const handleAccountChange = (value: string) => {
    props.onInput?.(value === props.value ? '' : value);
  };

  return (
    <div class="flex flex-col gap-2">
      <div class="text-sm text-label">{props.labelJSX}</div>
      <div class="flex w-full gap-5">
        <For each={props.options}>
          {(option) => (
            <CheckboxComponent
              value={option.value}
              checked={props.value === option.value}
              onClick={(ismodown) => {
                {
                  handleAccountChange(option.value);
                  props.onClick?.(ismodown);
                }
              }}
              label={option.label}
            />
          )}
        </For>
      </div>

      <Show when={props.error}>
        <div class="text-xs text-red-500">{props.error}</div>
      </Show>
    </div>
  );
};

const LabeledBankingInput: Component<LabeledBankingInputProps> = (props) => {
  const { t } = useLocalization();

  const form = useFormContext();
  const shouldThisFieldBeRequired = createMemo(() => {
    return !isEmptyData(props.fieldName ? form.formStore[props.fieldName] : {});
  });

  return (
    <div class="flex flex-col gap-5 py-6">
      <div class="flex gap-5">
        <div class="w-1/2">
          <FormItem
            label={t('Bank name')}
            placeholder={t('Enter bank name')}
            onClick={props.onClick}
            formFieldName={props.fieldName ? [props.fieldName, 'bankName'] : 'bankName'}
            component={LabeledTextInput}
            rules={bankAccountName(t, props.required)}
          />
        </div>
        <div class="w-1/2">
          <FormItem
            label={t('Account number')}
            onClick={props.onClick}
            placeholder={t('Enter account number')}
            formFieldName={props.fieldName ? [props.fieldName, 'accountNumber'] : 'accountNumber'}
            component={LabeledTextInput}
            rules={bankAccountNumber(t, shouldThisFieldBeRequired() || props.required)}
          />
        </div>
      </div>

      <div class="flex  gap-5">
        <div class="w-1/2">
          <FormItem
            label={t('Routing number')}
            onClick={props.onClick}
            placeholder={t('Enter routing number')}
            formFieldName={props.fieldName ? [props.fieldName, 'routingNumber'] : 'routingNumber'}
            component={LabeledTextInput}
            rules={bankAccountRoutingNumber(t, shouldThisFieldBeRequired() || props.required)}
          />
        </div>

        <div class="w-1/2" />
      </div>

      <div class="w-full">
        <FormItem
          onClick={props.onClick}
          formFieldName={props.fieldName ? [props.fieldName, 'accountType'] : 'accountType'}
          component={LabeledBankingAccountType}
          options={Object.entries(BankAccountType).map(([key, value]) => ({ label: t(key), value }))}
          rules={bankAccountType(t, shouldThisFieldBeRequired() || props.required)}
          class="px-2"
          label={t('Account type')}
        />
      </div>
    </div>
  );
};
export default LabeledBankingInput;
