import { Show } from 'solid-js';
import { HeadLayout } from '~/components/head-layout';
import { PropertyTitle } from '~/components/properties';
import { Warning } from '~/components/warning';
import { useProperties, useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';

export const PropertySummary = () => {
  const goBack = useGoBack();
  const { current, loading } = useProperties();
  const { t } = useLocalization();

  return (
    <>
      <Show when={!loading() && !current()?.active}>
        <Warning text={t('This property has been deactivated and is no longer operational.')} />
      </Show>
      <HeadLayout type="property" class="bg-essential-colour" backLink={() => goBack()}>
        <PropertyTitle
          showType={true}
          responsiveType={true}
          property={loading() ? undefined : current() || undefined}
          class="w-full text-lg font-semibold text-white"
        />
      </HeadLayout>
    </>
  );
};
