import { onMount } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { LeaseOrTenant, useAddOrEditMaintenanceModalContext } from './Context';

export const SelectLeaseOrTenant = (props: { initialLeaseId?: string }) => {
  const { t } = useLocalization();

  const form = useFormContext();

  const { setStore } = useAddOrEditMaintenanceModalContext();
  const { getLease, lease } = useLease();

  onMount(() => {
    if (props.initialLeaseId) {
      setStore({ isLeaseOrTenant: LeaseOrTenant.Lease });
      form.setFieldValue('leaseId', props.initialLeaseId);
      fetchLeaseDetails(props.initialLeaseId);
    }
  });

  const fetchLeaseDetails = async (leaseId: string) => {
    try {
      await getLease(leaseId);
      setStore({ isSelected: lease() });
    } catch (error) {
      console.error('Error fetching lease details:', error);
    }
  };

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        label={t(`Tenant`)}
        component={LabeledLeveledSearchSelect}
        rules={[
          {
            validator: () => {
              return !!form.formStore.requesterId;
            },
            message: t(`Please select tenant`),
          },
        ]}
        required
        formFieldName="requesterId"
        placeholder={t('Please select tenant')}
        enabledTypes={[CustomLevelSearch.Tenant]}
        singleTypeOnly
        onChange={(_, varints) => {
          if (varints.singleType === CustomLevelSearch.Tenant) {
            form.setFormStore({
              leaseId: varints.leveledIds[0].leaseId,
              requesterId: varints.leveledIds[0].tenantId,
            });
          } else {
            form.setFormStore({
              leaseId: undefined,
              requesterId: undefined,
            });
          }
        }}
      />
    </div>
  );
};
