import { Route, Routes } from '@solidjs/router';
import { Show } from 'solid-js';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { MovePortfolioModal } from '~/pages/portfolios/modals/MovePortfolioModal';
import { PropertiesRoutes } from '~/pages/properties';
import { PortfolioLayout } from './PortfolioLayout';
import { PortfolioOverview } from './PortfolioOverview';
import { PortfolioAnnouncement } from './components/PortfolioAnnouncement';
import { AddPortfolioModal } from './modals/AddPortfolioModal';
import { AddPropertyModal } from './modals/AddPropertyModal';
import { DeactivatePortfolioModal } from './modals/DeactivatePortfolioModal';
import { EditPortfolioModal } from './modals/EditPortfolioModal';

export const PortfoliosRoutes = () => {
  const { isMobileView } = useDeviceDetect();
  return (
    <>
      <Routes>
        <Route path="/:portfolioId/properties/*" component={PropertiesRoutes} />
        <Route path="/:portfolioId/add-property" component={AddPropertyModal} />
        <Show when={isMobileView()}>
          <Route path="/:portfolioId/add" component={AddPropertyModal} />
        </Show>
        <Route path="/:portfolioId?" component={PortfolioLayout}>
          <Route path="/announcements" component={PortfolioAnnouncement} />
          <Route path="/*" component={PortfolioOverview} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/add" component={AddPortfolioModal} />
        <Route path="/:portfolioId/edit" component={EditPortfolioModal} />
        <Route path="/:portfolioId/deactivate" component={DeactivatePortfolioModal} />
        <Show when={!isMobileView()}>
          <Route path="/:portfolioId/add" component={AddPropertyModal} />
        </Show>
        <Route path="/:portfolioId/move" component={MovePortfolioModal} />
      </Routes>
    </>
  );
};
