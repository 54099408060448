import { Show, createSignal, mergeProps } from 'solid-js';
import { Portal } from 'solid-js/web';
import { IconX } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { useLockBodyScroll } from '~/utils/hooks';
import ConfirmationModal from './ConfirmationModal';
import type { Component, JSX } from 'solid-js';

interface ModalProps {
  onClose: () => void;
  children: JSX.Element;
  confirmationTitle?: string;
  confirmationMessage?: string;
  cancelText?: string;
  confirmText?: string;
  confirmation?: boolean;
  class?: string;
  bodyClass?: string;
}

const MiddleModal: Component<ModalProps> = (originProps) => {
  const props = mergeProps(
    {
      confirmation: true,
    },
    originProps
  );
  const [showConfirmationModal, setShowConfirmationModal] = createSignal(false);

  const { t } = useLocalization();

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    props.onClose();
  };
  useLockBodyScroll();

  return (
    <Portal>
      <div class="fixed inset-0 z-50 flex items-center justify-center py-8">
        <div
          class="overlay fixed inset-0 bg-black/70"
          onClick={() => (props.confirmation ? setShowConfirmationModal(true) : handleConfirm())}
        />
        <div class={cn('relative flex max-h-full w-11/12 overflow-hidden rounded-lg bg-white lg:w-[889px]', props.class)}>
          <button
            class="absolute right-6 top-3 z-20 rounded-full border bg-white p-1.5 hover:bg-inputbox-bg"
            type="button"
            onClick={() => (props.confirmation ? setShowConfirmationModal(true) : handleConfirm())}>
            <IconX class="size-6 text-text-level03" />
          </button>
          <div class={cn('thinscroll flex flex-1 flex-col overflow-auto', props.bodyClass)}>{props.children}</div>
        </div>
      </div>
      <Show when={showConfirmationModal()}>
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          title={props.confirmationTitle || t('Confirmation')}
          message={props.confirmationMessage || t('Are you sure you want to cancel?')}
          cancelText={props.cancelText || t('Go back')}
          confirmText={props.confirmText || t('Yes')}
        />
      </Show>
    </Portal>
  );
};

export default MiddleModal;
