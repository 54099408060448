import { Section } from '~/components/common/FormSectionTitle';
import { RentalHistoryList } from '~/components/rental-application/components/RentalHistoryList';
import { useLocalization } from '~/contexts/global';

export const RentalHistory = () => {
  const { t } = useLocalization();
  return (
    <>
      <Section required border title={t('Residential History')}>
        <RentalHistoryList />
      </Section>
    </>
  );
};
