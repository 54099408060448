import { createMemo, For, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import MinusIcon from '~/assets/images/common/minus.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { Component, JSX } from 'solid-js';
import type { LabeledInputSearchProps, DefaultOptionItem } from '~/components/common/Inputs/LabeledInputSearch';
import type { EntrySearchProps } from '~/components/ui';

export type MutiLabeledInputSearchProps<T extends { id: unknown }> = Omit<LabeledInputSearchProps<T>, 'value' | 'onSelect'> & {
  addText: string;
  renderSelected?: (item: T) => JSX.Element;
  onSelect: (value: string[], items: T[]) => void;
  searchComponent: Component<EntrySearchProps<T, any>>;
  selectedItems?: T[];
  error?: string;
};

export function MutiLabeledInputSearch<OptionItem extends DefaultOptionItem>(props: MutiLabeledInputSearchProps<OptionItem>) {
  const { t } = useLocalization();
  const [store, setStore] = createStore({
    onAdd: false,
  });

  const selectedItems = createMemo(() => {
    return props.selectedItems || [];
  });

  const getSelectedItemsIds = (items: OptionItem[]) => {
    return items.map((item) => item.id);
  };

  const isAddMore = createMemo(() => {
    return selectedItems().length === 0 || store.onAdd;
  });

  const onChange = (type: 'add' | 'delete', value?: OptionItem | number) => {
    const newItems = selectedItems().slice();
    if (type === 'add' && typeof value === 'object') {
      newItems.push(value);
    }
    if (type === 'delete' && typeof value === 'number') {
      newItems.splice(value, 1);
    }
    props.onSelect?.(getSelectedItemsIds(newItems), newItems);
  };

  return (
    <div>
      <div class="flex gap-2">
        <div class="flex flex-1 flex-col gap-2">
          <For each={selectedItems()}>
            {(item, index) => {
              return (
                <div class="flex gap-1">
                  <div class="flex-1">{props.renderSelected?.(item)}</div>
                  <Button
                    id={`${props.id}-${index()}-delete-btn`}
                    size="sm"
                    onClick={() => {
                      onChange('delete', index());
                    }}
                    variant="text">
                    <MinusIcon />
                  </Button>
                </div>
              );
            }}
          </For>
          <Show when={isAddMore()}>
            <props.searchComponent
              id={props.id}
              onSelect={(option) => {
                const _option = option as OptionItem;
                if (!_option?.id) return;
                onChange('add', _option);
                setStore('onAdd', false);
              }}
              doNotRenderSelected
              exclude={(item) => getSelectedItemsIds(selectedItems()).includes(item.id)}
            />
          </Show>
        </div>

        <div class="flex gap-4">
          <Button
            id={`${props.id}-add-more-btn`}
            size="sm"
            onClick={() => {
              setStore('onAdd', true);
            }}
            disabled={isAddMore()}
            variant="outlined">
            <IconPlus />
            {t(props.addText)}
          </Button>
        </div>
      </div>
      <span class="mt-2 text-left text-xs text-red-500">{props.error}</span>
    </div>
  );
}
