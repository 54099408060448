import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useLocalization } from '~/contexts/global';
import { TermWithTableOfContents } from './TermWithTableOfContents';
import { unionSections, unionTableOfContents } from './TransUnionConstant';

export const TransUnionTerm = (props: { id?: string; title: string; checked: boolean; onInput: (checked: boolean) => void }) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-4">
      <TermWithTableOfContents
        tableOfContents={unionTableOfContents}
        sections={unionSections}
        title={props.title}
        scrollOffset={968}
        header={
          <>
            <h1 class="mb-8 text-3xl font-bold text-gray-900">Terms of Use</h1>
            <div class="mb-8 text-sm text-gray-600">
              Notice: Your use of this web site beyond the home page constitutes acceptance of the Terms of Use.
            </div>
          </>
        }
      />
      <Checkbox
        id={props.id}
        checkBoxClass="items-start"
        name="union-policy"
        required
        showLabel
        labelClass="inline-block whitespace-normal"
        label={t('I have read and accept the terms of service')}
        checked={props.checked}
        onInput={props.onInput}
      />
    </div>
  );
};
