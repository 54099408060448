import { useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import { ChatProvider, ChatsListProvider, ChatsSettingsProvider, FilesListProvider } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { ChatWindow } from '~/pages/chats/chat-details/ChatWindow';

export const ArchiveMessageDetailView = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const params = useParams<{ chatId: string }>();

  const breadcrumbItems = createMemo(() => [
    { label: t('Archive messages'), link: '/communications/archive-messages' },
    { label: t('Archive message detail') },
  ]);

  return (
    <div class="flex size-full flex-col gap-y-4 text-xs">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="flex h-section1 flex-col gap-4 px-8">
        <FilesListProvider entityType={'chat' as MagicDoor.Api.EntityType} entityId={params.chatId as string}>
          <ChatProvider>
            <ChatsListProvider>
              <ChatsSettingsProvider>
                <ChatWindow chatId={params.chatId} source="archive" />
              </ChatsSettingsProvider>
            </ChatsListProvider>
          </ChatProvider>
        </FilesListProvider>
      </div>
    </div>
  );
};
