import { useParams } from '@solidjs/router';
import { createMemo, Show, onMount, createEffect } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import LabeledPaymentInput from '~/components/common/Inputs/form-inputs/LabeledPaymentInput';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { LanguageSelect } from '~/components/languages';
import { toast } from '~/components/ui';
import { useLocalization, useOwners } from '~/contexts/global';
import { useGoBack } from '~/hooks';

export const EditOwner = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const breadcrumbItems = createMemo(() => [{ label: t('Owners'), link: '/users/owners' }, { label: t('Edit owner') }]);

  const form = useForm();
  const params = useParams<{ ownerId: string }>();
  const { current, setCurrentId, updateOwner } = useOwners();

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    const id = params.ownerId || current()?.id;
    if (!validateStatus || !id) throw new Error();
    try {
      await updateOwner(id, form.formStore as MagicDoor.Api.UpdateOwnerDto);
      goBack();
      toast.success(t('{name} has been edited successfully', { name: t('Owner') }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  onMount(() => {
    if (params.ownerId) {
      setCurrentId(params.ownerId);
    }
  });

  createEffect(() => {
    form.setFieldsValue({
      firstName: current()?.firstName,
      lastName: current()?.lastName,
      isCompany: current()?.isCompany,
      email: current()?.email,
      phone: current()?.phone,
      federalTaxInformation: current()?.federalTaxInformation,
      language: current()?.language,
      address: current()?.address,
    });
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <MultiStepsPanel
        onClose={() => goBack()}
        title={t('Edit owner')}
        disableNext={!form.isValidate || current.loading}
        onSubmit={handleSubmit}
        loading={true}>
        <div class="relative">
          <div class="mx-auto  w-10/12 ">
            <Form defaultForm={form} class="text-left">
              <Section title={t('Owner information')}>
                <div class="grid grid-cols-3 gap-5">
                  <FormItem
                    class="col-span-full md:col-span-1"
                    label={t('First name')}
                    placeholder={t('Please enter the {name}', { name: t('First name') })}
                    component={LabeledTextInput}
                    formFieldName="firstName"
                    rules={[
                      { required: true, message: t('{name} is required', { name: t('First name') }) },
                      { length: 150, message: t('{name} must be {length} characters or less', { name: t('First name'), length: '150' }) },
                    ]}
                  />
                  <FormItem
                    class="col-span-full md:col-span-1"
                    label={t('Last name')}
                    placeholder={t('Please enter the {name}', { name: t('Last name') })}
                    component={LabeledTextInput}
                    formFieldName="lastName"
                    rules={[
                      { length: 150, message: t('{name} must be {length} characters or less', { name: t('First name'), length: '150' }) },
                    ]}
                  />
                  <FormItem
                    class="col-span-full md:col-span-1"
                    label={t('Email')}
                    placeholder={t('Please enter the {name}', { name: t('Email') })}
                    component={LabeledTextInput}
                    formFieldName="email"
                    rules={[{ type: 'email', message: t('{name} is invalid', { name: t('Email') }) }]}
                  />
                  <FormItem
                    class="col-span-full md:col-span-1"
                    label={t('Phone')}
                    placeholder={t('Please enter the {name}', { name: t('Phone') })}
                    component={LabeledTextInput}
                    formFieldName="phone"
                    rules={[{ type: 'phone', message: t('{name} is invalid', { name: t('Phone') }) }]}
                  />
                  <FormItem
                    class="col-span-full md:col-span-1"
                    label={t('Language')}
                    placeholder={t('Select language')}
                    onChangeMethodName="onChange"
                    formFieldName="language"
                    component={LanguageSelect}
                  />
                  <FormItem
                    class="col-span-full md:col-span-1"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    label={t('Is company')}
                    placeholder={t('Select')}
                    onChangeMethodName="onChange"
                    formFieldName="isCompany"
                    component={DropdownMenu}
                  />
                </div>
              </Section>

              <ExpandableSection
                headerBorder
                class="px-0 text-base"
                title={
                  <span class="capitalize">
                    {t('Other information')} <span class="text-xs text-text-level03">({t('Optional sections')})</span>
                  </span>
                }>
                <div class="grid grid-cols-3 gap-4 gap-y-2 px-1">
                  <Section class="col-span-3" title={t('Payment information')}>
                    <div class="flex flex-col gap-y-4">
                      <FormItem
                        class="col-span-3"
                        formFieldName="federalTaxInformation"
                        fieldName="federalTaxInformation"
                        component={LabeledPaymentInput}
                      />
                    </div>
                  </Section>

                  <Section class="col-span-3" title={t('Address')}>
                    <div class="flex flex-col gap-y-4">
                      <FormItem class="col-span-3" formFieldName="address" component={LabeledAddressInput} />
                    </div>
                  </Section>
                </div>
              </ExpandableSection>
            </Form>
          </div>

          <Show when={current.loading}>
            <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" />
            </div>
          </Show>
        </div>
      </MultiStepsPanel>
    </div>
  );
};
