import { useNavigate, useParams } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import IconFacts from '~/assets/images/units/listing/factsDefault.png';
import { AiButton } from '~/components/common/Buttons/AiButton';
import { Panel } from '~/components/common/Panels';
import { IconChevronDown } from '~/components/ui/Icons';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { NotSet } from './NotSet';

export const ListingDescriptions = () => {
  const { t } = useLocalization();
  const { unitListingDetail } = useUnitListings();
  const navigate = useNavigate();
  const params = useParams();
  const [isViewed, setIsViewed] = createSignal<boolean>(false);

  return (
    <Panel
      title={
        <div class="flex items-center">
          <span class="mr-2">{t('Master listing description')}</span>
          <Show when={!unitListingDetail()?.description}>
            <NotSet />
          </Show>
        </div>
      }
      class="col-span-full"
      actions={
        <div class="flex items-center gap-2">
          <AiButton
            id="listing-descriptions-magic-ai-btn"
            text={t('Magic AI')}
            onClick={() => navigate(`/listings/${params.portfolioId}/details/${params.listingId}/edit-listing#magic-ai`)}
          />
        </div>
      }>
      <Show
        when={unitListingDetail()?.description}
        fallback={
          <div class="flex w-full flex-col items-center py-16">
            <img src={IconFacts} />
            <p class="mt-1 text-xs normal-case text-text-level03">{t("Click on 'Magic AI' to generate a description")}</p>
          </div>
        }>
        <div class="p-3 text-center text-sm normal-case text-[#575757] md:px-8 md:py-4">
          <div class={isViewed() ? 'text-left' : 'line-clamp-3 text-left md:line-clamp-none'}>{unitListingDetail()?.description}</div>
          {!isViewed() && (
            <div
              class="mt-3 inline-block rounded-full border border-primary/40 px-3 py-1 text-sm text-primary md:hidden"
              onClick={() => setIsViewed(true)}>
              {t('View all')}
              <IconChevronDown class="inline-block size-4" />
            </div>
          )}
        </div>
      </Show>
    </Panel>
  );
};
