import { Route, Routes } from '@solidjs/router';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ListVendorsPage } from './list-vendors';
import { GenerateTaxDocs } from './tax-documents/GenerateTaxDocs';
import { TaxDocumentsPage } from './tax-documents/TaxDocuments';

export const VendorTabRoutes = () => {
  const { t } = useLocalization();

  return (
    <div class="flex grow flex-col p-8">
      <TabLinks
        boxClass="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          { label: t('Overview'), href: location.search },
          { label: t('Tax documents'), href: `tax-documents${location.search}` },
        ]}
      />
      <Routes>
        <Route path="/*" component={ListVendorsPage} />
        <Route path="/tax-documents/generate/:vendorId?" component={GenerateTaxDocs} />
        <Route path="/tax-documents" component={TaxDocumentsPage} />
      </Routes>
    </div>
  );
};
