import { useParams } from '@solidjs/router';
import { DeleteModal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import type { Component } from 'solid-js';

interface DeleteOverwriteModalProps {
  source: 'vendor' | 'propertyManager';
}
export const DeleteOverwriteModal: Component<DeleteOverwriteModalProps> = (props) => {
  const goBack = useGoBack();
  const { t } = useLocalization();
  const params = useParams<{ runbookId: string; overwriteId: string }>();
  const { deletePropertyManagersOverride, deleteVendorOverride } = useRunBooks();

  const handleConfirm = async () => {
    if (props.source === 'vendor') {
      await deleteVendorOverride(params.runbookId, params.overwriteId);
    } else {
      await deletePropertyManagersOverride(params.runbookId, params.overwriteId);
    }
    toast.success(t('{name} has been deleted successfully', { name: t('Overrides') }));
    goBack();
  };

  return (
    <DeleteModal onConfirm={handleConfirm} onCancel={() => goBack()}>
      {t('Are you sure to delete the override?')}
    </DeleteModal>
  );
};
