import { createSignal, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useContributionsSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const Contributions = () => {
  const { t } = useLocalization();
  const { allContributionsSettings, updateContributionsSettings, updateContributionSettingsByLevel, deleteContributionsSettingsByLevel } =
    useContributionsSettings();
  const [showModal, setShowModal] = createSignal<boolean>(false);

  const onClose = () => {
    setShowModal(false);
  };

  const onDone = async (level: string, levelId: string) => {
    const data = allContributionsSettings()?.settings;

    if (!data?.contributionChartOfAccountId) return;
    await updateContributionSettingsByLevel(level, levelId, {
      ...data,
    });
    setShowModal(false);
  };

  const sections = [
    {
      field: 'contributionChartOfAccountId',
      label: 'Contribution chart of account',
      type: 'chartOfAccountSelect',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
  ];

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteContributionsSettingsByLevel(level, levelId);
        toast(t('Contributions has been deleted successfully'), 'success');
      },
    });
  };

  const handleChangeData = async (data: any, level?: string, levelId?: string) => {
    if (level && levelId) {
      updateContributionSettingsByLevel(level, levelId, {
        ...data,
      });
    } else {
      updateContributionsSettings({
        ...data,
      });
    }
    toast(t('Settings have been updated successfully'), 'success');
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Contributions')} />}
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm<MagicDoor.Api.ContributionSettingsDto>
                sections={sections}
                onOk={(data) => handleChangeData(data)}
                model={(allContributionsSettings()?.settings ?? {}) as MagicDoor.Api.ContributionSettingsDto}
              />
            </div>

            <Overrides overrides={allContributionsSettings()?.overrides || []} onDelete={onDelete} title={t('Contributions')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm
                      sections={sections}
                      onOk={(data) => handleChangeData(data, override.level, override.levelId)}
                      model={(override.settings ?? {}) as MagicDoor.Api.ContributionSettingsDto}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />

      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allContributionsSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={allContributionsSettings.loading}
        />
      </Show>
    </>
  );
};
