import { createSignal, onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import TextOverflow from '~/components/common/TextOverflow';
import { PropertyTitle } from '~/components/properties/PropertyTitle';
import { IconSettings, ListView } from '~/components/ui';
import { useLocalization, useManagementFee } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { AddManualManagementFeesModal } from './add-manual-management-fee/AddManualManagementFeesModal';

export const ManagementFeeTab = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('ManagementFeeListView');
  const { filtered, setFilter, deleteManagementFee } = useManagementFee();
  const [isModalOpen, setIsModalOpen] = createSignal<boolean>(false);

  onMount(() => setFilter({ page: page(), pageSize: pageSize() }));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDelete = (id: string) => {
    if (deleteManagementFee.loading) return;
    deleteManagementFee(id);
  };

  return (
    <div class="relative">
      <ListView
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize });
        }}
        title={t('Management fee')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        skeletonSize={10}
        rowLink={(item) => item.id}
        titleActions={
          <div class="flex gap-2">
            <Button class="text-sm capitalize" href="/settings/property/management-fees" variant="outlined">
              <IconSettings class="size-4" />
              {t('Management fee settings')}
            </Button>
            <Button onClick={openModal} class="text-sm capitalize" variant="outlined">
              <IconPlus class="size-5" />
              {t('Add management fees')}
            </Button>
            <Button href={'add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Calculate management fee')}
            </Button>
          </div>
        }
        columns={[
          {
            title: t('Date'),
            render: (item) => <div class="w-24 text-text-level01">{dateFormat('', item.billDate)}</div>,
          },
          {
            title: t('Property'),
            render: (item) => (
              <Show when={item.property} fallback={<span class="text-xs capitalize text-text-level03">{t('Unknown property')}</span>}>
                <PropertyTitle property={item.property ?? undefined} />
              </Show>
            ),
          },
          {
            title: t('Memo'),
            render: (item) => (
              <div>
                <Show when={item.memo} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={item.memo as string} />
                </Show>
              </div>
            ),
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney color="normal" money={item.totalAmount} />,
          },
        ]}
        actions={(item) => [
          {
            label: t('Delete'),
            icon: IconDelete,
            onClick: () => handleDelete(item.id),
          },
        ]}
      />
      <Show when={isModalOpen()}>
        <AddManualManagementFeesModal onClose={closeModal} />
      </Show>
    </div>
  );
};
