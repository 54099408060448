import { useParams, Route, Routes } from '@solidjs/router';
import { Show } from 'solid-js';
import { RentalApplicationDetails } from '~/components/rental-application/rental-application-details/RentalApplicationDetails';
import { AddServiceBillTypePage } from '~/components/service-bill/ServiceBillPage';
import { useProperties } from '~/contexts/global';
import { MaintenanceRequestsProvider, ServiceBillProvider } from '~/contexts/local';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { AddAndEditOwnerPage } from '~/pages/properties/components/AddAndEditOwnerPage';
import { AddUnit } from '~/pages/units/add-unit/AddUnit';
import { UnitDetails } from '~/pages/units/unit-details';
import { PropertyDetailsRoutes } from './view-components/PropertyDetailsRoutes';

export const PropertyDetailsPage = () => {
  const { isMobileView } = useDeviceDetect();
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { setCurrentId } = useProperties();

  setCurrentId(params.propertyId);

  return (
    <div class="flex w-full flex-col gap-y-4 pb-4 text-xs">
      <MaintenanceRequestsProvider>
        <ServiceBillProvider>
          <Routes>
            <Show when={isMobileView()}>
              <Route path={['/units/add', '/units/edit/:unitId', '/units/:unitId/edit-unit']} component={AddUnit} />
              <Route path={['/owners/add', '/owners/:ownerId']} component={AddAndEditOwnerPage} />
              <Route
                path={['/units/addServiceBill/:unitId', '/units/:unitId/add-service-bill']}
                element={<AddServiceBillTypePage type="unit" />}
              />
            </Show>

            <Route path="/units/:unitId/*" component={UnitDetails} matchFilters={{ unitId: /[^addeditaddServiceBill]/ }} />
            <Route path="/application-details/:applicationId/*" component={RentalApplicationDetails} />
            <Route path="/*" component={PropertyDetailsRoutes} />
          </Routes>
        </ServiceBillProvider>
      </MaintenanceRequestsProvider>
    </div>
  );
};
