import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import type { TaxYearDocumentRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class GetVendorTaxAmountsUseCase extends MagicUseCase {
  public async runLogic(request: TaxYearDocumentRequest) {
    const vendorsAndAmounts = await taxDocumentsRepository.getVendorTaxAmountsForYear(request);
    this.getState().taxInfo.vendorsAndAmounts = vendorsAndAmounts;
  }
}
