import { useSearchParams } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import IconNetwork from '~/assets/images/identity/network.png';
import IconRobot from '~/assets/images/identity/robotWand.png';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import LocalizationButton from '~/components/layouts/Navigation/LocalizationButton/LocalizationButton';
import { IconLoader } from '~/components/ui';
import { useAuth, useLocalization } from '~/contexts/global';

const SignInPage = () => {
  const { t } = useLocalization();
  const [query] = useSearchParams<{ redirect?: string }>();

  const [error, setError] = createSignal<string | undefined>();
  const [companies, setCompanies] = createSignal<{ id: string; name: string }[] | undefined>();
  const [showPassword, setShowPassword] = createSignal<boolean>(false);
  const signInForm = useForm();

  const { signIn, isAuthenticated, checkUrlForToken } = useAuth();

  const redirect = () => location.replace(query.redirect || '/');

  createEffect(() => {
    checkUrlForToken();
    if (isAuthenticated()) {
      redirect();
    }
  });

  const handleSubmit = async (store: any) => {
    const email = store.email;
    const password = store.password;
    const remember = store.remember;
    const companyId = store.company;

    if (password?.length < 5) {
      setError(t('{name} must be at least {minLength} characters', { name: t('Password'), minLength: '5' }));
      return;
    }

    const result = await signIn(email, password, companyId, remember);

    if (result.success) {
      return redirect();
    }

    if (result.companies) {
      signInForm.setFieldValue('company', result.companies[0].id);
      return setCompanies(result.companies);
    }

    if (result.error) {
      const errorMap: Record<typeof result.error, string> = {
        invalid_credentials: t('Incorrect email or password'),
        bad_request: t('Email and password are required'),
      };
      setError(errorMap[result.error] || t('Unknown error'));
    }
  };

  return (
    <div class="flex min-h-screen w-full items-center justify-center">
      <div class="flex size-full flex-col items-center justify-between bg-gradient-dark-purple-blue lg:w-2/5 lg:bg-white lg:bg-none ">
        <div />

        <Form
          defaultForm={signInForm}
          onFormSubmit={handleSubmit}
          class="flex w-[450px] flex-col gap-2 rounded-lg border-0 bg-white p-10 text-title-gray shadow-lg lg:p-2 lg:shadow-none">
          <div class="mb-3 flex items-center justify-between pb-3">
            <h1 class="text-3xl font-semibold">
              {t('Login')} <span class="text-essential-colour">MagicDoor</span>
            </h1>
            <LocalizationButton />
          </div>
          <div class={`${companies()?.length ? 'hidden' : 'flex'} flex-col gap-4 text-left`}>
            <div class="flex flex-col gap-1">
              <label class="font-medium text-title-gray" for="email">
                {t('Email')}
                <span class="text-error"> *</span>
              </label>
              <FormItem
                type="email"
                placeholder="mail@magicdoor.com"
                formFieldName="email"
                class="rounded-full border bg-inputbox-bg px-5 py-2.5 outline-0"
                required
                component="input"
                onChangeMethodName="onChange"
                getValueFromEvent={(e) => e.target.value}
              />
            </div>
            <div class="flex flex-col gap-1">
              <label class="font-medium text-title-gray" for="password">
                {t('Password')}
                <span class="text-error"> *</span>
              </label>
              <div class="relative w-full ">
                <FormItem
                  type={showPassword() ? 'text' : 'password'}
                  formFieldName="password"
                  placeholder={t('Enter password')}
                  class="w-full rounded-full border bg-inputbox-bg px-5 py-2.5 outline-0"
                  required
                  component="input"
                  onChangeMethodName="onChange"
                  getValueFromEvent={(e) => e.target.value}
                />
                <label class="absolute right-4 top-1/2 flex -translate-y-1/2 cursor-pointer items-center justify-center fill-[#a5a5b0] text-[30px]">
                  <input
                    type="checkbox"
                    class="size-0 cursor-pointer opacity-0"
                    checked={showPassword()}
                    onClick={() => setShowPassword(!showPassword())}
                  />
                  <svg class={`${showPassword() ? 'hidden' : ''}`} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 576 512">
                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                  </svg>
                  <svg class={`${showPassword() ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 640 512">
                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                  </svg>
                </label>
              </div>
            </div>
            <div class="flex gap-2">
              <FormItem formFieldName="remember" component={Checkbox} checkBoxClass="text-sm" label={t('Remember me')} showLabel={true} />
            </div>
            <div class="flex gap-2">
              <Checkbox
                checkBoxClass="items-start text-sm"
                name="policy"
                checked
                required
                showLabel
                labelClass="inline-block w-[280px] whitespace-normal md:w-[350px]"
                label={
                  <>
                    {t('By signing in, I agree to the')}
                    <a class="m-1 text-blue hover:underline" href="/terms-of-service.pdf" rel="noopener noreferrer" target="_blank">
                      {t('Terms of service')}
                    </a>
                    {t('And')}
                    <a
                      class="ml-1 text-blue hover:underline"
                      href="https://magicdoor.com/privacy-policy/"
                      rel="noopener noreferrer"
                      target="_blank">
                      {t('Privacy policy')}
                    </a>
                  </>
                }
              />
            </div>
          </div>
          <Show when={companies()?.length}>
            <FormItem
              label={t('Please select the company you would like to log in to')}
              labelClass="text-sm font-medium normal-case text-title-gray"
              formFieldName="company"
              component={LabeledSelect}
              options={companies()?.map((company) => ({ type: 'option', label: company.name, value: company.id })) ?? []}
              selectClass="h-14 rounded-full bg-inputbox-bg px-6"
              rules={[{ required: true, message: t('Please select company') }]}
            />
          </Show>
          <Show when={error()}>
            <div class="-mb-2 mt-2 text-center text-danger">{error()}</div>
          </Show>
          <button class="mt-8 flex justify-center rounded-full bg-essential-colour py-3.5 text-sm font-medium text-white transition-colors hover:bg-essential-colour/90">
            <Show when={signIn.pending} fallback={t('Login')}>
              <IconLoader class="size-5 animate-spin" />
            </Show>
          </button>
        </Form>

        <div class="py-3 text-sm text-white/70 lg:text-text-level03">
          @{new Date().getFullYear()} MagicDoor {t('All rights reserved')}
        </div>
      </div>
      <div class="hidden h-full w-3/5 bg-gradient-dark-purple-blue lg:flex">
        <div
          class=" size-full bg-contain bg-right bg-no-repeat"
          style={{
            'background-image': `url(${IconNetwork})`,
          }}>
          <div class="absolute right-4 top-4">
            <img src="/logo.png" alt="Logo" class="h-16" />
          </div>
          <div class="flex h-full flex-col items-center justify-center gap-8 text-center">
            <img src={IconRobot} alt="Robot" class="mx-auto" />

            <div>
              <h1 class="mb-2 text-3xl font-bold text-white">TIME IS MAGIC</h1>
              <p class="text-lg text-white/70">The AI-Enabled Property Management Software That Honors Your Time</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
